/* eslint-disable no-unused-vars,@typescript-eslint/no-explicit-any */
import React from 'react';
import { Box, IconButton, useTheme } from '@mui/material';
import useStyles from './FileContainer.styles.ts';
import { Typo } from 'components/primitive';
import FileIcon from '@mui/icons-material/Description';
import ClearIcon from '@mui/icons-material/Clear';
import ImageIcon from '@mui/icons-material/Image';

type FileContainerProps = {
  name: string;
  bytes: string;
  authToken: string;
  readOnly?: boolean;
  item: any;
  handleImageError: (e: React.SyntheticEvent<HTMLImageElement, Event>) => void;
  downloadFile?: (item: any) => void;
  removeFile?: (item: any) => void;
};

const palmettoBaseURL = import.meta.env.VITE_MAIN_PALMETTO_ENDPOINT;

function FileContainer({ readOnly = true, ...props }: FileContainerProps) {
  const theme = useTheme();
  const classes = useStyles(theme);

  // *: Check if the file is an image
  const isImage =
    props.item && Object.prototype.hasOwnProperty.call(props.item, 'cbrnDataFileName')
      ? props.item.cbrnDataFileName.toLocaleLowerCase().match(/\.(jpeg|jpg|gif|png)$/) != null
      : props.item.file_name.toLocaleLowerCase().match(/\.(jpeg|jpg|gif|png)$/) != null;
  // *: Create the image URL
  const imageURL = Object.prototype.hasOwnProperty.call(props.item, 'cbrnDataFileName')
    ? `${palmettoBaseURL}${props.item.cbrnDataFileURIID}?access_token=${props.authToken}`
    : `${palmettoBaseURL}/api/containers/fallbackbucket/download/${props.item.file_key}?access_token=${props.authToken}`;

  const handleDownload = () => {
    if (props.downloadFile) props.downloadFile(props.item);
    return null;
  };

  const handleRemove = () => {
    if (props.removeFile) props.removeFile(props.item);
    return null;
  };

  return (
    <Box sx={classes.file} onClick={props.downloadFile ? handleDownload : undefined}>
      <Box sx={classes.rowContainer}>
        {isImage ? (
          // If the file is an image, create a thumbnail
          <ImageIcon sx={{ width: '24px', color: theme.palette.grey[500] }} />
        ) : (
          // If the file is not an image, display the file icon
          <FileIcon sx={{ width: '24px', color: theme.palette.grey[500] }} />
        )}
        <Box sx={classes.fileNameWrapper}>
          <Typo sx={classes.fileName}>{props.name}</Typo>
          <Typo sx={classes.fileSize}>{props.bytes}</Typo>
        </Box>
        <Box sx={classes.removeBtn} hidden={readOnly}>
          <IconButton
            aria-label='Delete'
            aria-haspopup='true'
            onClick={props.removeFile ? handleRemove : undefined}>
            <ClearIcon className='icon-small' />
          </IconButton>
        </Box>
      </Box>
      {isImage && (
        <img
          src={imageURL}
          alt={'image'}
          style={{ width: '48px', height: '100%', objectFit: 'contain' }}
          onError={props.handleImageError}
        />
      )}
    </Box>
  );
}

export default FileContainer;
