import React from 'react';
import apiFetch, { genericErrorMessage } from '@/services/apiFetch.ts';
import { RootState, store } from '@/store';
import { getPersonnelDataSets } from '@/services/personnel.endpoint.ts';
import { PersonnelDetails } from 'interfaces/personnel.interface.ts';
import { Await, defer, useAsyncValue, useLoaderData } from 'react-router-dom';
import { Loading } from 'components/primitive';

const GridList = React.lazy(() => import('./Grid.tsx'));

interface PersonnelDataSetResponse {
  data: { count: number; dataset: PersonnelDetails[] };
}

// eslint-disable-next-line react-refresh/only-export-components
export const loaderPersonnelDataSet = () => {
  try {
    const state = store.getState() as RootState;
    const selectedUserGroup = state.accountsInfo.selectedGroupID ?? 0;
    const url = `api/personnel?filters[group_id]=${selectedUserGroup}`;
    const endpoint = getPersonnelDataSets(url);
    const personnelData = apiFetch<PersonnelDetails[]>(endpoint).then((res) => res.data ?? []);
    return defer({
      data: personnelData,
    });
  } catch (error) {
    let message = genericErrorMessage;
    if (error instanceof Error) message = error.message;
    else {
      if (typeof error === 'object' && error !== null) {
        if ('message' in error) {
          message = String(error.message);
        }
      }
    }
    throw Error(message);
  }
};

function RenderAwaitedData() {
  const data = useAsyncValue() as { count: number; dataset: PersonnelDetails[] };
  return <GridList data={data} />;
}

const GridContainer = () => {
  const data = useLoaderData() as PersonnelDataSetResponse;
  return (
    <React.Suspense fallback={<Loading />}>
      <Await resolve={data.data} errorElement={<p>Error loading personnel!</p>}>
        <RenderAwaitedData />
      </Await>
    </React.Suspense>
  );
};

export default GridContainer;
