import React from 'react';
import MuiButton from '@mui/material/Button';
import { MenuButtonProps } from './MenuButton.props';
import useStyles from './MenuButton.styles';

const MenuButton: React.FC<MenuButtonProps> = ({ fade = false, ...props }) => {
  const classes = useStyles(fade);
  return (
    <MuiButton
      {...props}
      sx={!props.issubmenu ? classes.container : classes.subMenuContainer}
      startIcon={props && props?.startIcon}
      endIcon={props && props?.endIcon}
      disableElevation>
      {props.children}
    </MuiButton>
  );
};

export default MenuButton;
