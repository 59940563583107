import { QueryState } from '@/common/enum';
import { Nullable } from 'interfaces/Nullable.ts';

export type EquipmentStateType = {
  queryState: QueryState;
  error: Nullable<string>;
};

export const EquipmentInitialState: EquipmentStateType = {
  queryState: QueryState.UNDEFINED,
  error: null,
};
