import React from 'react';
import FormControl from '@mui/material/FormControl';
import { RadioSelectOptions } from 'interfaces/SelectOptions.interface.ts';
import { CustomInputLabel } from '@zawarski/palmetto-ui-components';
import MSelect, { SelectProps } from '@mui/material/Select';
import InputAdornment from '@mui/material/InputAdornment';
import { FieldError } from 'react-hook-form';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import WarningIcon from '@mui/icons-material/ReportProblemRounded';
import { MapIcon as StateIcon } from '@/assets/icons';

interface SelectMenuProps {
  values: RadioSelectOptions[] | string[];
  withLabel?: boolean;
  withIcon?: boolean;
  label?: string;
  id?: string;
  fieldError?: FieldError | undefined;
  required?: boolean;
  helperText?: string;
  customIcon?: React.ReactNode;
  backgroundColor?: string;
  size?: 'small' | 'medium' | 'large';
}

const SelectMenu: React.FC<SelectMenuProps & SelectProps> = ({
  withLabel = false,
  withIcon = false,
  ...props
}) => {
  const menuProps = {
    PaperProps: {
      style: {
        maxHeight: 300,
        maxWidth: 400,
        paddingTop: 12,
      },
    },
  };

  return (
    <FormControl
      style={{
        width: '100%',
        margin: 0,
        background: props.backgroundColor ? props.backgroundColor : '#FFF',
      }}>
      {withLabel && (
        <CustomInputLabel
          error={Boolean(props.error || props.fieldError)}
          shrink
          htmlFor={props.id}
          style={{ margin: 0 }}>
          {props.label ?? ''} <span className={props.required ? 'required' : ''}></span>{' '}
        </CustomInputLabel>
      )}
      <MSelect
        labelId='demo-multiple-checkbox-label'
        id='demo-multiple-checkbox'
        disabled={props.disabled}
        error={props.error || Boolean(props.fieldError)}
        placeholder={props.placeholder}
        value={props.value}
        onChange={props.onChange}
        MenuProps={menuProps}
        variant={props.variant ? props.variant : 'standard'}
        size={props.size ? props.size : 'medium'}
        sx={{
          marginTop: 0,
          '& .MuiSelect-select': {
            display: 'flex',
            alignItems: 'center',
          },
        }}
        startAdornment={
          withIcon && (
            <InputAdornment position='start'>
              {props.customIcon ? props.customIcon : <WarningIcon sx={{ color: '#1F1F1F' }} />}
            </InputAdornment>
          )
        }>
        {props.values && props.values.length
          ? props.values.map((type, idx) => {
              if (type && typeof type === 'object') {
                return (
                  <MenuItem key={type.value} value={type.value} sx={{ alignItems: 'center' }}>
                    {type.label}
                    {type.withIndicator && <StateIcon color='blue' />}
                  </MenuItem>
                );
              } else {
                return (
                  <MenuItem key={idx} value={type}>
                    {type}
                  </MenuItem>
                );
              }
            })
          : null}
      </MSelect>
      {(Boolean(props.fieldError) || props.error) && (
        <FormHelperText error={Boolean(props.fieldError)} style={{ margin: 0 }}>
          {props.helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default SelectMenu;
