import { createAsyncThunk } from '@reduxjs/toolkit';
import apiFetch, { genericErrorMessage } from '@/services/apiFetch.ts';
import {
  getApplicantDataset,
  getApplicantSettings,
  setApplicantSettings,
} from '@/services/applicants.endpoint.ts';
import {
  ApplicantSettingsFilterType,
  ApplicantSettingsType,
  ApplicantType,
} from 'interfaces/applicants.interface.ts';
import { RootState } from '@/store';

export const fetchSelectedApplicantSettings = createAsyncThunk(
  'FETCH_SELECTED_APPLICANT_SETTINGS',
  async (_, thunkAPI) => {
    try {
      const store = thunkAPI.getState() as RootState;
      const pvAccountID = store.accountsInfo.account?.id;
      const incidentID = store.incidents.selectedIncident?.pvIncidentID;
      const { selectedGroup, selectedGroupID } = store.accountsInfo;
      const activeGroup = selectedGroup ? selectedGroup : selectedGroupID;
      if (!pvAccountID) return Promise.reject('No User Account ID Found');
      if (!incidentID) return Promise.reject('No Incident ID Found');
      const pvSettingType = `selectedApplicantSettings-${incidentID}`;
      const endpoint = getApplicantSettings({ pvSettingType, pvGroupID: Number(activeGroup) });
      return apiFetch<ApplicantSettingsType[]>(endpoint).then((res) => res.data ?? null);
    } catch (error) {
      let message = genericErrorMessage;
      if (error instanceof Error) message = error.message;
      else {
        if (typeof error === 'object' && error !== null) {
          if ('message' in error) {
            message = String(error.message);
          }
        }
      }
      throw Error(message);
    }
  }
);
export const fetchApplicants = createAsyncThunk('FETCH_APPLICANTS', async () => {
  try {
    const endpoint = getApplicantDataset();
    return await apiFetch<{ count: number; dataset: ApplicantType[] }>(endpoint).then(
      (res) => res.data
    );
  } catch (error) {
    let message = genericErrorMessage;
    if (error instanceof Error) message = error.message;
    else {
      if (typeof error === 'object' && error !== null) {
        if ('message' in error) {
          message = String(error.message);
        }
      }
    }
    throw Error(message);
  }
});

export const updateSelectedApplicantSettings = createAsyncThunk(
  'UPDATE_SELECTED_APPLICANT_SETTINGS',
  async (settings: ApplicantType, thunkAPI) => {
    try {
      const store = thunkAPI.getState() as RootState;
      const { account, selectedGroup, selectedGroupID } = store.accountsInfo;
      const pvAccountID = account?.id;
      const applicantSettingsID = store.applicants.userSelectedApplicantSettingID;
      const incidentId = store.incidents.selectedIncident?.pvIncidentID;
      const activeGroup = selectedGroup ? selectedGroup : selectedGroupID;
      if (!pvAccountID) return Promise.reject('No User Account ID Found');
      const payload: ApplicantSettingsFilterType = {
        pvSettingType: `selectedApplicantSettings-${incidentId}`,
        pvSettingValue: JSON.stringify(settings),
        pvGroupID: Number(activeGroup),
        pvAccountID,
      };
      if (applicantSettingsID) payload.pvSettingID = applicantSettingsID;
      const endpoint = setApplicantSettings(payload);
      return apiFetch<ApplicantSettingsType>(endpoint).then((res) => res.data ?? null);
    } catch (error) {
      let message = genericErrorMessage;
      if (error instanceof Error) message = error.message;
      else {
        if (typeof error === 'object' && error !== null) {
          if ('message' in error) {
            message = String(error.message);
          }
        }
      }
      throw Error(message);
    }
  }
);
