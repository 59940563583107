import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks.ts';
import {
  dbQueryStateSelector,
  iaDataSelector,
} from 'store/feature/dashboard/dashboard.selector.ts';
import { BuildingDamage, DamageStructure, QueryState } from '@/common/enum';
import Box from '@mui/material/Box';
import { Loading, Typo } from 'components/primitive';
import { Grid, useTheme } from '@mui/material';
import useStyles from '../IAPage.styles.ts';
import { fetchIAHomePage } from 'store/feature/dashboard/dashboard.thunk.ts';
import { SideInfoCardType } from 'pages/Dashboard/Dashboard.props.ts';
import { BarChart, CardCounter } from 'components/fragment';
import BusinessRoundedIcon from '@mui/icons-material/BusinessRounded';
import OtherHousesIcon from '@mui/icons-material/OtherHouses';
import MapIcon from '@mui/icons-material/Map';
import { GraphLabels } from '@/common/static';
import { EntriesByDamageTypes } from 'interfaces/ia.interface.ts';
import {
  accountGroupIDSelector,
  selectedGroupSelector,
} from 'store/feature/accountsInfo/accountsInfo.selector.ts';
import { formatToolTipData } from 'pages/Dashboard/IAPage/utils';

const CountyView: React.FC = () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(dbQueryStateSelector) === QueryState.AWAIT;
  const iaData = useAppSelector(iaDataSelector);
  const selectedGroup = useAppSelector(selectedGroupSelector);
  const selectedGroupID = useAppSelector(accountGroupIDSelector) ?? 1;
  const activeGroup = selectedGroup ? parseInt(selectedGroup) : selectedGroupID;

  const [countiesCount, setCountiesCount] = useState<number>(0);
  const [businessCount, setBusinessCount] = useState<number>(0);
  const [totalResidenceCount, setTotalResidenceCount] = useState<number>(0);
  const [sideInfo, setSideInfo] = useState<SideInfoCardType[]>([]);

  function formatDataSet(items: EntriesByDamageTypes[]) {
    const arr = [0, 0, 0, 0, 0, 0];
    items.forEach((item) => {
      if (item?.building_damage.toLowerCase() === BuildingDamage.destroyed) {
        arr[0] += item.count;
      }
      if (item?.building_damage.toLowerCase().trim().includes('major')) {
        arr[1] += item.count;
      }
      if (item?.building_damage.toLowerCase().trim().includes('minor')) {
        arr[2] += item.count;
      }
      if (item?.building_damage.toLowerCase().trim().includes('affected')) {
        arr[3] += item.count;
      }
      if (item?.building_damage.toLowerCase().trim().includes('inaccessible')) {
        arr[4] += item.count;
      }
      if (item?.building_damage.toLowerCase().trim().includes('no damage')) {
        arr[5] += item.count;
      }
    });
    return arr;
  }

  useEffect(() => {
    const fetchData = () => dispatch(fetchIAHomePage({ selectedGroupIndex: activeGroup }));
    fetchData();
    // return () => fetchData.abort();
  }, [activeGroup, dispatch]);

  useEffect(() => {
    if (!isLoading && iaData) {
      const reportedCounties =
        iaData.countiesReportingDamage &&
        iaData.countiesReportingDamage.length &&
        iaData.countiesReportingDamage.reduce((acc, cur) => acc + cur.count, 0);
      setCountiesCount(reportedCounties);

      const idxBusiness =
        iaData.entriesByStructureType &&
        iaData.entriesByStructureType.findIndex(
          (item) => item.structure_type.toLowerCase() === DamageStructure.business
        );
      if (idxBusiness !== -1) {
        setBusinessCount(iaData.entriesByStructureType[idxBusiness].count);
      }
      const filteredResidenceData =
        (iaData.entriesByStructureType &&
          iaData.entriesByStructureType.length &&
          iaData.entriesByStructureType.filter(
            (item) => item.structure_type.toLowerCase() !== DamageStructure.business
          )) ||
        [];
      const totalResidence = filteredResidenceData.reduce((acc, cur) => acc + cur.count, 0);
      setTotalResidenceCount(totalResidence);
      const sideInfo: SideInfoCardType[] = filteredResidenceData.map((item) => ({
        title: item.structure_type,
        value: item.count,
      }));
      setSideInfo(sideInfo);
    }
  }, [isLoading, iaData]);

  if (isLoading)
    return (
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          height: '100vh',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <Loading message={''} />
      </Box>
    );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6} sx={classes.gridWrapper}>
        <Typo
          fontWeight={700}
          color={theme.palette.text.secondary}
          sx={{ textTransform: 'uppercase' }}>
          Total entries by structure type
        </Typo>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <CardCounter
              bigCount={businessCount}
              footerTitle={'Business'}
              endIcon={
                <BusinessRoundedIcon
                  sx={{ width: '24px', color: theme.palette.grey[50], opacity: 0.5 }}
                />
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CardCounter
              bigCount={totalResidenceCount}
              withSideInfo={Boolean(sideInfo && sideInfo.length)}
              sideInfo={sideInfo}
              footerTitle={'Residence'}
              endIcon={
                <OtherHousesIcon
                  sx={{ width: '24px', color: theme.palette.grey[50], opacity: 0.5 }}
                />
              }
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={6} sx={classes.gridWrapper}>
        <Typo
          fontWeight={700}
          color={theme.palette.text.secondary}
          sx={{ textTransform: 'uppercase' }}>
          Total Counties reporting damage
        </Typo>
        <CardCounter
          bigCount={countiesCount}
          footerTitle={'Counties'}
          endIcon={<MapIcon sx={{ width: '24px', color: theme.palette.grey[50], opacity: 0.5 }} />}
        />
      </Grid>
      {iaData && iaData?.entriesByDamageType && (
        <Grid item xs={12} md={6} sx={classes.gridWrapper}>
          <Typo
            fontWeight={700}
            color={theme.palette.text.secondary}
            sx={{ textTransform: 'uppercase' }}>
            Total Entries By Damage Type
          </Typo>
          <Box sx={classes.chartContainer}>
            <BarChart
              labels={GraphLabels}
              data={formatDataSet(iaData.entriesByDamageType)}
              tooltipData={formatToolTipData(iaData.entriesByDamageAndStructureType)}
            />
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

export default CountyView;
