import { Theme } from '@mui/material';

const useStyles = (theme: Theme, alternate: boolean) => ({
  container: {
    position: 'relative',
    display: 'flex',
    minHeight: '28px',
    padding: '4px 8px 4px 4px',
    alignItems: 'center',
    gap: '4px',
    flex: '1 0 0',
    borderRadius: '2px',
    background: !alternate ? theme.palette.primary.main : theme.palette.grey[100],
    color: !alternate ? theme.palette.common.white : theme.palette.grey[400],
    fontSize: '12px',
    cursor: 'pointer',
    ':hover': {
      backgroundColor: !alternate ? theme.palette.primary.dark : theme.palette.grey[300],
    },
    ':hover span': {
      color: '#FFF',
    },
  },
  startIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '24px',
    height: '24px',
    padding: '4px',
    bgcolor: !alternate ? theme.palette.primary.dark : theme.palette.grey[200],
  },
  endIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '24px',
    height: '24px',
    padding: '4px',
    marginLeft: 'auto',
  },
});

export default useStyles;
