const useStyles = () => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  wrapper: {
    padding: '16px',
    textAlign: 'center',
    fontSize: '16px',
    lineHeight: '1.2',
    opacity: 0.54,
  },
});

export default useStyles;
