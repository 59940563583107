/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Controller } from 'react-hook-form';
import { HeaderButton, HeaderComponent } from '@zawarski/palmetto-ui-components';
import { Grid, IconButton, InputAdornment, Menu, useTheme } from '@mui/material';
import useStyles from './ShortSummary.styles';
import Box from '@mui/material/Box';
import MenuIcon from '@mui/icons-material/Menu';
import { PageMaxLayout } from 'components/layout';
import {
  DatePickerField,
  DecimalNumber,
  FormContent,
  NumberFormat,
  TextField,
  Typo,
} from 'components/primitive';
import { ShortSummaryViewProps } from './ShortSummary.props.ts';
import moment from 'moment-timezone';
import { ActionButtons, CardCounter } from 'components/fragment';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MenuItem from '@mui/material/MenuItem';
import BackIcon from '@mui/icons-material/ArrowBack';

const ShortSummaryView: React.FC<ShortSummaryViewProps> = ({
  isSaving,
  toggleDrawer,
  readOnly,
  permissions,
  handleEdit,
  handleCancel,
  form,
  anchorEl,
  handleMenuClick,
  handleCloseMenu,
  handlePAPrintShortFormReport,
  handleCatReport,
  handlePrintTable,
  onSubmit,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <Box className={'layout vertical full-height'} style={{ overflowY: 'auto' }}>
      <HeaderComponent
        title='Short form summary'
        icon={
          readOnly ? (
            <IconButton onClick={!isSaving ? () => toggleDrawer() : undefined}>
              <MenuIcon className='icon' />
            </IconButton>
          ) : (
            <IconButton onClick={!isSaving ? () => handleCancel() : undefined}>
              <BackIcon className='icon' />
            </IconButton>
          )
        }
        suffix={
          permissions.UPDATE && (
            <div className='form-margin-16'>
              {readOnly ? (
                <>
                  <HeaderButton onClick={() => handleEdit()}>
                    <Typo fontWeight={600}>EDIT</Typo>
                  </HeaderButton>
                  <IconButton onClick={(e) => handleMenuClick(e)}>
                    <MoreVertIcon className='icon' />
                  </IconButton>
                </>
              ) : null}
            </div>
          )
        }
      />
      <Menu
        id='overflow_menu'
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={(e: React.MouseEvent<HTMLButtonElement>) => handleCloseMenu(e)}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}>
        <MenuItem
          sx={classes.menuWrapper}
          onClick={(e) => {
            e.stopPropagation();
            handlePAPrintShortFormReport();
          }}>
          <Box sx={classes.menuContent}>Print PA Short Form Report</Box>
        </MenuItem>
        <MenuItem
          sx={classes.menuWrapper}
          onClick={(e) => {
            e.stopPropagation();
            handleCatReport();
          }}>
          <Box sx={classes.menuContent}>Print Categories Estimate Report</Box>
        </MenuItem>
        <MenuItem
          sx={classes.menuWrapper}
          onClick={(e) => {
            e.stopPropagation();
            handlePrintTable();
          }}>
          <Box sx={classes.menuContent}>Print PA Table</Box>
        </MenuItem>
      </Menu>
      <PageMaxLayout>
        <FormContent title='General Information' bgcolor={'#FFF'}>
          <Grid container spacing={2} sx={classes.gridContainer}>
            <Grid item xs={12}>
              <Controller
                control={form.control}
                name='population'
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    error={Boolean(error)}
                    errMsg={error}
                    label='Population'
                    placeholder='Enter population'
                    value={value}
                    onChange={onChange}
                    InputProps={{
                      readOnly: readOnly,
                      inputComponent: NumberFormat as any,
                    }}
                    helperTxt={error ? error.message : ''}
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                control={form.control}
                name='total_budget_approved'
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    error={Boolean(error)}
                    errMsg={error}
                    label='Total budget: Approved'
                    placeholder='Enter budget'
                    value={value}
                    onChange={onChange}
                    InputProps={{
                      readOnly: readOnly,
                      startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                      inputComponent: DecimalNumber as any,
                    }}
                    helperTxt={error ? error.message : ''}
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                control={form.control}
                name='total_budget_balance'
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    error={Boolean(error)}
                    errMsg={error}
                    label='Total budget: Balance'
                    placeholder='Enter budget'
                    value={value}
                    onChange={onChange}
                    InputProps={{
                      readOnly: readOnly,
                      startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                      inputComponent: DecimalNumber as any,
                    }}
                    helperTxt={error ? error.message : ''}
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                control={form.control}
                name='maintenance_budget_approved'
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    error={Boolean(error)}
                    errMsg={error}
                    label='Maintenance budget: Approved'
                    placeholder='Enter budget'
                    value={value}
                    onChange={onChange}
                    InputProps={{
                      readOnly: readOnly,
                      startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                      inputComponent: DecimalNumber as any,
                    }}
                    helperTxt={error ? error.message : ''}
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                control={form.control}
                name='maintenance_budget_balance'
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    error={Boolean(error)}
                    errMsg={error}
                    label='Maintenance budget: Balance'
                    placeholder='Enter budget'
                    value={value}
                    onChange={onChange}
                    InputProps={{
                      readOnly: readOnly,
                      startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                      inputComponent: DecimalNumber as any,
                    }}
                    helperTxt={error ? error.message : ''}
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={form.control}
                name='fiscal_year_start'
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <DatePickerField
                    label='Date fiscal year begins'
                    format={'MMMM DD, YYYY'}
                    value={moment.utc(value)}
                    onChange={onChange}
                    errMsg={error}
                    helperTxt={error ? error.message : ''}
                    readOnly={readOnly}
                  />
                )}
              />
            </Grid>
          </Grid>
        </FormContent>
        <FormContent title='Category A: Debris Clearance' bgcolor={'#FFF'}>
          <Grid container spacing={2} sx={classes.gridContainer}>
            <Grid item xs={12} md={4}>
              <Controller
                control={form.control}
                name='cat_a_num'
                render={({ field: { value } }) => (
                  <CardCounter
                    center
                    bigCount={Number(value) ?? 0}
                    titleVariant={'h2'}
                    footerTitle={'Number of Sites'}
                    footerVariant={'body1'}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                control={form.control}
                name='cat_a_applicant_estimate'
                render={({ field: { value } }) => (
                  <CardCounter
                    isDollar
                    bigCount={Number(value) ?? 0}
                    titleVariant={'h2'}
                    footerTitle={'Total estimate: applicant'}
                    footerVariant={'body1'}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                control={form.control}
                name='cat_a_team_estimate'
                render={({ field: { value } }) => (
                  <CardCounter
                    isDollar
                    bigCount={Number(value) ?? 0}
                    titleVariant={'h2'}
                    footerTitle={'Total estimate: team'}
                    footerVariant={'body1'}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={form.control}
                name='cat_a_local_funds'
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    error={Boolean(error)}
                    errMsg={error}
                    label='Potential local funds available'
                    placeholder='Enter funds available'
                    value={value}
                    onChange={onChange}
                    InputProps={{
                      readOnly: readOnly,
                      startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                      inputComponent: DecimalNumber as any,
                    }}
                    helperTxt={error ? error.message : ''}
                    fullWidth
                  />
                )}
              />
            </Grid>
          </Grid>
        </FormContent>
        <FormContent title='Category B: Protective Measures' bgcolor={'#FFF'}>
          <Grid container spacing={2} sx={classes.gridContainer}>
            <Grid item xs={12} md={4}>
              <Controller
                control={form.control}
                name='cat_b_num'
                render={({ field: { value } }) => (
                  <CardCounter
                    center
                    bigCount={Number(value) ?? 0}
                    titleVariant={'h2'}
                    footerTitle={'Number of Sites'}
                    footerVariant={'body1'}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                control={form.control}
                name='cat_b_applicant_estimate'
                render={({ field: { value } }) => (
                  <CardCounter
                    isDollar
                    bigCount={Number(value) ?? 0}
                    titleVariant={'h2'}
                    footerTitle={'Total estimate: applicant'}
                    footerVariant={'body1'}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                control={form.control}
                name='cat_b_team_estimate'
                render={({ field: { value } }) => (
                  <CardCounter
                    isDollar
                    bigCount={Number(value) ?? 0}
                    titleVariant={'h2'}
                    footerTitle={'Total estimate: team'}
                    footerVariant={'body1'}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={form.control}
                name='cat_b_local_funds'
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    error={Boolean(error)}
                    errMsg={error}
                    label='Potential local funds available'
                    placeholder='Enter funds available'
                    value={value}
                    onChange={onChange}
                    InputProps={{
                      readOnly: readOnly,
                      startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                      inputComponent: DecimalNumber as any,
                    }}
                    helperTxt={error ? error.message : ''}
                    fullWidth
                  />
                )}
              />
            </Grid>
          </Grid>
        </FormContent>
        <FormContent title='Category C: Road System' bgcolor={'#FFF'}>
          <Grid container spacing={2} sx={classes.gridContainer}>
            <Grid item xs={12} md={4}>
              <Controller
                control={form.control}
                name='cat_c_num'
                render={({ field: { value } }) => (
                  <CardCounter
                    center
                    bigCount={Number(value) ?? 0}
                    titleVariant={'h2'}
                    footerTitle={'Number of Sites'}
                    footerVariant={'body1'}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                control={form.control}
                name='cat_c_applicant_estimate'
                render={({ field: { value } }) => (
                  <CardCounter
                    isDollar
                    bigCount={Number(value) ?? 0}
                    titleVariant={'h2'}
                    footerTitle={'Total estimate: applicant'}
                    footerVariant={'body1'}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                control={form.control}
                name='cat_c_team_estimate'
                render={({ field: { value } }) => (
                  <CardCounter
                    isDollar
                    bigCount={Number(value) ?? 0}
                    titleVariant={'h2'}
                    footerTitle={'Total estimate: team'}
                    footerVariant={'body1'}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={form.control}
                name='cat_c_local_funds'
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    error={Boolean(error)}
                    errMsg={error}
                    label='Potential local funds available'
                    placeholder='Enter funds available'
                    value={value}
                    onChange={onChange}
                    InputProps={{
                      readOnly: readOnly,
                      startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                      inputComponent: DecimalNumber as any,
                    }}
                    helperTxt={error ? error.message : ''}
                    fullWidth
                  />
                )}
              />
            </Grid>
          </Grid>
        </FormContent>
        <FormContent title='Category D: Water Control Facilities' bgcolor={'#FFF'}>
          <Grid container spacing={2} sx={classes.gridContainer}>
            <Grid item xs={12} md={4}>
              <Controller
                control={form.control}
                name='cat_d_num'
                render={({ field: { value } }) => (
                  <CardCounter
                    center
                    bigCount={Number(value) ?? 0}
                    titleVariant={'h2'}
                    footerTitle={'Number of Sites'}
                    footerVariant={'body1'}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                control={form.control}
                name='cat_d_applicant_estimate'
                render={({ field: { value } }) => (
                  <CardCounter
                    isDollar
                    bigCount={Number(value) ?? 0}
                    titleVariant={'h2'}
                    footerTitle={'Total estimate: applicant'}
                    footerVariant={'body1'}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                control={form.control}
                name='cat_d_team_estimate'
                render={({ field: { value } }) => (
                  <CardCounter
                    isDollar
                    bigCount={Number(value) ?? 0}
                    titleVariant={'h2'}
                    footerTitle={'Total estimate: team'}
                    footerVariant={'body1'}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={form.control}
                name='cat_d_local_funds'
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    error={Boolean(error)}
                    errMsg={error}
                    label='Potential local funds available'
                    placeholder='Enter funds available'
                    value={value}
                    onChange={onChange}
                    InputProps={{
                      readOnly: readOnly,
                      startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                      inputComponent: DecimalNumber as any,
                    }}
                    helperTxt={error ? error.message : ''}
                    fullWidth
                  />
                )}
              />
            </Grid>
          </Grid>
        </FormContent>
        <FormContent title='Category E: Public Buildings and Equipments' bgcolor={'#FFF'}>
          <Grid container spacing={2} sx={classes.gridContainer}>
            <Grid item xs={12} md={4}>
              <Controller
                control={form.control}
                name='cat_e_num'
                render={({ field: { value } }) => (
                  <CardCounter
                    center
                    bigCount={Number(value) ?? 0}
                    titleVariant={'h2'}
                    footerTitle={'Number of Sites'}
                    footerVariant={'body1'}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                control={form.control}
                name='cat_e_applicant_estimate'
                render={({ field: { value } }) => (
                  <CardCounter
                    isDollar
                    bigCount={Number(value) ?? 0}
                    titleVariant={'h2'}
                    footerTitle={'Total estimate: applicant'}
                    footerVariant={'body1'}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                control={form.control}
                name='cat_e_team_estimate'
                render={({ field: { value } }) => (
                  <CardCounter
                    isDollar
                    bigCount={Number(value) ?? 0}
                    titleVariant={'h2'}
                    footerTitle={'Total estimate: team'}
                    footerVariant={'body1'}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={form.control}
                name='cat_e_local_funds'
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    error={Boolean(error)}
                    errMsg={error}
                    label='Potential local funds available'
                    placeholder='Enter funds available'
                    value={value}
                    onChange={onChange}
                    InputProps={{
                      readOnly: readOnly,
                      startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                      inputComponent: DecimalNumber as any,
                    }}
                    helperTxt={error ? error.message : ''}
                    fullWidth
                  />
                )}
              />
            </Grid>
          </Grid>
        </FormContent>
        <FormContent title='Category F: Public Utilities' bgcolor={'#FFF'}>
          <Grid container spacing={2} sx={classes.gridContainer}>
            <Grid item xs={12} md={4}>
              <Controller
                control={form.control}
                name='cat_f_num'
                render={({ field: { value } }) => (
                  <CardCounter
                    center
                    bigCount={Number(value) ?? 0}
                    titleVariant={'h2'}
                    footerTitle={'Number of Sites'}
                    footerVariant={'body1'}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                control={form.control}
                name='cat_f_applicant_estimate'
                render={({ field: { value } }) => (
                  <CardCounter
                    isDollar
                    bigCount={Number(value) ?? 0}
                    titleVariant={'h2'}
                    footerTitle={'Total estimate: applicant'}
                    footerVariant={'body1'}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                control={form.control}
                name='cat_f_team_estimate'
                render={({ field: { value } }) => (
                  <CardCounter
                    isDollar
                    bigCount={Number(value) ?? 0}
                    titleVariant={'h2'}
                    footerTitle={'Total estimate: team'}
                    footerVariant={'body1'}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={form.control}
                name='cat_f_local_funds'
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    error={Boolean(error)}
                    errMsg={error}
                    label='Potential local funds available'
                    placeholder='Enter funds available'
                    value={value}
                    onChange={onChange}
                    InputProps={{
                      readOnly: readOnly,
                      startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                      inputComponent: DecimalNumber as any,
                    }}
                    helperTxt={error ? error.message : ''}
                    fullWidth
                  />
                )}
              />
            </Grid>
          </Grid>
        </FormContent>
        <FormContent title='Category G: Parks/Other' bgcolor={'#FFF'}>
          <Grid container spacing={2} sx={classes.gridContainer}>
            <Grid item xs={12} md={4}>
              <Controller
                control={form.control}
                name='cat_g_num'
                render={({ field: { value } }) => (
                  <CardCounter
                    center
                    bigCount={Number(value) ?? 0}
                    titleVariant={'h2'}
                    footerTitle={'Number of Sites'}
                    footerVariant={'body1'}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                control={form.control}
                name='cat_g_applicant_estimate'
                render={({ field: { value } }) => (
                  <CardCounter
                    isDollar
                    bigCount={Number(value) ?? 0}
                    titleVariant={'h2'}
                    footerTitle={'Total estimate: applicant'}
                    footerVariant={'body1'}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                control={form.control}
                name='cat_g_team_estimate'
                render={({ field: { value } }) => (
                  <CardCounter
                    isDollar
                    bigCount={Number(value) ?? 0}
                    titleVariant={'h2'}
                    footerTitle={'Total estimate: team'}
                    footerVariant={'body1'}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={form.control}
                name='cat_g_local_funds'
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    error={Boolean(error)}
                    errMsg={error}
                    label='Potential local funds available'
                    placeholder='Enter funds available'
                    value={value}
                    onChange={onChange}
                    InputProps={{
                      readOnly: readOnly,
                      startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                      inputComponent: DecimalNumber as any,
                    }}
                    helperTxt={error ? error.message : ''}
                    fullWidth
                  />
                )}
              />
            </Grid>
          </Grid>
        </FormContent>
        <Box sx={{ display: 'flex', width: '100%', minHeight: '60px' }} />
      </PageMaxLayout>
      <ActionButtons
        readonly={readOnly}
        isSaving={isSaving}
        withSave
        save={!isSaving ? form.handleSubmit(onSubmit) : undefined}
      />
    </Box>
  );
};

export default ShortSummaryView;
