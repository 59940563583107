import { TableColumns } from 'interfaces/MuiTableTypes.ts';
import { convertToTitleCase, DateTimeFormat, HasFiles } from 'utils/FomattingUtils.ts';
import { StatusCell } from 'components/primitive';

const minWidth = 200;
const COLUMNS: TableColumns[] = [
  {
    flex: 1,
    field: 'applicant_name',
    numeric: false,
    headerName: 'Applicant',
    enabled: true,
    minWidth,
  },
  {
    flex: 1,
    field: 'structure_type',
    numeric: false,
    headerName: 'Structure Type',
    enabled: true,
    minWidth,
    renderCell: (param) => convertToTitleCase(param.value ?? ''),
  },
  {
    flex: 1,
    field: 'parcel_id',
    numeric: false,
    headerName: 'Parcel ID',
    enabled: true,
    minWidth,
  },
  {
    flex: 1,
    field: 'address',
    numeric: false,
    headerName: 'Address',
    enabled: true,
    minWidth,
  },
  {
    flex: 1,
    field: 'occupant_name',
    numeric: false,
    headerName: 'Occupant Name',
    enabled: true,
    minWidth,
  },
  {
    flex: 1,
    field: 'building_damage',
    numeric: false,
    headerName: 'Building Damage',
    enabled: true,
    minWidth,
    renderCell: (param) => convertToTitleCase(param.value ?? ''),
  },
  {
    flex: 1,
    field: 'posting',
    numeric: false,
    headerName: 'Posting',
    enabled: true,
    minWidth,
    renderCell: (param) => StatusCell({ value: param.value }),
  },
  {
    flex: 1,
    field: 'estimated_loss_fmt',
    numeric: false,
    headerName: 'Estimated Loss',
    enabled: false,
    minWidth,
  },
  {
    flex: 1,
    field: 'assessment_team_member',
    numeric: false,
    headerName: 'Assessment Team Member',
    enabled: false,
    minWidth,
  },
  {
    flex: 1,
    field: 'updated_at',
    numeric: false,
    headerName: 'Last Updated Date/Time',
    enabled: true,
    minWidth,
    renderCell: (param) => DateTimeFormat({ dte: param.value, format: 'MM/DD/YYYY hh:mm:ss A' }),
  },
  {
    flex: 1,
    field: 'address2',
    numeric: false,
    headerName: 'Address 2',
    enabled: false,
    minWidth,
  },
  {
    flex: 1,
    field: 'city',
    numeric: false,
    headerName: 'City',
    enabled: false,
    minWidth,
  },
  {
    flex: 1,
    field: 'county',
    numeric: false,
    headerName: 'County',
    enabled: false,
    minWidth,
  },
  {
    flex: 1,
    field: 'latitude',
    numeric: false,
    headerName: 'Latitude',
    enabled: false,
    minWidth,
  },
  {
    flex: 1,
    field: 'longitude',
    numeric: false,
    headerName: 'Longitude',
    enabled: false,
    minWidth,
  },
  {
    flex: 1,
    field: 'residence_status',
    numeric: false,
    headerName: 'Occupancy type',
    enabled: false,
    minWidth,
  },
  {
    flex: 1,
    field: 'house_type',
    numeric: false,
    headerName: 'Occupancy status',
    enabled: false,
    minWidth,
  },
  {
    flex: 1,
    field: 'utilities_out',
    numeric: false,
    headerName: 'Utilities out',
    enabled: false,
    minWidth,
  },
  {
    flex: 1,
    field: 'water_level',
    numeric: false,
    headerName: 'Water Level',
    enabled: false,
    minWidth,
  },
  {
    flex: 1,
    field: 'insurance_type',
    numeric: false,
    headerName: 'Insurance types',
    enabled: false,
    minWidth,
  },
  {
    flex: 1,
    field: 'comments',
    numeric: false,
    headerName: 'Comments',
    enabled: false,
    minWidth,
  },
  {
    flex: 1,
    field: 'zipCode',
    numeric: false,
    headerName: 'Zip Code',
    enabled: false,
    minWidth,
  },
  {
    flex: 1,
    field: 'has_files',
    numeric: false,
    headerName: 'Contains Files',
    enabled: true,
    minWidth,
    renderCell: (param) => HasFiles(param.value),
  },
];

export default COLUMNS;
