import { Theme } from '@mui/material';

const useStyles = (theme: Theme) => ({
  baseStyle: {
    display: 'flex',
    width: '100%',
    padding: '32px 48px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'space-between',
    alignSelf: 'stretch',
    gap: '8px',
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    outline: 'none',
    transition: 'border .24s ease-in-out',
  },
  focusedStyle: {
    borderColor: '#2196f3',
  },
  acceptStyle: {
    borderColor: '#2196f3',
  },
  rejectStyle: {
    borderColor: theme.palette.error.main,
  },
  fileUploadTitle: {
    color: theme.palette.common.black,
    textAlign: 'center',
    fontSize: '14px',
    fontWeight: '700',
    textTransform: 'uppercase',
  },
  fileUploadSubTitle: {
    color: theme.palette.common.black,
    textAlign: 'center',
    fontSize: '12px',
    fontWeight: '400',
    textTransform: 'uppercase',
  },
  btnContainer: {
    position: 'absolute',
    top: 6,
    right: 6,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '8px',
    padding: '4px 8px',
    borderRadius: '4px',
    bgcolor: theme.palette.primary.light,
    color: theme.palette.primary.main,
    fontSize: '14px',
    cursor: 'pointer',
  },
  errorContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  errorText: {
    color: theme.palette.error.main,
    fontWeight: 600,
    fontSize: '16px',
  },
});
export default useStyles;
