import React from 'react';
import { Await, defer, useAsyncValue, useLoaderData } from 'react-router-dom';
import { EquipmentDetails } from 'interfaces/equipment.interface.ts';
import apiFetch, { genericErrorMessage } from '@/services/apiFetch.ts';
import { RootState, store } from '@/store';
import { getEquipmentDataSet } from '@/services/equipment.endpoint.ts';
import { Loading } from 'components/primitive';

const GridList = React.lazy(() => import('./Grid.tsx'));

interface EquipmentDataSetResponse {
  data: { count: number; dataset: EquipmentDetails[] };
}

// eslint-disable-next-line react-refresh/only-export-components
export const loaderEquipmentDataSet = () => {
  try {
    const state = store.getState() as RootState;
    const selectedGroup = state.accountsInfo.selectedGroupID ?? 0;
    const url = `api/equipment?filters[group_id]=${selectedGroup}&offset=0&limit=5000`;
    const endpoint = getEquipmentDataSet(url);
    const equipmentData = apiFetch<{
      count: number;
      dataset: EquipmentDetails[];
    }>(endpoint).then((res) => res.data ?? { count: 0, dataset: [] });
    return defer({
      data: equipmentData,
    });
  } catch (error) {
    let message = genericErrorMessage;
    if (error instanceof Error) message = error.message;
    else {
      if (typeof error === 'object' && error !== null) {
        if ('message' in error) {
          message = String(error.message);
        }
      }
    }
    throw Error(message);
  }
};

function RenderAwaitedData() {
  const data = useAsyncValue() as { count: number; dataset: EquipmentDetails[] };
  return <GridList data={data} />;
}

const GridContainer = () => {
  const data = useLoaderData() as EquipmentDataSetResponse;
  return (
    <React.Suspense fallback={<Loading />}>
      <Await resolve={data.data} errorElement={<p>Error loading equipment!</p>}>
        <RenderAwaitedData />
      </Await>
    </React.Suspense>
  );
};

export default GridContainer;
