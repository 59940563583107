import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { object, string } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Nullable } from 'interfaces/Nullable.ts';

export interface IGenInfoFormData {
  id?: string;
  user_id?: number;
  group_id?: number;
  date: Date;
  county: string;
  applicant_name: string;
  applicant_email: string;
  applicant_phone_number: string;
  safety_hazard_description: string;
  population_description: string;
  damage_economic: Nullable<string>;
  repair_funds_search: Nullable<string>;
  repair_speed: Nullable<string>;
  public_service_impact: Nullable<string>;
  damage_cost_corps: Nullable<number>;
  damage_cost_conservation: Nullable<number>;
  damage_cost_highway: Nullable<number>;
  repair_damage_description: Nullable<string>;
}

export const defaultValues: IGenInfoFormData = {
  date: new Date(),
  county: '',
  applicant_name: '',
  applicant_email: '',
  applicant_phone_number: '',
  safety_hazard_description: '',
  population_description: '',
  damage_economic: null,
  repair_damage_description: null,
  repair_funds_search: null,
  repair_speed: null,
  public_service_impact: null,
  damage_cost_corps: null,
  damage_cost_conservation: null,
  damage_cost_highway: null,
};

function useGenInfoForm() {
  const validationSchema = useMemo(
    () =>
      object().shape({
        date: string(),
        county: string(),
        applicant_name: string(),
        applicant_email: string().email('Invalid email address'),
      }),
    []
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return useForm<any>({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });
}

export default useGenInfoForm;
