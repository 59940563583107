import { EntriesByStructureTypes } from 'interfaces/ia.interface.ts';
import { DamageStructure } from '@/common/enum';

function TotalCountByStructureType(
  structureTypes: EntriesByStructureTypes[],
  isNotStructureType: DamageStructure
) {
  return structureTypes.reduce((acc, cur) => {
    if (cur.structure_type.toLowerCase() !== isNotStructureType) {
      acc += cur.count;
    }
    return acc;
  }, 0);
}

function GetCountByStructureType(
  structureTypes: EntriesByStructureTypes[],
  structureType: DamageStructure
) {
  const idx = structureTypes.findIndex(
    (item) => item.structure_type.toLowerCase() === structureType
  );
  if (idx !== -1) {
    return structureTypes[idx].count;
  } else return 0;
}

function GenerateSideInfo(structureTypes: EntriesByStructureTypes[]) {
  return structureTypes
    .filter((item) => item.structure_type.toLowerCase() !== DamageStructure.business)
    .map((item) => ({
      title: item.structure_type,
      value: item.count,
    }));
}

export { TotalCountByStructureType, GenerateSideInfo, GetCountByStructureType };
