import React from 'react';
import { Await, defer, LoaderFunctionArgs, useAsyncValue, useLoaderData } from 'react-router-dom';
import apiFetch, { genericErrorMessage } from '@/services/apiFetch.ts';
import { getEquipmentData } from '@/services/equipment.endpoint.ts';
import { EquipmentDetails } from 'interfaces/equipment.interface.ts';
import { Loading } from 'components/primitive';
import { ErrorHandler } from 'components/fragment';

const DetailsPage = React.lazy(() => import('./Details.tsx'));

export interface EquipmentDataResponse {
  data: EquipmentDetails | null;
}

// eslint-disable-next-line react-refresh/only-export-components
export const loaderEqDetails = ({ params }: LoaderFunctionArgs) => {
  try {
    const { id } = params;
    const url = `/api/equipment/${id}`;
    const endpoint = getEquipmentData(url);
    const eqData = apiFetch<EquipmentDetails>(endpoint).then((res) => res.data ?? null);
    return defer({
      data: eqData,
    });
  } catch (error) {
    let message = genericErrorMessage;
    if (error instanceof Error) message = error.message;
    else {
      if (typeof error === 'object' && error !== null) {
        if ('message' in error) {
          message = String(error.message);
        }
      }
    }
    throw Error(message);
  }
};

function RenderAwaitedData() {
  const data = useAsyncValue() as EquipmentDetails;
  return <DetailsPage data={data} />;
}

const DetailsContainer: React.FC = () => {
  const data = useLoaderData() as EquipmentDataResponse;
  return (
    <React.Suspense fallback={<Loading />}>
      <Await
        resolve={data.data}
        errorElement={<ErrorHandler headerTitle='Equipment' errorMsg='Something went wrong' />}>
        <RenderAwaitedData />
      </Await>
    </React.Suspense>
  );
};

export default DetailsContainer;
