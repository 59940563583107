import type IEndPoint from 'interfaces/EndPointTypes.ts';
import { AuthProtect, BaseURLSelect } from '@/common/enum';
import {
  CatBCostContractObj,
  CatBEquipmentObj,
  CatBLaborObj,
  CatBMaterialsObj,
  CatBRentalObj,
} from 'interfaces/category.interface.ts';

export const getCatBCost = (): IEndPoint => ({
  authorization: AuthProtect.REQUIRED,
  method: 'GET',
  url: '/api/catB/cost',
  defaultBaseURL: BaseURLSelect.DMA,
});

export const getCatBLaborNames = (url: string): IEndPoint => ({
  authorization: AuthProtect.REQUIRED,
  method: 'GET',
  url,
  defaultBaseURL: BaseURLSelect.DMA,
});

export const getCatBLaborData = (url: string): IEndPoint => ({
  authorization: AuthProtect.REQUIRED,
  method: 'GET',
  url,
  defaultBaseURL: BaseURLSelect.DMA,
});

export const saveCatBLabor = (url: string, data: CatBLaborObj): IEndPoint => ({
  authorization: AuthProtect.REQUIRED,
  method: data?.id ? 'PUT' : 'POST',
  url,
  data,
  defaultBaseURL: BaseURLSelect.DMA,
});

export const deleteCatBLabor = (id: string): IEndPoint => ({
  authorization: AuthProtect.REQUIRED,
  method: 'DELETE',
  url: `/api/catB/labor/${id}`,
  defaultBaseURL: BaseURLSelect.DMA,
});

export const getCatBEquipmentNames = (url: string): IEndPoint => ({
  authorization: AuthProtect.REQUIRED,
  method: 'GET',
  url,
  defaultBaseURL: BaseURLSelect.DMA,
});

export const getCatBEquipmentData = (url: string): IEndPoint => ({
  authorization: AuthProtect.REQUIRED,
  method: 'GET',
  url,
  defaultBaseURL: BaseURLSelect.DMA,
});

export const saveCatBEquipment = (url: string, data: CatBEquipmentObj): IEndPoint => ({
  authorization: AuthProtect.REQUIRED,
  method: data?.id ? 'PUT' : 'POST',
  url,
  data,
  defaultBaseURL: BaseURLSelect.DMA,
});

export const deleteCatBEquipment = (id: string): IEndPoint => ({
  authorization: AuthProtect.REQUIRED,
  method: 'DELETE',
  url: `/api/catB/equipment/${id}`,
  defaultBaseURL: BaseURLSelect.DMA,
});

export const getCatBMaterials = (id: string): IEndPoint => ({
  authorization: AuthProtect.REQUIRED,
  method: 'GET',
  url: `/api/catB/materials/${id}`,
  defaultBaseURL: BaseURLSelect.DMA,
});

export const saveCatBMaterials = (url: string, data: CatBMaterialsObj): IEndPoint => ({
  authorization: AuthProtect.REQUIRED,
  method: data?.id ? 'PUT' : 'POST',
  url,
  data,
  defaultBaseURL: BaseURLSelect.DMA,
});

export const deleteCatBMaterials = (id: string): IEndPoint => ({
  authorization: AuthProtect.REQUIRED,
  method: 'DELETE',
  url: `/api/catB/materials/${id}`,
  defaultBaseURL: BaseURLSelect.DMA,
});

export const getCatBRentals = (id: string): IEndPoint => ({
  authorization: AuthProtect.REQUIRED,
  method: 'GET',
  url: `/api/catB/rental/${id}`,
  defaultBaseURL: BaseURLSelect.DMA,
});

export const saveCatBRentals = (url: string, data: CatBRentalObj): IEndPoint => ({
  authorization: AuthProtect.REQUIRED,
  method: data?.id ? 'PUT' : 'POST',
  url,
  data,
  defaultBaseURL: BaseURLSelect.DMA,
});

export const deleteCatBRentals = (id: string): IEndPoint => ({
  authorization: AuthProtect.REQUIRED,
  method: 'DELETE',
  url: `/api/catB/rental/${id}`,
  defaultBaseURL: BaseURLSelect.DMA,
});

export const getCatBContracts = (id: string): IEndPoint => ({
  authorization: AuthProtect.REQUIRED,
  method: 'GET',
  url: `/api/catB/contracts/${id}`,
  defaultBaseURL: BaseURLSelect.DMA,
});

export const saveCatBContracts = (url: string, data: CatBCostContractObj): IEndPoint => ({
  authorization: AuthProtect.REQUIRED,
  method: data?.id ? 'PUT' : 'POST',
  url,
  data,
  defaultBaseURL: BaseURLSelect.DMA,
});

export const deleteCatBContracts = (id: string): IEndPoint => ({
  authorization: AuthProtect.REQUIRED,
  method: 'DELETE',
  url: `/api/catB/contracts/${id}`,
  defaultBaseURL: BaseURLSelect.DMA,
});
