import { createAsyncThunk } from '@reduxjs/toolkit';
import apiFetch, { genericErrorMessage } from '@/services/apiFetch.ts';
import type { RootState } from '@/store';
import { SitePermissionTypes, UserType } from '@/common/enum';
import { deleteIA, getIADataSet, postIAData } from '@/services/ia.endpoint.ts';
import { IADataSet, IADetails } from 'interfaces/ia.interface.ts';

export const fetchIADataSet = createAsyncThunk(
  'ia/fetchIADataSet',
  async (payload: { incidentID?: number } | undefined, thunkAPI) => {
    try {
      const store = thunkAPI.getState() as RootState;
      const { selectedGroupID, selectedGroup, allSubGroups, userType } = store.accountsInfo;
      const { selectedIncident } = store.incidents;
      if (!userType || !selectedIncident) return;
      const inc = payload?.incidentID ?? selectedIncident.pvIncidentID;
      const activeGroupID = selectedGroup ? Number(selectedGroup) : selectedGroupID;
      let url = `/api/ia?filters[incident_id]=${inc}&filters[group_id]=${activeGroupID}`;
      if (userType === UserType.STATE_USER) {
        url = `/api/ia_state?filters[incident_id]=${inc}&filters[group_id]=${activeGroupID}`;
      }
      if (allSubGroups && allSubGroups.length) {
        allSubGroups.forEach((subgroup) => {
          url += `&filters[group_id]=${subgroup.pvGroupID}`;
        });
      }
      const defaultURL = url;
      const endpoint = getIADataSet(`${url}&limit=2`);
      const dataResponse = await apiFetch<IADataSet>(endpoint).then((res) => res.data ?? null);
      // *: MANUAL PAGINATION FETCH FOR IA DATA CHUNK OF 500
      const limit = 500;
      const totalCount = dataResponse?.count ?? 0;
      const totalPages = Math.ceil(totalCount / limit);
      const combinedData = [];
      for (let i = 0; i < totalPages; i++) {
        const offset = i * limit;
        const endpoint = getIADataSet(`${defaultURL}&limit=${limit}&offset=${offset}`);
        const dataResponse = await apiFetch<IADataSet>(endpoint).then((res) => res.data ?? null);
        combinedData.push(...(dataResponse?.dataset ?? []));
      }
      return {
        dataset: combinedData,
        count: totalCount,
      };
    } catch (error) {
      let message = genericErrorMessage;
      if (error instanceof Error) message = error.message;
      else {
        if (typeof error === 'object' && error !== null) {
          if ('message' in error) {
            message = String(error.message);
          }
        }
      }
      throw Error(message);
    }
  }
);
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const saveIAData = createAsyncThunk('ia/saveIAData', async (payload: any, thunkAPI) => {
  try {
    const store = thunkAPI.getState() as RootState;
    const { userType } = store.accountsInfo;
    const { accessType } = store.authToken;
    const { isJPDA_IA_Active } = store.incidents;
    if (!userType) return;
    let isNew = true;
    let url: string = '/api';
    let urlState: string = '/api/ia_state';
    // *: THE RULE IS:
    // When an IA entry is updated if county IS NOT IN JPDA active status,
    // update the corresponding ia_state entry.
    // ia_state editing -- it can only be done when the county IS IN JPDA active status
    // If the county is in JPDA active status, new IA state entries will be created in ia_state only
    if (userType === UserType.STATE_USER) {
      if (
        !isJPDA_IA_Active &&
        (accessType === SitePermissionTypes.L || accessType === SitePermissionTypes.A)
      ) {
        url += '/ia';
      } else {
        url += '/ia_state';
      }
    } else url += '/ia';

    if (payload.id) {
      url += `/${payload.id}`;
      urlState += `/${payload.id}`;
      isNew = false;
    }

    const endpoint_IA = postIAData(url, payload);
    const endpoint_IA_STATE = postIAData(urlState, payload);
    if (
      userType === UserType.STATE_USER &&
      !isNew &&
      !isJPDA_IA_Active &&
      (accessType === SitePermissionTypes.L || accessType === SitePermissionTypes.A)
    ) {
      // *: Update ia_state entry
      await apiFetch<{ success: boolean }>(endpoint_IA_STATE).then((res) => res.data ?? null);
    }
    return await apiFetch<{ success: boolean }>(endpoint_IA).then((res) => res.data ?? null);
  } catch (error) {
    let message = genericErrorMessage;
    if (error instanceof Error) message = error.message;
    else {
      if (typeof error === 'object' && error !== null) {
        if ('message' in error) {
          message = String(error.message);
        }
      }
    }
    throw Error(message);
  }
});

export const getIADataFiltered = createAsyncThunk(
  'ia/getIADataFiltered',
  (payload: { parcelID?: string; entryId?: string }, thunkAPI) => {
    const store = thunkAPI.getState() as RootState;
    if (payload.parcelID) {
      const filteredData = store.ia.iaDataset?.dataset.filter(
        (item) => item.parcel_id === payload.parcelID
      );
      return filteredData?.sort((a, b) => {
        const dateA = new Date(a.created_at);
        const dateB = new Date(b.created_at);
        return dateB.getTime() - dateA.getTime();
      });
    }
    if (payload.entryId) {
      const filteredData = store.ia.iaDataset?.dataset.filter(
        (item) => item.id === payload.entryId
      );
      return filteredData?.sort((a, b) => {
        const dateA = new Date(a.created_at);
        const dateB = new Date(b.created_at);
        return dateB.getTime() - dateA.getTime();
      });
    }
  }
);

export const deleteIAData = createAsyncThunk('ia/DeleteIAData', async (id: string, thunkAPI) => {
  try {
    const store = thunkAPI.getState() as RootState;
    const { userType } = store.accountsInfo;
    const { accessType } = store.authToken;
    const { isJPDA_IA_Active } = store.incidents;
    let url: string = '/api';
    const urlState: string = `/api/ia_state/${id}`;
    if (userType === UserType.STATE_USER) {
      if (
        !isJPDA_IA_Active &&
        (accessType === SitePermissionTypes.L || accessType === SitePermissionTypes.A)
      ) {
        url += '/ia';
      } else {
        url += '/ia_state';
      }
    } else url += '/ia';

    url += `/${id}`;

    const endpoint = deleteIA(url);
    if (
      userType === UserType.STATE_USER &&
      !isJPDA_IA_Active &&
      (accessType === SitePermissionTypes.L || accessType === SitePermissionTypes.A)
    ) {
      // *: Delete ia_state entry
      await apiFetch<{ success: boolean }>(deleteIA(urlState)).then((res) => res.data ?? null);
    }
    return await apiFetch<IADetails | []>(endpoint).then((res) => res.data ?? null);
  } catch (error) {
    let message = genericErrorMessage;
    if (error instanceof Error) message = error.message;
    else {
      if (typeof error === 'object' && error !== null) {
        if ('message' in error) {
          message = String(error.message);
        }
      }
    }
    throw Error(message);
  }
});
