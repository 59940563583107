/* eslint-disable @typescript-eslint/no-explicit-any,react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { AxiosRequestConfig } from 'axios';
import { useDropzone } from 'react-dropzone';
import useUploadForm from 'hooks/useUploadForm.ts';
import useStyles from './DropFileZone.styles.ts';
import { Box, LinearProgress, useTheme } from '@mui/material';
import { Button, Typo } from 'components/primitive';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { DMAFileTypes } from 'interfaces/ia.interface.ts';
import { IUserPortal } from 'interfaces/UserAccountInfo.interface.ts';
import { FileContainer } from 'components/fragment';
import { bytesToSize, getProperKeyNameDummySearchString } from 'utils/index.ts';

interface FileType extends DMAFileTypes, File {
  name: string;
  size: number;
}

type DropFileZoneProps = {
  readOnly: boolean;
  authToken: string;
  uuid: string;
  user: IUserPortal;
  pvDataID?: number;
  hasOldData?: boolean;
  files?: DMAFileTypes[];
  filesQueuedCallback: React.Dispatch<React.SetStateAction<any>>;
  filesDeletedCallback: React.Dispatch<React.SetStateAction<any>>;
};

const palmettoBaseURL = import.meta.env.VITE_MAIN_PALMETTO_ENDPOINT;
const DropFileZone: React.FC<DropFileZoneProps> = ({ hasOldData = false, ...props }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const baseStyle = classes.baseStyle;
  const focusedStyle = classes.focusedStyle;
  const acceptStyle = classes.acceptStyle;
  const rejectStyle = classes.rejectStyle;

  const {
    uploadForm,
    downloadForm,
    uploadedDocuments,
    progress,
    inProgress,
    setUploadedDocuments,
  } = useUploadForm<FileType>(hasOldData);
  const [files, setFiles] = useState<FileType[]>([]);
  const [filesDeleted, setFilesDeleted] = useState<FileType[]>([]);
  const [uploadError, setUploadError] = useState(false);

  useEffect(() => {
    // *: Get the uploaded files
    const fetchData = async (url: string) => {
      const config: AxiosRequestConfig = {
        headers: { 'Content-Type': 'application/json' },
      };
      return await downloadForm(url, config, props.files || []);
    };
    if (props.uuid && props.authToken && !inProgress) {
      const url = `${palmettoBaseURL}/api/files?access_token=${props.authToken}&filter=${JSON.stringify({ where: { pvGlobalGroupID: props.uuid } })}`;
      fetchData(url).catch((error) => {
        console.log('🚀 fetchData error: ', error);
      });
    }
  }, [hasOldData, props.authToken, props.uuid, props.files]);

  const removeFile = (fileToRemove: FileType) => {
    const arr = uploadedDocuments;
    const fileToDelete: any[] | FileType[] = [];
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].pvDataID === fileToRemove.pvDataID) {
        fileToDelete.push(arr[i]);
        arr.splice(i, 1);
      }
    }
    setUploadedDocuments((prevFiles) =>
      prevFiles.filter((file) => file.pvDataID !== fileToRemove.pvDataID)
    );
    const arr2 = [...filesDeleted, ...fileToDelete];
    setFilesDeleted(arr2);
    props.filesDeletedCallback && props.filesDeletedCallback(() => arr2);
  };

  const onDrop = useCallback(async (acceptedFiles: FileType[] | any[]) => {
    setFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
    const uploadFiles = [...files, ...acceptedFiles];
    // noinspection TypeScriptValidateJSTypes
    props.filesQueuedCallback && props.filesQueuedCallback(uploadFiles);
    const tempUploadedFiles: FileType[] = [];
    try {
      for (let i = 0; i < uploadFiles.length; i++) {
        const file = uploadFiles[i];
        const url = `${palmettoBaseURL}/api/files/uploadWithoutID?access_token=${props.authToken}&filter=${JSON.stringify({ where: { pvGlobalGroupID: props.uuid } })}`;
        const config: AxiosRequestConfig = {
          headers: {
            'Content-Type': 'multipart/form-data',
            uuid: props.uuid,
            userid: props.user.id,
            username: props.user.username,
          },
        };

        const uploadedFile = await uploadForm<FileType>(file, url, config);
        console.log('🚀  uploadedFile: ', uploadedFile);
        if (uploadedFile.status === 200) tempUploadedFiles.push(uploadedFile.data);
      }
      setUploadedDocuments((prevFiles) => [...prevFiles, ...tempUploadedFiles]);
      // noinspection TypeScriptValidateJSTypes
      props.filesQueuedCallback && props.filesQueuedCallback(tempUploadedFiles);
    } catch (error) {
      console.log('🚀onDrop Upload error: ', error);
      setUploadError(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject, open } = useDropzone({
    onDrop,
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
  });

  const handleImageError = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    const target = e.target as HTMLImageElement;
    target.src = target.src.replace('fallbackbucket', 'palmettodev');
  };

  const style: React.CSSProperties | any = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [baseStyle, isFocused, focusedStyle, isDragAccept, acceptStyle, isDragReject, rejectStyle]
  );

  if (!props.uuid) return null;

  return (
    <>
      {!inProgress && Array.isArray(uploadedDocuments) && uploadedDocuments.length > 0 ? (
        <Box sx={classes.btnContainer} onClick={open}>
          Add File
          <AddCircleIcon fontSize='small' />
        </Box>
      ) : null}
      {inProgress ? (
        <Box sx={{ width: '100%' }}>
          <LinearProgress variant='determinate' value={progress} />
        </Box>
      ) : null}
      <>
        {!inProgress && Array.isArray(uploadedDocuments) && uploadedDocuments.length > 0
          ? uploadedDocuments.map((file: FileType, idx: number) => {
              if (!file.pvVoid || file.deleted === 0 || file.deleted === null) {
                const fileName = Object.prototype.hasOwnProperty.call(file, 'cbrnDataFileName')
                  ? (file.cbrnDataFileName ?? '')
                  : Object.prototype.hasOwnProperty.call(file, 'file_name')
                    ? (file.file_name ?? '')
                    : '';
                const hasDummyName: boolean = fileName?.indexOf('_DUMMYSEARCHSTRING_') >= 0;
                const name: string = hasDummyName
                  ? getProperKeyNameDummySearchString(fileName ?? 'FILE NAME')
                  : (fileName ?? 'FILE NAME');
                const byteSize = Object.prototype.hasOwnProperty.call(file, 'cbrnDataFileSize')
                  ? bytesToSize(file?.cbrnDataFileSize ?? 0)
                  : bytesToSize(file?.file_size ?? 0);
                return (
                  <FileContainer
                    readOnly={props.readOnly}
                    key={file?.pvDataID || idx}
                    authToken={props.authToken}
                    name={name}
                    item={file}
                    bytes={byteSize}
                    removeFile={(item) => removeFile(item)}
                    handleImageError={handleImageError}
                  />
                );
              }
              return <></>;
            })
          : null}
      </>
      {!inProgress && uploadedDocuments.length === 0 && (
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} />
          <CloudUploadIcon sx={{ color: '#C2C2C2', fontSize: '36px' }} />
          <Typo sx={classes.fileUploadTitle}>Drag & drop a file to upload</Typo>
          <Typo sx={classes.fileUploadSubTitle}>Or</Typo>
          <Button onClick={open}>BROWSE</Button>
        </div>
      )}
      {!inProgress && uploadError && (
        <Box sx={classes.errorContainer}>
          <Typo sx={classes.errorText}>Error uploading file</Typo>
        </Box>
      )}
    </>
  );
};

export default DropFileZone;
