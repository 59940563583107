import React from 'react';
import useStyles from './PageMaxLayout.styles';
import { useTheme } from '@mui/material';
import Box, { BoxProps } from '@mui/material/Box';

const PageMaxLayout: React.FC<BoxProps> = (props) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <Box sx={classes.container}>
      <Box sx={classes.content}>{props.children}</Box>
    </Box>
  );
};

export default PageMaxLayout;
