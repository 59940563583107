import { QueryState } from '@/common/enum';
import { Nullable } from 'interfaces/Nullable.ts';
import { IADashboardData, PADashboardData } from 'interfaces/ia.interface.ts';

export type DashboardStateTypes = {
  queryState: QueryState;
  error: Nullable<string>;
  iaData: Nullable<IADashboardData>;
  paData: Nullable<PADashboardData>;
  allIAData: Nullable<IADashboardData>;
  selectedIdx: number;
  initialSetup: boolean;
};

export const DashboardInitialState: DashboardStateTypes = {
  queryState: QueryState.UNDEFINED,
  selectedIdx: 0,
  error: null,
  iaData: null,
  paData: null,
  allIAData: null,
  initialSetup: true,
};
