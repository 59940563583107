import type IEndPoint from 'interfaces/EndPointTypes.ts';
import { AuthProtect, BaseURLSelect } from '@/common/enum';
import {
  BodyChangeIncidentGatheringType,
  BodyUpdateIncidentSettingForGroupType,
  BodyUpdateIncidentSettingType,
  BodyUpdateJPDAIncidentSettingType,
  IncidentsSettingsFilterParams,
  JPDAStatusPayloadType,
} from 'interfaces/incidents.interface.ts';

function setSettingsParams(filter: IncidentsSettingsFilterParams): IncidentsSettingsFilterParams {
  const params: IncidentsSettingsFilterParams = {};
  if (filter.pvGroupID) {
    params.pvGroupID = filter.pvGroupID;
  }

  if (filter.pvSettingType) {
    params.pvSettingType = filter.pvSettingType;
  }

  if (filter.pvAccountID) {
    params.pvAccountID = filter.pvAccountID;
  }

  return params;
}

export const getIncidentsSettings = (filters: IncidentsSettingsFilterParams): IEndPoint => {
  const params = setSettingsParams(filters);
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'GET',
    url: '/api/settings',
    params,
    defaultBaseURL: BaseURLSelect.DMA,
  };
};

export const getUsersSavedIncidents = (filters: string): IEndPoint => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'GET',
    url: `/api/incidents?filter=${filters}`,
  };
};

export const changeIncidentGathering = (data: BodyChangeIncidentGatheringType): IEndPoint => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: data.pvSettingID ? 'PUT' : 'POST',
    url: '/api/settings',
    data,
    defaultBaseURL: BaseURLSelect.DMA,
  };
};

export const updateIncidentSettingForGroup = (
  data: BodyUpdateIncidentSettingForGroupType
): IEndPoint => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'POST',
    url: '/api/updateincidentsettingforgroup',
    data,
    defaultBaseURL: BaseURLSelect.DMA,
  };
};

export const updateIncidentSetting = (data: BodyUpdateIncidentSettingType): IEndPoint => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: data.pvSettingID ? 'PUT' : 'POST',
    url: '/api/settings',
    data,
    defaultBaseURL: BaseURLSelect.DMA,
  };
};

export const updateJPDAIncidentSetting = (data: BodyUpdateJPDAIncidentSettingType): IEndPoint => ({
  authorization: AuthProtect.REQUIRED,
  method: data.pvSettingID ? 'PUT' : 'POST',
  url: '/api/settings',
  data,
  defaultBaseURL: BaseURLSelect.DMA,
});

export const updateJPDAStatus = (url: string, data: JPDAStatusPayloadType): IEndPoint => ({
  authorization: AuthProtect.REQUIRED,
  method: 'POST',
  url,
  data,
  defaultBaseURL: BaseURLSelect.DMA,
});
