import React, { Suspense } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { LinearProgress } from '@mui/material';
import { DrawerMenu } from 'components/primitive';

const MainLayout = () => {
  const location = useLocation();
  return (
    <React.Fragment>
      <Suspense fallback={<LinearProgress />}>
        <DrawerMenu />
        <Outlet key={location.key} />
      </Suspense>
    </React.Fragment>
  );
};

export default MainLayout;
