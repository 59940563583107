import {
  DamageTypeObj,
  EntriesByDamageAndStructureTypes,
  IADashboardData,
  StructureTypes,
} from 'interfaces/ia.interface.ts';
import { Nullable } from 'interfaces/Nullable.ts';
import { SideInfoCardType } from 'pages/Dashboard/Dashboard.props.ts';
import {
  GenerateSideInfo,
  GetCountByStructureType,
  TotalCountByStructureType,
} from 'pages/Dashboard/IAPage/StateView/utils';
import { DamageStructure } from '@/common/enum';

function formatToolTipData(items: EntriesByDamageAndStructureTypes[]) {
  const damageTypeObj: DamageTypeObj = {
    affected: { business: 0, single: 0, manufactured: 0, multi: 0, mobile: 0 },
    minor: { business: 0, single: 0, manufactured: 0, multi: 0, mobile: 0 },
    major: { business: 0, single: 0, manufactured: 0, multi: 0, mobile: 0 },
    inaccessible: { business: 0, single: 0, manufactured: 0, multi: 0, mobile: 0 },
    destroyed: { business: 0, single: 0, manufactured: 0, multi: 0, mobile: 0 },
    'no damage': { business: 0, single: 0, manufactured: 0, multi: 0, mobile: 0 },
  };
  const keys = Object.keys(damageTypeObj.affected);
  items.forEach((item) => {
    if (item?.building_damage.toLowerCase().includes('affected')) {
      keys.forEach((key) => {
        const keyValue = key as StructureTypes;
        const value = setToolTipDataEntry(item, keyValue);
        damageTypeObj.affected[keyValue] += value[keyValue];
      });
    }
    if (item?.building_damage.toLowerCase().includes('minor')) {
      keys.forEach((key) => {
        const keyValue = key as StructureTypes;
        const value = setToolTipDataEntry(item, keyValue);
        damageTypeObj.minor[keyValue] += value[keyValue];
      });
    }
    if (item?.building_damage.toLowerCase().includes('major')) {
      keys.forEach((key) => {
        const keyValue = key as StructureTypes;
        const value = setToolTipDataEntry(item, keyValue);
        damageTypeObj.major[keyValue] += value[keyValue];
      });
    }
    if (item?.building_damage.toLowerCase().includes('inaccessible')) {
      keys.forEach((key) => {
        const keyValue = key as StructureTypes;
        const value = setToolTipDataEntry(item, keyValue);
        damageTypeObj.inaccessible[keyValue] += value[keyValue];
      });
    }
    if (item?.building_damage.toLowerCase().includes('destroyed')) {
      keys.forEach((key) => {
        const keyValue = key as StructureTypes;
        const value = setToolTipDataEntry(item, keyValue);
        damageTypeObj.destroyed[keyValue] += value[keyValue];
      });
    }
    if (item?.building_damage.toLowerCase().includes('no damage')) {
      keys.forEach((key) => {
        const keyValue = key as StructureTypes;
        const value = setToolTipDataEntry(item, keyValue);
        damageTypeObj['no damage'][keyValue] += value[keyValue];
      });
    }
  });
  return damageTypeObj;
}

function setToolTipDataEntry(
  item: EntriesByDamageAndStructureTypes,
  name: StructureTypes
): {
  business: number;
  single: number;
  manufactured: number;
  multi: number;
  mobile: number;
} {
  const { structure_type, structure_type_count } = item;
  const value = {
    business: 0,
    single: 0,
    manufactured: 0,
    multi: 0,
    mobile: 0,
  };
  if (structure_type && structure_type.toLowerCase().includes(name)) {
    value[name] += structure_type_count;
  }
  return value;
}

function generateAllInfoData(stateIAData: Nullable<IADashboardData>) {
  const data: {
    sideInfo: SideInfoCardType[];
    businessCount: number;
    totalResidenceCount: number;
    allCountiesCount: number;
  } = {
    sideInfo: [],
    businessCount: 0,
    totalResidenceCount: 0,
    allCountiesCount: 0,
  };
  if (stateIAData && stateIAData?.entriesByStructureType) {
    data.sideInfo = GenerateSideInfo(stateIAData.entriesByStructureType);
    data.businessCount = GetCountByStructureType(
      stateIAData.entriesByStructureType,
      DamageStructure.business
    );
    data.totalResidenceCount = TotalCountByStructureType(
      stateIAData.entriesByStructureType,
      DamageStructure.business
    );
  }
  if (stateIAData && stateIAData.countiesReportingDamage.length) {
    data.allCountiesCount = stateIAData.countiesReportingDamage[0]?.count ?? 0;
  }
  return data;
}

function generateCountyInfoData(iaData: Nullable<IADashboardData>) {
  const data: {
    sideInfo: SideInfoCardType[];
    businessCount: number;
    totalResidenceCount: number;
  } = {
    sideInfo: [],
    businessCount: 0,
    totalResidenceCount: 0,
  };

  if (iaData && iaData?.entriesByStructureType) {
    data.sideInfo = GenerateSideInfo(iaData.entriesByStructureType);
    data.businessCount = GetCountByStructureType(
      iaData.entriesByStructureType,
      DamageStructure.business
    );
    data.totalResidenceCount = TotalCountByStructureType(
      iaData.entriesByStructureType,
      DamageStructure.business
    );
  }
  return data;
}

export { formatToolTipData, generateAllInfoData, generateCountyInfoData };
