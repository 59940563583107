import type IEndPoint from 'interfaces/EndPointTypes.ts';
import { AuthProtect, BaseURLSelect } from '@/common/enum';
import BaseURLConfig from '@/common/enum/BaseURLSelection.ts';
import { BodyChangeGroupSettingsType } from 'interfaces/UserAccountInfo.interface.ts';

export const getUserDMASettings = (accountID: number): IEndPoint => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'GET',
    url: '/api/damageassessment_ms' + '?pvAccountID=' + accountID,
    defaultBaseURL: BaseURLSelect.DMA,
  };
};

export const getPalmettoGroups = (): IEndPoint => {
  return {
    authorization: AuthProtect.NOT_REQUIRED,
    method: 'GET',
    url: 'https://damage.palmettoeoc.com/s3db/groups.json',
    defaultBaseURL: BaseURLConfig.NULL,
  };
};

export const changeSelectedGroupForMapForStateUser = (
  data: BodyChangeGroupSettingsType
): IEndPoint => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: data.pvSettingID ? 'PUT' : 'POST',
    url: '/api/settings',
    data,
    defaultBaseURL: BaseURLSelect.DMA,
  };
};
