/* eslint-disable no-unused-vars */
enum OccupancyType {
  Owned = 'Owned',
  Rented = 'Rented',
  Unknown = 'Unknown',
}

enum OccupancyStatus {
  Primary = 'Primary',
  Secondary = 'Secondary',
  Vacant = 'Vacant',
}

enum JPDAStatus {
  notVisited = 'Not visited',
  noChanges = 'No changes',
  dataChanged = 'Data changed',
}

enum PAJPDAStatus {
  siteNotVisited = 'Site Not visited',
  siteVisited = 'Site Visited',
}

enum JPDAAssessment {
  SBA = 'SBA',
  IA = 'IA',
  Both = 'Both',
}

export { OccupancyType, OccupancyStatus, JPDAStatus, PAJPDAStatus, JPDAAssessment };
