const useStyles = () => ({
  root: {
    width: '100%',
    '& .MuiInputBase-root.MuiFilledInput-root': {
      // Apply your custom styles to the input field
      borderStyle: 'none',
      backgroundColor: '#FFF',
      border: 'none',
      maxHeight: '42px',
      height: '42px',
    },
    '& .MuiPickersBasePicker-container': {
      // Apply your custom styles to the date picker container
      backgroundColor: '#FFF',
      borderStyle: 'none',
      border: 'none',
      borderBottom: '1px solid black',
      borderRadius: 0,
    },
  },
});

export default useStyles;
