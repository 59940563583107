import React from 'react';
import { Await, defer, LoaderFunctionArgs, useAsyncValue, useLoaderData } from 'react-router-dom';
import EditPage from './Edit.container.tsx';
import { Loading } from 'components/primitive';
import apiFetch, { genericErrorMessage } from '@/services/apiFetch.ts';
import { RootState, store } from '@/store';
import { PADetail } from 'interfaces/pa.detail.interface.ts';
import { UserType } from '@/common/enum';
import { getPADetailsData } from '@/services/pa.endpoints.ts';
import { ErrorHandler } from 'components/fragment';

export interface EditDataResponse {
  data: PADetail | null;
}

// eslint-disable-next-line react-refresh/only-export-components
export const loaderPADetails = ({ params }: LoaderFunctionArgs) => {
  try {
    const { id } = params;
    const state = store.getState() as RootState;
    const { userType } = state.accountsInfo;
    const url = `/api/${userType === UserType.STATE_USER ? 'pa_state' : 'pa'}/${id}`;
    const endpoint = getPADetailsData(url);
    const PAData = apiFetch<PADetail>(endpoint).then((res) => res.data ?? null);
    return defer({
      data: PAData,
    });
  } catch (error) {
    let message = genericErrorMessage;
    if (error instanceof Error) message = error.message;
    else {
      if (typeof error === 'object' && error !== null) {
        if ('message' in error) {
          message = String(error.message);
        }
      }
    }
    throw Error(message);
  }
};

function RenderAwaitedData(data: { path: string; category: string; title: string }) {
  const { path, category, title } = data;
  const PAData = useAsyncValue() as PADetail;
  return <EditPage data={PAData} newEntry={false} path={path} category={category} title={title} />;
}

const EditDetailsContainer: React.FC<{
  category: string;
  path: string;
  title: string;
}> = ({ path, category, title }) => {
  const PAData = useLoaderData() as EditDataResponse;
  return (
    <React.Suspense fallback={<Loading />}>
      <Await resolve={PAData.data} errorElement={<ErrorHandler headerTitle={title} />}>
        <RenderAwaitedData path={path} category={category} title={title} />
      </Await>
    </React.Suspense>
  );
};

export default EditDetailsContainer;
