import type IEndpoint from 'interfaces/EndPointTypes.ts';
import { AuthProtect, BaseURLSelect } from '@/common/enum';

export const getIAData = (endURL: string): IEndpoint => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'GET',
    url: `/api/ia/homepage?${endURL}`,
    defaultBaseURL: BaseURLSelect.DMA,
  };
};

export const getPAData = (endURL: string): IEndpoint => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'GET',
    url: endURL,
    defaultBaseURL: BaseURLSelect.DMA,
  };
};
