import React from 'react';
import useStyles from './ButtonTwo.styles';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material';

type ButtonTwoProps = {
  alternate?: boolean;
  rightText: string | React.ReactElement | React.ReactNode;
  leftText: string | React.ReactElement | React.ReactNode;
  startIcon?: React.ReactElement | React.ReactNode;
  endIcon?: React.ReactElement | React.ReactNode;
  onClick?: () => void;
};

const ButtonTwo: React.FC<ButtonTwoProps> = ({
  alternate = false,
  startIcon,
  rightText,
  leftText,
  endIcon,
  onClick,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme, alternate);

  return (
    <Box id='TwoBtnDiv' sx={classes.container} onClick={onClick}>
      <Box sx={classes.startIcon}>{startIcon}</Box>
      {rightText}
      {leftText}
      <Box sx={classes.endIcon}>{endIcon}</Box>
    </Box>
  );
};

export default ButtonTwo;
