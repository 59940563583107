import React from 'react';
import { PageMaxLayout } from 'components/layout';
import { useAppSelector } from 'store/hooks.ts';
import { userTypeSelector } from 'store/feature/accountsInfo/accountsInfo.selector.ts';
import { UserType } from '@/common/enum';
import StateView from './StateView';
import CountyView from '../IAPage/CountyView';

const IAPageView = () => {
  const selectUserType = useAppSelector(userTypeSelector);
  const isStateUser = selectUserType === UserType.STATE_USER;
  const isCountyUser = selectUserType === UserType.COUNTY_USER;

  return (
    <PageMaxLayout>
      {isStateUser && <StateView />}
      {isCountyUser && <CountyView />}
    </PageMaxLayout>
  );
};

export default IAPageView;
