import { Theme } from '@mui/material';

const useStyles = (theme: Theme, withDelete: boolean, isModal: boolean) => ({
  footerContainer: {
    zIndex: 10,
    display: 'flex',
    width: isModal ? '100%' : '100vw',
    padding: '24px',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    right: 0,
    bottom: 0,
    background: theme.palette.common.white,
    boxShadow:
      '0px 4.13211px 10.01724px 0px rgba(0, 0, 0, 0.13), 0px 33px 80px 0px rgba(0, 0, 0, 0.26)',
  },
  footerWrapper: {
    display: 'flex',
    maxWidth: '1440px',
    width: '100%',
    justifyContent: withDelete ? 'space-between' : 'flex-end',
    alignItems: 'center',
    gap: '24px',
  },
  endContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '24px',
  },
});

export default useStyles;
