import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { RadioSelectOptions } from 'interfaces/SelectOptions.interface.ts';
import { number, object, string } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Nullable } from 'interfaces/Nullable.ts';

export type DBModalSettings = {
  incidentOptions: RadioSelectOptions[];
  groupOptions: RadioSelectOptions[];
  applicantsOptions: RadioSelectOptions[];
  incident: Nullable<number>;
  dataGathering: string;
  group: string | number;
  applicant: string | number;
};

export const defaultValues: DBModalSettings = {
  incidentOptions: [],
  groupOptions: [],
  applicantsOptions: [],
  incident: null,
  dataGathering: '',
  group: '',
  applicant: '',
};

function useDBModalSettingsForm() {
  const validationSchema = useMemo(
    () =>
      object().shape({
        incident: number().required('A viewing incident is required'),
        dataGathering: string().required('A data gathering incident is required'),
        group: string().required('A group is required'),
      }),
    []
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return useForm<any>({ defaultValues, resolver: yupResolver(validationSchema) });
}

export default useDBModalSettingsForm;
