import { QueryState } from '@/common/enum';
import { Nullable } from 'interfaces/Nullable.ts';
import { PAData, PADataSet } from 'interfaces/pa.interface.ts';
import {
  LegendTypes,
  MarkerTypeWithoutIcon,
} from 'pages/MapPage/components/ModalLegends/Constants.tsx';

export type PAStateTypes = {
  queryState: QueryState;
  error: Nullable<string>;
  paDataset: Nullable<PADataSet>;
  currentEntry: Nullable<PAData>;
  paTitle: Nullable<string>;
  mapLegendTypes: MarkerTypeWithoutIcon[];
  originalDataset: Nullable<PAData[]>;
  allCheckedTypes: boolean;
};

export const PAInitialState: PAStateTypes = {
  queryState: QueryState.UNDEFINED,
  error: null,
  paDataset: null,
  currentEntry: null,
  paTitle: null,
  mapLegendTypes: LegendTypes.map(({ icon, ...rest }) => rest),
  originalDataset: null,
  allCheckedTypes: true,
};
