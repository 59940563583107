import React, { useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks.ts';
import Box from '@mui/material/Box';
import useStyles from '../IAPage.styles.ts';
import { Grid, useTheme } from '@mui/material';
import { IScrollTabOption } from 'interfaces/NavTabsOptionTypes.ts';
import { Loading, ScrollTab, Typo } from 'components/primitive';
import { BarChart, CardCounter } from 'components/fragment';
import BusinessRoundedIcon from '@mui/icons-material/BusinessRounded';
import OtherHousesIcon from '@mui/icons-material/OtherHouses';
import MapIcon from '@mui/icons-material/Map';
import { userIncidentsSelector } from 'store/feature/incidents/incidents.selector.ts';
import { fetchIAHomePage } from 'store/feature/dashboard/dashboard.thunk.ts';
import {
  allIADataSelector,
  dbQueryStateSelector,
  iaDataSelector,
  selectedIdxSelector,
} from 'store/feature/dashboard/dashboard.selector.ts';
import { BuildingDamage, QueryState } from '@/common/enum';
import { EntriesByDamageTypes } from 'interfaces/ia.interface.ts';
import { GraphLabels } from '@/common/static';
import {
  formatToolTipData,
  generateAllInfoData,
  generateCountyInfoData,
} from 'pages/Dashboard/IAPage/utils';
import { setSelectedIdx } from 'store/feature/dashboard/dashboard.slice.ts';

const StateView: React.FC = () => {
  const dispatch = useAppDispatch();
  const selectedIncident = useAppSelector(userIncidentsSelector);
  const isLoading = useAppSelector(dbQueryStateSelector) === QueryState.AWAIT;
  const iaData = useAppSelector(iaDataSelector);
  const stateIAData = useAppSelector(allIADataSelector);
  const selectedIdx = useAppSelector(selectedIdxSelector);
  const theme = useTheme();
  const classes = useStyles(theme);

  const [scrollTabLists, setScrollTabLists] = useState<IScrollTabOption[]>([]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const activeIndex = useMemo(() => scrollTabLists[selectedIdx], [selectedIdx]);

  const allInfoData = generateAllInfoData(stateIAData);

  const countyInfoData = generateCountyInfoData(iaData);

  function formatDataSet(items: EntriesByDamageTypes[]) {
    const arr = [0, 0, 0, 0, 0, 0];
    items.forEach((item) => {
      if (item?.building_damage.toLowerCase() === BuildingDamage.destroyed) {
        arr[0] += item.count;
      }
      if (item?.building_damage.toLowerCase().trim().includes('major')) {
        arr[1] += item.count;
      }
      if (item?.building_damage.toLowerCase().trim().includes('minor')) {
        arr[2] += item.count;
      }
      if (item?.building_damage.toLowerCase().trim().includes('affected')) {
        arr[3] += item.count;
      }
      if (item?.building_damage.toLowerCase().trim().includes('inaccessible')) {
        arr[4] += item.count;
      }
      if (item?.building_damage.toLowerCase().trim().includes('no damage')) {
        arr[5] += item.count;
      }
    });
    return arr;
  }

  useEffect(() => {
    const controller = new AbortController();
    if (selectedIncident) {
      dispatch(fetchIAHomePage({ controller }));
    }
    // return () => controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIncident]);

  useEffect(() => {
    if (activeIndex) {
      const { id } = activeIndex;
      dispatch(fetchIAHomePage({ selectedGroupIndex: id }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeIndex]);

  useEffect(() => {
    if (!isLoading && stateIAData) {
      const tempList =
        (stateIAData.affectedCounties &&
          stateIAData.affectedCounties.length &&
          stateIAData.affectedCounties
            .map((item) => ({
              id: item.group_id,
              title: item.group_name,
            }))
            .sort((a, b) => a.title.localeCompare(b.title))) ||
        [];
      setScrollTabLists([{ id: 0, title: 'Show All' }, ...tempList]);
    }
  }, [activeIndex, isLoading, stateIAData]);

  if (isLoading)
    return (
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          height: '100vh',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <Loading message={''} />
      </Box>
    );

  return (
    <>
      <Box sx={classes.filterContainer}>
        <Typo sx={classes.filterTitle}>Filters</Typo>
        <ScrollTab
          options={scrollTabLists}
          setIndex={(idx) => dispatch(setSelectedIdx(idx))}
          selectedIndex={selectedIdx}
        />
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} sx={classes.gridWrapper}>
          <Typo
            fontWeight={700}
            color={theme.palette.text.secondary}
            sx={{ textTransform: 'uppercase' }}>
            Total entries by structure type
          </Typo>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <CardCounter
                bigCount={allInfoData.businessCount}
                footerTitle={'Business'}
                endIcon={
                  <BusinessRoundedIcon
                    sx={{ width: '24px', color: theme.palette.grey[50], opacity: 0.5 }}
                  />
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <CardCounter
                bigCount={allInfoData.totalResidenceCount}
                withSideInfo={Boolean(allInfoData.sideInfo && allInfoData.sideInfo.length)}
                sideInfo={allInfoData.sideInfo}
                footerTitle={'Residence'}
                endIcon={
                  <OtherHousesIcon
                    sx={{ width: '24px', color: theme.palette.grey[50], opacity: 0.5 }}
                  />
                }
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6} sx={classes.gridWrapper}>
          {!activeIndex || activeIndex?.id === 0 ? (
            <>
              <Typo
                fontWeight={700}
                color={theme.palette.text.secondary}
                sx={{ textTransform: 'uppercase' }}>
                Total Counties reporting damage
              </Typo>
              <CardCounter
                bigCount={allInfoData.allCountiesCount}
                footerTitle={'Counties'}
                endIcon={
                  <MapIcon sx={{ width: '24px', color: theme.palette.grey[50], opacity: 0.5 }} />
                }
              />
            </>
          ) : null}
          {activeIndex && activeIndex.id ? (
            <>
              <Typo
                fontWeight={700}
                color={theme.palette.text.secondary}
                sx={{ textTransform: 'uppercase' }}>
                {`${activeIndex.title} county structure type`}
              </Typo>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <CardCounter
                    bigCount={countyInfoData.businessCount}
                    footerTitle={'Business'}
                    endIcon={
                      <BusinessRoundedIcon
                        sx={{ width: '24px', color: theme.palette.grey[50], opacity: 0.5 }}
                      />
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <CardCounter
                    bigCount={countyInfoData.totalResidenceCount}
                    withSideInfo={Boolean(
                      countyInfoData.sideInfo && countyInfoData.sideInfo.length
                    )}
                    sideInfo={countyInfoData.sideInfo}
                    footerTitle={'Residence'}
                    endIcon={
                      <OtherHousesIcon
                        sx={{ width: '24px', color: theme.palette.grey[50], opacity: 0.5 }}
                      />
                    }
                  />
                </Grid>
              </Grid>
            </>
          ) : null}
        </Grid>
        {stateIAData && stateIAData?.entriesByDamageType && (
          <Grid item xs={12} md={6} sx={classes.gridWrapper}>
            <Typo
              fontWeight={700}
              color={theme.palette.text.secondary}
              sx={{ textTransform: 'uppercase' }}>
              Total Entries By Damage Type
            </Typo>
            <Box sx={classes.chartContainer}>
              <BarChart
                labels={GraphLabels}
                data={formatDataSet(stateIAData.entriesByDamageType)}
                tooltipData={formatToolTipData(stateIAData.entriesByDamageAndStructureType)}
              />
            </Box>
          </Grid>
        )}
        {!!selectedIdx && iaData && iaData?.damageTypeByCounty && (
          <Grid item xs={12} md={6} sx={classes.gridWrapper}>
            <Typo
              fontWeight={700}
              color={theme.palette.text.secondary}
              sx={{ textTransform: 'uppercase' }}>
              {`${activeIndex?.title ?? ''} County Total Entries By Damage Type`}
            </Typo>
            <Box sx={classes.chartContainer}>
              <BarChart
                labels={GraphLabels}
                data={formatDataSet(iaData.damageTypeByCounty)}
                tooltipData={formatToolTipData(iaData.entriesByDamageAndStructureTypeByCounty)}
              />
            </Box>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default StateView;
