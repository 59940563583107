import React from 'react';
import Typography, { TypographyProps } from '@mui/material/Typography';

export interface TypoProps extends TypographyProps {
  alternate?: boolean;
  neutral?: boolean;
  progress?: boolean;
}

const Typo: React.FC<TypoProps> = ({ variant = 'body1', ...props }) => {
  return (
    <Typography {...props} variant={variant}>
      {props.children}
    </Typography>
  );
};

export default Typo;
