/* eslint-disable no-unused-vars */
import React from 'react';
import { PhoneNumberFormat } from 'utils/FomattingUtils.ts';

const handlePhoneChange =
  (onChange: (value: string | undefined | null) => void) =>
  (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(PhoneNumberFormat(event.target.value));
  };

export default handlePhoneChange;
