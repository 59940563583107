import DefaultPaletteOptions from '../defaultPallete.ts';

export default (pallet: DefaultPaletteOptions): any => {
  return {
    styleOverrides: {
      root: {
        backgroundColor: pallet.primary.main,
        fontSize: 'unset !important',
        fontWeight: 'unset !important',
        '& .MuiTab-root': {
          color: 'unset !important',
          backgroundColor: 'unset !important',
        },
        '& .Mui-selected': {
          color: 'unset !important',
          backgroundColor: 'unset !important',
          fontSize: 'unset !important',
          fontWeight: 'unset !important',
          borderRadius: '0 !important',
          minHeight: 'unset !important',
          maxHeight: 'unset !important',
          margin: 'unset !important',
          height: 'unset !important',
        },
      },
    },
  };
};
