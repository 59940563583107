import { ICost } from 'interfaces/DashboardTypes.ts';

function calculateSum(array: ICost[], property: string): number {
  return array.reduce((accumulator, object) => {
    if (Object.prototype.hasOwnProperty.call(object, property)) {
      const value = object[property];
      if (typeof value !== 'number') {
        return accumulator;
      }
      return accumulator + value;
    }
    return accumulator;
  }, 0);
}

export default calculateSum;
