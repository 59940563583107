import React from 'react';
import { Await, defer, useAsyncValue, useLoaderData } from 'react-router-dom';
import { RootState, store } from '@/store';
import ShortSummary from './ShortSummary.tsx';
import apiFetch, { genericErrorMessage } from '@/services/apiFetch.ts';
import { getPAShortSummaryData } from '@/services/pa.endpoints.ts';
import { Loading } from 'components/primitive';
import { PAShortSummary } from 'interfaces/pa.interface.ts';
import { ErrorHandler } from 'components/fragment';

export interface PAShortSummaryDataResponse {
  data: Partial<PAShortSummary> | null;
}

// eslint-disable-next-line react-refresh/only-export-components
export const loaderShortSummary = () => {
  try {
    const state = store.getState() as RootState;
    const { selectedIncident } = state.incidents;
    const { selectedGroupID, selectedGroup, userType } = state.accountsInfo;
    const applicant = state.applicants.selectedApplicant;
    const activeGroup = selectedGroup ? Number(selectedGroup) : selectedGroupID;
    let url = `/api/pasf/${selectedIncident?.pvIncidentID}/${activeGroup}?userType=${userType}`;
    if (applicant) url += `&filters[applicant_id]=${applicant.id}`;
    const endpoint = getPAShortSummaryData(url);
    const PAShortSummaryData = apiFetch<Partial<PAShortSummary>>(endpoint).then(
      (res) => res.data ?? null
    );
    return defer({
      data: PAShortSummaryData,
    });
  } catch (error) {
    let message = genericErrorMessage;
    if (error instanceof Error) message = error.message;
    else {
      if (typeof error === 'object' && error !== null) {
        if ('message' in error) {
          message = String(error.message);
        }
      }
    }
    throw Error(message);
  }
};

function RenderAwaitData() {
  const data = useAsyncValue() as Partial<PAShortSummary>;
  return <ShortSummary data={data} />;
}

const ShortSummeryLoader: React.FC = () => {
  const data = useLoaderData() as PAShortSummaryDataResponse;

  return (
    <React.Suspense fallback={<Loading />}>
      <Await resolve={data.data} errorElement={<ErrorHandler headerTitle='Short Form Summary' />}>
        <RenderAwaitData />
      </Await>
    </React.Suspense>
  );
};

export default ShortSummeryLoader;
