const useStyles = (fade: boolean) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#FFF',
    minWidth: '280px',
    justifyContent: 'left',
    color: '#000',
    fontWeight: 500,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    '& .MuiButton-endIcon': {
      right: '16px',
    },
    opacity: fade ? 0.3 : 1,
  },
  subMenuContainer: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#FFF',
    fontSize: '14px',
    minWidth: '280px',
    justifyContent: 'left',
    color: '#707070',
    fontWeight: 500,
    overflow: 'hidden',
    '& .MuiButton-endIcon': {
      right: '16px',
    },
  },
});

export default useStyles;
