/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Controller } from 'react-hook-form';
import moment from 'moment-timezone';
import { Grid, IconButton, InputAdornment, useTheme } from '@mui/material';
import useStyles from './GenInfo.styles';
import { HeaderButton, HeaderComponent } from '@zawarski/palmetto-ui-components';
import Box from '@mui/material/Box';
import MenuIcon from '@mui/icons-material/Menu';
import BackIcon from '@mui/icons-material/ArrowBack';
import { PageMaxLayout } from 'components/layout';
import { DatePickerField, DecimalNumber, FormContent, TextField, Typo } from 'components/primitive';
import { convertToTitleCase, handlePhoneChange, PhoneNumberFormat } from 'utils/index.ts';
import { GenInfoViewProps } from './GenInfo.props.ts';
import { ActionButtons } from 'components/fragment';

const GenInfoView: React.FC<GenInfoViewProps> = ({
  form,
  handleEdit,
  isSaving,
  readOnly,
  onSubmit,
  permissions,
  toggleDrawer,
  handleCancel,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <Box className={'layout vertical full-height'} style={{ overflowY: 'auto' }}>
      <HeaderComponent
        title='General Information'
        icon={
          readOnly ? (
            <IconButton onClick={!isSaving ? () => toggleDrawer() : undefined}>
              <MenuIcon className='icon' />
            </IconButton>
          ) : (
            <IconButton onClick={!isSaving ? () => handleCancel() : undefined}>
              <BackIcon className='icon' />
            </IconButton>
          )
        }
        suffix={
          permissions.UPDATE && (
            <div className='form-margin-16'>
              {readOnly ? (
                <HeaderButton onClick={() => handleEdit()}>
                  <Typo fontWeight={600}>EDIT</Typo>
                </HeaderButton>
              ) : null}
            </div>
          )
        }
      />
      <PageMaxLayout>
        <FormContent title='Applicant/Inspector Information' bgcolor={'#FFF'}>
          <Grid container spacing={2} sx={classes.gridContainer}>
            <Grid item xs={12} md={6}>
              <Controller
                control={form.control}
                name='date'
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <DatePickerField
                    label='Date'
                    format={'MMMM DD, YYYY'}
                    value={moment.utc(value)}
                    onChange={onChange}
                    errMsg={error}
                    helperTxt={error ? error.message : ''}
                    readOnly={readOnly}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                control={form.control}
                name='county'
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    error={Boolean(error)}
                    errMsg={error}
                    label='County'
                    placeholder='Enter county'
                    value={convertToTitleCase(value)}
                    onChange={onChange}
                    InputProps={{
                      readOnly: true,
                    }}
                    fullWidth
                    helperTxt={error ? error.message : ''}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                control={form.control}
                name='applicant_name'
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    error={Boolean(error)}
                    errMsg={error}
                    label='Applicant POC'
                    placeholder='Enter name'
                    value={value}
                    onChange={onChange}
                    InputProps={{
                      readOnly: readOnly,
                    }}
                    fullWidth
                    helperTxt={error ? error.message : ''}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                control={form.control}
                name='applicant_email'
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    error={Boolean(error)}
                    errMsg={error}
                    label='Applicant POC email address'
                    placeholder='Enter email address'
                    value={value}
                    onChange={onChange}
                    InputProps={{
                      readOnly: readOnly,
                    }}
                    fullWidth
                    helperTxt={error ? error.message : ''}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                control={form.control}
                name='applicant_phone_number'
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    error={Boolean(error)}
                    errMsg={error}
                    label='Applicant POD phone number'
                    placeholder='Enter phone number'
                    value={PhoneNumberFormat(value ?? '')}
                    onChange={handlePhoneChange(onChange)}
                    InputProps={{
                      readOnly: readOnly,
                    }}
                    fullWidth
                    helperTxt={error ? error.message : ''}
                  />
                )}
              />
            </Grid>
          </Grid>
        </FormContent>
        <FormContent title='General Impact' bgcolor={'#FFF'}>
          <Grid container spacing={2} sx={classes.gridContainer}>
            <Grid item xs={12}>
              <Controller
                control={form.control}
                name='safety_hazard_description'
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    error={Boolean(error)}
                    errMsg={error}
                    label='Identify and describe the damages that constitute a health and/or safety hazard to the general public'
                    placeholder='Enter answer'
                    value={value}
                    onChange={onChange}
                    InputProps={{
                      readOnly: readOnly,
                    }}
                    fullWidth
                    multiline
                    helperTxt={error ? error.message : ''}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={form.control}
                name='population_description'
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    error={Boolean(error)}
                    errMsg={error}
                    label='Describe the population adversely affected directly or indirectly by the loss of or the damage to these public facilities'
                    placeholder='Enter answer'
                    value={value}
                    onChange={onChange}
                    InputProps={{
                      readOnly: readOnly,
                    }}
                    fullWidth
                    multiline
                    helperTxt={error ? error.message : ''}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={form.control}
                name='damage_economic'
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    error={Boolean(error)}
                    errMsg={error}
                    label='What economic activities are adversely affected by the loss of the public facilities or the damage to the facilities?'
                    placeholder='Enter answer'
                    value={value}
                    onChange={onChange}
                    InputProps={{
                      readOnly: readOnly,
                    }}
                    fullWidth
                    multiline
                    helperTxt={error ? error.message : ''}
                  />
                )}
              />
            </Grid>
          </Grid>
        </FormContent>
        <FormContent title='Response Capabilities' bgcolor={'#FFF'}>
          <Grid container spacing={2} sx={classes.gridContainer}>
            <Grid item xs={12}>
              <Controller
                control={form.control}
                name='repair_damage_description'
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    error={Boolean(error)}
                    errMsg={error}
                    label='Describe how quickly the applicant intends to repair the damages and the schedule to accomplish the work.'
                    placeholder='Enter answer'
                    value={value}
                    onChange={onChange}
                    InputProps={{
                      readOnly: readOnly,
                    }}
                    fullWidth
                    multiline
                    helperTxt={error ? error.message : ''}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={form.control}
                name='repair_funds_search'
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    error={Boolean(error)}
                    errMsg={error}
                    label='Descibe the source and availability of funds to accomplish the repairs.'
                    placeholder='Enter answer'
                    value={value}
                    onChange={onChange}
                    InputProps={{
                      readOnly: readOnly,
                    }}
                    fullWidth
                    multiline
                    helperTxt={error ? error.message : ''}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={form.control}
                name='repair_speed'
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    error={Boolean(error)}
                    errMsg={error}
                    label='How quickly can the damages be repaired without degradation of public services?'
                    placeholder='Enter answer'
                    value={value}
                    onChange={onChange}
                    InputProps={{
                      readOnly: readOnly,
                    }}
                    fullWidth
                    multiline
                    helperTxt={error ? error.message : ''}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={form.control}
                name='public_service_impact'
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    error={Boolean(error)}
                    errMsg={error}
                    label='Impact on public services if a declaration is not made:'
                    placeholder='Enter answer'
                    value={value}
                    onChange={onChange}
                    InputProps={{
                      readOnly: readOnly,
                    }}
                    fullWidth
                    multiline
                    helperTxt={error ? error.message : ''}
                  />
                )}
              />
            </Grid>
          </Grid>
        </FormContent>
        <FormContent title='Other Estimates' bgcolor={'#FFF'}>
          <Grid container spacing={2} sx={classes.gridContainer}>
            <Grid item xs={12} md={4}>
              <Controller
                control={form.control}
                name='damage_cost_corps'
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    error={Boolean(error)}
                    errMsg={error}
                    label='Damage cost estimate: Corps of Engineers'
                    placeholder='Enter estimate'
                    value={value}
                    onChange={onChange}
                    InputProps={{
                      readOnly: readOnly,
                      startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                      inputComponent: DecimalNumber as any,
                    }}
                    helperTxt={error ? error.message : ''}
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                control={form.control}
                name='damage_cost_conservation'
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    error={Boolean(error)}
                    errMsg={error}
                    label='Damage cost estimate: National Resource Conservation Service'
                    placeholder='Enter estimate'
                    value={value}
                    onChange={onChange}
                    InputProps={{
                      readOnly: readOnly,
                      startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                      inputComponent: DecimalNumber as any,
                    }}
                    helperTxt={error ? error.message : ''}
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                control={form.control}
                name='damage_cost_highway'
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    error={Boolean(error)}
                    errMsg={error}
                    label='Damage cost estimate: Federal Highway Administration'
                    placeholder='Enter estimate'
                    value={value}
                    onChange={onChange}
                    InputProps={{
                      readOnly: readOnly,
                      startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                      inputComponent: DecimalNumber as any,
                    }}
                    helperTxt={error ? error.message : ''}
                    fullWidth
                  />
                )}
              />
            </Grid>
          </Grid>
        </FormContent>
        <Box sx={{ display: 'flex', width: '100%', minHeight: '65px' }} />
      </PageMaxLayout>
      <ActionButtons
        readonly={readOnly}
        isSaving={isSaving}
        withSave
        save={!isSaving ? form.handleSubmit(onSubmit) : undefined}
      />
    </Box>
  );
};

export default GenInfoView;
