import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DefaultSideMenuState, SideMenuState } from './sideMenu.state.ts';

const initialState: SideMenuState = DefaultSideMenuState;

export const sideMenuSlice = createSlice({
  name: 'sideMenu',
  initialState,
  reducers: {
    // changeMenu: (state, action: PayloadAction<boolean>) => {
    //   state.openDrawer = action.payload;
    // },
    toggleIncidentModal: (state, action: PayloadAction<boolean>) => {
      state.openIncidentModal = action.payload;
    },
    toggleGroupModal: (state, action: PayloadAction<boolean>) => {
      state.openGroupModal = action.payload;
    },
  },
});

export default sideMenuSlice.reducer;
