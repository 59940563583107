import React, { useEffect, useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import useGenInfoForm, { defaultValues, IGenInfoFormData } from './hook/useGenInfoForm';
import GenInfoView from './GenInfo.view.tsx';
import { GenInfoViewProps } from './GenInfo.props.ts';
import { useAppSelector } from 'store/hooks.ts';
import {
  accountGroupIDSelector,
  selectAccount,
  selectedGroupNameSelector,
} from 'store/feature/accountsInfo/accountsInfo.selector.ts';
import { selectedIncidentSelector } from 'store/feature/incidents/incidents.selector.ts';
import { accessTypeSelector } from 'store/feature/authToken/authToken.selector.ts';
import { PAGenInfoSummary } from 'interfaces/pa.interface.ts';
import { SitePermissionTypes, ToastStatus } from '@/common/enum';
import { postPAGenInfoData } from '@/services/pa.endpoints.ts';
import { DateTimeFormat } from 'utils/FomattingUtils.ts';
import apiFetch from '@/services/apiFetch.ts';
import { toast } from 'react-toastify';
import { ToastMessage } from 'components/primitive';

const toggleDrawer = () => window.dispatchEvent(new CustomEvent('toggle-sidebar'));
const GenInfo: React.FC<{ data: PAGenInfoSummary }> = ({ data }) => {
  const form = useGenInfoForm();
  const userAccount = useAppSelector(selectAccount);
  const userGroupID = useAppSelector(accountGroupIDSelector) ?? 0;
  const userGroupName = useAppSelector(selectedGroupNameSelector) ?? '';
  const incidentID = useAppSelector(selectedIncidentSelector)?.pvIncidentID ?? 0;
  const userPermission = useAppSelector(accessTypeSelector);
  const permissions = {
    UPDATE: userPermission === SitePermissionTypes.A,
  };

  const [readOnly, setReadOnly] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState(false);

  const userFullName =
    userAccount !== null ? `${userAccount?.pvPersonGivenName} ${userAccount?.pvPersonSurName}` : '';

  useEffect(() => {
    if (typeof data === 'object' && Object.keys(data).length) {
      setReadOnly(true);
      if (userAccount) {
        const formValues: IGenInfoFormData = {
          id: data.id,
          county: data.county ?? userGroupName,
          date: data.date ? new Date(data.date) : new Date(),
          applicant_name: data.applicant_name,
          applicant_email: data.applicant_email,
          applicant_phone_number: data.applicant_phone_number,
          safety_hazard_description: data.safety_hazard_description ?? '',
          population_description: data.population_description ?? '',
          damage_economic: data.damage_economic ?? null,
          repair_funds_search: data.repair_funds_search ?? null,
          repair_speed: data.repair_speed ?? null,
          public_service_impact: data.public_service_impact ?? null,
          damage_cost_corps: data.damage_cost_corps ?? null,
          damage_cost_conservation: data.damage_cost_conservation ?? null,
          damage_cost_highway: data.damage_cost_highway ?? null,
          repair_damage_description: data.repair_damage_description ?? null,
          group_id: userGroupID,
          user_id: userAccount.id,
        };

        form.reset(formValues);
      }
    } else {
      if (userAccount) {
        const formValues: IGenInfoFormData = {
          ...defaultValues,
          applicant_name: userFullName,
          group_id: userGroupID,
          user_id: userAccount.id,
          county: userGroupName,
        };

        form.reset(formValues);
      } else {
        form.reset(defaultValues);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const onSubmit: SubmitHandler<IGenInfoFormData> = async (data) => {
    try {
      console.log('onSubmit: ', data);
      setIsSaving(true);
      const url = `/api/pagis/${incidentID}/${userGroupID}`;
      const payload: PAGenInfoSummary = {
        ...data,
        date: DateTimeFormat({ dte: data.date, format: 'YYYY-MM-DD HH:mm:ss' }),
        user_id: userAccount?.id ?? 0,
        group_id: userGroupID,
        incident_id: incidentID,
      };
      const endpoint = postPAGenInfoData(url, payload);
      await apiFetch(endpoint).then((res) => {
        setIsSaving(false);
        if (res.status === 200) {
          setReadOnly(true);
          toast.info(<ToastMessage status={ToastStatus.SUCCESS} message='Successfully Process' />);
        }
      });
    } catch (error) {
      console.log(error);
      setIsSaving(false);
      toast.error(<ToastMessage status={ToastStatus.ERROR} message='Encountered System Error' />);
    }
  };

  const handleEdit = () => {
    setReadOnly((prevState) => !prevState);
  };

  const combineProps: GenInfoViewProps = {
    form,
    readOnly,
    permissions,
    isSaving,
    toggleDrawer,
    handleEdit,
    onSubmit,
    handleCancel: () => setReadOnly(true),
  };
  return <GenInfoView {...combineProps} />;
};

export default GenInfo;
