/* eslint-disable no-unused-vars */
import React from 'react';
import { NumericFormat } from 'react-number-format';

interface NumberFormatProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  inputRef: any;
  onChange: (event: { target: { value: string } }) => void;
}

export const NumberCellFormat: React.FC<{ value?: string; prefix?: string }> = ({
  value,
  prefix = '$',
}) => {
  return (
    <>
      {value ? (
        <NumericFormat value={value} prefix={prefix} displayType='text' thousandSeparator={true} />
      ) : (
        ''
      )}
    </>
  );
};

const NumberFormat: React.FC<NumberFormatProps> = (props) => {
  const { inputRef, onChange, ...others } = props;
  return (
    <NumericFormat
      {...others}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      allowNegative={false}
      decimalScale={0}
      fixedDecimalScale={true}
      thousandSeparator={true}
      // inputMode='decimal'
      pattern='[0-9]*'
    />
  );
};

export default NumberFormat;
