/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import ShortSummaryView from './ShortSummary.view';
import { useAppDispatch, useAppSelector } from 'store/hooks.ts';
import { accessTypeSelector, selectAuthToken } from 'store/feature/authToken/authToken.selector.ts';
import { SitePermissionTypes, ToastStatus } from '@/common/enum';
import { PA_COLS_REPORT, ShortSummaryViewProps } from './ShortSummary.props.ts';
import useShortSumForm, { defaultValues, IShortSumFormData } from './hook/useShortSumForm.ts';
import { SubmitHandler } from 'react-hook-form';
import { PAShortSummary } from 'interfaces/pa.interface.ts';
import {
  accountGroupIDSelector,
  selectAccount,
  selectedGroupSelector,
} from 'store/feature/accountsInfo/accountsInfo.selector.ts';
import { selectedIncidentSelector } from 'store/feature/incidents/incidents.selector.ts';
import { DateTimeFormat } from 'utils/FomattingUtils.ts';
import { postPAShortSummaryData } from '@/services/pa.endpoints.ts';
import { toast } from 'react-toastify';
import { ToastMessage } from 'components/primitive';
import apiFetch from '@/services/apiFetch.ts';
import {
  fetchPADataSet,
  generateCatReport,
  generateShortSummaryReport,
} from 'store/feature/pa/pa.thunk.ts';
import { HandleDownloadExcelFile, HandlePrintTable } from 'utils/index.ts';

const toggleDrawer = () => window.dispatchEvent(new CustomEvent('toggle-sidebar'));
const DocumentBaseAPI = import.meta.env.VITE_PALMETTO_SERVERLESS_DOCUMENTS_ENDPOINT;
const ShortSummary: React.FC<{ data: Partial<PAShortSummary> }> = ({ data }) => {
  const dispatch = useAppDispatch();
  const form = useShortSumForm();
  const token = useAppSelector(selectAuthToken);
  const userAccount = useAppSelector(selectAccount);
  const userGroupID = useAppSelector(accountGroupIDSelector) ?? 0;
  const selectedGroup = useAppSelector(selectedGroupSelector);
  const incidentID = useAppSelector(selectedIncidentSelector)?.pvIncidentID || 0;
  const userPermission = useAppSelector(accessTypeSelector);
  const permissions = {
    UPDATE: userPermission === SitePermissionTypes.A,
  };
  const activeGroupID = selectedGroup ? selectedGroup : userGroupID;
  const [readOnly, setReadOnly] = useState<boolean>(true);
  const [isSaving, setIsSaving] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  useEffect(() => {
    if (data) {
      const formData: IShortSumFormData = {
        ...defaultValues,
        ...data,
        fiscal_year_start: data.fiscal_year_start ? new Date(data.fiscal_year_start) : null,
        cat_a_applicant_estimate: Number(data.cat_a_applicant_estimate) ?? 0,
        cat_a_team_estimate: Number(data.cat_a_team_estimate) ?? 0,
        cat_b_applicant_estimate: Number(data.cat_b_applicant_estimate) ?? 0,
        cat_b_team_estimate: Number(data.cat_b_team_estimate) ?? 0,
        cat_c_applicant_estimate: Number(data.cat_c_applicant_estimate) ?? 0,
        cat_c_team_estimate: Number(data.cat_c_team_estimate) ?? 0,
        cat_d_applicant_estimate: Number(data.cat_d_applicant_estimate) ?? 0,
        cat_d_team_estimate: Number(data.cat_d_team_estimate) ?? 0,
        cat_e_applicant_estimate: Number(data.cat_e_applicant_estimate) ?? 0,
        cat_e_team_estimate: Number(data.cat_e_team_estimate) ?? 0,
        cat_f_applicant_estimate: Number(data.cat_f_applicant_estimate) ?? 0,
        cat_f_team_estimate: Number(data.cat_f_team_estimate) ?? 0,
        cat_g_applicant_estimate: Number(data.cat_g_applicant_estimate) ?? 0,
        cat_g_team_estimate: Number(data.cat_g_team_estimate) ?? 0,
      };

      form.reset(formData);
    } else {
      form.reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleMenuClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const handleCloseMenu = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  const handleEdit = () => {
    setReadOnly((prevState) => !prevState);
  };

  const generatePAShortFormReport = () => {
    return new Promise((resolve, reject) => {
      console.log('Generating Short Form Report');
      if (!token || !incidentID || !activeGroupID) return reject('Download Report Failed');
      dispatch(generateShortSummaryReport())
        .unwrap()
        .then((data) => {
          const url = `${DocumentBaseAPI}/documents/damageassessment/pa/report/${incidentID}/${activeGroupID}`;
          HandleDownloadExcelFile('PA_Worksheet', url, token, data)
            .then(() => {
              setTimeout(() => resolve('Download Report Complete'), 1000);
            })
            .catch((error) => {
              console.log('HandleDownloadExcelFile catch:', error);
              reject('Download Report Failed');
            });
        })
        .catch((error) => {
          console.log('catch:', error);
          reject('Download Report Failed');
        });
    });
  };
  const handlePAPrintShortFormReport = async () => {
    setAnchorEl(null);
    try {
      await toast.promise(generatePAShortFormReport(), {
        pending: {
          render() {
            return <ToastMessage status={ToastStatus.LOADING} message='Generating Report' />;
          },
        },
        success: {
          render() {
            return <ToastMessage status={ToastStatus.SUCCESS} message='Download Report Complete' />;
          },
        },
        error: {
          render() {
            return <ToastMessage status={ToastStatus.ERROR} message='Download Report Failed' />;
          },
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  const generatePACatReport = () => {
    return new Promise((resolve, reject) => {
      console.log('Generating Cat Report');
      if (!token) return reject('Download Report Failed');
      dispatch(generateCatReport())
        .unwrap()
        .then((data) => {
          const url = `${DocumentBaseAPI}/documents/damageassessment/categories-summary`;
          HandleDownloadExcelFile('categories-estimate', url, token, data).then(() => {
            setTimeout(() => resolve('Download Report Complete'), 1000);
          });
        })
        .catch(() => reject('Download Report Failed'));
    });
  };

  const handleCatReport = async () => {
    setAnchorEl(null);
    await toast.promise(generatePACatReport(), {
      pending: {
        render() {
          return <ToastMessage status={ToastStatus.LOADING} message='Generating Report' />;
        },
      },
      success: {
        render() {
          return <ToastMessage status={ToastStatus.SUCCESS} message='Download Report Complete' />;
        },
      },
      error: {
        render() {
          return <ToastMessage status={ToastStatus.ERROR} message='Download Report Failed' />;
        },
      },
    });
  };

  const generatePrintTable = () => {
    return new Promise((resolve, reject) => {
      console.log('Generating Print Table');
      if (!token) return reject('Download Report Failed');
      dispatch(fetchPADataSet())
        .unwrap()
        .then((data) => {
          const payload = {
            items: data?.dataset || [],
            columns: PA_COLS_REPORT,
            timezone: 'America/New_York',
          };
          const url = `${DocumentBaseAPI}/documents/pa-table/print`;
          HandlePrintTable('pa-table', url, payload)
            .then(() => {
              setTimeout(() => resolve('Download Report Complete'), 1000);
            })
            .catch(() => reject('Download Report Failed'));
        })
        .catch(() => reject('Download Report Failed'));
    });
  };

  const handlePrintTable = async () => {
    setAnchorEl(null);
    await toast.promise(generatePrintTable, {
      pending: {
        render() {
          return <ToastMessage status={ToastStatus.LOADING} message='Generating Report' />;
        },
      },
      success: {
        render() {
          return <ToastMessage status={ToastStatus.SUCCESS} message='Download Report Complete' />;
        },
      },
      error: {
        render() {
          return <ToastMessage status={ToastStatus.ERROR} message='Download Report Failed' />;
        },
      },
    });
  };

  const onSubmit: SubmitHandler<IShortSumFormData> = async (data) => {
    setIsSaving(true);
    try {
      const payload: PAShortSummary = {
        ...data,
        cat_a_num: data.cat_a_num ?? 0,
        cat_b_num: data.cat_b_num ?? 0,
        cat_c_num: data.cat_c_num ?? 0,
        cat_d_num: data.cat_d_num ?? 0,
        cat_e_num: data.cat_e_num ?? 0,
        cat_f_num: data.cat_f_num ?? 0,
        cat_g_num: data.cat_g_num ?? 0,
        fiscal_year_start: DateTimeFormat({
          dte: data.fiscal_year_start,
          format: 'YYYY-MM-DD HH:mm:ss',
        }),
        user_id: userAccount?.id ?? 0,
        group_id: userGroupID,
        incident_id: incidentID,
      };
      const url = `/api/pasf/${incidentID}/${userGroupID}`;
      const endpoint = postPAShortSummaryData(url, payload);
      await apiFetch(endpoint).then((res) => {
        setIsSaving(false);
        if (res.status === 200) {
          setReadOnly(true);
          toast.info(<ToastMessage status={ToastStatus.SUCCESS} message='Successfully Process' />);
        }
      });
    } catch (error) {
      console.log(error);
      setIsSaving(false);
      toast.error(<ToastMessage status={ToastStatus.ERROR} message='Encountered System Error' />);
    }
  };

  const combineProps: ShortSummaryViewProps = {
    form,
    isSaving,
    anchorEl,
    toggleDrawer,
    readOnly,
    permissions,
    handlePAPrintShortFormReport,
    handleCatReport,
    handlePrintTable,
    handleCloseMenu,
    handleMenuClick,
    handleEdit,
    handleCancel: () => setReadOnly(true),
    onSubmit,
  };

  return <ShortSummaryView {...combineProps} />;
};

export default ShortSummary;
