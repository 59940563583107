import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import useStyles from './Grid.styles.ts';
import Box from '@mui/material/Box';
import { PalmettoReactTable } from '@zawarski/palmetto-direflow-react-table';
import { CardComponent3R1C } from '@zawarski/palmetto-ui-components';
import { useAppSelector } from 'store/hooks.ts';
import {
  accessTypeSelector,
  selectAuthToken,
  userIDSelector,
} from 'store/feature/authToken/authToken.selector.ts';
import { Fab, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { SitePermissionTypes, ToastStatus, UserType } from '@/common/enum';
import COLUMNS from './TableColumns.ts';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { IADetails } from 'interfaces/ia.interface.ts';
import { FixColumnConfig, TableRowData } from 'interfaces/PalmettoReactTableTypes.ts';
import { HandleDownloadDoc, HandleDownloadExcelFile, HandlePrintTable } from 'utils/index.ts';
import { toast } from 'react-toastify';
import { ToastMessage } from 'components/primitive';
import {
  isJPDA_IA_ActiveSelector,
  selectedIncidentSelector,
} from 'store/feature/incidents/incidents.selector.ts';
import {
  accountGroupIDSelector,
  allSubGroupsSelector,
  selectedGroupSelector,
  userTypeSelector,
} from 'store/feature/accountsInfo/accountsInfo.selector.ts';

const toggleDrawer = () => window.dispatchEvent(new CustomEvent('toggle-sidebar'));
const DocumentBaseAPI = import.meta.env.VITE_PALMETTO_SERVERLESS_DOCUMENTS_ENDPOINT;
const dmaBaseURL = import.meta.env.VITE_DAMAGEASSESSMENT_API_ENDPOINT;

const Grid: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const token = useAppSelector(selectAuthToken);
  const userId = useAppSelector(userIDSelector);
  const selectedIncident = useAppSelector(selectedIncidentSelector);
  const selectedGroupID = useAppSelector(accountGroupIDSelector);
  const selectedGroup = useAppSelector(selectedGroupSelector);
  const userType = useAppSelector(userTypeSelector);
  const allSubGroups = useAppSelector(allSubGroupsSelector);
  const userPermission = useAppSelector(accessTypeSelector);
  const isJPDA_IA_Active = useAppSelector(isJPDA_IA_ActiveSelector);
  const activeGroupID = selectedGroup ? selectedGroup : selectedGroupID;
  const incidentName = selectedIncident?.pvIncidentName ?? '';

  const permission: boolean = useMemo(() => {
    // *: Check if the user is a state user permission
    if (userType === UserType.STATE_USER) {
      if (
        !isJPDA_IA_Active &&
        (userPermission === SitePermissionTypes.U || userPermission === SitePermissionTypes.F)
      )
        return false;
    }
    return true;
  }, [userType, userPermission, isJPDA_IA_Active]);

  const fixColumnConfiguration: FixColumnConfig[] = useMemo(
    () =>
      COLUMNS.map((column) => ({
        title: column.headerName,
        path: column.field,
        checked: column.enabled ?? false,
      })),
    []
  );

  const onRowClick = ({ row }: TableRowData<IADetails>) => {
    // dispatch(setCurrentEntry(row));
    setTimeout(() => {
      navigate(`/ia/${row.id}`);
    }, 100);
  };

  const handleNew = () => {
    navigate('/ia/new');
  };

  const mobileCardContent = (data: { row: IADetails }) => {
    if (data && data.row && data.row.id) {
      return (
        <Box sx={classes.cardContainer}>
          <Box className='layout horizontal' onClick={() => onRowClick({ row: data.row })}>
            <Box sx={{ width: '100%', mb: '20px' }}>
              <CardComponent3R1C
                className=''
                key={data.row.id}
                title={data.row.address}
                title2={data.row.occupant_name}
                title3={
                  <Box>
                    <Box>
                      {data.row.structure_type} • {data.row.building_damage}
                    </Box>
                    <Box>
                      {data.row.estimated_loss
                        ? data.row.estimated_loss_fmt
                          ? data.row.estimated_loss_fmt + ' loss est.'
                          : ''
                        : ''}
                    </Box>
                  </Box>
                }
              />
            </Box>
          </Box>
        </Box>
      );
    } else return <></>;
  };

  const generateIATableReport = (payload: { columns: FixColumnConfig[] }) => {
    return new Promise((resolve, reject) => {
      console.log('Generating IA Table Report');
      const url = `${DocumentBaseAPI}/documents/damageassessment/ia/report/${selectedIncident?.pvIncidentID}/${activeGroupID}?userType=${userType}`;
      HandlePrintTable('Palmetto IA-table', url, payload)
        .then(() => {
          setTimeout(() => resolve('Download Report Complete'), 1000);
        })
        .catch(() => reject('Download Report Failed'));
    });
  };

  const handlePrintIATable = async () => {
    try {
      const fixTempColumnConfiguration: FixColumnConfig[] = [];
      for (const el of fixColumnConfiguration) {
        if (el.title === 'Estimated Loss') el.path = 'estimated_loss';
        if (!el.checked) el.checked = true; // *: MAKE ALL COLUMNS VISIBLE IN THE REPORT
        fixTempColumnConfiguration.push(el);
      }
      const payload = { columns: fixTempColumnConfiguration };
      await toast.promise(generateIATableReport(payload), {
        pending: {
          render() {
            return <ToastMessage status={ToastStatus.LOADING} message='Generating Report' />;
          },
        },
        success: {
          render() {
            return <ToastMessage status={ToastStatus.SUCCESS} message='Download Report Complete' />;
          },
        },
        error: {
          render() {
            return <ToastMessage status={ToastStatus.ERROR} message='Download Report Failed' />;
          },
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  const generateIAReport = () => {
    return new Promise((resolve, reject) => {
      console.log('Generating IA Report');
      if (!token || !selectedIncident?.pvIncidentID || !activeGroupID)
        return reject('Download Report Failed');
      const url = `${DocumentBaseAPI}/documents/dma/iareport/${selectedIncident.pvIncidentID}?group_id=${activeGroupID}&userType=${userType}`;
      HandleDownloadExcelFile('iareport', url, token).then(() => {
        setTimeout(() => resolve('Download Report Complete'), 1000);
      });
    });
  };

  const handlePrintIAReport = async () => {
    await toast.promise(generateIAReport(), {
      pending: {
        render() {
          return <ToastMessage status={ToastStatus.LOADING} message='Generating Report' />;
        },
      },
      success: {
        render() {
          return <ToastMessage status={ToastStatus.SUCCESS} message='Download Report Complete' />;
        },
      },
      error: {
        render() {
          return <ToastMessage status={ToastStatus.ERROR} message='Download Report Failed' />;
        },
      },
    });
  };

  const generateHousingSupplementReport = () => {
    return new Promise((resolve, reject) => {
      console.log('Generating Housing Supplement Report');
      if (!token || !selectedIncident?.pvIncidentID || !activeGroupID)
        return reject('Download Report Failed');
      const url = `${DocumentBaseAPI}/documents/dma/housingsupplement/${selectedIncident.pvIncidentID}?group_id=${activeGroupID}`;
      HandleDownloadDoc('housing-supplement-report.docx', url, token).then(() => {
        setTimeout(() => resolve('Download Report Complete'), 1000);
      });
    });
  };

  const handlePrintHousingSupplement = async () => {
    await toast.promise(generateHousingSupplementReport(), {
      pending: {
        render() {
          return <ToastMessage status={ToastStatus.LOADING} message='Generating Report' />;
        },
      },
      success: {
        render() {
          return <ToastMessage status={ToastStatus.SUCCESS} message='Download Report Complete' />;
        },
      },
      error: {
        render() {
          return <ToastMessage status={ToastStatus.ERROR} message='Download Report Failed' />;
        },
      },
    });
  };

  const getDataURL = (): string => {
    if (!selectedIncident || !activeGroupID) return '';
    let url = `${dmaBaseURL}/api/ia`;
    if (userType === UserType.STATE_USER) url = `${dmaBaseURL}/api/ia_state`;
    return url;
  };

  const defaultFilters = (): string[] => {
    if (!selectedIncident || !activeGroupID) return [];
    const filters = [
      `filters[incident_id]=${selectedIncident.pvIncidentID}`,
      `filters[group_id]=${activeGroupID}`,
    ];
    if (allSubGroups && allSubGroups.length) {
      allSubGroups.forEach((subgroup) => {
        filters.push(`filters[group_id]=${subgroup.pvGroupID}`);
      });
    }
    return filters;
  };

  const DATA_URL = getDataURL();
  const DEFAULT_FILTERS = defaultFilters();

  return (
    <Box className='layout vertical full-height' style={{ position: 'relative' }}>
      <Box
        className='layout vertical full-height'
        style={{
          position: 'relative',
          height: 'calc(100% - 24px)',
        }}>
        <PalmettoReactTable
          auth={{ accessToken: token, userId, groupId: activeGroupID }}
          title='Individual Assistance'
          subtitlePrefix={incidentName}
          headerIcon={
            <IconButton aria-label='close' onClick={() => toggleDrawer()}>
              <MenuIcon className='icon' />
            </IconButton>
          }
          columns={COLUMNS}
          dataUrl={DATA_URL}
          defaultFilters={DEFAULT_FILTERS}
          extraHeaders={<></>}
          limit={200}
          idField={'id'}
          loading={true}
          noHelp={true}
          hideFilter={true}
          hideSearch={true}
          rowHeight={52}
          virtualRowHeight={null}
          onRowClick={onRowClick}
          components={{
            MobileRow: mobileCardContent,
          }}
          MenuItems={[
            { title: 'Print IA Report', onClick: () => handlePrintIAReport() },
            {
              title: 'Print IA Table',
              onClick: () => handlePrintIATable(),
            },
            {
              title: 'Print Housing Supplement',
              onClick: () => handlePrintHousingSupplement(),
            },
          ]}
        />
        {permission && (
          <Fab
            sx={classes.fab}
            color='primary'
            variant='extended'
            size='medium'
            onClick={() => handleNew()}>
            New IA <AddCircleIcon className='icon' style={{ marginLeft: '6px' }} />
          </Fab>
        )}
      </Box>
    </Box>
  );
};

export default Grid;
