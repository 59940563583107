import { ICost } from 'interfaces/DashboardTypes.ts';

function calculateTotalEstimatedCost(array: ICost[]): number {
  return array.reduce((accumulator, object) => {
    if (Object.prototype.hasOwnProperty.call(object, 'sum')) {
      const value = object.sum;
      if (typeof value !== 'number') {
        return accumulator;
      }
      return accumulator + value;
    }
    return accumulator;
  }, 0);
}

export default calculateTotalEstimatedCost;
