import React, { useEffect, useMemo } from 'react';
import { Grid, useTheme } from '@mui/material';
import { CardCounter, CardInfo, MuiTableGrid } from 'components/fragment';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import PaidRoundedIcon from '@mui/icons-material/PaidRounded';
import AttachMoneyRoundedIcon from '@mui/icons-material/AttachMoneyRounded';
import { useAppDispatch, useAppSelector } from 'store/hooks.ts';
import { fetchPAHomePage } from 'store/feature/dashboard/dashboard.thunk.ts';
import {
  dbQueryStateSelector,
  paDataSelector,
} from 'store/feature/dashboard/dashboard.selector.ts';
import { QueryState } from '@/common/enum';
import { Loading, Typo } from 'components/primitive';
import {
  PADashboardData,
  TotalEstimatedCostByCategoryByCountyTypes,
} from 'interfaces/ia.interface.ts';
import { ICost } from 'interfaces/DashboardTypes.ts';
import {
  calculateSum,
  calculateTotalEstimatedCost,
  getCategoryByName,
  PADataColumns,
} from 'utils/index.ts';
import Box from '@mui/material/Box';
import { DefaultEstCostByCategory } from '@/common/static';
import { Nullable } from 'interfaces/Nullable.ts';

const PAStateView: React.FC = () => {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(dbQueryStateSelector) === QueryState.AWAIT;
  const isReady = useAppSelector(dbQueryStateSelector) === QueryState.READY;
  const paData = useAppSelector(paDataSelector);
  const theme = useTheme();

  function generateData(isReady: boolean, data: Nullable<PADashboardData>) {
    let totalCount = 0;
    let stateTotalThreshold = 0;
    const ttlEstCostByCat: ICost[] = DefaultEstCostByCategory;
    let dataTable: TotalEstimatedCostByCategoryByCountyTypes[] = [];
    if (!isReady || !data)
      return {
        stateTotalThreshold,
        paReportedDamageCounty: totalCount,
        totalEstimatedByCategoryCards: ttlEstCostByCat,
        dataTable,
      };
    const {
      countiesReportingDamage,
      totalStateThreshold,
      totalEstimatedCostByCategoryByCounty,
      totalEstimatedCostByCategory,
    } = data;
    if (countiesReportingDamage && countiesReportingDamage.length) {
      totalCount = countiesReportingDamage.reduce((acc, cur) => acc + cur.count, 0);
    }
    if (totalStateThreshold && totalStateThreshold.length) {
      stateTotalThreshold = totalStateThreshold[0].StateThreshold;
    }
    const ttlEstCostByCatByCounty = totalEstimatedCostByCategoryByCounty || [];
    if (ttlEstCostByCatByCounty && ttlEstCostByCatByCounty.length) {
      const sumCatA = calculateSum(ttlEstCostByCatByCounty, 'CatA');
      const sumCatB = calculateSum(ttlEstCostByCatByCounty, 'CatB');
      const sumCatC = calculateSum(ttlEstCostByCatByCounty, 'CatC');
      const sumCatD = calculateSum(ttlEstCostByCatByCounty, 'CatD');
      const sumCatE = calculateSum(ttlEstCostByCatByCounty, 'CatE');
      const sumCatF = calculateSum(ttlEstCostByCatByCounty, 'CatF');
      const sumCatG = calculateSum(ttlEstCostByCatByCounty, 'CatG');
      if (totalEstimatedCostByCategory && totalEstimatedCostByCategory.length) {
        for (const iterator of totalEstimatedCostByCategory) {
          const { category } = iterator;
          if (category === 'A') {
            ttlEstCostByCat[0]['sum'] = sumCatA;
          }
          if (category === 'B') {
            ttlEstCostByCat[1]['sum'] = sumCatB;
          }
          if (category === 'C') {
            ttlEstCostByCat[2]['sum'] = sumCatC;
          }
          if (category === 'D') {
            ttlEstCostByCat[3]['sum'] = sumCatD;
          }
          if (category === 'E') {
            ttlEstCostByCat[4]['sum'] = sumCatE;
          }
          if (category === 'F') {
            ttlEstCostByCat[5]['sum'] = sumCatF;
          }
          if (category === 'G') {
            ttlEstCostByCat[6]['sum'] = sumCatG;
          }
        }
      }
      dataTable = totalEstimatedCostByCategoryByCounty.map((item) => {
        const { group_id, group_name, Threshold, CatA, CatB, CatC, CatD, CatE, CatF, CatG } = item;
        return {
          group_id,
          group_name,
          Threshold,
          CatA,
          CatB,
          CatC,
          CatD,
          CatE,
          CatF,
          CatG,
          total:
            Number(CatA) +
            Number(CatB) +
            Number(CatC) +
            Number(CatD) +
            Number(CatE) +
            Number(CatF) +
            Number(CatG),
        };
      });
    }
    return {
      stateTotalThreshold,
      paReportedDamageCounty: totalCount,
      totalEstimatedByCategoryCards: ttlEstCostByCat,
      dataTable,
    };
  }

  const paStateDataResp = useMemo(() => generateData(isReady, paData), [isReady, paData]);

  useEffect(() => {
    const fetchData = () => dispatch(fetchPAHomePage());
    fetchData();
  }, [dispatch]);

  if (isLoading || !paData)
    return (
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          height: '100vh',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <Loading message={''} />
      </Box>
    );

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <CardInfo
            startIcon={<WarningRoundedIcon sx={{ color: theme.palette.primary.main }} />}
            title={String(paStateDataResp.paReportedDamageCounty)}
            subTitle={'Counties Reported PA Damage'}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <CardInfo
            isDollar
            startIcon={<PaidRoundedIcon sx={{ color: theme.palette.primary.main }} />}
            value={calculateTotalEstimatedCost(paStateDataResp.totalEstimatedByCategoryCards)}
            subTitle={'Total Estimated Cost'}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <CardInfo
            isDollar
            startIcon={<AttachMoneyRoundedIcon sx={{ color: theme.palette.primary.main }} />}
            value={paStateDataResp.stateTotalThreshold}
            subTitle={'State Threshold'}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typo
            fontWeight={700}
            color={theme.palette.text.secondary}
            sx={{ textTransform: 'uppercase', mb: '12px' }}>
            Total entries by structure type
          </Typo>
          <Grid container spacing={2}>
            {paStateDataResp.totalEstimatedByCategoryCards &&
              paStateDataResp.totalEstimatedByCategoryCards.length > 0 &&
              paStateDataResp.totalEstimatedByCategoryCards.map((item, idx) => {
                const category = item['category'].toString() || '';
                const sum = item['sum'] as number;
                if (sum === 0) return null;
                return (
                  <Grid item xs={12} md={3} key={`cardCounter-${category}-${idx}`}>
                    <CardCounter
                      isDollar
                      footerTitle={getCategoryByName(category)}
                      bigCount={sum}
                      footerVariant={'body1'}
                    />
                  </Grid>
                );
              })}
          </Grid>
        </Grid>
      </Grid>
      {MuiTableGrid<TotalEstimatedCostByCategoryByCountyTypes>({
        data: paStateDataResp.dataTable,
        columns: PADataColumns,
      })}
    </>
  );
};

export default PAStateView;
