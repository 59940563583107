import { QueryState } from '@/common/enum';
import { Nullable } from 'interfaces/Nullable.ts';

export type MapStateTypes = {
  queryState: QueryState;
  error: Nullable<string>;
  mapId: Nullable<string>;
  mapSettingsID: Nullable<number>;
};

export const MapInitialState: MapStateTypes = {
  queryState: QueryState.UNDEFINED,
  error: null,
  mapId: null,
  mapSettingsID: null,
};
