import { Nullable } from 'interfaces/Nullable.ts';
import { useForm } from 'react-hook-form';

export interface IShortSumFormData {
  id?: string;
  population: Nullable<number>;
  total_budget_approved: Nullable<number>;
  total_budget_balance: Nullable<number>;
  maintenance_budget_approved: Nullable<number>;
  maintenance_budget_balance: Nullable<number>;
  fiscal_year_start: Nullable<Date>;
  cat_a_local_funds: Nullable<number>;
  cat_b_local_funds: Nullable<number>;
  cat_c_local_funds: Nullable<number>;
  cat_d_local_funds: Nullable<number>;
  cat_e_local_funds: Nullable<number>;
  cat_f_local_funds: Nullable<number>;
  cat_g_local_funds: Nullable<number>;
  cat_a_num?: Nullable<number>;
  cat_a_applicant_estimate?: Nullable<number>;
  cat_a_team_estimate?: Nullable<number>;
  cat_b_num?: Nullable<number>;
  cat_b_applicant_estimate?: Nullable<number>;
  cat_b_team_estimate?: Nullable<number>;
  cat_c_num?: Nullable<number>;
  cat_c_applicant_estimate?: Nullable<number>;
  cat_c_team_estimate?: Nullable<number>;
  cat_d_num?: Nullable<number>;
  cat_d_applicant_estimate?: Nullable<number>;
  cat_d_team_estimate?: Nullable<number>;
  cat_e_num?: Nullable<number>;
  cat_e_applicant_estimate?: Nullable<number>;
  cat_e_team_estimate?: Nullable<number>;
  cat_f_num?: Nullable<number>;
  cat_f_applicant_estimate?: Nullable<number>;
  cat_f_team_estimate?: Nullable<number>;
  cat_g_num?: Nullable<number>;
  cat_g_applicant_estimate?: Nullable<number>;
  cat_g_team_estimate?: Nullable<number>;
}

export const defaultValues: IShortSumFormData = {
  population: null,
  total_budget_approved: 0,
  total_budget_balance: 0,
  maintenance_budget_approved: 0,
  maintenance_budget_balance: 0,
  fiscal_year_start: new Date(),
  cat_a_local_funds: 0,
  cat_b_local_funds: 0,
  cat_c_local_funds: 0,
  cat_d_local_funds: 0,
  cat_e_local_funds: 0,
  cat_f_local_funds: 0,
  cat_g_local_funds: 0,
  cat_a_num: 0,
  cat_a_applicant_estimate: 0,
  cat_a_team_estimate: 0,
  cat_b_num: 0,
  cat_b_applicant_estimate: 0,
  cat_b_team_estimate: 0,
  cat_c_num: 0,
  cat_c_applicant_estimate: 0,
  cat_c_team_estimate: 0,
  cat_d_num: 0,
  cat_d_applicant_estimate: 0,
  cat_d_team_estimate: 0,
  cat_e_num: 0,
  cat_e_applicant_estimate: 0,
  cat_e_team_estimate: 0,
  cat_f_num: 0,
  cat_f_applicant_estimate: 0,
  cat_f_team_estimate: 0,
  cat_g_num: 0,
  cat_g_applicant_estimate: 0,
  cat_g_team_estimate: 0,
};

function useShortSumForm() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return useForm<any>({
    defaultValues,
  });
}

export default useShortSumForm;
