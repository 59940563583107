import { Theme } from '@mui/material';

const useStyles = (theme: Theme) => ({
  file: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '12px',
    pr: '36px',
    gap: '4px',
    borderRadius: '4px',
    background: theme.palette.common.white,
    cursor: 'pointer',
    position: 'relative',
    height: 'auto',
    overflow: 'hidden',
  },
  rowContainer: {
    display: 'flex',
    gap: '12px',
  },
  fileNameWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  fileName: {
    fontSize: '14px',
    fontWeight: 700,
    color: theme.palette.grey[500],
    textTransform: 'uppercase',
  },
  fileSize: {
    fontSize: '14px',
    fontWeight: 500,
    color: theme.palette.grey[400],
  },
  removeBtn: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
});

export default useStyles;
