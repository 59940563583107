import React from 'react';
import { Await, defer, LoaderFunctionArgs, useAsyncValue, useLoaderData } from 'react-router-dom';
import { RootState, store } from '@/store';
import apiFetch, { genericErrorMessage } from '@/services/apiFetch.ts';
import { getTeamDetail } from '@/services/teams.endpoint.ts';
import { getEquipmentDataSet } from '@/services/equipment.endpoint.ts';
import { TeamsDetails } from 'interfaces/teams.interface.ts';
import { EquipmentDetails } from 'interfaces/equipment.interface.ts';
import { PersonnelDetails } from 'interfaces/personnel.interface.ts';
import { Loading } from 'components/primitive';
import { getPersonnelDataSets } from '@/services/personnel.endpoint.ts';
import UserType from '../../../common/enum/UserType.ts';

const TeamDetails = React.lazy(() => import('./Details.tsx'));

interface TeamDataResponse {
  data: [
    { count: number; dataset: EquipmentDetails[] },
    {
      count: number;
      dataset: PersonnelDetails[];
    },
    TeamsDetails | null,
    { groupName: string | null },
  ];
}

// eslint-disable-next-line react-refresh/only-export-components
export const loaderTeamsDetails = ({ params }: LoaderFunctionArgs) => {
  try {
    const state = store.getState() as RootState;
    const {
      userType,
      selectedGroupName,
      selectedGroupNameForParcels,
      selectedGroup,
      selectedGroupID,
    } = state.accountsInfo;
    const activeGroup = selectedGroup ? selectedGroup : selectedGroupID;
    const { id } = params;
    const urlGroup = `api/equipment?filters[group_id]=${activeGroup}&offset=0&limit=1000`;
    const urlPersonnel = `api/personnel?filters[group_id]=${activeGroup}&offset=0&limit=1000`;
    const urlTeams = `/api/teams/${id}`;
    const endpointTeam = getTeamDetail(urlTeams);
    const endpointGroup = getEquipmentDataSet(urlGroup);
    const endpointPersonnel = getPersonnelDataSets(urlPersonnel);
    let foundGroup: string | null;
    if (userType === UserType.STATE_USER) {
      foundGroup = selectedGroupName;
    } else {
      foundGroup = selectedGroupNameForParcels ?? null;
    }
    let promise = [];
    if (promise.length) promise = [];
    promise.push(
      apiFetch<{
        count: number;
        dataset: EquipmentDetails[];
      }>(endpointGroup)
        .then((res) => res.data ?? { count: 0, dataset: [] })
        .catch(() => ({ count: 0, dataset: [] }))
    );
    promise.push(
      apiFetch<{
        count: number;
        dataset: PersonnelDetails[];
      }>(endpointPersonnel)
        .then((res) => res.data ?? { count: 0, dataset: [] })
        .catch(() => ({
          count: 0,
          dataset: [],
        }))
    );
    if (id) {
      promise.push(apiFetch<TeamsDetails>(endpointTeam).then((res) => res.data ?? null));
    } else {
      promise.push(Promise.resolve(null));
    }
    promise.push({ groupName: foundGroup });
    const teamData = Promise.all(promise);
    return defer({ data: teamData });
  } catch (error) {
    let message = genericErrorMessage;
    if (error instanceof Error) message = error.message;
    else {
      if (typeof error === 'object' && error !== null) {
        if ('message' in error) {
          message = String(error.message);
        }
      }
    }
    throw Error(message);
  }
};

function RenderAwaitedData() {
  const data = useAsyncValue() as [
    { count: number; dataset: EquipmentDetails[] },
    {
      count: number;
      dataset: PersonnelDetails[];
    },
    TeamsDetails | null,
    { groupName: string | null },
  ];
  const isNew = data[2] === null;
  return <TeamDetails data={data} newEntry={isNew} />;
}

const DetailsContainer = () => {
  const data = useLoaderData() as TeamDataResponse;
  return (
    <React.Suspense fallback={<Loading />}>
      <Await resolve={data.data} errorElement={<p>Error loading teams!</p>}>
        <RenderAwaitedData />
      </Await>
    </React.Suspense>
  );
};

export default DetailsContainer;
