import type { RootState } from '@/store';

export const selectedIncidentSelector = (state: RootState) => state.incidents.selectedIncident;
export const userIncidentsSelector = (state: RootState) => state.incidents.userIncidents;
export const incidentForParcelSyncSelector = (state: RootState) =>
  state.incidents.selectedIncidentForParcelSync;
export const JPDAIncidentSettingValueSelector = (state: RootState) =>
  state.incidents.selectedJPDAIncidentSettingValue;
export const isJPDA_IA_ActiveSelector = (state: RootState) => state.incidents.isJPDA_IA_Active;
export const isJPDA_PA_ActiveSelector = (state: RootState) => state.incidents.isJPDA_PA_Active;
