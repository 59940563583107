/* eslint-disable no-unused-vars */
enum PostingOptions {
  Unsafe = 'Unsafe',
  RestrictedUse = 'Restricted Use',
  Inspected = 'Inspected',
}

enum PostingTheme {
  Unsafe = '#990013',
  RestrictedUse = '#c2b61b',
  Inspected = '#417505',
}

enum PostingThemeBG {
  Unsafe = 'linear-gradient(0deg, rgba(153,0,19, 0.04) 0%, rgba(153,15,32, 0.04) 100%), #FFF',
  RestrictedUse = 'linear-gradient(0deg, rgba(194,182,27, 0.04) 0%, rgba(194, 182, 27, 0.04) 100%), #FFF',
  Inspected = 'linear-gradient(0deg, rgba(65, 117, 5, 0.04) 0%, rgba(38, 92, 0, 0.04) 100%), #FFF',
}

export { PostingOptions, PostingTheme, PostingThemeBG };
