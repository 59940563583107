/* eslint-disable no-unused-vars */
import React from 'react';
import {
  FormControl,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  useTheme,
} from '@mui/material';
import useStyles from './DrawerMenu.styles.ts';
import { Button, Modal, TextField, Typo } from 'components/primitive';
import CloseIcon from '@mui/icons-material/Close';
import { HeaderComponent } from '@zawarski/palmetto-ui-components';
import Box from '@mui/material/Box';
import { Controller, useFormContext } from 'react-hook-form';
import { RadioSelectOptions } from 'interfaces/SelectOptions.interface.ts';
import { FieldName } from 'hooks/useSideMenu.ts';
import TurnedInNotIcon from '@mui/icons-material/TurnedInNot';

type ModalSelectProps = {
  headerTitle: string;
  optionData: 'incidentOptions' | 'groupOptions' | 'applicantsOptions';
  isSaving: boolean;
  withFilter?: boolean;
  onClose: (action: boolean) => void;
  onConfirm: (fieldName: FieldName) => void;
};

const ModalSelect: React.FC<ModalSelectProps> = ({
  onClose,
  onConfirm,
  headerTitle,
  optionData,
  isSaving = false,
  withFilter = false,
}) => {
  const { control, watch } = useFormContext();
  const options: RadioSelectOptions[] = watch(optionData);
  const isModalOpen = watch('isOpen');
  const fieldName: FieldName = watch('fieldName');
  const filteredName = watch('filterName');
  const theme = useTheme();
  const classes = useStyles(theme);
  return (
    <Modal open={isModalOpen} onClose={!isSaving ? () => onClose(false) : undefined}>
      <>
        <HeaderComponent
          title={headerTitle}
          icon={
            <IconButton onClick={!isSaving ? () => onClose(false) : undefined}>
              <CloseIcon className='icon' />
            </IconButton>
          }
        />
        <Box sx={classes.modal.content}>
          {withFilter && (
            <Controller
              control={control}
              name='filterName'
              render={({ field: { onChange, value } }) => (
                <TextField
                  label='Filter search'
                  placeholder='Enter search'
                  value={value}
                  onChange={onChange}
                  fullWidth
                />
              )}
            />
          )}
          <Controller
            control={control}
            name={fieldName}
            render={({ field: { onChange, value } }) => (
              <FormControl sx={classes.modal.controllerWrapper}>
                <RadioGroup
                  sx={classes.modal.controllerWrapper}
                  aria-labelledby='demo-controlled-radio-buttons-group'
                  name='controlled-radio-buttons-group'
                  value={value}
                  onChange={onChange}>
                  {options && options.length
                    ? options.map((item) => {
                        const isActive = Number(value) === item.value;
                        const withIndicator = item?.withIndicator;
                        if (
                          withFilter &&
                          filteredName &&
                          !item.label.toLowerCase().includes(filteredName.toLowerCase())
                        ) {
                          return null;
                        }
                        return (
                          <FormControlLabel
                            sx={[
                              classes.modal.radioGroupWrapper,
                              isActive ? { border: `1px solid ${theme.palette.primary.main}` } : {},
                            ]}
                            key={item.value}
                            control={<Radio />}
                            value={item.value}
                            label={
                              withIndicator ? (
                                <Box sx={classes.modal.labelWrapper}>
                                  <Box sx={classes.modal.textWrapper}>
                                    <Typo fontWeight={500}>{item.label}</Typo>
                                  </Box>
                                  <TurnedInNotIcon fontSize={'small'} />
                                </Box>
                              ) : (
                                <Typo fontWeight={500}>{item.label}</Typo>
                              )
                            }
                          />
                        );
                      })
                    : null}
                </RadioGroup>
              </FormControl>
            )}
          />
        </Box>
        <Box sx={classes.modal.footer}>
          <Box sx={classes.modal.footerWrapper}>
            <Button
              fullWidth
              neutral
              sx={{ color: theme.palette.text.secondary }}
              onClick={!isSaving ? () => onClose(false) : undefined}>
              CANCEL
            </Button>
            <Button fullWidth progress={isSaving} onClick={() => onConfirm(fieldName)}>
              CONFIRM
            </Button>
          </Box>
        </Box>
      </>
    </Modal>
  );
};

export default ModalSelect;
