import React from 'react';
import { NumericFormat as NumberFormat } from 'react-number-format';
import useStyles from './CardInfo.styles.ts';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import { Typo } from 'components/primitive';
import MoneyIcon from '@mui/icons-material/AttachMoneyRounded';

type CardInfoProps = {
  startIcon: React.ReactElement;
  title?: string;
  subTitle: string;
  isDollar?: boolean;
  value?: number;
};

const CardInfo: React.FC<CardInfoProps> = ({
  startIcon,
  title = '',
  subTitle,
  isDollar = false,
  value = 0,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <Box sx={classes.container}>
      <Box sx={classes.iconWrapper}>{startIcon}</Box>
      <Box sx={classes.groupInfo}>
        <Typo variant={'h2'} fontWeight={500}>
          {!isDollar ? (
            title
          ) : (
            <Box sx={classes.numericWrapper}>
              <MoneyIcon sx={{ color: theme.palette.grey[400], fontSize: '32px' }} />
              <NumberFormat
                value={value.toFixed(2)}
                displayType={'text'}
                thousandSeparator={true}
                decimalScale={2}
              />
            </Box>
          )}
        </Typo>
        <Typo color={theme.palette.grey[400]} fontWeight={400}>
          {subTitle}
        </Typo>
      </Box>
    </Box>
  );
};

export default CardInfo;
