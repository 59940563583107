import { Nullable } from 'interfaces/Nullable.ts';
import { QueryState } from '@/common/enum';
import { ApplicantType } from 'interfaces/applicants.interface.ts';

export type ApplicantsStateTypes = {
  queryState: QueryState;
  error: Nullable<string>;
  applicants: Nullable<ApplicantType[]>;
  stateWideApplicants: Nullable<ApplicantType[]>;
  selectedApplicant: Nullable<ApplicantType>;
  userSelectedApplicantSettingID: Nullable<number>;
};

export const ApplicantsInitialState: ApplicantsStateTypes = {
  queryState: QueryState.UNDEFINED,
  error: null,
  applicants: [],
  stateWideApplicants: [],
  selectedApplicant: null,
  userSelectedApplicantSettingID: null,
};
