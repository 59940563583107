import { Theme } from '@mui/material';

const useStyles = (theme: Theme) => ({
  container: {
    display: 'flex',
    padding: '12px 16px',
    alignItems: 'center',
    gap: '16px',
    flex: '1 0 0',
    borderRadius: '8px',
    background: theme.palette.common.white,
    boxShadow: '0px 0.85px 3px 0px rgba(0, 0, 0, 0.19), 0px 0.25px 1px 0px rgba(0, 0, 0, 0.04)',
  },
  iconWrapper: {
    display: 'flex',
    width: '48px',
    height: '48px',
    paddingBottom: '2px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '32px',
    border: '1px solid rgba(151, 151, 151, 0.08)',
    background:
      'linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.04) 100%), #F4F4F4',
  },
  groupInfo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '2px',
  },
  numericWrapper: {
    display: 'flex',
    gap: '2px',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '100%',
  },
});

export default useStyles;
