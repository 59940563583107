import React, { useEffect, useRef } from 'react';
import { Chart } from 'chart.js/auto';
import { ProjectColor } from '@/common/static';
import { DamageTypeObj, StructureTypes } from 'interfaces/ia.interface.ts';

type BarChartProps = {
  data: number[];
  labels: string[];
  barChartColors?: string[];
  tooltipData?: DamageTypeObj;
};

const BarChart: React.FC<BarChartProps> = ({
  data,
  tooltipData,
  labels,
  barChartColors = Object.values(ProjectColor),
}) => {
  const chartRef = useRef<HTMLCanvasElement>(null);
  const chartId = useRef<string | null>(null);

  useEffect(() => {
    if (chartId.current !== null) {
      return;
    }
    if (chartRef.current) {
      const ctx = chartRef.current.getContext('2d');
      if (ctx) {
        const dataChart = new Chart(ctx, {
          type: 'bar',
          data: {
            labels: labels,
            datasets: [
              {
                label: 'Data',
                data: data,
                backgroundColor: barChartColors,
                borderColor: barChartColors,
                borderWidth: 1,
                barPercentage: 0.5,
                barThickness: 30,
                maxBarThickness: 60,
                minBarLength: 2,
                borderRadius: 6,
              },
            ],
          },
          options: {
            scales: {
              y: {
                beginAtZero: true,
              },
            },
            plugins: {
              legend: {
                display: false,
              },
              tooltip: {
                callbacks: {
                  label: function (context) {
                    return context.dataset.label + ': ' + context.parsed.y;
                  },
                  footer: (tooltipItems) => {
                    if (!tooltipData) return [];
                    const key = tooltipItems[0].label.toLowerCase().trim() as
                      | 'affected'
                      | 'minor'
                      | 'major'
                      | 'inaccessible'
                      | 'destroyed'
                      | 'no damage';
                    const addOnInfo: string[] = [];
                    const objData = tooltipData[key];
                    const objKeys = Object.keys(objData);
                    if (objKeys.length > 0) {
                      objKeys.forEach((key) => {
                        const keyValue = key as StructureTypes;
                        const objValue = objData[keyValue];
                        if (objValue > 0) {
                          if (keyValue === 'business') addOnInfo.push(`Business: ${objValue}`);
                          if (keyValue === 'single') addOnInfo.push(`Single Family: ${objValue}`);
                          if (keyValue === 'multi') addOnInfo.push(`Multi Family: ${objValue}`);
                          if (keyValue === 'manufactured')
                            addOnInfo.push(`Manufactured Home: ${objValue}`);
                          if (keyValue === 'mobile') addOnInfo.push(`Mobile: ${objValue}`);
                        }
                      });
                    }
                    return addOnInfo;
                  },
                },
              },
            },
          },
        });
        chartId.current = dataChart.id;
      }
    }
  }, [barChartColors, data, labels, tooltipData]);

  return <canvas ref={chartRef} />;
};

export default BarChart;
