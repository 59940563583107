/* eslint-disable @typescript-eslint/no-explicit-any,no-unused-vars */
import { SubmitHandler, UseFormReturn } from 'react-hook-form';
import { IShortSumFormData } from './hook/useShortSumForm.ts';
import React from 'react';
import { FixColumnConfig } from 'interfaces/PalmettoReactTableTypes.ts';

export const PA_COLS_REPORT: FixColumnConfig[] = [
  {
    title: 'Address',
    path: 'address',
    checked: true,
  },
  {
    title: 'Date',
    path: 'date',
    checked: true,
  },
  {
    title: 'County',
    path: 'county',
    checked: true,
  },
  {
    title: 'Name',
    path: 'name',
    checked: true,
  },
  {
    title: 'Last updated',
    path: 'updated_at',
    checked: true,
  },
  {
    title: 'Files Attached',
    path: 'downloadLink',
    checked: true,
  },
];

interface IShortSummaryProps {
  readOnly: boolean;
  permissions: { UPDATE: boolean };
  isSaving: boolean;
  form: UseFormReturn<any>;
  anchorEl: null | HTMLElement;
  toggleDrawer: () => void;
  handleEdit: () => void;
  handleCancel: () => void;
  handlePAPrintShortFormReport: () => void;
  handleCatReport: () => void;
  handlePrintTable: () => void;
  handleCloseMenu: (e: React.MouseEvent<HTMLButtonElement>) => void;
  handleMenuClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onSubmit: SubmitHandler<IShortSumFormData>;
}

export type ShortSummaryViewProps = IShortSummaryProps;
