import useScript from 'hooks/useScript.ts';
import L from 'leaflet';
import 'leaflet.markercluster';

window.L = L; // The libraries loaded later will complain if we don't set this

function ScriptsLoader() {
  useScript('https://palmetto-dma-pwa-lib.s3.amazonaws.com/sql-allow-memory-growth.js');
  useScript('https://palmetto-dma-pwa-lib.s3.amazonaws.com/Leaflet.VectorGrid.bundled.min.js');
  useScript('https://palmetto-dma-pwa-lib.s3.amazonaws.com/ctxtextpath.js');
  useScript('https://palmetto-dma-pwa-lib.s3.amazonaws.com/leaflet-compass.min.js');
  useScript('https://unpkg.com/esri-leaflet-vector@4.2.3/dist/esri-leaflet-vector.js');
  useScript(
    'https://palmetto-dma-pwa-lib.s3.amazonaws.com/esri-leaflet-3.0.12-renderer-omnivore-webmap.js'
  );
  return null;
}

export default ScriptsLoader;
