/* eslint-disable no-unused-vars */
import React from 'react';
import { NumericFormat } from 'react-number-format';

interface DecimalNumberProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  inputRef: any;
  onChange: (event: { target: { value: string } }) => void;
}

const DecimalNumber: React.FC<DecimalNumberProps> = (props) => {
  const { inputRef, onChange, ...others } = props;
  return (
    <NumericFormat
      {...others}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      allowNegative={false}
      decimalScale={2}
      fixedDecimalScale={true}
      thousandSeparator={true}
      inputMode='decimal'
      pattern='[0-9]*'
    />
  );
};

export default DecimalNumber;
