import type IEndPoint from 'interfaces/EndPointTypes.ts';
import { AuthProtect, BaseURLSelect } from '@/common/enum';
import { MapLayerSettings } from 'interfaces/map.interface.ts';

export const saveMapIDSettings = (data: MapLayerSettings): IEndPoint => ({
  authorization: AuthProtect.REQUIRED,
  method: 'PUT',
  url: '/api/settings',
  data,
  defaultBaseURL: BaseURLSelect.DMA,
});

export const getMapLayerSettings = (params: {
  pvGroupID: number;
  pvSettingType: string;
}): IEndPoint => ({
  authorization: AuthProtect.REQUIRED,
  method: 'GET',
  url: '/api/settings',
  params,
  defaultBaseURL: BaseURLSelect.DMA,
});
