// noinspection JSUnusedGlobalSymbols

import { Action, combineReducers, configureStore, ThunkAction } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist';
import sideMenuReducer from './feature/sidemenu/sideMenu.slice.ts';
import authTokenReducer from './feature/authToken/authToken.slice.ts';
import accountsInfoReducer from './feature/accountsInfo/accountsInfo.slice.ts';
import incidentsReducer from './feature/incidents/incidents.slice.ts';
import dashboardReducer from './feature/dashboard/dashboard.slice.ts';
import iaReducer from './feature/ia/ia.slice.ts';
import paReducer from './feature/pa/pa.slice.ts';
import equipmentReducer from './feature/equipment/equipment.slice.ts';
import teamsReducer from './feature/teams/teams.slice.ts';
import mapReducer from './feature/map/map.slice.ts';
import applicantsReducer from './feature/applicants/applicants.slice.ts';

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  whitelist: [],
};

const rootReducer = combineReducers({
  menu: sideMenuReducer,
  authToken: authTokenReducer,
  accountsInfo: accountsInfoReducer,
  incidents: incidentsReducer,
  dashboard: dashboardReducer,
  ia: iaReducer,
  pa: paReducer,
  equipment: equipmentReducer,
  teams: teamsReducer,
  map: mapReducer,
  applicants: applicantsReducer,
});

const NODE_ENV = import.meta.env.MODE;

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action>;
