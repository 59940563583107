import React, { useEffect, useMemo } from 'react';
import { Grid, useTheme } from '@mui/material';
import { BarChart, CardInfo } from 'components/fragment';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import PaidRoundedIcon from '@mui/icons-material/PaidRounded';
import AttachMoneyRoundedIcon from '@mui/icons-material/AttachMoneyRounded';
import { useAppDispatch, useAppSelector } from 'store/hooks.ts';
import { fetchPAHomePage } from 'store/feature/dashboard/dashboard.thunk.ts';
import {
  dbQueryStateSelector,
  paDataSelector,
} from 'store/feature/dashboard/dashboard.selector.ts';
import { QueryState } from '@/common/enum';
import Box from '@mui/material/Box';
import { Loading, Typo } from 'components/primitive';
import { ICost } from 'interfaces/DashboardTypes.ts';
import { CategoryLabels, DefaultEstCostByCategory } from '@/common/static';
import calculateSum from 'utils/CaculateSumByCategory';
import calculateTotalEstimatedCost from 'utils/CalculateTotalEstimatedCost.ts';
import useStyles from 'pages/Dashboard/PAPage/PAPage.styles.ts';
import { PADashboardData } from 'interfaces/ia.interface.ts';
import { Nullable } from 'interfaces/Nullable.ts';

const PACountyView = () => {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(dbQueryStateSelector) === QueryState.AWAIT;
  const isReady = useAppSelector(dbQueryStateSelector) === QueryState.READY;
  const paData = useAppSelector(paDataSelector);
  const theme = useTheme();
  const classes = useStyles(theme);

  function generateData(isReady: boolean, data: Nullable<PADashboardData>) {
    let totalCountyThreshold = 0;
    let totalCount = 0;
    const graphData = [0, 0, 0, 0, 0, 0, 0];
    const ttlEstCostByCat: ICost[] = DefaultEstCostByCategory;
    if (!isReady || !data)
      return {
        totalCountyThreshold,
        paReportedDamageCounty: totalCount,
        totalEstimatedByCategoryCards: ttlEstCostByCat,
        graphData,
      };
    const {
      countiesReportingDamage,
      totalEstimatedCostByCategoryByCounty,
      totalEstimatedCostByCategory,
    } = data;

    if (countiesReportingDamage && countiesReportingDamage.length) {
      totalCount = countiesReportingDamage.reduce((acc, cur) => acc + cur.count, 0);
    }
    const ttlEstCostByCatByCounty = totalEstimatedCostByCategoryByCounty || [];

    if (ttlEstCostByCatByCounty && ttlEstCostByCatByCounty.length) {
      totalCountyThreshold = Number(ttlEstCostByCatByCounty[0].Threshold) ?? 0;
      const sumCatA = calculateSum(ttlEstCostByCatByCounty, 'CatA');
      const sumCatB = calculateSum(ttlEstCostByCatByCounty, 'CatB');
      const sumCatC = calculateSum(ttlEstCostByCatByCounty, 'CatC');
      const sumCatD = calculateSum(ttlEstCostByCatByCounty, 'CatD');
      const sumCatE = calculateSum(ttlEstCostByCatByCounty, 'CatE');
      const sumCatF = calculateSum(ttlEstCostByCatByCounty, 'CatF');
      const sumCatG = calculateSum(ttlEstCostByCatByCounty, 'CatG');
      if (totalEstimatedCostByCategory && totalEstimatedCostByCategory.length) {
        for (const iterator of totalEstimatedCostByCategory) {
          const { category } = iterator;
          if (category === 'A') {
            ttlEstCostByCat[0]['sum'] = sumCatA;
          }
          if (category === 'B') {
            ttlEstCostByCat[1]['sum'] = sumCatB;
          }
          if (category === 'C') {
            ttlEstCostByCat[2]['sum'] = sumCatC;
          }
          if (category === 'D') {
            ttlEstCostByCat[3]['sum'] = sumCatD;
          }
          if (category === 'E') {
            ttlEstCostByCat[4]['sum'] = sumCatE;
          }
          if (category === 'F') {
            ttlEstCostByCat[5]['sum'] = sumCatF;
          }
          if (category === 'G') {
            ttlEstCostByCat[6]['sum'] = sumCatG;
          }
        }
      }
      graphData[0] = sumCatA;
      graphData[1] = sumCatB;
      graphData[2] = sumCatC;
      graphData[3] = sumCatD;
      graphData[4] = sumCatE;
      graphData[5] = sumCatF;
      graphData[6] = sumCatG;
    }

    return {
      totalCountyThreshold,
      paReportedDamageCounty: totalCount,
      totalEstimatedByCategoryCards: ttlEstCostByCat,
      graphData,
    };
  }

  const paDataResp = useMemo(() => generateData(isReady, paData), [isReady, paData]);

  useEffect(() => {
    const fetchData = () => dispatch(fetchPAHomePage());
    fetchData();
  }, [dispatch]);

  if (isLoading || !paData)
    return (
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          height: '100vh',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <Loading message={''} />
      </Box>
    );

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <CardInfo
            startIcon={<WarningRoundedIcon sx={{ color: theme.palette.primary.main }} />}
            title={String(paDataResp.paReportedDamageCounty)}
            subTitle={'Counties Reported PA Damage'}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <CardInfo
            isDollar
            startIcon={<PaidRoundedIcon sx={{ color: theme.palette.primary.main }} />}
            value={calculateTotalEstimatedCost(paDataResp.totalEstimatedByCategoryCards)}
            subTitle={'Total Estimated Cost'}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <CardInfo
            isDollar
            startIcon={<AttachMoneyRoundedIcon sx={{ color: theme.palette.primary.main }} />}
            value={paDataResp.totalCountyThreshold}
            subTitle={'County Threshold'}
          />
        </Grid>
        <Grid item xs={12} sx={classes.gridWrapper}>
          <Typo
            fontWeight={700}
            color={theme.palette.text.secondary}
            sx={{ textTransform: 'uppercase' }}>
            Cost ESTIMATE BY CATEGORY
          </Typo>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Box sx={classes.chartContainer}>
                {isReady && (
                  <BarChart
                    labels={CategoryLabels}
                    data={paDataResp.graphData}
                    barChartColors={['#007499']}
                  />
                )}
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default PACountyView;
