import React from 'react';
import apiFetch, { genericErrorMessage } from '@/services/apiFetch.ts';
import { RootState, store } from '@/store';
import { PersonnelDetails } from 'interfaces/personnel.interface.ts';
import { getCatBLaborData, getCatBLaborNames } from '@/services/category.endpoint.ts';
import { Await, defer, LoaderFunctionArgs, useAsyncValue, useLoaderData } from 'react-router-dom';
import { Loading } from 'components/primitive';
import { ErrorHandler } from 'components/fragment';
import { CatBLaborObj } from 'interfaces/category.interface.ts';

const DetailsPage = React.lazy(() => import('./Detail.container'));

export interface LaborDetailsResponse {
  data: [{ dataset: PersonnelDetails[] }, CatBLaborObj | null];
}

// eslint-disable-next-line react-refresh/only-export-components
export const loaderLaborDetails = ({ params }: LoaderFunctionArgs) => {
  try {
    const { id } = params;
    const state = store.getState() as RootState;
    const { selectedGroupID } = state.accountsInfo;
    const url = `/api/catB/labor/getNames?filters[group_id]=${selectedGroupID}`;
    const promise = [];
    const endpointLaborNames = getCatBLaborNames(url);
    promise.push(
      apiFetch<{ dataset: PersonnelDetails[] }>(endpointLaborNames).then((res) => res.data ?? [])
    );
    if (id) {
      const endpointLaborData = getCatBLaborData(`/api/catB/labor/${id}`);
      promise.push(
        apiFetch<CatBLaborObj | null>(endpointLaborData).then((res) => res.data ?? null)
      );
    }

    const laborData = Promise.all(promise);
    return defer({
      data: laborData,
    });
  } catch (error) {
    let message = genericErrorMessage;
    if (error instanceof Error) message = error.message;
    else {
      if (typeof error === 'object' && error !== null) {
        if ('message' in error) {
          message = String(error.message);
        }
      }
    }
    throw Error(message);
  }
};

function RenderAwaitedData(props: { newEntry: boolean }) {
  const { newEntry } = props;
  const data = useAsyncValue() as [{ dataset: PersonnelDetails[] }, CatBLaborObj];
  const isNew = newEntry ?? data[1] === null;
  return <DetailsPage newEntry={isNew} data={data} />;
}

const LaborDetails: React.FC<{ newEntry: boolean }> = ({ newEntry }) => {
  const data = useLoaderData() as LaborDetailsResponse;
  return (
    <React.Suspense fallback={<Loading />}>
      <Await resolve={data.data} errorElement={<ErrorHandler headerTitle='Category B: Labor' />}>
        <RenderAwaitedData newEntry={newEntry} />
      </Await>
    </React.Suspense>
  );
};

export default LaborDetails;
