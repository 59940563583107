import { createSlice } from '@reduxjs/toolkit';
import { TeamsInitialState, TeamsStateTypes } from 'store/feature/teams/teams.state.ts';
import { postTeam, putTeam } from 'store/feature/teams/teams.thunk.ts';
import { QueryState } from '@/common/enum';

const initialState: TeamsStateTypes = TeamsInitialState;

export const teamsSlice = createSlice({
  name: 'teams',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(postTeam.pending, (state) => {
        state.queryState = QueryState.AWAIT;
        state.error = null;
      })
      .addCase(postTeam.fulfilled, (state) => {
        state.queryState = QueryState.READY;
      })
      .addCase(postTeam.rejected, (state, action) => {
        state.queryState = QueryState.FAIL;
        state.error = action.error.message ?? 'Something went wrong!';
      })
      .addCase(putTeam.pending, (state) => {
        state.queryState = QueryState.AWAIT;
        state.error = null;
      })
      .addCase(putTeam.fulfilled, (state) => {
        state.queryState = QueryState.READY;
      })
      .addCase(putTeam.rejected, (state, action) => {
        state.queryState = QueryState.FAIL;
        state.error = action.error.message ?? 'Something went wrong!';
      });
  },
});

export default teamsSlice.reducer;
