import { createSlice } from '@reduxjs/toolkit';
import { EquipmentInitialState, EquipmentStateType } from './equipment.state.ts';
import { saveEqData } from 'store/feature/equipment/equipment.thunk.ts';
import QueryState from '@/common/enum/QueryState.ts';

const initialState: EquipmentStateType = EquipmentInitialState;

export const equipmentSlice = createSlice({
  name: 'equipment',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(saveEqData.pending, (state) => {
        state.queryState = QueryState.AWAIT;
        state.error = null;
      })
      .addCase(saveEqData.fulfilled, (state) => {
        state.queryState = QueryState.CREATED;
      })
      .addCase(saveEqData.rejected, (state, action) => {
        state.queryState = QueryState.FAIL;
        state.error = action.error.message ?? 'Something went wrong';
      });
  },
});

export default equipmentSlice.reducer;
