import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PAInitialState, PAStateTypes } from './pa.state.ts';
import { fetchPADataSet } from 'store/feature/pa/pa.thunk.ts';
import { QueryState } from '@/common/enum';
import { PAData } from 'interfaces/pa.interface.ts';
import {
  LegendTypes,
  MarkerTypeWithoutIcon,
} from 'pages/MapPage/components/ModalLegends/Constants.tsx';

const initialState: PAStateTypes = PAInitialState;

export const paSlice = createSlice({
  name: 'pa',
  initialState,
  reducers: {
    setCurrentPAEntry: (state, action: PayloadAction<PAData | null>) => {
      state.currentEntry = action.payload;
    },
    setCurrentPATitle: (state, action: PayloadAction<string>) => {
      state.paTitle = action.payload;
    },
    updatePADataSet: (state, action: PayloadAction<PAData[]>) => {
      if (!state.paDataset) return;
      state.paDataset.dataset = action.payload;
    },
    updateMapLegendTypes: (state, action: PayloadAction<MarkerTypeWithoutIcon[]>) => {
      state.mapLegendTypes = action.payload;
    },
    setAllCheckedTypes: (state, action: PayloadAction<boolean>) => {
      state.allCheckedTypes = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchPADataSet.pending, (state) => {
        state.queryState = QueryState.AWAIT;
        state.error = null;
      })
      .addCase(fetchPADataSet.fulfilled, (state, action) => {
        state.queryState = QueryState.READY;
        state.paDataset = action.payload ?? null;
        if (action.payload && action.payload.dataset) {
          state.allCheckedTypes = true;
          state.originalDataset = action.payload.dataset;
          state.mapLegendTypes = LegendTypes.map(({ icon, ...rest }) => rest);
        }
      })
      .addCase(fetchPADataSet.rejected, (state, action) => {
        state.queryState = QueryState.FAIL;
        state.error = action.error.message ?? 'Something went wrong!';
      });
  },
});

export const {
  setCurrentPAEntry,
  setCurrentPATitle,
  updatePADataSet,
  updateMapLegendTypes,
  setAllCheckedTypes,
} = paSlice.actions;

export default paSlice.reducer;
