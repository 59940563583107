import { TableColumns } from 'interfaces/MuiTableTypes.ts';

const minWidth = 200;
export const PADataColumns: TableColumns[] = [
  {
    field: 'group_id',
    enabled: false,
    headerName: 'GroupID',
    minWidth,
    numeric: true,
  },
  {
    field: 'group_name',
    enabled: true,
    headerName: 'County',
    minWidth,
    numeric: false,
    align: 'left',
  },
  {
    field: 'CatA',
    enabled: true,
    headerName: 'Cat. A',
    minWidth,
    numeric: true,
  },
  {
    field: 'CatB',
    enabled: true,
    headerName: 'Cat. B',
    minWidth,
    numeric: true,
  },
  {
    field: 'CatC',
    enabled: true,
    headerName: 'Cat. C',
    minWidth,
    numeric: true,
  },
  {
    field: 'CatD',
    enabled: true,
    headerName: 'Cat. D',
    minWidth,
    numeric: true,
  },
  {
    field: 'CatE',
    enabled: true,
    headerName: 'Cat. E',
    minWidth,
    numeric: true,
  },
  {
    field: 'CatF',
    enabled: true,
    headerName: 'Cat. F',
    minWidth,
    numeric: true,
  },
  {
    field: 'CatG',
    enabled: true,
    headerName: 'Cat. G',
    minWidth,
    numeric: true,
  },
  {
    field: 'Threshold',
    enabled: true,
    headerName: 'Threshold',
    minWidth,
    numeric: true,
  },
  {
    field: 'total',
    enabled: true,
    headerName: 'Total',
    minWidth,
    numeric: true,
  },
];
