/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosRequestConfig } from 'axios';
import { FixColumnConfig } from 'interfaces/PalmettoReactTableTypes.ts';

async function HandlePrintTable(
  fileName: string,
  url: string,
  payload: { columns: FixColumnConfig[] }
) {
  try {
    const config: AxiosRequestConfig = {
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
    };
    const DocumentServiceResponse = await axios.post(url, payload, config);
    const AnchorTag = document.createElement('a');
    // Define the url for the document
    const blob = new Blob([DocumentServiceResponse.data], { type: 'application/vnd.ms-excel' });

    // Defining the filename
    const ExcelFileName = `${fileName}.xlsx`;
    // *: IE/Edge specific download
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    if (window.navigator && window.navigator?.msSaveOrOpenBlob) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      window.navigator?.msSaveOrOpenBlob(blob, ExcelFileName);
    } else {
      AnchorTag.href = URL.createObjectURL(blob);
      AnchorTag.target = '_blank';
      AnchorTag.setAttribute('download', ExcelFileName);
      // Attach a link to the body of our public html file
      document.body.appendChild(AnchorTag);
      // Click the link
      AnchorTag.click();
      // Remove the element
      document.body.removeChild(AnchorTag);
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export default HandlePrintTable;
