import { createAsyncThunk } from '@reduxjs/toolkit';
import apiFetch, { genericErrorMessage } from '@/services/apiFetch.ts';
import { RootState } from '@/store';
import { UserType } from '@/common/enum';
import { getIAData, getPAData } from '@/services/dashboard.endpoint.ts';
import { IADashboardData, PADashboardData } from 'interfaces/ia.interface.ts';

export const fetchIAHomePage = createAsyncThunk(
  'fetchIAHomePage',
  async (arg: { selectedGroupIndex?: number; controller?: AbortController }, thunkAPI) => {
    try {
      const { selectedGroupIndex, controller } = arg;
      const { accountsInfo, incidents } = thunkAPI.getState() as RootState;
      const { selectedGroup, selectedGroupID, userType } = accountsInfo;
      const { selectedIncident } = incidents;
      const activeGroup = selectedGroup ? selectedGroup : selectedGroupID;

      if (!selectedIncident) return;
      const incID = selectedIncident?.pvIncidentID;
      let url;
      if (userType && userType === UserType.STATE_USER) {
        url = `filters[incident_id]=${incID}&userType=${UserType.STATE_USER}`;
        if (selectedGroupIndex) url += `&county_dropdown_group_id=${selectedGroupIndex}`;
      } else {
        url = `filters[incident_id]=${incID}&filters[group_id]=${activeGroup}`;
        if (selectedGroupIndex) url += `&county_dropdown_group_id=${selectedGroupIndex}`;
        else url += `&county_dropdown_group_id=${activeGroup}`;
      }
      const endpoint = getIAData(url);
      const respData = await apiFetch<IADashboardData>(endpoint, controller).then(
        (res) => res?.data || null
      );
      return {
        isAllData: !selectedGroupIndex || selectedGroupIndex === 0,
        data: respData,
      };
    } catch (error) {
      let message = genericErrorMessage;
      if (error instanceof Error) message = error.message;
      else {
        if (typeof error === 'object' && error !== null) {
          if ('message' in error) {
            message = String(error.message);
          }
        }
      }
      throw Error(message);
    }
  }
);

export const fetchPAHomePage = createAsyncThunk(
  'fetchPAHomePage',
  async (controller: AbortController | undefined, thunkAPI) => {
    try {
      const { accountsInfo, incidents } = thunkAPI.getState() as RootState;
      const { selectedGroup, selectedGroupID, userType } = accountsInfo;
      const { selectedIncident } = incidents;
      // const { selectedApplicant } = applicants;
      const activeGroup = selectedGroup ? selectedGroup : selectedGroupID;

      if (!selectedIncident) return;
      const incID = selectedIncident?.pvIncidentID;
      // const applicantId = selectedApplicant?.id;
      let url;
      if (userType && userType === UserType.STATE_USER) {
        url = `/api/pa_state/homepage?filters[incident_id]=${incID}`;
      } else {
        url = `/api/pa/homepage?filters[incident_id]=${incID}&filters[group_id]=${activeGroup}`;
      }
      // if (applicantId) {
      //   url += `&filters[applicant_id]=${applicantId}`;
      // }
      const endpoint = getPAData(url);
      return await apiFetch<PADashboardData>(endpoint, controller).then((res) => res?.data || null);
    } catch (error) {
      let message = genericErrorMessage;
      if (error instanceof Error) message = error.message;
      else {
        if (typeof error === 'object' && error !== null) {
          if ('message' in error) {
            message = String(error.message);
          }
        }
      }
      throw Error(message);
    }
  }
);
