import DefaultPaletteOptions from '../defaultPallete';

export default (theme: DefaultPaletteOptions): any => {
  return {
    styleOverrides: {
      root: {
        minWidth: 'unset !important',
        minHeight: '56px',
        fontSize: '16px',
        color: 'unset !important',
        '& .MuiInputBase-input': {
          fontSize: '16px',
          color: theme.common?.black,
        },
        '& .Mui-focused': {
          color: '#767676',
        },
      },
    },
  };
};
