/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosRequestConfig } from 'axios';

// Gets the filename from the content disposition header
function getFilenameFromHeader(contentDisposition: any) {
  // attachment;  filename="ICS_203-Alien Invasion1-0112211159.docx"
  const beginning = 'filename=';
  return contentDisposition
    .substring(contentDisposition.indexOf(beginning) + beginning.length)
    .replaceAll('"', '')
    .replaceAll("'", '');
}

async function HandleDownloadDoc(fileName: string, url: string, token: string) {
  try {
    const config: AxiosRequestConfig = {
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
      params: {
        // eslint-disable-next-line camelcase
        access_token: token,
      },
    };

    const DocumentServiceResponse = await axios.get(url, config);
    let filename = 'document';
    if (fileName) {
      filename = fileName;
    } else if (DocumentServiceResponse.headers['content-disposition']) {
      filename = getFilenameFromHeader(DocumentServiceResponse.headers['content-disposition']);
    }
    const type = DocumentServiceResponse.headers['content-type'] || 'application/vnd.ms-excel';
    const AnchorTag = document.createElement('a');
    // Define the url for the document
    const blob = new Blob([DocumentServiceResponse.data], { type });

    // *: IE/Edge specific download
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    if (window.navigator && window.navigator?.msSaveOrOpenBlob) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      window.navigator?.msSaveOrOpenBlob(blob, filename);
    } else {
      AnchorTag.href = URL.createObjectURL(blob);
      AnchorTag.target = '_blank';
      AnchorTag.setAttribute('download', filename);
      // Attach a link to the body of our public HTML file
      document.body.appendChild(AnchorTag);
      // Click the link
      AnchorTag.click();
      // Remove the element
      document.body.removeChild(AnchorTag);
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export default HandleDownloadDoc;
