import React from 'react';
import useStyles from './InfoModal.styles.ts';
import { HeaderComponent } from '@zawarski/palmetto-ui-components';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material';
import { Button, Modal, Typo } from 'components/primitive';

type InfoModalProps = {
  open: boolean;
  onHandleConfirm: (confirm: boolean) => void;
};

const InfoModal: React.FC<InfoModalProps> = ({ open, onHandleConfirm }) => {
  const theme = useTheme();
  const classes = useStyles();
  return (
    <Modal open={open} onClose={undefined}>
      <>
        <HeaderComponent title='Message' />
        <Box sx={classes.content}>
          <Typo sx={{ fontSize: '16px!important' }}>Save form without entering cost estimate?</Typo>
        </Box>
        <Box sx={classes.footer}>
          <Box sx={classes.footerWrapper}>
            <Button
              fullWidth
              neutral
              sx={{ color: theme.palette.text.secondary }}
              onClick={() => onHandleConfirm(false)}>
              CANCEL
            </Button>
            <Button fullWidth onClick={() => onHandleConfirm(true)}>
              CONFIRM
            </Button>
          </Box>
        </Box>
      </>
    </Modal>
  );
};

export default InfoModal;
