/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import DashboardView from './Dashboard.View';
import { DashboardProps } from './Dashboard.props';
import { ErrorMessage, Loading } from 'components/primitive';
import { useAppDispatch, useAppSelector } from 'store/hooks.ts';
import { QueryState } from '@/common/enum';
import { selectAuthTokenVerification } from 'store/feature/authToken/authToken.selector.ts';
import {
  selectAccountState,
  selectAppLoading,
} from 'store/feature/accountsInfo/accountsInfo.selector.ts';
import {
  fetchAllGroups,
  fetchUserDMASettings,
} from 'store/feature/accountsInfo/accountsInfo.thunk.ts';
import {
  fetchIncidentForParcelSync,
  fetchJPDAIncidentSetting,
  fetchSelectedIncidentMothershipSetting,
  fetchUsersSavedIncidents,
} from 'store/feature/incidents/incidents.thunk.ts';
import { setLoadingState } from 'store/feature/accountsInfo/accountsInfo.actions.ts';
import Box from '@mui/material/Box';
import { setErrorMsg } from 'store/feature/authToken/authToken.action.ts';
import scriptLoader from 'utils/ScriptLoader.ts';
import {
  fetchApplicants,
  fetchSelectedApplicantSettings,
} from 'store/feature/applicants/applicants.thunk.ts';
import { fetchMapLayerID } from 'store/feature/map/map.thunk.ts';
import { applicantsSelector } from 'store/feature/applicants/applicants.selector.ts';
import { initialSetupSelector } from 'store/feature/dashboard/dashboard.selector.ts';
import { setInitialSetup } from 'store/feature/dashboard/dashboard.slice.ts';

const toggleDrawer = () => window.dispatchEvent(new CustomEvent('toggle-sidebar'));

const DashBoard: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const initialSetup = useAppSelector(initialSetupSelector);
  const auth = useAppSelector(selectAuthTokenVerification);
  const applicants = useAppSelector(applicantsSelector);
  const isAppLoading = useAppSelector(selectAppLoading);
  const isError = useAppSelector(selectAccountState) === QueryState.FAIL;
  const isApplicantsEmpty = !applicants?.length;
  const isExecuted = useRef(false);
  scriptLoader();

  const fetchData = async () => {
    await dispatch(fetchUserDMASettings(undefined))
      .unwrap()
      .then(() => {
        dispatch(fetchSelectedIncidentMothershipSetting(undefined))
          .unwrap()
          .then(async () => {
            await dispatch(fetchIncidentForParcelSync()).unwrap();
            dispatch(fetchUsersSavedIncidents())
              .unwrap()
              .then(async () => {
                const promises = [
                  await dispatch(fetchAllGroups()).unwrap(),
                  await dispatch(fetchJPDAIncidentSetting()).unwrap(),
                  dispatch(fetchApplicants()),
                  dispatch(fetchSelectedApplicantSettings()),
                ];
                await Promise.all(promises);
              })
              .then(() => {
                dispatch(fetchMapLayerID());
                return console.log('FETCH DATA ALL DONE');
              });
          });
      })
      .catch((error) => {
        console.error('FETCHING ALL DATA', error);
        const errorMsg = error.message || 'Something went wrong. Please contact support.';
        dispatch(setErrorMsg(errorMsg));
        dispatch(setLoadingState(false));
        navigate('/login-error');
      });
  };
  useEffect(() => {
    if (auth && isAppLoading && !isExecuted.current) {
      fetchData().finally(() => {
        dispatch(setLoadingState(false));
      });
    }

    return () => {
      isExecuted.current = true;
    };
  }, [auth]);

  const handleInitialSetup = () => {
    dispatch(setInitialSetup(false));
  };

  const combineProps: DashboardProps = {
    toggleDrawer,
    initialSetup,
    handleInitialSetup,
  };

  const container = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    width: '100vw',
  };

  let content = (
    <Box sx={container}>
      <Loading message={'Loading application, please wait...'} />
    </Box>
  );

  if (isAppLoading)
    content = (
      <Box sx={container}>
        <Loading message={'Loading application, please wait...'} />
      </Box>
    );

  if (isError && !isAppLoading)
    content = (
      <Box sx={container}>
        <ErrorMessage message={'Failed to load application. please contact support.'} />
      </Box>
    );

  if (!isError && !isApplicantsEmpty) content = <DashboardView {...combineProps} />;

  return content;
};

export default DashBoard;
