import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MapInitialState, MapStateTypes } from 'store/feature/map/map.state.ts';
import { fetchMapLayerID, saveMapID } from 'store/feature/map/map.thunk.ts';
import { QueryState } from '@/common/enum';
import { genericErrorMessage } from '@/services/apiFetch.ts';

const initialState: MapStateTypes = MapInitialState;

export const mapSlice = createSlice({
  name: 'map',
  initialState,
  reducers: {
    setMapId: (state, action: PayloadAction<string>) => {
      state.mapId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(saveMapID.pending, (state) => {
        state.queryState = QueryState.AWAIT;
        state.error = null;
      })
      .addCase(saveMapID.fulfilled, (state, action) => {
        state.queryState = QueryState.UPDATED;
        state.mapId = action.payload?.pvSettingValue ?? null;
        state.mapSettingsID = action.payload?.pvSettingID ?? null;
      })
      .addCase(saveMapID.rejected, (state, action) => {
        state.queryState = QueryState.FAIL;
        state.error = action.error.message ?? genericErrorMessage;
      })
      .addCase(fetchMapLayerID.fulfilled, (state, action) => {
        const payloadData = action.payload;
        if (Array.isArray(payloadData) && payloadData.length > 0) {
          const data = payloadData[0];
          state.mapId = data.pvSettingValue;
          state.mapSettingsID = data.pvSettingID;
        }
      });
  },
});

export default mapSlice.reducer;
