/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef, useState } from 'react';
import useStyles from './DatePickerField.styles.ts';
import { FieldError } from 'react-hook-form';
import {
  DatePicker,
  DatePickerProps as MuiDatePickerProps,
  LocalizationProvider,
} from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import TextField from '../TextField';

interface DatePickerProps extends MuiDatePickerProps<string> {
  errMsg: FieldError | undefined;
  helperTxt: string | undefined;
  label: string;
  value: any;
  required?: boolean;
  format?: string;
  refFunc?: any;
  readOnly?: boolean;
}

const DatePickerField: React.FC<DatePickerProps> = ({ readOnly = false, ...props }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const dateInput = useRef(null);
  const format = props.format || 'MM/DD/YYYY';

  const toggleDatePicker = () => setOpen(!open);

  const closeDatePicker = () => setOpen(false);

  useEffect(() => {
    props.refFunc &&
      props.refFunc({
        inputRef: dateInput.current,
        toggleDatePicker: toggleDatePicker,
        closeDatePicker: closeDatePicker,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DatePicker
        readOnly={readOnly}
        format={format}
        label={props.label}
        value={props.value}
        onChange={props.onChange}
        slotProps={{ textField: { variant: 'filled' } }}
        slots={{ textField: TextField, ...props.slots }}
        // renderInput={(params: any) => <TextField {...params} />}
        sx={classes.root}
      />
    </LocalizationProvider>
  );
};

export default DatePickerField;
