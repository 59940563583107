import { Theme } from '@mui/material';

const useStyles = (theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    alignSelf: 'stretch',
    background: '#FFF',
    borderRadius: '6px',
    boxShadow: '0px 0.25px 1px 0px rgba(0, 0, 0, 0.04), 0px 0.85px 3px 0px rgba(0, 0, 0, 0.19)',
  },
  sectionHeader: {
    display: 'flex',
    padding: '8px 8px 8px 16px',
    alignItems: 'flex-start',
    gap: '10px',
    alignSelf: 'stretch',
    justifyContent: 'space-between',
    color: theme.palette.primary.dark,
    textTransform: 'uppercase',
    fontSize: '14px',
    fontWeight: 700,
    borderBottom: `1px solid ${theme.palette.secondary.main}`,
  },
  section: {
    body: {
      display: 'flex',
      width: '100%',
      padding: '8px',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '4px',
      background: '#FFF',
    },
    col: {
      display: 'flex',
      padding: '8px',
      width: '100%',
      background: '#FFF',
    },
    row: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      alignContent: 'space-between',
      gap: '4px',
      alignSelf: 'stretch',
    },
  },
});

export default useStyles;
