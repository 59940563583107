import { Theme } from '@mui/material';

const defaultActiveBg =
  'linear-gradient(0deg, rgba(28, 67, 177, 0.04) 0%, rgba(28, 67, 177, 0.04) 100%), #FFF';
const useStyles = (
  theme: Theme,
  isActive: boolean,
  disabled: boolean,
  customActiveBorder?: string,
  customActiveBg?: string
) => ({
  container: {
    display: 'flex',
    marginLeft: '0px',
    pr: '12px',
    borderRadius: '4px',
    border: `1px solid ${isActive ? (customActiveBorder ? customActiveBorder : theme.palette.primary.main) : disabled ? theme.palette.secondary.main : '#C2C2C2'}`,
    background: !isActive
      ? disabled
        ? theme.palette.secondary.main
        : theme.palette.common.white
      : customActiveBg
        ? customActiveBg
        : defaultActiveBg,
    opacity: 1,
    flex: 1,
  },
});

export default useStyles;
