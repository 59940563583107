import { createAsyncThunk } from '@reduxjs/toolkit';
import apiFetch, { genericErrorMessage } from '@/services/apiFetch.ts';
import {
  changeSelectedGroupForMapForStateUser,
  getPalmettoGroups,
  getUserDMASettings,
} from '@/services/accountsInfo.endpoint.ts';
import {
  BodyChangeGroupSettingsType,
  DMAUserSettings,
  PalmettoGroups,
  UserDMASettings,
} from 'interfaces/UserAccountInfo.interface.ts';
import type { RootState } from '@/store';
import { PalmettoGroupsJSON } from '@/common/data';

export const fetchUserDMASettings = createAsyncThunk(
  'fetchUserDMASettings',
  async (controller: AbortController | undefined, thunkAPI) => {
    try {
      const store = thunkAPI.getState() as RootState;
      const account = store.accountsInfo.account;
      const endpoint = getUserDMASettings(account?.id || 0);
      const response = await apiFetch<UserDMASettings | null>(endpoint, controller).then(
        (res) => res?.data || null
      );
      return {
        account,
        response,
      };
    } catch (error) {
      let message = genericErrorMessage;
      if (error instanceof Error) message = error.message;
      else {
        if (typeof error === 'object' && error !== null) {
          if ('message' in error) {
            message = String(error.message);
          }
        }
      }
      throw Error(message);
    }
  }
);

export const fetchAllGroups = createAsyncThunk(
  'fetchAllGroups',
  async (controller?: AbortController) => {
    try {
      const endpoint = getPalmettoGroups();
      return await apiFetch<PalmettoGroups[]>(endpoint, controller)
        .then((res) => res?.data || null)
        .catch(() => {
          console.log('Error fetching groups and use local data');
          return PalmettoGroupsJSON as PalmettoGroups[];
        });
    } catch (error) {
      let message = genericErrorMessage;
      if (error instanceof Error) message = error.message;
      else {
        if (typeof error === 'object' && error !== null) {
          if ('message' in error) {
            message = String(error.message);
          }
        }
      }
      throw Error(message);
    }
  }
);

export const updatedSelectedGroupForMapForStateUser = createAsyncThunk(
  'updatedSelectedGroupForMapForStateUser',
  async (groupId: number, thunkAPI) => {
    try {
      const store = thunkAPI.getState() as RootState;
      const { selectedGroup, selectedGroupID, allGroups } = store.accountsInfo;
      const activeGroup = selectedGroup ? Number(selectedGroup) : selectedGroupID;
      if (groupId === activeGroup) return Promise.reject('Group is already selected');
      const account = store.accountsInfo.account;
      const mapSettingID = store.accountsInfo.stateUserSelectedMapSettingID;
      if (!account) return Promise.reject('Missing required data');
      const payload: BodyChangeGroupSettingsType = {
        pvAccountID: account.id,
        pvSettingType: 'stateUserSelectedGroupForDMAMap',
        pvSettingValue: groupId.toString(),
        pvSettingID: mapSettingID,
      };
      const endpoint = changeSelectedGroupForMapForStateUser(payload);
      const respData = await apiFetch<DMAUserSettings>(endpoint).then((res) => res?.data || null);
      const payloadResp: {
        dmaUserSettings: DMAUserSettings;
        groupSetting: Partial<PalmettoGroups>;
      } = {
        dmaUserSettings: respData,
        groupSetting: {},
      };
      const selectedNewGroupIdx =
        allGroups && allGroups.findIndex((group) => group.pvGroupID === groupId);
      if (selectedNewGroupIdx && selectedNewGroupIdx > 0) {
        payloadResp.groupSetting = allGroups[selectedNewGroupIdx];
      }
      return payloadResp;
    } catch (error) {
      let message = genericErrorMessage;
      if (error instanceof Error) message = error.message;
      else {
        if (typeof error === 'object' && error !== null) {
          if ('message' in error) {
            message = String(error.message);
          }
        }
      }
      throw Error(message);
    }
  }
);
