import { Theme } from '@mui/material';

const useStyles = (theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flex: '1 0 0',
    borderRadius: '8px',
    background: theme.palette.common.white,
    boxShadow: '0px 0.85px 3px 0px rgba(0, 0, 0, 0.19), 0px 0.25px 1px 0px rgba(0, 0, 0, 0.04)',
    height: '100%',
    width: '100%',
  },
  content: {
    display: 'flex',
    padding: '16px',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '10px',
    alignSelf: 'stretch',
    minHeight: '56px',
  },
  bigTitle: {
    // fontSize: '40px !important',
    fontWeight: 700,
    textTransform: 'uppercase',
  },
  numericWrapper: {
    display: 'flex',
    width: '100%',
    gap: '2px',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  sideInfoContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-end',
    gap: '2px',
    flex: '1 0 0',
    alignSelf: 'stretch',
  },
  sideInfoWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '4px',
  },
  sideInfoTitle: {
    textAlign: 'right',
    fontSize: '16px !important',
    color: theme.palette.text.secondary,
  },
  footerContainer: {
    display: 'flex',
    padding: '8px 8px 8px 16px',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '10px',
    alignSelf: 'stretch',
    bgcolor: theme.palette.primary.main,
    borderRadius: '0 0 8px 8px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    marginTop: 'auto',
  },
  // footerTitle: {
  // },
});

export default useStyles;
