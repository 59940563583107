import React, { useEffect, useMemo, useState } from 'react';
import { IconButton, useTheme } from '@mui/material';
import useStyles from './DrawerMenu.styles.ts';
import { Button, CheckButton, Modal, Typo } from 'components/primitive';
import CloseIcon from '@mui/icons-material/Close';
import { HeaderComponent } from '@zawarski/palmetto-ui-components';
import Box from '@mui/material/Box';
import { CheckSelectOptions } from 'interfaces/SelectOptions.interface.ts';
import { PalmettoGroups } from 'interfaces/UserAccountInfo.interface.ts';

type ModalJPDASelectGroupProps = {
  settingType: 'IA' | 'PA';
  actionType: 'Begin' | 'Complete';
  isOpen: boolean;
  isSaving: boolean;
  allGroups: PalmettoGroups[];
  jpdaStarted: number[];
  steps: 1 | 2;
  onClose: () => void;
  // eslint-disable-next-line no-unused-vars
  onSubmit: (selectedOptions: CheckSelectOptions[]) => void;
};

const ModalJPDASelectGroup: React.FC<ModalJPDASelectGroupProps> = ({
  steps,
  settingType,
  actionType,
  allGroups = [],
  jpdaStarted = [],
  isOpen,
  isSaving,
  onClose,
  onSubmit,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const headerTitle = useMemo(() => {
    return steps === 1 ? `${actionType} JPDA - ${settingType}` : `${actionType} JPDA Workflow`;
  }, [actionType, settingType, steps]);

  const [selectOptions, setSelectOptions] = useState<CheckSelectOptions[]>([]);
  const [selectedGroups, setSelectedGroups] = useState<CheckSelectOptions[]>([]);

  function SetBeginSelectionOptions() {
    const options: CheckSelectOptions[] = allGroups.map((group) => ({
      checked: false,
      label: group.pvGroupName,
      value: group.pvGroupID,
    }));
    const activeOptions: CheckSelectOptions[] = [];
    options.forEach((option) => {
      option.disabled = jpdaStarted.includes(Number(option.value));
      activeOptions.push(option);
    });

    setSelectOptions(activeOptions);
  }

  function SetCompleteSelectOptions() {
    const activeGroup = allGroups.filter((group) => jpdaStarted.includes(group.pvGroupID));
    const options: CheckSelectOptions[] = activeGroup.map((group) => ({
      checked: false,
      label: group.pvGroupName,
      value: group.pvGroupID,
    }));
    setSelectOptions(options);
  }

  useEffect(() => {
    if (allGroups && allGroups.length) {
      if (actionType === 'Begin') {
        SetBeginSelectionOptions();
      }
      if (actionType === 'Complete') {
        SetCompleteSelectOptions();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, allGroups, jpdaStarted, actionType]);

  const handleCheck = (e: React.ChangeEvent<HTMLInputElement>, idx: number) => {
    const { checked } = e.target;
    const updatedOptions = [...selectOptions];
    updatedOptions[idx].checked = checked;
    setSelectOptions(updatedOptions);
  };

  function GroupItem(item: CheckSelectOptions, index: number) {
    return (
      <CheckButton
        key={index}
        label={item.label ?? ''}
        value={item.checked}
        disabled={item.disabled}
        onChange={(e) => handleCheck(e, index)}
      />
    );
  }

  const handleSubmit = () => {
    const selectedGroups = selectOptions.filter((group) => group.checked);
    console.log(selectedGroups);
    setSelectedGroups(selectedGroups);
    onSubmit(selectedGroups);
  };

  const handleClose = () => {
    if (steps === 1) return onClose();
    else {
      if (actionType === 'Begin') {
        SetBeginSelectionOptions();
      }
      if (actionType === 'Complete') {
        SetCompleteSelectOptions();
      }
      onClose();
    }
  };

  return (
    <Modal open={isOpen} onClose={!isSaving ? () => onClose() : undefined}>
      <>
        <HeaderComponent
          title={headerTitle}
          icon={
            steps === 1 ? (
              <IconButton onClick={onClose}>
                <CloseIcon className='icon' />
              </IconButton>
            ) : null
          }
        />
        <Box sx={classes.modal.content}>
          {steps === 1 && selectOptions && selectOptions.length ? (
            selectOptions.map((group, idx) => GroupItem(group, idx))
          ) : (
            <Typo>
              You have selected to {actionType.toLowerCase()} JPDAs{' '}
              {selectedGroups.length === 1 ? '1 County' : `${selectedGroups.length} Counties`} Is
              this correct?
            </Typo>
          )}
        </Box>
        <Box sx={classes.modal.footer}>
          <Box sx={classes.modal.footerWrapper}>
            <Button
              fullWidth
              neutral
              disabled={isSaving}
              sx={{ color: theme.palette.text.secondary }}
              onClick={!isSaving ? () => handleClose() : undefined}>
              {steps === 1 ? 'CLOSE' : 'NO'}
            </Button>
            <Button
              fullWidth
              progress={isSaving}
              onClick={!isSaving ? () => handleSubmit() : undefined}>
              {steps === 1 ? `${actionType} JPDA` : 'YES'}
            </Button>
          </Box>
        </Box>
      </>
    </Modal>
  );
};

export default ModalJPDASelectGroup;
