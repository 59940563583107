import React from 'react';
import { Await, defer, useAsyncValue, useLoaderData } from 'react-router-dom';
import { RootState, store } from '@/store';
import apiFetch, { genericErrorMessage } from '@/services/apiFetch.ts';
import { getPAGenInfoData } from '@/services/pa.endpoints.ts';
import { Loading } from 'components/primitive';
import { ErrorHandler } from 'components/fragment';
import GenInfoPage from './GenInfo.container.tsx';
import { PAGenInfoSummary } from 'interfaces/pa.interface.ts';

export interface PAGenInfoDataResponse {
  data: PAGenInfoSummary | null;
}

// eslint-disable-next-line react-refresh/only-export-components
export const loaderGenInfoDetails = () => {
  try {
    const state = store.getState() as RootState;
    const { selectedIncident } = state.incidents;
    const { selectedGroupID, selectedGroup } = state.accountsInfo;
    const activeGroup = selectedGroup ? selectedGroup : selectedGroupID;
    const url = `/api/pagis/${selectedIncident?.pvIncidentID}/${activeGroup}`;
    const endpoint = getPAGenInfoData(url);
    const PAGenInfoData = apiFetch<PAGenInfoSummary>(endpoint).then((res) => res.data ?? null);
    return defer({
      data: PAGenInfoData,
    });
  } catch (error) {
    let message = genericErrorMessage;
    if (error instanceof Error) message = error.message;
    else {
      if (typeof error === 'object' && error !== null) {
        if ('message' in error) {
          message = String(error.message);
        }
      }
    }
    throw Error(message);
  }
};

function RenderAwaitedData() {
  const data = useAsyncValue() as PAGenInfoSummary;
  return <GenInfoPage data={data} />;
}

const GenInfoLoader: React.FC = () => {
  const data = useLoaderData() as PAGenInfoDataResponse;
  return (
    <React.Suspense fallback={<Loading />}>
      <Await resolve={data.data} errorElement={<ErrorHandler headerTitle='General Information' />}>
        <RenderAwaitedData />
      </Await>
    </React.Suspense>
  );
};

export default GenInfoLoader;
