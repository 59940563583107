import React from 'react';
import { Await, defer, LoaderFunctionArgs, useAsyncValue, useLoaderData } from 'react-router-dom';
import { RootState, store } from '@/store';
import { UserType } from '@/common/enum';
import { getIAData } from '@/services/ia.endpoint.ts';
import apiFetch, { genericErrorMessage } from '@/services/apiFetch.ts';
import { IADetails } from 'interfaces/ia.interface.ts';
import { Loading } from 'components/primitive';
import { ErrorHandler } from 'components/fragment';

const DetailsPage = React.lazy(() => import('./Details.tsx'));

export interface IADataResponse {
  iaData: IADetails | null;
}

// eslint-disable-next-line react-refresh/only-export-components
export const loaderDetails = ({ params }: LoaderFunctionArgs) => {
  try {
    const { id } = params;
    const state = store.getState() as RootState;
    const { userType } = state.accountsInfo;
    const url = `/api/${userType === UserType.STATE_USER ? 'ia_state' : 'ia'}/${id}`;
    const endpoint = getIAData(url);
    const IAData = apiFetch<IADetails>(endpoint).then((res) => res.data ?? null);
    return defer({
      iaData: IAData,
    });
  } catch (error) {
    let message = genericErrorMessage;
    if (error instanceof Error) message = error.message;
    else {
      if (typeof error === 'object' && error !== null) {
        if ('message' in error) {
          message = String(error.message);
        }
      }
    }
    throw Error(message);
  }
};

function RenderAwaitedData() {
  const data = useAsyncValue() as IADetails;
  return <DetailsPage data={data} />;
}

const DetailsContainer: React.FC = () => {
  const data = useLoaderData() as IADataResponse;
  return (
    <React.Suspense fallback={<Loading />}>
      <Await
        resolve={data.iaData}
        errorElement={
          <ErrorHandler headerTitle='Individual Assistance' errorMsg='Something went wrong' />
        }>
        <RenderAwaitedData />
      </Await>
    </React.Suspense>
  );
};

export default DetailsContainer;
