import React from 'react';
import apiFetch, { genericErrorMessage } from '@/services/apiFetch.ts';
import { RootState, store } from '@/store';
import { getTeamsDataSet } from '@/services/teams.endpoint.ts';
import { TeamsDetailsView } from 'interfaces/teams.interface.ts';
import { Await, defer, useAsyncValue, useLoaderData } from 'react-router-dom';
import { Loading } from 'components/primitive';

const GridList = React.lazy(() => import('./Grid.tsx'));

interface TeamsDataResponse {
  data: { count: number; dataset: TeamsDetailsView[] };
}

// eslint-disable-next-line react-refresh/only-export-components
export const loaderTeamsDataSet = () => {
  try {
    const state = store.getState() as RootState;
    const selectedUserGroup = state.accountsInfo.selectedGroupID ?? 0;
    const selectedGroup = state.accountsInfo.selectedGroup ?? null;
    const allSubGroups = state.accountsInfo.allSubGroups ?? [];
    const selectedIncident = state.incidents.selectedIncident ?? null;
    const selectedIncidentId = selectedIncident?.pvIncidentID ?? 0;
    const activeGroup = selectedGroup ? Number(selectedGroup) : selectedUserGroup;
    const defaultFilters = [
      `filters[incident_id]=${selectedIncidentId}`,
      `filters[group_id]=${activeGroup}`,
    ];
    if (allSubGroups && allSubGroups.length) {
      allSubGroups.map((item) => {
        defaultFilters.push(`filters[group_id]=${item.pvGroupID}`);
      });
    }
    let url = 'api/teams';
    if (defaultFilters.length) {
      url += `?${defaultFilters.join('&')}`;
    }
    const endpoint = getTeamsDataSet(url);
    const teamsData = apiFetch<TeamsDetailsView[]>(endpoint).then((res) => res.data ?? []);
    return defer({
      data: teamsData,
    });
  } catch (error) {
    let message = genericErrorMessage;
    if (error instanceof Error) message = error.message;
    else {
      if (typeof error === 'object' && error !== null) {
        if ('message' in error) {
          message = String(error.message);
        }
      }
    }
    throw Error(message);
  }
};

function RenderAwaitedData() {
  const data = useAsyncValue() as { count: number; dataset: TeamsDetailsView[] };
  return <GridList data={data} />;
}

const GridContainer = () => {
  const data = useLoaderData() as TeamsDataResponse;
  return (
    <React.Suspense fallback={<Loading />}>
      <Await resolve={data.data} errorElement={<p>Error loading teams data!</p>}>
        <RenderAwaitedData />
      </Await>
    </React.Suspense>
  );
};

export default GridContainer;
