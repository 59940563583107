import { Attributes } from 'interfaces/attribute.interface.ts';

const getPermissionsForSite = (
  requestedSite: string,
  requestedGroupId: number,
  data: Attributes[]
): { sitePermissions: string[]; siteStatus: string } => {
  let sitePermissions: string[] = [];
  let siteStatus: string = 'DNY';
  if (!data) {
    return { sitePermissions, siteStatus };
  }
  for (let i = 0; i < data.length; i++) {
    const attributes = data[i];
    const site = attributes && Object.keys(attributes) && Object.keys(attributes)[0];
    if (requestedSite === site) {
      for (let j = 0; j < Object.values(attributes)[0].length; j++) {
        const attribute = Object.values(attributes)[0][j];
        const key = Object.keys(attribute)[0];
        if (!isNaN(Number(key))) {
          // Group ID
          const groupId = parseInt(key);
          let status = 'DNY';
          if (requestedGroupId === groupId) {
            for (let k = 0; k < Object.values(attribute)[0].length; k++) {
              const values = Object.values(attribute)[0][k];
              if (values && Object.keys(values) && Object.keys(values)[0]) {
                if (Object.keys(values)[0] === 'S') {
                  status = Object.values(values)[0] as string;
                } else if (Object.keys(values)[0] === 'P') {
                  sitePermissions = Object.values(values)[0] as string[];
                  break;
                }
              }
            }
            siteStatus = status;
            break;
          }
        } else {
          const status = Object.values(attribute)[0];
          siteStatus = status as string;
        }
      }
      break;
    }
  }
  return { sitePermissions, siteStatus };
};

export default getPermissionsForSite;
