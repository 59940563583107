import type IEndPoint from 'interfaces/EndPointTypes.ts';
import type { Method } from 'axios';
import { AuthProtect, BaseURLSelect } from '@/common/enum';
import { PersonnelFormData } from 'pages/Personnel/hook/usePersonnelForm.ts';
import BaseURLConfig from '@/common/enum/BaseURLSelection.ts';

export const getPersonnelDataSets = (url: string): IEndPoint => ({
  authorization: AuthProtect.REQUIRED,
  method: 'GET',
  url,
  defaultBaseURL: BaseURLSelect.DMA,
});

export const getPersonnelData = (url: string): IEndPoint => ({
  authorization: AuthProtect.REQUIRED,
  method: 'GET',
  url,
  defaultBaseURL: BaseURLSelect.DMA,
});

export const postPersonnelData = (
  url: string,
  method: Method,
  data: PersonnelFormData
): IEndPoint => ({
  authorization: AuthProtect.REQUIRED,
  method,
  url,
  defaultBaseURL: BaseURLConfig.DMA,
  data,
});
