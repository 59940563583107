import React from 'react';
import Box from '@mui/material/Box';
import { Typo } from 'components/primitive';
import { useTheme } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';

type ErrorMessageProps = {
  message?: string;
};

const classes = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    gap: '12px',
  },
};

const ErrorMessage: React.FC<ErrorMessageProps> = ({ message = 'Something went wrong.' }) => {
  const theme = useTheme();
  return (
    <Box sx={classes.container}>
      <ErrorIcon fontSize={'large'} sx={{ color: theme.palette.grey[400] }} />
      <Typo color={theme.palette.text.secondary}>{message}</Typo>
    </Box>
  );
};

export default ErrorMessage;
