import { QueryState } from '@/common/enum';
import { Nullable } from 'interfaces/Nullable.ts';
import { IAData, IADataSet } from 'interfaces/ia.interface.ts';
import {
  LegendStatus,
  MarkerTypeWithoutIcon,
} from 'pages/MapPage/components/ModalLegends/Constants.tsx';

export type IAStateTypes = {
  queryState: QueryState;
  error: Nullable<string>;
  iaDataset: Nullable<IADataSet>;
  currentEntry: Nullable<IAData>;
  mapLegendStatus: MarkerTypeWithoutIcon[];
  originalDataset: Nullable<IAData[]>;
  allCheckedStatus: boolean;
};

export const IAInitialState: IAStateTypes = {
  queryState: QueryState.UNDEFINED,
  error: null,
  iaDataset: null,
  currentEntry: null,
  mapLegendStatus: LegendStatus.map(({ icon, ...rest }) => rest),
  originalDataset: null,
  allCheckedStatus: true,
};
