import React from 'react';
import { Await, defer, useAsyncValue, useLoaderData } from 'react-router-dom';
import apiFetch, { genericErrorMessage } from '@/services/apiFetch.ts';
import { Loading } from 'components/primitive';
import { ErrorHandler } from 'components/fragment';
import { ApplicantType } from 'interfaces/applicants.interface.ts';
import { getApplicantDataset } from '@/services/applicants.endpoint.ts';

const GridList = React.lazy(() => import('./Grid.container.tsx'));

interface ApplicantsDataSetResponse {
  data: { count: number; dataset: ApplicantType[] };
}

// eslint-disable-next-line react-refresh/only-export-components
export const loaderApplicantsDataSet = () => {
  try {
    const endpoint = getApplicantDataset();
    const response = apiFetch<ApplicantsDataSetResponse['data']>(endpoint).then((res) => res.data);
    return defer({
      data: response,
    });
  } catch (error) {
    let message = genericErrorMessage;
    if (error instanceof Error) message = error.message;
    else {
      if (typeof error === 'object' && error !== null) {
        if ('message' in error) {
          message = String(error.message);
        }
      }
    }
    throw Error(message);
  }
};

function RenderAwaitedData() {
  const data = useAsyncValue() as ApplicantsDataSetResponse['data'];
  return <GridList data={data} />;
}

const GridContainer = () => {
  const data = useLoaderData() as ApplicantsDataSetResponse;
  return (
    <React.Suspense fallback={<Loading />}>
      <Await
        resolve={data.data}
        errorElement={<ErrorHandler headerTitle='Applicants' errorMsg='Something went wrong' />}>
        <RenderAwaitedData />
      </Await>
    </React.Suspense>
  );
};

export default GridContainer;
