import React from 'react';
import { Await, defer, LoaderFunctionArgs, useAsyncValue, useLoaderData } from 'react-router-dom';
import apiFetch, { genericErrorMessage } from '@/services/apiFetch.ts';
import { Loading } from 'components/primitive';
import { ErrorHandler } from 'components/fragment';
import { ApplicantType } from 'interfaces/applicants.interface.ts';
import { getApplicantDetails } from '@/services/applicants.endpoint.ts';

const DetailsPage = React.lazy(() => import('./Details.container.tsx'));

interface ApplicantDetailsResponse {
  data: ApplicantType | null;
}

// eslint-disable-next-line react-refresh/only-export-components
export const loaderApplicantsDetails = ({ params }: LoaderFunctionArgs) => {
  try {
    const { id } = params;
    if (id) {
      const endpoint = getApplicantDetails(id);
      const response = apiFetch<ApplicantType>(endpoint).then((res) => res.data ?? null);
      return defer({
        data: response,
      });
    } else {
      return defer({
        data: null,
      });
    }
  } catch (error) {
    let message = genericErrorMessage;
    if (error instanceof Error) message = error.message;
    else {
      if (typeof error === 'object' && error !== null) {
        if ('message' in error) {
          message = String(error.message);
        }
      }
    }
    throw Error(message);
  }
};

function RenderAwaitedData(props: { newEntry: boolean }) {
  const { newEntry } = props;
  const data = useAsyncValue() as ApplicantType | null;
  const isNew = newEntry ?? data === null;
  return <DetailsPage newEntry={isNew} data={data} />;
}

const DetailsContainer: React.FC<{ newEntry: boolean }> = ({ newEntry }) => {
  const data = useLoaderData() as ApplicantDetailsResponse;
  return (
    <React.Suspense fallback={<Loading />}>
      <Await
        resolve={data.data}
        errorElement={<ErrorHandler headerTitle='Applicants' errorMsg='Something went wrong' />}>
        <RenderAwaitedData newEntry={newEntry} />
      </Await>
    </React.Suspense>
  );
};

export default DetailsContainer;
