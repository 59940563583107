import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApplicantsInitialState, ApplicantsStateTypes } from './applicants.state.ts';
import {
  fetchApplicants,
  fetchSelectedApplicantSettings,
  updateSelectedApplicantSettings,
} from 'store/feature/applicants/applicants.thunk.ts';
import { QueryState } from '@/common/enum';
import { ApplicantType } from 'interfaces/applicants.interface.ts';

const initialState: ApplicantsStateTypes = ApplicantsInitialState;

export const applicantsSlice = createSlice({
  name: 'applicants',
  initialState,
  reducers: {
    updateApplicantDataSet: (state, action: PayloadAction<ApplicantType[]>) => {
      state.applicants = action.payload;
    },
    resetApplicant: () => {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchApplicants.pending, (state) => {
        state.queryState = QueryState.AWAIT;
      })
      .addCase(fetchApplicants.fulfilled, (state, action) => {
        state.queryState = QueryState.READY;
        state.applicants = action.payload.dataset;
        state.stateWideApplicants = action.payload.dataset.filter(
          (applicant) => applicant.state_wide === 1
        );
      })
      .addCase(fetchApplicants.rejected, (state, action) => {
        state.queryState = QueryState.FAIL;
        state.error = action.error.message ?? 'Error fetching applicants';
      })
      .addCase(fetchSelectedApplicantSettings.fulfilled, (state, action) => {
        const dataResponse = action.payload;
        if (dataResponse && Array.isArray(dataResponse) && dataResponse.length) {
          const settings = dataResponse[0];
          state.userSelectedApplicantSettingID = settings.pvSettingID;
          state.selectedApplicant = JSON.parse(settings.pvSettingValue);
        }
      })
      .addCase(updateSelectedApplicantSettings.fulfilled, (state, action) => {
        const applicantSettingValue = action.payload.pvSettingValue;
        const applicant = JSON.parse(applicantSettingValue) as ApplicantType;
        if (Object.keys(applicant).length) {
          state.selectedApplicant = applicant;
        }
      });
  },
});

export default applicantsSlice.reducer;
