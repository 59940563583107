/* eslint-disable no-unused-vars */
import React from 'react';
import { FormControlLabel, useTheme } from '@mui/material';
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';
import useStyles from './CheckButton.styles.ts';
import { Typo } from 'components/primitive';

type CheckButtonProps = {
  value: boolean;
  label: string;
  isActive?: boolean;
};

const CheckButton: React.FC<CheckButtonProps & CheckboxProps> = ({
  onChange,
  value,
  label,
  ...props
}) => {
  const theme = useTheme();
  const classes = useStyles(theme, value, props.disabled);
  return (
    <FormControlLabel
      sx={classes.container}
      control={
        <Checkbox
          color='primary'
          checked={value}
          onChange={!props.disabled ? onChange : undefined}
        />
      }
      label={<Typo style={{ textWrap: 'nowrap', fontSize: 14, fontWeight: 500 }}>{label}</Typo>}
    />
  );
};

export default CheckButton;
