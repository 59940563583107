import { Theme } from '@mui/material';

const useStyles = (theme: Theme) => ({
  root: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    zIndex: 1000,
    width: '100%',
    bgcolor: theme.palette.primary.dark,
    // padding: '8px',
  },
  tabs: {
    color: theme.palette.common.white,
    '& .MuiTabs-indicator': {
      display: 'none',
    },
    '& .MuiTab-root': {
      textTransform: 'none', // Adjust text transformation
      fontWeight: 500, // Change font weight
      color: 'rgba(255, 255, 255, 0.7) !important',
      height: '48px !important',
      margin: '0 !important',
      fontSize: '16px',
      minHeight: 'unset !important',
      borderRadius: '0 !important',
    },
    '& .MuiTab-root.Mui-selected': {
      backgroundColor: '#1C43B1 !important',
      color: '#fff !important',
      height: '48px !important',
      margin: '0 !important',
    },
  },
});
export default useStyles;
