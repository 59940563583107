import React from 'react';
import { FieldError } from 'react-hook-form';
import { TextFieldProps as MuiTFieldProps } from '@mui/material/TextField';

import { NormalTextField, RequiredTextField } from '@zawarski/palmetto-ui-components';

interface TextFieldProps {
  errMsg?: FieldError | undefined | { message: string };
  helperTxt?: string;
  required?: boolean;
}

const TextField: React.FC<TextFieldProps & MuiTFieldProps> = ({
  errMsg,
  helperText,
  required,
  ...props
}) => {
  const hasError = errMsg !== undefined && Object.prototype.hasOwnProperty.call(errMsg, 'message');
  const msgText = hasError ? errMsg.message : helperText;
  if (required) {
    return (
      <RequiredTextField
        {...props}
        style={{ marginLeft: 0, marginRight: 0 }}
        fullWidth
        InputLabelProps={{ shrink: true, color: hasError ? 'error' : 'secondary' }}
        helperText={msgText}
        onFocus={(e: React.ChangeEvent<HTMLInputElement>) => e.target.select()}
      />
    );
  }
  return (
    <NormalTextField
      {...props}
      style={{ marginLeft: 0, marginRight: 0 }}
      variant={props?.variant || 'standard'}
      fullWidth
      InputLabelProps={{ shrink: true, color: hasError ? 'error' : 'secondary' }}
      helperText={msgText}
      onFocus={(e: React.ChangeEvent<HTMLInputElement>) => e.target.select()}
    />
  );
};

export default TextField;
