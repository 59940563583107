import React, { useEffect, useState } from 'react';
import { Tab, Tabs, useMediaQuery, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import { INavTabsOption } from 'interfaces/NavTabsOptionTypes.ts';
import useStyles from './NavTabs.styles.ts';

type NavTabsProps = {
  options: INavTabsOption[];
  index: number;
  setIndex: React.Dispatch<React.SetStateAction<number>>;
  // eslint-disable-next-line no-unused-vars
  onChange: (value: string | number) => void;
  selectedItem: string | number;
  setSelectedItem: React.Dispatch<React.SetStateAction<string>>;
  customRootStyle?: object;
};

const NavTabs: React.FC<NavTabsProps> = ({ ...props }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const classes = useStyles(theme);

  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  useEffect(() => {
    const tempOpts = props.options;
    for (let i = 0; i < tempOpts.length; i++) {
      if (tempOpts[i].id === props.selectedItem) {
        if (selectedIndex !== i) {
          setSelectedIndex(i);
        }
        break;
      }
    }
  }, [props.selectedItem, props.options, selectedIndex]);

  const handleChange = (idx: number) => {
    setSelectedIndex(() => idx);
    props.setIndex(() => idx);
    props.onChange(props.options[idx].id);
  };

  return (
    <Box sx={props.customRootStyle ? props.customRootStyle : classes.root}>
      <Tabs sx={classes.tabs} value={selectedIndex} variant='fullWidth' indicatorColor={undefined}>
        {props.options.map((item, idx) => {
          return (
            <Tab
              key={item.id}
              iconPosition='start'
              label={isMobile ? item.mobileTitle : item.title}
              icon={item.icon}
              onClick={() => handleChange(idx)}
            />
          );
        })}
      </Tabs>
    </Box>
  );
};

export default NavTabs;
