import type IEndPoint from 'interfaces/EndPointTypes.ts';
import { AuthProtect, BaseURLSelect } from '@/common/enum';
import { PAGenInfoSummary, PAShortSummary } from 'interfaces/pa.interface.ts';
import { PADetail } from 'interfaces/pa.detail.interface.ts';

export const getPADataSet = (url: string): IEndPoint => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'GET',
    url,
    defaultBaseURL: BaseURLSelect.DMA,
  };
};

export const getPAGenInfoData = (url: string): IEndPoint => ({
  authorization: AuthProtect.REQUIRED,
  method: 'GET',
  url,
  defaultBaseURL: BaseURLSelect.DMA,
});

export const postPAGenInfoData = (url: string, data: PAGenInfoSummary): IEndPoint => ({
  authorization: AuthProtect.REQUIRED,
  method: 'POST',
  url,
  data,
  defaultBaseURL: BaseURLSelect.DMA,
});

export const getPAShortSummaryData = (url: string): IEndPoint => ({
  authorization: AuthProtect.REQUIRED,
  method: 'GET',
  url,
  defaultBaseURL: BaseURLSelect.DMA,
});

export const postPAShortSummaryData = (url: string, data: PAShortSummary): IEndPoint => ({
  authorization: AuthProtect.REQUIRED,
  method: 'POST',
  url,
  data,
  defaultBaseURL: BaseURLSelect.DMA,
});

export const getPADetailsData = (url: string): IEndPoint => ({
  authorization: AuthProtect.REQUIRED,
  method: 'GET',
  url,
  defaultBaseURL: BaseURLSelect.DMA,
});

export const postPADetailsData = (url: string, data: PADetail): IEndPoint => ({
  authorization: AuthProtect.REQUIRED,
  method: data?.id ? 'PUT' : 'POST',
  url,
  data,
  defaultBaseURL: BaseURLSelect.DMA,
});

export const deletePADetailsData = (url: string): IEndPoint => ({
  authorization: AuthProtect.REQUIRED,
  method: 'DELETE',
  url,
  defaultBaseURL: BaseURLSelect.DMA,
});
