import { Theme } from '@mui/material';

const useStyles = (theme: Theme) => ({
  filterContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    background: 'transparent',
    gap: '10px',
  },
  filterTitle: {
    color: theme.palette.text.secondary,
    textTransform: 'uppercase',
    fontWeight: 700,
    fontSize: '14px',
  },
  gridWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    padding: '1rem',
  },
  chartContainer: {
    display: 'flex',
    width: '100%',
    minHeight: '254px',
    padding: '1rem',
    alignItems: 'flex-start',
    borderRadius: '8px',
    background: '#FFF',
    boxShadow: '0px 0.85px 3px 0px rgba(0, 0, 0, 0.19), 0px 0.25px 1px 0px rgba(0, 0, 0, 0.04)',
  },
  emptyCounty: {
    display: 'flex',
    padding: '16px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flex: '1 0 0',
    alignSelf: 'stretch',
    borderRadius: '8px',
    background: '#FFF',
    boxShadow: '0px 0.85px 3px 0px rgba(0, 0, 0, 0.19), 0px 0.25px 1px 0px rgba(0, 0, 0, 0.04)',
  },
});

export default useStyles;
