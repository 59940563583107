import { createAsyncThunk } from '@reduxjs/toolkit';
import apiFetch, { genericErrorMessage } from '@/services/apiFetch.ts';
import { TeamsDetails, TeamsPayload } from 'interfaces/teams.interface.ts';
import { createTeam, deleteTeam, updateTeam } from '@/services/teams.endpoint.ts';

export const postTeam = createAsyncThunk('teams/postTeam', async (payload: TeamsPayload) => {
  try {
    const endpoint = createTeam(payload);
    return await apiFetch<TeamsDetails>(endpoint).then((res) => res.data ?? null);
  } catch (error) {
    let message = genericErrorMessage;
    if (error instanceof Error) message = error.message;
    else {
      if (typeof error === 'object' && error !== null) {
        if ('message' in error) {
          message = String(error.message);
        }
      }
    }
    throw Error(message);
  }
});

export const putTeam = createAsyncThunk('teams/putTeam', async (payload: TeamsPayload) => {
  try {
    const endpoint = updateTeam(payload);
    return await apiFetch<TeamsDetails>(endpoint).then((res) => res.data ?? null);
  } catch (error) {
    let message = genericErrorMessage;
    if (error instanceof Error) message = error.message;
    else {
      if (typeof error === 'object' && error !== null) {
        if ('message' in error) {
          message = String(error.message);
        }
      }
    }
    throw Error(message);
  }
});

export const deleteTeamRecord = createAsyncThunk('teams/deleteTeam', async (id: string) => {
  try {
    const endpoint = deleteTeam(id);
    return await apiFetch(endpoint).then((res) => res.data ?? null);
  } catch (error) {
    let message = genericErrorMessage;
    if (error instanceof Error) message = error.message;
    else {
      if (typeof error === 'object' && error !== null) {
        if ('message' in error) {
          message = String(error.message);
        }
      }
    }
    throw Error(message);
  }
});
