import React from 'react';
import { Await, defer, useAsyncValue, useLoaderData } from 'react-router-dom';
import { RootState, store } from '@/store';
import apiFetch, { genericErrorMessage } from '@/services/apiFetch.ts';
import { getCatBCost } from '@/services/category.endpoint.ts';
import { CatCostDataSet } from 'interfaces/category.interface.ts';
import { Loading } from 'components/primitive';
import { ErrorHandler } from 'components/fragment';

const CostPage = React.lazy(() => import('./Cost.container.tsx'));

export interface CostDataResponse {
  costData: CatCostDataSet | [];
}

// eslint-disable-next-line react-refresh/only-export-components
export const loaderCostData = () => {
  try {
    const state = store.getState() as RootState;
    const { selectedIncident } = state.incidents;
    if (!selectedIncident) return Error('Incident not found');
    const endpoint = getCatBCost();
    const costData = apiFetch<CatCostDataSet>(endpoint).then((res) => res.data ?? []);
    return defer({
      costData,
    });
  } catch (error) {
    let message = genericErrorMessage;
    if (error instanceof Error) message = error.message;
    else {
      if (typeof error === 'object' && error !== null) {
        if ('message' in error) {
          message = String(error.message);
        }
      }
    }
    throw Error(message);
  }
};

function RenderAwaitedData() {
  const data = useAsyncValue() as CatCostDataSet;
  return <CostPage data={data} />;
}

const CostContainer: React.FC = () => {
  const data = useLoaderData() as CostDataResponse;
  return (
    <React.Suspense fallback={<Loading />}>
      <Await resolve={data.costData} errorElement={<ErrorHandler headerTitle='CategoryB: Cost' />}>
        <RenderAwaitedData />
      </Await>
    </React.Suspense>
  );
};

export default CostContainer;
