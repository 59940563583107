import React, { useEffect, useMemo, useState } from 'react';
import { FormProvider, SubmitHandler, UseFormReturn } from 'react-hook-form';
import { Modal, Typo } from 'components/primitive';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import SectionView from '../../Section';
import useStyles from './SettingsView.styles.ts';
import CircleIcon from '@mui/icons-material/DonutLarge';
import GroupsIcon from '@mui/icons-material/Groups3';
import AccountIcon from '@mui/icons-material/AccountBox';
import { DBModalSettings } from 'pages/Dashboard/hooks/useDBModalSettingsForm.ts';
import { useAppDispatch, useAppSelector } from 'store/hooks.ts';
import {
  incidentForParcelSyncSelector,
  selectedIncidentSelector,
  userIncidentsSelector,
} from 'store/feature/incidents/incidents.selector.ts';
import { RadioSelectOptions } from 'interfaces/SelectOptions.interface.ts';
import {
  accountGroupIDSelector,
  allGroupsSelector,
  selectedGroupSelector,
  userTypeSelector,
} from 'store/feature/accountsInfo/accountsInfo.selector.ts';
import {
  applicantsSelector,
  selectedApplicantSelector,
} from 'store/feature/applicants/applicants.selector.ts';
import { ActionButtons } from 'components/fragment';
import { UserType } from '@/common/enum';
import { updatedSelectedGroupForMapForStateUser } from 'store/feature/accountsInfo/accountsInfo.thunk.ts';
import {
  saveSelectedIncidentSetting,
  updateDataSyncIncident,
} from 'store/feature/incidents/incidents.thunk.ts';
import { updateSelectedApplicantSettings } from 'store/feature/applicants/applicants.thunk.ts';
import { fetchIADataSet } from 'store/feature/ia/ia.thunk.ts';
import { fetchPADataSet } from 'store/feature/pa/pa.thunk.ts';
import { fetchIAHomePage, fetchPAHomePage } from 'store/feature/dashboard/dashboard.thunk.ts';

type ModalSettingsViewProps = {
  open: boolean;
  onClose: () => void;
  form: UseFormReturn<DBModalSettings>;
};

const ModalSettingsView: React.FC<ModalSettingsViewProps> = ({ open, onClose, form }) => {
  const dispatch = useAppDispatch();
  const userType = useAppSelector(userTypeSelector);
  const userIncidents = useAppSelector(userIncidentsSelector);
  const selectedIncident = useAppSelector(selectedIncidentSelector);
  const selectedIncidentForParcelSync = useAppSelector(incidentForParcelSyncSelector);
  const applicantsDataSet = useAppSelector(applicantsSelector);
  const selectedApplicant = useAppSelector(selectedApplicantSelector);
  const allGroups = useAppSelector(allGroupsSelector);
  const selectedGroup = useAppSelector(selectedGroupSelector);
  const selectedGroupID = useAppSelector(accountGroupIDSelector);
  const activeGroup = selectedGroup ? selectedGroup : selectedGroupID;

  const theme = useTheme();
  const classes = useStyles(theme);

  const applicantsData = useMemo(() => applicantsDataSet ?? [], [applicantsDataSet]);
  const isStateUser = useMemo(() => userType === UserType.STATE_USER, [userType]);

  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    if (userIncidents && userIncidents.length) {
      const incidentOptions: RadioSelectOptions[] = userIncidents
        .map((incident) => {
          return { label: incident.pvIncidentName, value: incident.pvIncidentID };
        })
        .sort((a, b) => a.label.localeCompare(b.label));
      form.setValue('incidentOptions', incidentOptions);
      if (selectedIncident) form.setValue('incident', selectedIncident?.pvIncidentID);
      if (selectedIncidentForParcelSync)
        form.setValue('dataGathering', selectedIncidentForParcelSync);
    }

    if (allGroups && allGroups.length) {
      const groupOptions: RadioSelectOptions[] = allGroups
        .map((group) => {
          return { label: group.pvGroupName, value: group.pvGroupID };
        })
        .sort((a, b) => a.label.localeCompare(b.label));
      form.setValue('groupOptions', groupOptions);
      form.setValue('group', Number(activeGroup));
    }
    if (applicantsData && applicantsData.length) {
      const applicantOptions: RadioSelectOptions[] = applicantsData
        .map((applicant) => {
          return {
            label: applicant.applicant_name ?? '',
            value: applicant.id ?? '',
            withIndicator: Boolean(applicant.state_wide),
          };
        })
        .sort((a, b) => a.label.localeCompare(b.label));
      form.setValue('applicantsOptions', applicantOptions);
      if (selectedApplicant && selectedApplicant.id)
        form.setValue('applicant', selectedApplicant.id);
    }
  }, [
    userIncidents,
    selectedIncident,
    selectedIncidentForParcelSync,
    allGroups,
    activeGroup,
    selectedApplicant,
    applicantsData,
    form,
  ]);

  function hasDataChanged(data: DBModalSettings) {
    const hasDataChanged = {
      incident: false,
      gathering: false,
      group: false,
      applicant: false,
    };
    if (Number(data.incident) !== Number(selectedIncident?.pvIncidentID)) {
      hasDataChanged.incident = true;
    }
    if (Number(data.dataGathering) !== Number(selectedIncidentForParcelSync)) {
      hasDataChanged.gathering = true;
    }
    if (Number(data.group) !== Number(activeGroup)) {
      hasDataChanged.group = true;
    }
    if (data.applicant !== selectedApplicant?.id) {
      hasDataChanged.applicant = true;
    }
    return hasDataChanged;
  }

  function validateIncidentExists(incidentID: number): number {
    if (!userIncidents) return -1;
    return userIncidents.findIndex((item) => Number(item.pvIncidentID) === incidentID);
  }

  const onSubmit: SubmitHandler<DBModalSettings> = async (formValues) => {
    const dataChanged = hasDataChanged(formValues);
    const hasAnyDataChanged = Object.values(dataChanged).some((value) => value);
    if (!hasAnyDataChanged) {
      onClose();
      return;
    }

    try {
      setIsSaving(true);
      if (dataChanged.incident && userIncidents) {
        // dispatch action to update incident
        const found = validateIncidentExists(Number(formValues.incident));
        if (found !== -1) {
          const incident = userIncidents[found];
          await dispatch(saveSelectedIncidentSetting(incident)).unwrap();
          dispatch(fetchIADataSet({ incidentID: incident.pvIncidentID }));
          dispatch(fetchPADataSet({ incidentID: incident.pvIncidentID }));
          if (isStateUser) dispatch(fetchIAHomePage({ selectedGroupIndex: 0 }));
          else dispatch(fetchIAHomePage({ selectedGroupIndex: Number(activeGroup) }));
          dispatch(fetchPAHomePage());
        }
      }

      if (dataChanged.gathering && userIncidents) {
        // dispatch action to update data gathering
        const found = validateIncidentExists(Number(formValues.dataGathering));
        if (found !== -1) {
          const incident = userIncidents[found];
          const payload = {
            incidentID: Number(formValues.dataGathering),
            incidentName: incident.pvIncidentName,
          };
          await dispatch(updateDataSyncIncident(payload)).unwrap();
        }
      }

      if (dataChanged.group) {
        // dispatch action to update a state user group
        await dispatch(updatedSelectedGroupForMapForStateUser(Number(formValues.group))).unwrap();
        dispatch(fetchIAHomePage({ selectedGroupIndex: Number(activeGroup) }));
        dispatch(fetchPAHomePage());
      }

      if (dataChanged.applicant) {
        // dispatch action to update applicant
        const applicantID = formValues.applicant;
        const applicantData = applicantsData.find((applicant) => applicant.id === applicantID);
        if (applicantData) {
          await dispatch(updateSelectedApplicantSettings(applicantData)).unwrap();
        }
      }
      setIsSaving(false);
      onClose();
    } catch (error) {
      console.log(error);
      setIsSaving(false);
    }
  };

  return (
    <Modal open={open} contentStyle={classes.modalContent}>
      <>
        <Box sx={classes.header.container}>
          <Typo sx={classes.header.title}>Initial Setup</Typo>
          <Typo sx={classes.header.subTitle}>Configure your initial settings to get started.</Typo>
        </Box>
        <Box sx={classes.content}>
          <Box sx={classes.form}>
            <FormProvider {...form}>
              <SectionView
                required={true}
                title='View Incident'
                fieldName='incident'
                optionName='incidentOptions'
              />
              <SectionView
                required={true}
                title='Data Gathering Incident'
                fieldName='dataGathering'
                optionName='incidentOptions'
                customIcon={<CircleIcon sx={{ color: '#1F1F1F' }} />}
              />
              <SectionView
                required={isStateUser}
                readOnly={!isStateUser}
                title='County'
                fieldName='group'
                optionName='groupOptions'
                customIcon={<GroupsIcon sx={{ color: '#1F1F1F' }} />}
              />
              <SectionView
                title='Applicants'
                fieldName='applicant'
                optionName='applicantsOptions'
                customIcon={<AccountIcon sx={{ color: '#1F1F1F' }} />}
              />
            </FormProvider>
          </Box>
        </Box>
        <ActionButtons
          withSave
          isModal={true}
          readonly={false}
          isSaving={isSaving}
          saveLabel={isSaving ? 'Saving' : 'Continue'}
          save={form.handleSubmit(onSubmit)}
        />
      </>
    </Modal>
  );
};

export default ModalSettingsView;
