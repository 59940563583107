const useStyles = () => ({
  container: {
    padding: '1px',
    borderRadius: '4px',
    textAlign: 'center',
    color: 'rgba(255, 255, 255, 0.87)',
  },
});

export default useStyles;
