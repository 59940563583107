import { Theme } from '@mui/material';

const useStyles = (theme: Theme) => ({
  gridWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    padding: '1rem',
  },
  chartContainer: {
    display: 'flex',
    width: '100%',
    minHeight: '254px',
    padding: '1rem',
    alignItems: 'flex-start',
    borderRadius: '8px',
    background: theme.palette.common.white,
    boxShadow: '0px 0.85px 3px 0px rgba(0, 0, 0, 0.19), 0px 0.25px 1px 0px rgba(0, 0, 0, 0.04)',
  },
});

export default useStyles;
