import { Theme } from '@mui/material';

const useStyles = (theme: Theme) => ({
  gridContainer: {
    px: '16px',
  },
  container: {
    display: 'flex',
    padding: '16px',
    alignItems: 'center',
    gap: '4px',
  },
  wrapper: {
    display: 'flex',
    width: '100%',
    padding: '12px',
    alignItems: 'flex-start',
    alignContent: 'space-between',
    gap: '4px',
    alignSelf: 'stretch',
    borderRadius: '4px',
    border: `1px solid ${theme.palette.grey[100]}`,
    background: theme.palette.secondary.main,
    flexWrap: 'wrap',
  },
  menuWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
  menuContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '8px',
    width: '100%',
    justifyContent: 'space-between',
  },
});

export default useStyles;
