import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AccountsInfoInitialState, AccountsInfoTypes } from './accountsInfo.state.ts';
import {
  fetchAllGroups,
  fetchUserDMASettings,
  updatedSelectedGroupForMapForStateUser,
} from './accountsInfo.thunk.ts';
import { QueryState, UserType } from '@/common/enum';
import { Nullable } from 'interfaces/Nullable.ts';
import { IUserPortal } from 'interfaces/UserAccountInfo.interface.ts';

const initialState: AccountsInfoTypes = AccountsInfoInitialState;

export const accountsInfoSlice = createSlice({
  name: 'accountsInfo',
  initialState,
  reducers: {
    setLoadingState: (state, action) => {
      state.appLoading = action.payload;
    },
    setSelectedGroupMapParcel: (
      state,
      action: PayloadAction<{
        selectedGroup: string;
        selectedGroupName: string;
      }>
    ) => {
      state.selectedGroup = action.payload.selectedGroup;
      state.selectedGroupName = action.payload.selectedGroupName;
    },
    setAccount: (state, action: PayloadAction<IUserPortal>) => {
      state.account = action.payload;
    },
    resetAccountsInfo: () => {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchUserDMASettings.fulfilled, (state, action) => {
        const account = action.payload?.account;
        const settings = action.payload?.response?.settings ?? [];
        console.log('🚀 settings: ', settings);
        const SUBGROUP_USER = action?.payload?.response?.SUBGROUP_USER ?? null;
        let selectedGroupID: number = 0;
        // let parentGroupID: number | null = 0;
        let selectedGroup: Nullable<string> = null;
        let selectedGroupName: Nullable<string> = null;
        let selectedGroupNameForParcels;
        let isUserGroupAdmin = false;
        let stateUserSelectedMapSettingID: Nullable<number> = null;
        if (Object.prototype.hasOwnProperty.call(action.payload, 'SUBGROUP_USER')) {
          let groupFound = false;
          if (account && account.currentGroups && account.currentGroups.length) {
            for (let i = 0; i < account.currentGroups.length; i++) {
              if (account.currentGroups[i].i && account.currentGroups[i].a) {
                isUserGroupAdmin = true;
              }
              if (account.currentGroups[i].i === SUBGROUP_USER) {
                groupFound = true;
                // parentGroupID = account.currentGroups[i].pvParentGroupID;
                break;
              }
            }
          }
          if (!groupFound) {
            throw new Error(
              'User is not part of the group anymore. Please contact the administrator.'
            );
          }
        } else {
          for (let i = 0; i < settings.length; i++) {
            if (settings[i].pvSettingType === 'selectedGroup') {
              selectedGroupID = parseInt(settings[i].pvSettingValue);
              selectedGroupName = settings[i]?.pvGroupName ?? null;
              selectedGroupNameForParcels = settings[i]?.pvGroupName ?? null;
            }
          }
          for (let i = 0; i < settings.length; i++) {
            // check if the user is a state user with a selected group 1
            // if so, get the selected group id as the active DMA group
            if (
              selectedGroupID === 1 &&
              settings[i].pvSettingType === 'stateUserSelectedGroupForDMAMap'
            ) {
              stateUserSelectedMapSettingID = Number(settings[i].pvSettingID);
              selectedGroup = settings[i].pvSettingValue ?? null;
            }
          }
          // here check if the selected group is part of users account object
          let groupFound = false;
          if (account && account.currentGroups && account.currentGroups.length) {
            for (let i = 0; i < account.currentGroups.length; i++) {
              if (account.currentGroups[i].i && account.currentGroups[i].a) {
                isUserGroupAdmin = true;
              }
              if (Number(account.currentGroups[i].i) === Number(selectedGroupID)) {
                groupFound = true;
                break;
              }
            }
            if (!groupFound) {
              throw new Error(
                'User is not part of the group anymore. Please contact the administrator.'
              );
            }
          }
        }
        if (selectedGroupID === 1) {
          state.userType = UserType.STATE_USER;
        } else {
          state.userType = UserType.COUNTY_USER;
        }

        state.selectedGroupName = selectedGroupName;
        state.selectedUserGroupName = selectedGroupName;
        state.selectedGroup = selectedGroup;
        state.selectedGroupID = selectedGroupID;
        state.selectedGroupNameForParcels = selectedGroupNameForParcels;
        // state.parentGroupID = null;
        state.isUserGroupAdmin = isUserGroupAdmin;
        state.stateUserSelectedMapSettingID = stateUserSelectedMapSettingID;
        state.userDMASettings = action.payload.response;
        state.queryState = QueryState.READY;
      })
      .addCase(fetchUserDMASettings.rejected, (state, action) => {
        state.error = action.error.message || 'Something went wrong!';
        state.queryState = QueryState.FAIL;
      })
      .addCase(fetchAllGroups.fulfilled, (state, action) => {
        const allGroups = action.payload ?? [];
        //  *: FILTER SUBGROUPS
        const currentGroupID = state.selectedGroupID;
        const currentGroupNo = state.selectedGroup;
        const activeGroup = currentGroupID ? currentGroupID : currentGroupNo;
        const activeGroupDetails = allGroups.filter((group) => group.pvGroupID === activeGroup);
        state.allSubGroups = allGroups.filter((group) => group.pvParentGroupID === activeGroup);
        // *: Only take groups with id 1-48;
        const filteredGroups = allGroups.filter(
          (group) => Number(group.pvGroupID) >= 1 && Number(group.pvGroupID) <= 48
        );
        if (activeGroup && Number(activeGroup) > 48) {
          // *: Add the active group missing from the list
          filteredGroups.push(...activeGroupDetails);
          const parentGroupID = activeGroupDetails[0].pvParentGroupID;
          const parentGroupIdx = filteredGroups.findIndex(
            (item) => item.pvGroupID === parentGroupID
          );
          if (parentGroupIdx !== -1) {
            state.parentGroupID = parentGroupID;
            state.selectedGroupNameForParcels = filteredGroups[parentGroupIdx].pvGroupName;
          }
        }
        state.allGroups = filteredGroups.sort((a, b) => a.pvGroupName.localeCompare(b.pvGroupName));
        if (state.userType === UserType.STATE_USER) {
          if (Number(state.selectedGroupID) !== Number(state.selectedGroup)) {
            console.log('@@@ IS STATE USER - SELECT ACTIVE DMA GROUP @@@');
            const activeGroup = filteredGroups.find(
              (group) => group.pvGroupID === Number(state.selectedGroup)
            );
            if (activeGroup) {
              state.selectedGroupName = activeGroup.pvGroupName;
            }
          }
        }
      })
      .addCase(fetchAllGroups.rejected, (state, action) => {
        state.error = action.error.message || 'Something went wrong!';
      })
      .addCase(updatedSelectedGroupForMapForStateUser.fulfilled, (state, action) => {
        const dmaUserSettingsResp = action.payload.dmaUserSettings;
        const groupSetting = action.payload.groupSetting;
        state.stateUserSelectedMapSettingID = dmaUserSettingsResp?.pvSettingID ?? null;
        state.selectedGroup = String(groupSetting?.pvGroupID) ?? null;
        state.selectedGroupName = (Object.keys(groupSetting) && groupSetting?.pvGroupName) ?? null;
      });
  },
});

export default accountsInfoSlice.reducer;
