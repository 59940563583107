import React from 'react';
import { FormControlLabel, Radio, useTheme } from '@mui/material';
import { Typo } from 'components/primitive';
import useStyles from './RadioButton.styles.ts';

type RadioButtonProps = {
  value: string;
  label: string;
  isActive?: boolean;
  disabled?: boolean;
  customActiveBg?: string;
  customActiveBorder?: string;
};

const RadioButton: React.FC<RadioButtonProps> = ({
  value,
  label,
  isActive = false,
  disabled = false,
  customActiveBg,
  customActiveBorder,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme, isActive, disabled, customActiveBorder, customActiveBg);

  if (customActiveBorder)
    return (
      <FormControlLabel
        sx={classes.container}
        value={value}
        control={
          <Radio
            sx={{
              '&.Mui-checked': {
                color: customActiveBorder ?? 'primary',
              },
            }}
          />
        }
        label={<Typo style={{ fontSize: 14, fontWeight: 500 }}>{label}</Typo>}
      />
    );

  return (
    <FormControlLabel
      sx={classes.container}
      value={value}
      control={<Radio />}
      label={<Typo style={{ fontSize: 14, fontWeight: 500 }}>{label}</Typo>}
    />
  );
};

export default RadioButton;
