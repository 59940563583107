/* eslint-disable @typescript-eslint/no-explicit-any */
// noinspection TypeScriptValidateJSTypes

import React from 'react';
import moment from 'moment-timezone';
import { Controller } from 'react-hook-form';
import { HeaderButton, HeaderComponent } from '@zawarski/palmetto-ui-components';
import useStyles from './Edit.styles.ts';
import Box from '@mui/material/Box';
import { Grid, IconButton, InputAdornment, RadioGroup, useTheme } from '@mui/material';
import BackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import { PageMaxLayout } from 'components/layout';
import {
  DatePickerField,
  DecimalNumber,
  FormContent,
  FormGroup,
  RadioButton,
  SelectMenu,
  TextField,
  Typo,
} from 'components/primitive';
import {
  ActionButtons,
  Documents,
  geoCodeFromLatLonInput,
  SearchInputLocation,
} from 'components/fragment';
import { EditViewProps } from './Edit.props.ts';
import { handlePhoneChange, PhoneNumberFormat } from 'utils/index.ts';
import { DateTimeFormat } from 'utils/FomattingUtils.ts';
import { PAJPDAStatus } from '@/common/enum';
import { InfoModal } from './component';

const EditView: React.FC<EditViewProps> = ({
  isNew,
  authToken,
  palmettoUser,
  title,
  form,
  isSaving,
  isDeleting,
  isStateUser,
  isJPDA_PA_Active,
  readOnly,
  showModalMsg,
  onBack,
  countyOptions,
  allowAutoFill,
  handleEdit,
  onSubmit,
  onDelete,
  toggleAutoFill,
  onAddressSelect,
  setFilesAdded,
  setFilesDeleted,
  setFilesUploaded,
  onHandleConfirm,
  permissions,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const pvGlobalID = form.watch('pvGlobalID');
  const dataID = form.watch('id');
  const disableCostEstimateApplicant = isStateUser ? isJPDA_PA_Active : false;
  const disableCostEstimateTeam = isStateUser ? !isJPDA_PA_Active : true;

  const getCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const lat = parseFloat(position.coords.latitude.toFixed(8).toString());
          const lon = parseFloat(position.coords.longitude.toFixed(8).toString());
          await geoCodeFromLatLonInput(lat, lon, onAddressSelect);
        },
        (err) => console.log(err),
        { maximumAge: 60000, timeout: 10000, enableHighAccuracy: true }
      );
    }
  };

  return (
    <Box className={'layout vertical full-height'} style={{ overflowY: 'auto' }}>
      <HeaderComponent
        title={title}
        icon={
          <IconButton onClick={!isSaving ? () => onBack() : undefined}>
            {readOnly ? <BackIcon className='icon' /> : <CloseIcon className='icon' />}
          </IconButton>
        }
        suffix={
          <div className='form-margin-16'>
            {readOnly ? (
              permissions.UPDATE ? (
                <HeaderButton onClick={() => handleEdit()}>
                  <Typo fontWeight={600}>EDIT</Typo>
                </HeaderButton>
              ) : null
            ) : null}
          </div>
        }
      />
      <PageMaxLayout>
        <FormContent title='Applicant Information' bgcolor={'#FFF'}>
          <Grid container spacing={2} sx={classes.gridContainer}>
            <Grid item xs={12} md={4}>
              <Controller
                control={form.control}
                name='applicant_name'
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    error={Boolean(error)}
                    errMsg={error}
                    label='Applicant'
                    placeholder='Enter id'
                    value={value}
                    onChange={onChange}
                    InputProps={{
                      readOnly: true,
                    }}
                    fullWidth
                    helperTxt={error ? error.message : ''}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                control={form.control}
                name='name'
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    error={Boolean(error)}
                    errMsg={error}
                    label='Name'
                    placeholder='Enter name'
                    value={value}
                    onChange={onChange}
                    InputProps={{
                      readOnly: readOnly,
                    }}
                    fullWidth
                    helperTxt={error ? error.message : ''}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              {readOnly && (
                <Controller
                  control={form.control}
                  name='date'
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      error={Boolean(error)}
                      errMsg={error}
                      label='Date'
                      placeholder='Enter name'
                      value={DateTimeFormat({ dte: value, format: 'MMMM DD, YYYY' })}
                      onChange={onChange}
                      InputProps={{
                        readOnly: true,
                      }}
                      fullWidth
                      helperTxt={error ? error.message : ''}
                    />
                  )}
                />
              )}
              {!readOnly && (
                <Controller
                  control={form.control}
                  name='date'
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <DatePickerField
                      label='Date'
                      format={'MMMM DD, YYYY'}
                      value={moment.utc(value)}
                      onChange={onChange}
                      errMsg={error}
                      helperTxt={error ? error.message : ''}
                      readOnly={readOnly}
                    />
                  )}
                />
              )}
            </Grid>
            <Grid item xs={12} md={4}>
              {readOnly && (
                <Controller
                  control={form.control}
                  name='county'
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      error={Boolean(error)}
                      errMsg={error}
                      label='County'
                      placeholder='Enter name'
                      value={value}
                      onChange={onChange}
                      InputProps={{
                        readOnly: true,
                      }}
                      helperTxt={error ? error.message : ''}
                      fullWidth
                    />
                  )}
                />
              )}
              {!readOnly && (
                <Controller
                  control={form.control}
                  name='applicant_county'
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <SelectMenu
                      withLabel
                      label='County'
                      value={value}
                      onChange={onChange}
                      values={countyOptions}
                      disabled={readOnly}
                      error={Boolean(error)}
                      fieldError={error}
                      helperText={error ? error.message : ''}
                    />
                  )}
                />
              )}
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                control={form.control}
                name='phone'
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    error={Boolean(error)}
                    errMsg={error}
                    label='Phone'
                    placeholder='Enter phone number'
                    value={PhoneNumberFormat(value ?? '')}
                    onChange={handlePhoneChange(onChange)}
                    InputProps={{
                      readOnly: readOnly,
                    }}
                    fullWidth
                    helperTxt={error ? error.message : ''}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                control={form.control}
                name='email'
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    error={Boolean(error)}
                    errMsg={error}
                    label='Email'
                    placeholder='Enter email'
                    value={value}
                    onChange={onChange}
                    InputProps={{
                      readOnly: readOnly,
                    }}
                    fullWidth
                    helperTxt={error ? error.message : ''}
                  />
                )}
              />
            </Grid>
          </Grid>
        </FormContent>
        <FormContent
          title='Site Location'
          bgcolor={'#FFF'}
          isAddress
          allowAutoFill={allowAutoFill}
          toggleAutoFill={toggleAutoFill}
          onClick={() => getCurrentLocation()}>
          <Grid container spacing={2} sx={classes.gridContainer}>
            <Grid item xs={12} md={6}>
              {!allowAutoFill && (
                <Controller
                  control={form.control}
                  name='address'
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      error={Boolean(error)}
                      errMsg={error}
                      label='Address'
                      placeholder='Enter Address'
                      value={value}
                      onChange={onChange}
                      InputProps={{
                        readOnly: readOnly,
                      }}
                      helperTxt={error ? error.message : ''}
                      fullWidth
                    />
                  )}
                />
              )}
              {allowAutoFill && (
                <Controller
                  control={form.control}
                  name='address'
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <SearchInputLocation
                      id='address'
                      label='Address'
                      placeholder='Enter Address'
                      value={value}
                      onChange={onChange}
                      callbackAddressSelect={(value: any) => onAddressSelect(value)}
                      error={Boolean(error)}
                      errMsg={error}
                      InputProps={{
                        readOnly: readOnly,
                      }}
                    />
                  )}
                />
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                control={form.control}
                name='address2'
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    error={Boolean(error)}
                    errMsg={error}
                    label='Address 2'
                    placeholder='Enter Address 2'
                    value={value}
                    onChange={onChange}
                    InputProps={{
                      readOnly: readOnly,
                    }}
                    helperTxt={error ? error.message : ''}
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Controller
                control={form.control}
                name='city'
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    error={Boolean(error)}
                    errMsg={error}
                    label='City'
                    placeholder='Enter City'
                    value={value}
                    onChange={onChange}
                    InputProps={{
                      readOnly: readOnly,
                    }}
                    helperTxt={error ? error.message : ''}
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Controller
                control={form.control}
                name='county'
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    error={Boolean(error)}
                    errMsg={error}
                    label='County'
                    placeholder='Enter County'
                    value={value}
                    onChange={onChange}
                    InputProps={{
                      readOnly: true,
                    }}
                    helperTxt={error ? error.message : ''}
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Controller
                control={form.control}
                name='state'
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    error={Boolean(error)}
                    errMsg={error}
                    label='State'
                    placeholder='Enter State'
                    value={value}
                    onChange={onChange}
                    InputProps={{
                      readOnly: readOnly,
                    }}
                    helperTxt={error ? error.message : ''}
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Controller
                control={form.control}
                name='zipCode'
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    error={Boolean(error)}
                    errMsg={error}
                    label='Zipcode'
                    placeholder='Enter Zipcode'
                    value={value}
                    onChange={onChange}
                    InputProps={{
                      readOnly: readOnly,
                    }}
                    helperTxt={error ? error.message : ''}
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                control={form.control}
                name='latitude'
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    error={Boolean(error)}
                    errMsg={error}
                    label='Latitude'
                    placeholder='Enter Latitude'
                    value={value}
                    onChange={onChange}
                    InputProps={{
                      readOnly: readOnly,
                    }}
                    helperTxt={error ? error.message : ''}
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                control={form.control}
                name='longitude'
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    error={Boolean(error)}
                    errMsg={error}
                    label='Latitude'
                    placeholder='Enter Longitude'
                    value={value}
                    onChange={onChange}
                    InputProps={{
                      readOnly: readOnly,
                    }}
                    helperTxt={error ? error.message : ''}
                    fullWidth
                  />
                )}
              />
            </Grid>
          </Grid>
        </FormContent>
        <FormContent title='Site Details' bgcolor={'#FFF'}>
          <Grid container spacing={2} sx={classes.gridContainer}>
            {isStateUser && (
              <Grid item xs={12}>
                <Controller
                  control={form.control}
                  name='jpda_status'
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <FormGroup label='JPDA Status' error={error}>
                      <RadioGroup
                        aria-labelledby='controlled-radio-buttons-group'
                        name='controlled-radio-buttons-group'
                        value={value ? value.toLowerCase() : null}
                        onChange={!readOnly ? onChange : undefined}
                        sx={classes.groupRadioContainer}>
                        <RadioButton
                          isActive={
                            value
                              ? value.toLowerCase() === PAJPDAStatus.siteVisited.toLowerCase()
                              : false
                          }
                          value={PAJPDAStatus.siteVisited.toLowerCase()}
                          label={PAJPDAStatus.siteVisited}
                          disabled={readOnly}
                        />
                        <RadioButton
                          isActive={
                            value
                              ? value.toLowerCase() === PAJPDAStatus.siteNotVisited.toLowerCase()
                              : false
                          }
                          value={PAJPDAStatus.siteNotVisited.toLowerCase()}
                          label={PAJPDAStatus.siteNotVisited}
                          disabled={readOnly}
                        />
                      </RadioGroup>
                    </FormGroup>
                  )}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <Controller
                control={form.control}
                name='damage_description'
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    error={Boolean(error)}
                    errMsg={error}
                    label='Description of damage'
                    placeholder='Enter description'
                    value={value}
                    onChange={onChange}
                    InputProps={{
                      readOnly: readOnly,
                    }}
                    helperTxt={error ? error.message : ''}
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                control={form.control}
                name='impact'
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    error={Boolean(error)}
                    errMsg={error}
                    label='Impact to jurisdiction'
                    placeholder='Enter impact'
                    value={value}
                    onChange={onChange}
                    InputProps={{
                      readOnly: readOnly,
                    }}
                    helperTxt={error ? error.message : ''}
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                control={form.control}
                name='impacted_department'
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    error={Boolean(error)}
                    errMsg={error}
                    label='Impacted Department'
                    placeholder='Enter department'
                    value={value}
                    onChange={onChange}
                    InputProps={{
                      readOnly: readOnly,
                    }}
                    helperTxt={error ? error.message : ''}
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                control={form.control}
                name='percent_complete'
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    error={Boolean(error)}
                    errMsg={error}
                    label='% Complete'
                    placeholder='Enter % complete'
                    value={value}
                    onChange={onChange}
                    InputProps={{
                      readOnly: readOnly,
                    }}
                    helperTxt={error ? error.message : ''}
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={form.control}
                name='comments'
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    error={Boolean(error)}
                    errMsg={error}
                    label='Team comments'
                    placeholder='Enter comments'
                    value={value}
                    onChange={onChange}
                    InputProps={{
                      readOnly: readOnly,
                    }}
                    helperTxt={error ? error.message : ''}
                    fullWidth
                    multiline
                  />
                )}
              />
            </Grid>
          </Grid>
        </FormContent>
        <FormContent title='Estimates' bgcolor={'#FFF'}>
          <Grid container spacing={2} sx={classes.gridContainer}>
            <Grid item xs={12} md={6}>
              <Controller
                control={form.control}
                name='team_cost_estimate'
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    error={Boolean(error)}
                    errMsg={error}
                    label='Cost estimate - Team'
                    placeholder='Enter estimate'
                    value={value}
                    onChange={onChange}
                    disabled={disableCostEstimateTeam}
                    InputProps={{
                      readOnly: readOnly || disableCostEstimateTeam,
                      startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                      inputComponent: DecimalNumber as any,
                    }}
                    helperTxt={error ? error.message : ''}
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                control={form.control}
                name='applicant_cost_estimate'
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    error={Boolean(error)}
                    errMsg={error}
                    label='Cost estimate - Applicant'
                    placeholder='Enter estimate'
                    value={value}
                    onChange={onChange}
                    disabled={disableCostEstimateApplicant}
                    InputProps={{
                      readOnly: readOnly || disableCostEstimateApplicant,
                      startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                      inputComponent: DecimalNumber as any,
                    }}
                    helperTxt={error ? error.message : ''}
                    fullWidth
                  />
                )}
              />
            </Grid>
          </Grid>
        </FormContent>
        <FormContent title='File Attachments' bgcolor={'#FFF'}>
          {!!authToken && palmettoUser && pvGlobalID && (
            <Box sx={{ padding: '16px' }}>
              <Documents
                readOnly={readOnly}
                authToken={authToken}
                uuid={pvGlobalID}
                user={palmettoUser}
                pvDataID={dataID ?? ''}
                filesQueuedCallback={setFilesAdded}
                filesDeletedCallback={setFilesDeleted}
                filesUploadedCallback={setFilesUploaded}
              />
            </Box>
          )}
        </FormContent>
        <Box sx={{ display: 'flex', width: '100%', minHeight: '65px' }} />
      </PageMaxLayout>
      <InfoModal open={showModalMsg} onHandleConfirm={onHandleConfirm} />
      <ActionButtons
        withSave
        withDelete={!isNew}
        readonly={readOnly}
        isSaving={isSaving}
        isDeleting={isDeleting}
        delete={!isDeleting ? onDelete : undefined}
        save={!isSaving ? form.handleSubmit(onSubmit) : undefined}
      />
    </Box>
  );
};

export default EditView;
