import type IEndPoint from 'interfaces/EndPointTypes.ts';
import { AuthProtect, BaseURLSelect } from '@/common/enum';
import { TeamsPayload } from 'interfaces/teams.interface.ts';

export const getTeamsDataSet = (url: string): IEndPoint => ({
  authorization: AuthProtect.REQUIRED,
  method: 'GET',
  url,
  defaultBaseURL: BaseURLSelect.DMA,
});

export const getTeamDetail = (url: string): IEndPoint => ({
  authorization: AuthProtect.REQUIRED,
  method: 'GET',
  url,
  defaultBaseURL: BaseURLSelect.DMA,
});

export const createTeam = (data: TeamsPayload): IEndPoint => ({
  authorization: AuthProtect.REQUIRED,
  method: 'POST',
  url: '/api/teams',
  data,
  defaultBaseURL: BaseURLSelect.DMA,
});

export const updateTeam = (data: TeamsPayload): IEndPoint => ({
  authorization: AuthProtect.REQUIRED,
  method: 'PUT',
  url: `/api/teams/${data.id}`,
  data,
  defaultBaseURL: BaseURLSelect.DMA,
});

export const deleteTeam = (id: string): IEndPoint => ({
  authorization: AuthProtect.REQUIRED,
  method: 'DELETE',
  url: `/api/teams/${id}`,
  defaultBaseURL: BaseURLSelect.DMA,
});
