import React from 'react';
import { FieldError } from 'react-hook-form';
import { CustomInputLabel } from '@zawarski/palmetto-ui-components';
import Box from '@mui/material/Box';
import FormHelperText from '@mui/material/FormHelperText';
import { TypographyProps } from '@mui/material/Typography';

interface FormGroupProps extends TypographyProps {
  children: React.ReactNode;
  label: string;
  required?: boolean;
  color?: string;
  error: FieldError | { message: string } | undefined;
  fontWeight?: number;
}

const FormGroup: React.FC<FormGroupProps> = ({ label, required = false, error, ...props }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', gap: '8px' }} {...props}>
      <CustomInputLabel
        error={Boolean(error)}
        shrink
        htmlFor={props.id ?? ''}
        style={{ margin: 0 }}>
        {label ?? ''} <span className={required ? 'required' : ''}></span>{' '}
      </CustomInputLabel>
      {props.children}
      <Box
        sx={{
          display: `${error ? 'flex' : 'none'}`,
          alignItems: 'center',
          textAlign: 'center',
          marginTop: '4px',
        }}>
        <FormHelperText error={!!error} sx={{ mt: 0 }}>
          {error?.message}
        </FormHelperText>
      </Box>
    </Box>
  );
};

export default FormGroup;
