import moment from 'moment-timezone';

moment.tz.setDefault('America/New_York');

export const convertToTitleCase = (str: string) => {
  str = str.toLowerCase();
  return str.replace(/(^|\s)\S/g, function (t) {
    return t.toUpperCase();
  });
};

export function PhoneNumberFormat(value: string | null | undefined) {
  if (!value) return value;
  const phoneNumber = value.toString().replace(/\D/g, '');
  const phoneNumberLength = phoneNumber.length;
  if (phoneNumberLength < 4) return phoneNumber;
  if (phoneNumberLength < 7) {
    return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3)}`;
  }

  return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
}

type DateTimeFormatProps = {
  dte: string | null | undefined | Date;
  format?: string;
  tz?: string;
};
export const DateTimeFormat = ({
  dte,
  format = 'MM-DD-YYYY HH:MM',
  tz = 'America/New_York',
}: DateTimeFormatProps) => {
  if (dte === undefined || dte === null) return '';
  return moment(dte).tz(tz).format(format);
};

export function HasFiles(value: string | null | undefined): string {
  if (!value) return 'No';
  if (value && Number(value) > 0) return 'Yes';
  return 'No';
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isValidDate(date: any): boolean {
  return !isNaN(Date.parse(date));
}
