export const CatAIcon = () => (
  <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect width='32' height='32' rx='16' fill='#FF4181' />
    <rect x='0.5' y='0.5' width='31' height='31' rx='15.5' stroke='black' strokeOpacity='0.08' />
    <path
      d='M16.3223 11.3223L12.4551 23.1094H9.33984L14.623 8.89062H16.6055L16.3223 11.3223ZM19.5352 23.1094L15.6582 11.3223L15.3457 8.89062H17.3477L22.6602 23.1094H19.5352ZM19.3594 17.8164V20.1113H11.8496V17.8164H19.3594Z'
      fill='white'
    />
  </svg>
);

export const CatBIcon = () => (
  <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect width='32' height='32' rx='16' fill='#5C00A4' />
    <rect x='0.5' y='0.5' width='31' height='31' rx='15.5' stroke='black' strokeOpacity='0.08' />
    <path
      d='M16.3359 16.7598H12.6836L12.6641 14.748H15.7305C16.2708 14.748 16.7135 14.6797 17.0586 14.543C17.4036 14.3997 17.6608 14.1947 17.8301 13.9277C18.0059 13.6543 18.0938 13.3223 18.0938 12.9316C18.0938 12.4889 18.0091 12.1309 17.8398 11.8574C17.6771 11.584 17.4199 11.3854 17.0684 11.2617C16.7233 11.138 16.2773 11.0762 15.7305 11.0762H13.6992V23H10.7695V8.78125H15.7305C16.5573 8.78125 17.2962 8.85938 17.9473 9.01562C18.6048 9.17188 19.1615 9.40951 19.6172 9.72852C20.0729 10.0475 20.4212 10.4512 20.6621 10.9395C20.903 11.4212 21.0234 11.9941 21.0234 12.6582C21.0234 13.2441 20.89 13.7845 20.623 14.2793C20.3626 14.7741 19.9492 15.1777 19.3828 15.4902C18.8229 15.8027 18.0905 15.9753 17.1855 16.0078L16.3359 16.7598ZM16.209 23H11.8828L13.0254 20.7148H16.209C16.7233 20.7148 17.1432 20.6335 17.4688 20.4707C17.7943 20.3014 18.0352 20.0736 18.1914 19.7871C18.3477 19.5007 18.4258 19.1719 18.4258 18.8008C18.4258 18.3841 18.3542 18.0228 18.2109 17.7168C18.0742 17.4108 17.8529 17.1764 17.5469 17.0137C17.2409 16.8444 16.8372 16.7598 16.3359 16.7598H13.5137L13.5332 14.748H17.0488L17.7227 15.5391C18.5885 15.526 19.2852 15.679 19.8125 15.998C20.3464 16.3105 20.7337 16.7174 20.9746 17.2188C21.222 17.7201 21.3457 18.2572 21.3457 18.8301C21.3457 19.7415 21.1471 20.5098 20.75 21.1348C20.3529 21.7533 19.7702 22.2188 19.002 22.5312C18.2402 22.8438 17.3092 23 16.209 23Z'
      fill='white'
    />
  </svg>
);

export const CatCIcon = () => (
  <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect width='32' height='32' rx='16' fill='#DB60CE' />
    <rect x='0.5' y='0.5' width='31' height='31' rx='15.5' stroke='black' strokeOpacity='0.08' />
    <path
      d='M18.5117 18.2832H21.4316C21.373 19.2402 21.1094 20.0898 20.6406 20.832C20.1784 21.5742 19.5306 22.1536 18.6973 22.5703C17.8704 22.987 16.8743 23.1953 15.709 23.1953C14.7975 23.1953 13.9805 23.0391 13.2578 22.7266C12.5352 22.4076 11.9167 21.9518 11.4023 21.3594C10.8945 20.7669 10.5072 20.0508 10.2402 19.2109C9.97331 18.3711 9.83984 17.4303 9.83984 16.3887V15.4023C9.83984 14.3607 9.97656 13.4199 10.25 12.5801C10.5299 11.7337 10.9271 11.0143 11.4414 10.4219C11.9622 9.82943 12.584 9.3737 13.3066 9.05469C14.0293 8.73568 14.8366 8.57617 15.7285 8.57617C16.9134 8.57617 17.9128 8.79102 18.7266 9.2207C19.5469 9.65039 20.1816 10.2428 20.6309 10.998C21.0866 11.7533 21.36 12.6126 21.4512 13.5762H18.5215C18.4889 13.0033 18.375 12.5182 18.1797 12.1211C17.9844 11.7174 17.6882 11.4147 17.291 11.2129C16.9004 11.0046 16.3796 10.9004 15.7285 10.9004C15.2402 10.9004 14.8138 10.9915 14.4492 11.1738C14.0846 11.3561 13.7786 11.6328 13.5312 12.0039C13.2839 12.375 13.0983 12.8438 12.9746 13.4102C12.8574 13.9701 12.7988 14.6276 12.7988 15.3828V16.3887C12.7988 17.1243 12.8542 17.7721 12.9648 18.332C13.0755 18.8854 13.2448 19.3542 13.4727 19.7383C13.707 20.1159 14.0065 20.4023 14.3711 20.5977C14.7422 20.7865 15.1882 20.8809 15.709 20.8809C16.321 20.8809 16.8255 20.7832 17.2227 20.5879C17.6198 20.3926 17.9225 20.1029 18.1309 19.7188C18.3457 19.3346 18.4727 18.8561 18.5117 18.2832Z'
      fill='white'
    />
  </svg>
);

export const CatDIcon = () => (
  <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect width='32' height='32' rx='16' fill='#003771' />
    <rect x='0.5' y='0.5' width='31' height='31' rx='15.5' stroke='black' strokeOpacity='0.08' />
    <path
      d='M15.1348 23H12.0391L12.0586 20.7148H15.1348C15.903 20.7148 16.5508 20.5423 17.0781 20.1973C17.6055 19.8457 18.0026 19.3346 18.2695 18.6641C18.543 17.9935 18.6797 17.1829 18.6797 16.2324V15.5391C18.6797 14.8099 18.6016 14.1686 18.4453 13.6152C18.2956 13.0618 18.071 12.5964 17.7715 12.2188C17.472 11.8411 17.1042 11.5579 16.668 11.3691C16.2318 11.1738 15.7305 11.0762 15.1641 11.0762H11.9805V8.78125H15.1641C16.1146 8.78125 16.9837 8.94401 17.7715 9.26953C18.5658 9.58854 19.2526 10.0475 19.832 10.6465C20.4115 11.2454 20.8574 11.9616 21.1699 12.7949C21.4889 13.6217 21.6484 14.543 21.6484 15.5586V16.2324C21.6484 17.2415 21.4889 18.1628 21.1699 18.9961C20.8574 19.8294 20.4115 20.5456 19.832 21.1445C19.2591 21.737 18.5723 22.196 17.7715 22.5215C16.9772 22.8405 16.0983 23 15.1348 23ZM13.6992 8.78125V23H10.7695V8.78125H13.6992Z'
      fill='white'
    />
  </svg>
);

export const CatEIcon = () => (
  <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect width='32' height='32' rx='16' fill='#F57E23' />
    <rect x='0.5' y='0.5' width='31' height='31' rx='15.5' stroke='black' strokeOpacity='0.08' />
    <path
      d='M20.8105 20.7148V23H13.2422V20.7148H20.8105ZM14.1992 8.78125V23H11.2695V8.78125H14.1992ZM19.8242 14.5723V16.7988H13.2422V14.5723H19.8242ZM20.8008 8.78125V11.0762H13.2422V8.78125H20.8008Z'
      fill='white'
    />
  </svg>
);

export const CatFIcon = () => (
  <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect width='32' height='32' rx='16' fill='#F44336' />
    <rect x='0.5' y='0.5' width='31' height='31' rx='15.5' stroke='black' strokeOpacity='0.08' />
    <path
      d='M14.6992 8.78125V23H11.7695V8.78125H14.6992ZM20.3633 14.8555V17.1406H13.8984V14.8555H20.3633ZM21.0469 8.78125V11.0762H13.8984V8.78125H21.0469Z'
      fill='white'
    />
  </svg>
);

export const CatGIcon = () => (
  <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect width='32' height='32' rx='16' fill='#007499' />
    <rect x='0.5' y='0.5' width='31' height='31' rx='15.5' stroke='black' strokeOpacity='0.08' />
    <path
      d='M21.5391 15.5684V21.2031C21.3177 21.4701 20.9759 21.7598 20.5137 22.0723C20.0514 22.3783 19.4557 22.6419 18.7266 22.8633C17.9974 23.0846 17.1152 23.1953 16.0801 23.1953C15.1621 23.1953 14.3255 23.0456 13.5703 22.7461C12.8151 22.4401 12.1641 21.9941 11.6172 21.4082C11.0768 20.8223 10.6602 20.1061 10.3672 19.2598C10.0742 18.4069 9.92773 17.4336 9.92773 16.3398V15.4512C9.92773 14.3574 10.0677 13.3841 10.3477 12.5312C10.6341 11.6784 11.041 10.959 11.5684 10.373C12.0957 9.78711 12.724 9.34115 13.4531 9.03516C14.1823 8.72917 14.9928 8.57617 15.8848 8.57617C17.1217 8.57617 18.1374 8.77799 18.9316 9.18164C19.7259 9.57878 20.3314 10.1322 20.748 10.8418C21.1712 11.5449 21.4316 12.3522 21.5293 13.2637H18.6875C18.6159 12.7819 18.4792 12.362 18.2773 12.0039C18.0755 11.6458 17.7858 11.3659 17.4082 11.1641C17.0371 10.9622 16.5553 10.8613 15.9629 10.8613C15.4746 10.8613 15.0384 10.9622 14.6543 11.1641C14.2767 11.3594 13.9577 11.6491 13.6973 12.0332C13.4368 12.4173 13.2383 12.8926 13.1016 13.459C12.9648 14.0254 12.8965 14.6829 12.8965 15.4316V16.3398C12.8965 17.082 12.9681 17.7396 13.1113 18.3125C13.2546 18.8789 13.4661 19.3574 13.7461 19.748C14.0326 20.1322 14.3841 20.4219 14.8008 20.6172C15.2174 20.8125 15.7025 20.9102 16.2559 20.9102C16.7181 20.9102 17.1022 20.8711 17.4082 20.793C17.7207 20.7148 17.9714 20.6204 18.1602 20.5098C18.3555 20.3926 18.5052 20.2819 18.6094 20.1777V17.6582H15.9336V15.5684H21.5391Z'
      fill='white'
    />
  </svg>
);

export const DestroyedIcon = () => (
  <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect width='32' height='32' rx='16' fill='#D0021B' />
    <rect x='0.5' y='0.5' width='31' height='31' rx='15.5' stroke='black' strokeOpacity='0.08' />
    <path
      d='M18.9 7H13.1C12.57 7 12.06 7.21 11.68 7.59L7.58 11.69C7.21 12.06 7 12.57 7 13.1V18.9C7 19.43 7.21 19.94 7.59 20.31L11.69 24.41C12.06 24.79 12.57 25 13.1 25H18.9C19.43 25 19.94 24.79 20.31 24.41L24.41 20.31C24.79 19.94 25 19.43 25 18.9V13.1C25 12.57 24.79 12.06 24.41 11.69L20.31 7.59C19.94 7.21 19.43 7 18.9 7ZM19.54 19.54C19.4475 19.6327 19.3376 19.7063 19.2166 19.7564C19.0957 19.8066 18.966 19.8324 18.835 19.8324C18.704 19.8324 18.5743 19.8066 18.4534 19.7564C18.3324 19.7063 18.2225 19.6327 18.13 19.54L16 17.41L13.88 19.53C13.7874 19.6226 13.6775 19.696 13.5565 19.7461C13.4356 19.7962 13.3059 19.822 13.175 19.822C13.0441 19.822 12.9144 19.7962 12.7935 19.7461C12.6725 19.696 12.5626 19.6226 12.47 19.53C12.3774 19.4374 12.304 19.3275 12.2539 19.2065C12.2038 19.0856 12.178 18.9559 12.178 18.825C12.178 18.6941 12.2038 18.5644 12.2539 18.4435C12.304 18.3225 12.3774 18.2126 12.47 18.12L14.59 16L12.46 13.88C12.3674 13.7874 12.294 13.6775 12.2439 13.5565C12.1938 13.4356 12.168 13.3059 12.168 13.175C12.168 13.0441 12.1938 12.9144 12.2439 12.7935C12.294 12.6725 12.3674 12.5626 12.46 12.47C12.5526 12.3774 12.6625 12.304 12.7835 12.2539C12.9044 12.2038 13.0341 12.178 13.165 12.178C13.2959 12.178 13.4256 12.2038 13.5465 12.2539C13.6675 12.304 13.7774 12.3774 13.87 12.47L16 14.59L18.12 12.47C18.2126 12.3774 18.3225 12.304 18.4435 12.2539C18.5644 12.2038 18.6941 12.178 18.825 12.178C18.9559 12.178 19.0856 12.2038 19.2065 12.2539C19.3275 12.304 19.4374 12.3774 19.53 12.47C19.6226 12.5626 19.696 12.6725 19.7461 12.7935C19.7962 12.9144 19.822 13.0441 19.822 13.175C19.822 13.3059 19.7962 13.4356 19.7461 13.5565C19.696 13.6775 19.6226 13.7874 19.53 13.88L17.41 16L19.53 18.12C19.93 18.51 19.93 19.15 19.54 19.54Z'
      fill='#F8FAFF'
    />
  </svg>
);

export const MajorDamageIcon = () => (
  <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect width='32' height='32' rx='16' fill='#FF4181' />
    <rect x='0.5' y='0.5' width='31' height='31' rx='15.5' stroke='black' strokeOpacity='0.08' />
    <path
      d='M18.9 7H13.1C12.57 7 12.06 7.21 11.68 7.59L7.58 11.69C7.21 12.06 7 12.57 7 13.1V18.9C7 19.43 7.21 19.94 7.59 20.31L11.69 24.41C12.06 24.79 12.57 25 13.1 25H18.9C19.43 25 19.94 24.79 20.31 24.41L24.41 20.31C24.79 19.94 25 19.43 25 18.9V13.1C25 12.57 24.79 12.06 24.41 11.69L20.31 7.59C19.94 7.21 19.43 7 18.9 7ZM19.54 19.54C19.4475 19.6327 19.3376 19.7063 19.2166 19.7564C19.0957 19.8066 18.966 19.8324 18.835 19.8324C18.704 19.8324 18.5743 19.8066 18.4534 19.7564C18.3324 19.7063 18.2225 19.6327 18.13 19.54L16 17.41L13.88 19.53C13.7874 19.6226 13.6775 19.696 13.5565 19.7461C13.4356 19.7962 13.3059 19.822 13.175 19.822C13.0441 19.822 12.9144 19.7962 12.7935 19.7461C12.6725 19.696 12.5626 19.6226 12.47 19.53C12.3774 19.4374 12.304 19.3275 12.2539 19.2065C12.2038 19.0856 12.178 18.9559 12.178 18.825C12.178 18.6941 12.2038 18.5644 12.2539 18.4435C12.304 18.3225 12.3774 18.2126 12.47 18.12L14.59 16L12.46 13.88C12.3674 13.7874 12.294 13.6775 12.2439 13.5565C12.1938 13.4356 12.168 13.3059 12.168 13.175C12.168 13.0441 12.1938 12.9144 12.2439 12.7935C12.294 12.6725 12.3674 12.5626 12.46 12.47C12.5526 12.3774 12.6625 12.304 12.7835 12.2539C12.9044 12.2038 13.0341 12.178 13.165 12.178C13.2959 12.178 13.4256 12.2038 13.5465 12.2539C13.6675 12.304 13.7774 12.3774 13.87 12.47L16 14.59L18.12 12.47C18.2126 12.3774 18.3225 12.304 18.4435 12.2539C18.5644 12.2038 18.6941 12.178 18.825 12.178C18.9559 12.178 19.0856 12.2038 19.2065 12.2539C19.3275 12.304 19.4374 12.3774 19.53 12.47C19.6226 12.5626 19.696 12.6725 19.7461 12.7935C19.7962 12.9144 19.822 13.0441 19.822 13.175C19.822 13.3059 19.7962 13.4356 19.7461 13.5565C19.696 13.6775 19.6226 13.7874 19.53 13.88L17.41 16L19.53 18.12C19.93 18.51 19.93 19.15 19.54 19.54Z'
      fill='#F8FAFF'
    />
  </svg>
);

export const MinorDamageIcon = () => (
  <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect width='32' height='32' rx='16' fill='#F57E23' />
    <rect x='0.5' y='0.5' width='31' height='31' rx='15.5' stroke='black' strokeOpacity='0.08' />
    <path
      d='M18.9 7H13.1C12.57 7 12.06 7.21 11.68 7.59L7.58 11.69C7.21 12.06 7 12.57 7 13.1V18.9C7 19.43 7.21 19.94 7.59 20.31L11.69 24.41C12.06 24.79 12.57 25 13.1 25H18.9C19.43 25 19.94 24.79 20.31 24.41L24.41 20.31C24.79 19.94 25 19.43 25 18.9V13.1C25 12.57 24.79 12.06 24.41 11.69L20.31 7.59C19.94 7.21 19.43 7 18.9 7ZM19.54 19.54C19.4475 19.6327 19.3376 19.7063 19.2166 19.7564C19.0957 19.8066 18.966 19.8324 18.835 19.8324C18.704 19.8324 18.5743 19.8066 18.4534 19.7564C18.3324 19.7063 18.2225 19.6327 18.13 19.54L16 17.41L13.88 19.53C13.7874 19.6226 13.6775 19.696 13.5565 19.7461C13.4356 19.7962 13.3059 19.822 13.175 19.822C13.0441 19.822 12.9144 19.7962 12.7935 19.7461C12.6725 19.696 12.5626 19.6226 12.47 19.53C12.3774 19.4374 12.304 19.3275 12.2539 19.2065C12.2038 19.0856 12.178 18.9559 12.178 18.825C12.178 18.6941 12.2038 18.5644 12.2539 18.4435C12.304 18.3225 12.3774 18.2126 12.47 18.12L14.59 16L12.46 13.88C12.3674 13.7874 12.294 13.6775 12.2439 13.5565C12.1938 13.4356 12.168 13.3059 12.168 13.175C12.168 13.0441 12.1938 12.9144 12.2439 12.7935C12.294 12.6725 12.3674 12.5626 12.46 12.47C12.5526 12.3774 12.6625 12.304 12.7835 12.2539C12.9044 12.2038 13.0341 12.178 13.165 12.178C13.2959 12.178 13.4256 12.2038 13.5465 12.2539C13.6675 12.304 13.7774 12.3774 13.87 12.47L16 14.59L18.12 12.47C18.2126 12.3774 18.3225 12.304 18.4435 12.2539C18.5644 12.2038 18.6941 12.178 18.825 12.178C18.9559 12.178 19.0856 12.2038 19.2065 12.2539C19.3275 12.304 19.4374 12.3774 19.53 12.47C19.6226 12.5626 19.696 12.6725 19.7461 12.7935C19.7962 12.9144 19.822 13.0441 19.822 13.175C19.822 13.3059 19.7962 13.4356 19.7461 13.5565C19.696 13.6775 19.6226 13.7874 19.53 13.88L17.41 16L19.53 18.12C19.93 18.51 19.93 19.15 19.54 19.54Z'
      fill='#F8FAFF'
    />
  </svg>
);

export const AffectedIcon = () => (
  <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect width='32' height='32' rx='16' fill='#D7C804' />
    <rect x='0.5' y='0.5' width='31' height='31' rx='15.5' stroke='black' strokeOpacity='0.08' />
    <path
      d='M18.9 7H13.1C12.57 7 12.06 7.21 11.68 7.59L7.58 11.69C7.21 12.06 7 12.57 7 13.1V18.9C7 19.43 7.21 19.94 7.59 20.31L11.69 24.41C12.06 24.79 12.57 25 13.1 25H18.9C19.43 25 19.94 24.79 20.31 24.41L24.41 20.31C24.79 19.94 25 19.43 25 18.9V13.1C25 12.57 24.79 12.06 24.41 11.69L20.31 7.59C19.94 7.21 19.43 7 18.9 7ZM19.54 19.54C19.4475 19.6327 19.3376 19.7063 19.2166 19.7564C19.0957 19.8066 18.966 19.8324 18.835 19.8324C18.704 19.8324 18.5743 19.8066 18.4534 19.7564C18.3324 19.7063 18.2225 19.6327 18.13 19.54L16 17.41L13.88 19.53C13.7874 19.6226 13.6775 19.696 13.5565 19.7461C13.4356 19.7962 13.3059 19.822 13.175 19.822C13.0441 19.822 12.9144 19.7962 12.7935 19.7461C12.6725 19.696 12.5626 19.6226 12.47 19.53C12.3774 19.4374 12.304 19.3275 12.2539 19.2065C12.2038 19.0856 12.178 18.9559 12.178 18.825C12.178 18.6941 12.2038 18.5644 12.2539 18.4435C12.304 18.3225 12.3774 18.2126 12.47 18.12L14.59 16L12.46 13.88C12.3674 13.7874 12.294 13.6775 12.2439 13.5565C12.1938 13.4356 12.168 13.3059 12.168 13.175C12.168 13.0441 12.1938 12.9144 12.2439 12.7935C12.294 12.6725 12.3674 12.5626 12.46 12.47C12.5526 12.3774 12.6625 12.304 12.7835 12.2539C12.9044 12.2038 13.0341 12.178 13.165 12.178C13.2959 12.178 13.4256 12.2038 13.5465 12.2539C13.6675 12.304 13.7774 12.3774 13.87 12.47L16 14.59L18.12 12.47C18.2126 12.3774 18.3225 12.304 18.4435 12.2539C18.5644 12.2038 18.6941 12.178 18.825 12.178C18.9559 12.178 19.0856 12.2038 19.2065 12.2539C19.3275 12.304 19.4374 12.3774 19.53 12.47C19.6226 12.5626 19.696 12.6725 19.7461 12.7935C19.7962 12.9144 19.822 13.0441 19.822 13.175C19.822 13.3059 19.7962 13.4356 19.7461 13.5565C19.696 13.6775 19.6226 13.7874 19.53 13.88L17.41 16L19.53 18.12C19.93 18.51 19.93 19.15 19.54 19.54Z'
      fill='#F8FAFF'
    />
  </svg>
);

export const NoDamageIcon = () => (
  <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect width='32' height='32' rx='16' fill='#6EAA2C' />
    <rect x='0.5' y='0.5' width='31' height='31' rx='15.5' stroke='black' strokeOpacity='0.08' />
    <path
      d='M6 16C6 10.48 10.48 6 16 6C21.52 6 26 10.48 26 16C26 21.52 21.52 26 16 26C10.48 26 6 21.52 6 16ZM16 22C19.31 22 22 19.31 22 16C22 12.69 19.31 10 16 10C12.69 10 10 12.69 10 16C10 19.31 12.69 22 16 22Z'
      fill='#F8FAFF'
    />
  </svg>
);

export const InaccessibleIcon = () => (
  <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect width='32' height='32' rx='16' fill='#63606A' />
    <rect x='0.5' y='0.5' width='31' height='31' rx='15.5' stroke='black' strokeOpacity='0.08' />
    <path
      d='M18.9 7H13.1C12.57 7 12.06 7.21 11.68 7.59L7.58 11.69C7.21 12.06 7 12.57 7 13.1V18.9C7 19.43 7.21 19.94 7.59 20.31L11.69 24.41C12.06 24.79 12.57 25 13.1 25H18.9C19.43 25 19.94 24.79 20.31 24.41L24.41 20.31C24.79 19.94 25 19.43 25 18.9V13.1C25 12.57 24.79 12.06 24.41 11.69L20.31 7.59C19.94 7.21 19.43 7 18.9 7ZM19.54 19.54C19.4475 19.6327 19.3376 19.7063 19.2166 19.7564C19.0957 19.8066 18.966 19.8324 18.835 19.8324C18.704 19.8324 18.5743 19.8066 18.4534 19.7564C18.3324 19.7063 18.2225 19.6327 18.13 19.54L16 17.41L13.88 19.53C13.7874 19.6226 13.6775 19.696 13.5565 19.7461C13.4356 19.7962 13.3059 19.822 13.175 19.822C13.0441 19.822 12.9144 19.7962 12.7935 19.7461C12.6725 19.696 12.5626 19.6226 12.47 19.53C12.3774 19.4374 12.304 19.3275 12.2539 19.2065C12.2038 19.0856 12.178 18.9559 12.178 18.825C12.178 18.6941 12.2038 18.5644 12.2539 18.4435C12.304 18.3225 12.3774 18.2126 12.47 18.12L14.59 16L12.46 13.88C12.3674 13.7874 12.294 13.6775 12.2439 13.5565C12.1938 13.4356 12.168 13.3059 12.168 13.175C12.168 13.0441 12.1938 12.9144 12.2439 12.7935C12.294 12.6725 12.3674 12.5626 12.46 12.47C12.5526 12.3774 12.6625 12.304 12.7835 12.2539C12.9044 12.2038 13.0341 12.178 13.165 12.178C13.2959 12.178 13.4256 12.2038 13.5465 12.2539C13.6675 12.304 13.7774 12.3774 13.87 12.47L16 14.59L18.12 12.47C18.2126 12.3774 18.3225 12.304 18.4435 12.2539C18.5644 12.2038 18.6941 12.178 18.825 12.178C18.9559 12.178 19.0856 12.2038 19.2065 12.2539C19.3275 12.304 19.4374 12.3774 19.53 12.47C19.6226 12.5626 19.696 12.6725 19.7461 12.7935C19.7962 12.9144 19.822 13.0441 19.822 13.175C19.822 13.3059 19.7962 13.4356 19.7461 13.5565C19.696 13.6775 19.6226 13.7874 19.53 13.88L17.41 16L19.53 18.12C19.93 18.51 19.93 19.15 19.54 19.54Z'
      fill='#F8FAFF'
    />
  </svg>
);
