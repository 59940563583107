import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DashboardInitialState, DashboardStateTypes } from './dashboard.state.ts';
import { fetchIAHomePage, fetchPAHomePage } from 'store/feature/dashboard/dashboard.thunk.ts';
import { QueryState } from '@/common/enum';

const initialState: DashboardStateTypes = DashboardInitialState;

export const DashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setSelectedIdx: (state, action: PayloadAction<number>) => {
      state.selectedIdx = action.payload;
    },
    setInitialSetup: (state, action: PayloadAction<boolean>) => {
      state.initialSetup = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchIAHomePage.pending, (state) => {
        state.queryState = QueryState.AWAIT;
        state.error = null;
      })
      .addCase(fetchIAHomePage.fulfilled, (state, action) => {
        const respData = action.payload ? action.payload?.data : null;
        state.queryState = QueryState.READY;
        state.iaData = !action.payload?.isAllData ? respData : null;
        if (action.payload?.isAllData) {
          state.allIAData = respData;
          if (state.selectedIdx !== 0) state.selectedIdx = 0;
        }
      })
      .addCase(fetchIAHomePage.rejected, (state, action) => {
        state.queryState = QueryState.FAIL;
        state.iaData = null;
        state.error = action.error.message || 'Something went wrong!';
      })
      .addCase(fetchPAHomePage.pending, (state) => {
        state.queryState = QueryState.AWAIT;
        state.error = null;
      })
      .addCase(fetchPAHomePage.fulfilled, (state, action) => {
        state.queryState = QueryState.READY;
        state.paData = action.payload ? action.payload : null;
      })
      .addCase(fetchPAHomePage.rejected, (state, action) => {
        state.queryState = QueryState.FAIL;
        state.paData = null;
        state.error = action.error.message || 'Something went wrong!';
      });
  },
});

export const { setSelectedIdx, setInitialSetup } = DashboardSlice.actions;

export default DashboardSlice.reducer;
