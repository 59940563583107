import React from 'react';
import { Await, defer, LoaderFunctionArgs, useAsyncValue, useLoaderData } from 'react-router-dom';
import apiFetch, { genericErrorMessage } from '@/services/apiFetch.ts';
import { getPersonnelData } from '@/services/personnel.endpoint.ts';
import { PersonnelDetails } from 'interfaces/personnel.interface.ts';
import { Loading } from 'components/primitive';

const DetailsPage = React.lazy(() => import('./Details.tsx'));

interface PersonnelDataResponse {
  data: PersonnelDetails | null;
}

// eslint-disable-next-line react-refresh/only-export-components
export const loaderPersonnelDetails = ({ params }: LoaderFunctionArgs) => {
  try {
    const { id } = params;
    const url = `/api/personnel/${id}`;
    const endpoint = getPersonnelData(url);
    const personnelData = apiFetch<PersonnelDetails>(endpoint).then((res) => res.data ?? null);
    return defer({
      data: personnelData,
    });
  } catch (error) {
    let message = genericErrorMessage;
    if (error instanceof Error) message = error.message;
    else {
      if (typeof error === 'object' && error !== null) {
        if ('message' in error) {
          message = String(error.message);
        }
      }
    }
    throw Error(message);
  }
};

function RenderAwaitedData() {
  const data = useAsyncValue() as PersonnelDetails;
  return <DetailsPage data={data} />;
}

const DetailsContainer = () => {
  const data = useLoaderData() as PersonnelDataResponse;
  return (
    <React.Suspense fallback={<Loading />}>
      <Await resolve={data.data} errorElement={<p>Error loading personnel!</p>}>
        <RenderAwaitedData />
      </Await>
    </React.Suspense>
  );
};

export default DetailsContainer;
