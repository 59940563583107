/* eslint-disable no-unused-vars */
import { useState } from 'react';
// import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';

type PageNavProps = {
  initialItem: string;
};

export enum PageNavItems {
  IA = 'ia',
  PA = 'pa',
}

const usePageNav = ({ initialItem }: PageNavProps) => {
  const [selectedItem, setSelectedItem] = useState<string>(initialItem);
  const [index, setIndex] = useState<number>(0);

  const onChange = (item: string | number) => {
    if (item === PageNavItems.IA && selectedItem !== PageNavItems.IA) {
      setSelectedItem(PageNavItems.IA);
      setIndex(0);
    }
    if (item === PageNavItems.PA && selectedItem !== PageNavItems.PA) {
      setSelectedItem(PageNavItems.PA);
      setIndex(1);
    }
  };

  return { selectedItem, setSelectedItem, index, setIndex, onChange };
};

export default usePageNav;
