/* eslint-disable @typescript-eslint/no-explicit-any */
// noinspection TypeScriptValidateJSTypes

import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SubmitHandler, UseFormReturn } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import usePADataForm, { defaultValues } from './hook/usePADataForm.ts';
import EditView from './Edit.view.tsx';
import { PADetail } from 'interfaces/pa.detail.interface.ts';
import { useAppDispatch, useAppSelector } from 'store/hooks.ts';
import { accessTypeSelector, selectAuthToken } from 'store/feature/authToken/authToken.selector.ts';
import { ToastStatus, UserType } from '@/common/enum';
import { EditViewProps } from './Edit.props.ts';
import { RadioSelectOptions } from 'interfaces/SelectOptions.interface.ts';
import { CountiesLatLng } from '@/common/static';
import { addOrDeleteFilesFunc, convertToTitleCase, prepareDMAFilesData } from 'utils/index.ts';
import { AddressSelectCBType } from 'interfaces/SearchInputLocationTypes.ts';
import {
  accountGroupIDSelector,
  selectAccount,
  selectedGroupNameSelector,
  selectedGroupSelector,
  userTypeSelector,
} from 'store/feature/accountsInfo/accountsInfo.selector.ts';
import { DMAFileTypes } from 'interfaces/ia.interface.ts';
import { deletePAData, savePAData } from 'store/feature/pa/pa.thunk.ts';
import { toast } from 'react-toastify';
import { ToastMessage } from 'components/primitive';
import { DateTimeFormat, isValidDate } from 'utils/FomattingUtils.ts';
import {
  isJPDA_PA_ActiveSelector,
  selectedIncidentSelector,
} from 'store/feature/incidents/incidents.selector.ts';
import {
  applicantsSelector,
  selectedApplicantSelector,
} from 'store/feature/applicants/applicants.selector.ts';
import SitePermissionTypes from '../../../common/enum/SitePermissionTypes.ts';

interface EditProps {
  newEntry: boolean;
  category: string;
  path: string;
  title: string;
  data?: PADetail;
}

const Edit: React.FC<EditProps> = ({ category, data, newEntry, title }) => {
  const navigate = useNavigate();
  const form: UseFormReturn<PADetail> = usePADataForm();
  const dispatch = useAppDispatch();
  const authToken = useAppSelector(selectAuthToken) ?? '';
  const palmettoUser = useAppSelector(selectAccount);
  const selectedIncident = useAppSelector(selectedIncidentSelector);
  const userType = useAppSelector(userTypeSelector);
  const userGroupName = useAppSelector(selectedGroupNameSelector) ?? '';
  const selectedApplicant = useAppSelector(selectedApplicantSelector);
  const applicantsDataSet = useAppSelector(applicantsSelector) ?? [];
  const selectedGroupID = useAppSelector(accountGroupIDSelector) ?? 0;
  const selectedGroup = useAppSelector(selectedGroupSelector);
  const isJPDA_PA_Active = useAppSelector(isJPDA_PA_ActiveSelector);
  const userPermission = useAppSelector(accessTypeSelector);
  const activeGroupID = selectedGroup ? Number(selectedGroup) : selectedGroupID;
  const isStateUser = userType === UserType.STATE_USER;

  const [readOnly, setReadOnly] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [countyOptions, setCountyOptions] = useState<RadioSelectOptions[]>([]);
  const [allowAutoFill, setAllowAutoFill] = useState(true);
  const [filesAdded, setFilesAdded] = useState<DMAFileTypes[] | any>([]);
  const [filesDeleted, setFilesDeleted] = useState<DMAFileTypes[] | any>([]);
  const [filesUploaded, setFilesUploaded] = useState<DMAFileTypes[] | any[]>([]);
  const [showModalMsg, setShowModalMsg] = useState<boolean>(false);

  const permissions: { UPDATE: boolean } = useMemo(() => {
    const value = { UPDATE: true };
    if (isStateUser) {
      if (
        !isJPDA_PA_Active &&
        (userPermission === SitePermissionTypes.U || userPermission === SitePermissionTypes.F)
      ) {
        value.UPDATE = false;
      }
    }
    return value;
  }, [isStateUser, userPermission, isJPDA_PA_Active]);

  useEffect(() => {
    if (newEntry) {
      setReadOnly(false);
      const activeGroupName = convertToTitleCase(userGroupName);
      const personName =
        palmettoUser && palmettoUser?.pvPersonGivenName && palmettoUser?.pvPersonSurName
          ? `${palmettoUser?.pvPersonGivenName} ${palmettoUser?.pvPersonSurName}`
          : '';
      const formData = {
        ...defaultValues,
        group_id: activeGroupID,
        user_id: palmettoUser?.id ?? 0,
        applicant_id: selectedApplicant?.id ?? '',
        applicant_name: selectedApplicant?.applicant_name ?? '',
        name: personName,
        phone: palmettoUser?.pvOfficePhone ?? '',
        email: palmettoUser?.email ?? '',
        category: category,
        applicant_county: activeGroupName,
        county: activeGroupName,
        pvGlobalID: uuidv4(),
        incident_id: selectedIncident?.pvIncidentID ?? 0,
        user_group_id: selectedGroupID,
      };
      form.reset(formData);
    } else {
      if (data) {
        const activeApplicant = applicantsDataSet.find(
          (applicant) => applicant.id === data.applicant_id
        );
        const formInitData: PADetail = {
          ...defaultValues,
          ...data,
          applicant_name: activeApplicant?.applicant_name ?? '',
          date: data.date ? new Date(data.date) : null,
          user_id: palmettoUser?.id ?? data.user_id,
          user_group_id: selectedGroupID,
        };
        form.reset(formInitData);
      } else {
        form.reset(defaultValues);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newEntry, data, userGroupName, selectedIncident]);

  useEffect(() => {
    const tempOptions: RadioSelectOptions[] = [];
    Object.keys(CountiesLatLng).map((key) => {
      if (key !== 'scemd')
        tempOptions.push({ label: convertToTitleCase(key), value: convertToTitleCase(key) });
    });
    setCountyOptions(tempOptions.sort((a, b) => a.label.localeCompare(b.label)));
  }, []);

  const handleEdit = () => {
    setReadOnly(!readOnly);
  };

  const onBack = () => {
    if (newEntry) return navigate(-1);
    if (readOnly) return navigate(-1);
    else setReadOnly(true);
  };

  const toggleAutoFill = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    setAllowAutoFill(checked);
  };

  const onAddressSelect = (value: AddressSelectCBType) => {
    if (value) {
      form.setValue('address', value.address1);
      form.setValue('address2', value.address2);
      form.setValue('city', value.city);
      form.setValue('county', value.county);
      form.setValue('state', value.state);
      form.setValue('zipCode', value.zipCode);
      form.setValue('latitude', value.latitude);
      form.setValue('longitude', value.longitude);
    }
  };

  const onSubmit: SubmitHandler<PADetail> = async (data) => {
    if (isStateUser) {
      if (
        !isJPDA_PA_Active &&
        (Number(data.applicant_cost_estimate === 0) || !data.applicant_cost_estimate)
      ) {
        return setShowModalMsg(true);
      }
      if (isJPDA_PA_Active && (Number(data.team_cost_estimate) === 0 || !data.team_cost_estimate)) {
        return setShowModalMsg(true);
      }
    }
    if (
      !isStateUser &&
      (Number(data.applicant_cost_estimate === 0) || !data.applicant_cost_estimate)
    ) {
      return setShowModalMsg(true);
    }
    await handleSave();
  };

  const onHandleConfirm = async (confirm: boolean) => {
    setShowModalMsg(false);
    if (confirm) {
      await handleSave();
    }
  };

  const handleSave = async () => {
    const data = form.getValues();
    const payload = {
      ...data,
      date: DateTimeFormat({ dte: data.date, format: 'YYYY-MM-DD HH:mm:ss' }),
      user_group_id: selectedGroupID,
      has_files: 0,
    };
    if (!data.pvGlobalID || !authToken) return;
    try {
      setIsSaving(true);
      await addOrDeleteFilesFunc({
        filesToAdd: filesAdded,
        filesToDelete: filesDeleted,
        uuid: data.pvGlobalID,
        authToken,
      });

      const finalFiles: any[] = [];
      const storedFiles: DMAFileTypes[] | any[] = data.files ?? [];
      if (data.id) {
        if (filesAdded.length) {
          const filesToAdd = prepareDMAFilesData(filesAdded, 'pa');
          finalFiles.push(...filesToAdd);
        }

        if (filesDeleted.length) {
          // *: Check if a file is deleted that exist in the active files
          const findDeletedIndex = storedFiles.findIndex((file: DMAFileTypes) =>
            filesDeleted.some(
              (deletedFile: DMAFileTypes) => deletedFile.cbrnDataFileName === file.file_name
            )
          );
          if (findDeletedIndex > -1) {
            storedFiles[findDeletedIndex].deleted = 1;
          }
        }
        // *: Check if a file does not exist in the S3 bucket
        for (let i = 0; i < storedFiles.length; i++) {
          const file = storedFiles[i];
          const isFileAdded = filesUploaded.some(
            (addedFile: DMAFileTypes) => addedFile.file_name === file.file_name
          );
          if (!isFileAdded) {
            finalFiles.push({
              ...file,
              deleted: 1,
            });
          } else {
            finalFiles.push(file);
          }
        }
      } else {
        const filesToAdd = prepareDMAFilesData(filesAdded, 'pa');
        finalFiles.push(...filesToAdd);
      }

      const filteredFiles = finalFiles.filter((file) => file !== undefined);
      // *: Format date/time stamp for SQL
      const today = new Date();
      payload.files = filteredFiles.map((file) => {
        return {
          ...file,
          created_at: DateTimeFormat({
            dte: isValidDate(file.created_at) ? file.created_at : today,
            format: 'YYYY-MM-DD HH:mm:ss',
          }),
          updated_at: DateTimeFormat({
            dte: isValidDate(file.updated_at) ? file.created_at : today,
            format: 'YYYY-MM-DD HH:mm:ss',
          }),
        };
      });
      let fileCount = 0;
      payload.files.forEach((file) => {
        if (file.deleted === 0) {
          fileCount += 1;
        }
      });
      payload.has_files = fileCount > 0 ? 1 : 0;

      if (!isStateUser) {
        delete payload.jpda_status;
      }

      await dispatch(savePAData(payload))
        .unwrap()
        .then(() => {
          toast.info(<ToastMessage status={ToastStatus.SUCCESS} message='Successfully Process' />);
          setIsSaving(false);
          setTimeout(() => navigate(-1), 2000);
        })
        .catch(() => {
          toast.info(<ToastMessage status={ToastStatus.ERROR} message='Error Processing' />);
          setIsSaving(false);
        });
    } catch (_error) {
      toast.info(<ToastMessage status={ToastStatus.ERROR} message='Error Processing' />);
      setIsSaving(false);
    }
  };

  const onDelete = async () => {
    const { id } = form.getValues();
    if (!id) return;
    setIsDeleting(true);
    await dispatch(deletePAData({ id }))
      .unwrap()
      .then(() => {
        toast.info(<ToastMessage status={ToastStatus.SUCCESS} message='Successfully Deleted' />);
        setIsDeleting(false);
        setTimeout(() => navigate(-1), 2000);
      })
      .catch(() => {
        toast.info(<ToastMessage status={ToastStatus.ERROR} message='Error Deleting' />);
        setIsDeleting(false);
      });
  };

  const combineProps: EditViewProps = {
    isNew: newEntry,
    authToken,
    palmettoUser,
    title,
    form,
    readOnly,
    isSaving,
    isDeleting,
    showModalMsg,
    permissions,
    countyOptions,
    allowAutoFill,
    isStateUser,
    isJPDA_PA_Active,
    filesAdded,
    filesDeleted,
    onBack,
    handleEdit,
    onSubmit,
    onDelete,
    toggleAutoFill,
    onAddressSelect,
    setFilesAdded,
    setFilesDeleted,
    setFilesUploaded,
    onHandleConfirm,
  };

  return <EditView {...combineProps} />;
};

export default Edit;
