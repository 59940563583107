import { createAsyncThunk } from '@reduxjs/toolkit';
import apiFetch, { genericErrorMessage } from '@/services/apiFetch.ts';
import { RootState } from '@/store';
import { getMapLayerSettings, saveMapIDSettings } from '@/services/map.endpoint.ts';
import { MapLayerSettings } from 'interfaces/map.interface.ts';

export const saveMapID = createAsyncThunk('SAVE_MAP_ID', async (mapId: string, thunkAPI) => {
  try {
    const store = thunkAPI.getState() as RootState;
    const { account, selectedGroup, selectedGroupID } = store.accountsInfo;
    const mapSettingsID = store.map.mapSettingsID;
    const activeGroup = selectedGroup ? selectedGroup : selectedGroupID;
    if (!activeGroup || !mapId) return Promise.reject('No active group or map ID');
    const payload = {
      pvAccountID: account?.id,
      pvSettingType: 'mapLayerIdSetting',
      pvSettingValue: mapId,
      pvSettingID: mapSettingsID,
      pvGroupID: Number(activeGroup),
    };
    const endpoint = saveMapIDSettings(payload);
    return await apiFetch<MapLayerSettings>(endpoint).then((res) => res.data ?? null);
  } catch (error) {
    let message = genericErrorMessage;
    if (error instanceof Error) message = error.message;
    else {
      if (typeof error === 'object' && error !== null) {
        if ('message' in error) {
          message = String(error.message);
        }
      }
    }
    throw Error(message);
  }
});

export const fetchMapLayerID = createAsyncThunk('FETCH_MAP_LAYER_ID', async (_, thunkAPI) => {
  try {
    const store = thunkAPI.getState() as RootState;
    const { selectedGroup, selectedGroupID } = store.accountsInfo;
    const activeGroup = selectedGroup ? selectedGroup : selectedGroupID;
    if (!activeGroup) return Promise.reject('No active group');

    const endpoint = getMapLayerSettings({
      pvGroupID: Number(activeGroup),
      pvSettingType: 'mapLayerIdSetting',
    });
    return apiFetch<MapLayerSettings[]>(endpoint).then((res) => res.data ?? null);
  } catch (error) {
    let message = genericErrorMessage;
    if (error instanceof Error) message = error.message;
    else {
      if (typeof error === 'object' && error !== null) {
        if ('message' in error) {
          message = String(error.message);
        }
      }
    }
    throw Error(message);
  }
});
