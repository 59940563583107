/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Controller, useFormContext, UseFormReturn } from 'react-hook-form';
import useStyles from './SectionView.styles.ts';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material';
import { DBModalSettings } from '../../hooks/useDBModalSettingsForm.ts';
import { SelectMenu, Typo } from 'components/primitive';
import { RadioSelectOptions } from 'interfaces/SelectOptions.interface.ts';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';

type SectionViewProps = {
  title: string;
  fieldName: any;
  optionName: any;
  required?: boolean;
  customIcon?: React.ReactNode;
  readOnly?: boolean;
};

const SectionView: React.FC<SectionViewProps> = ({
  title,
  fieldName,
  optionName,
  required = false,
  readOnly = false,
  ...props
}) => {
  const form: UseFormReturn<DBModalSettings> = useFormContext();
  const theme = useTheme();
  const classes = useStyles(theme);
  const options: RadioSelectOptions[] = form.watch(optionName);
  const fieldValue: string | number | undefined = form.watch(fieldName);

  return (
    <Box key={fieldName} sx={classes.container}>
      <Box sx={classes.sectionHeader}>
        <Typo fontSize={'14px'} fontWeight={700}>
          {title}
          {required && <span className={'required'} />}
        </Typo>
        {fieldValue ? (
          <CheckCircleRoundedIcon fontSize='small' sx={{ color: '#6EAA2C' }} />
        ) : (
          <CheckCircleOutlineIcon fontSize='small' sx={{ color: theme.palette.grey[300] }} />
        )}
      </Box>
      <Box sx={classes.section.body}>
        <Box sx={classes.section.col}>
          <Controller
            control={form.control}
            name={fieldName}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <SelectMenu
                withIcon
                customIcon={props.customIcon}
                variant={'outlined'}
                backgroundColor={'#F0F0F0'}
                value={value}
                onChange={onChange}
                values={options}
                disabled={readOnly}
                error={Boolean(error)}
                fieldError={error}
                helperText={error ? error.message : ''}
              />
            )}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default SectionView;
