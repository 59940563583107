import type IEndPoint from 'interfaces/EndPointTypes.ts';
import { AuthProtect, BaseURLSelect } from '@/common/enum';
import { EQFormData } from 'pages/Equipment/hook/useEQForm.ts';

export const getEquipmentDataSet = (url: string): IEndPoint => ({
  authorization: AuthProtect.REQUIRED,
  method: 'GET',
  url,
  defaultBaseURL: BaseURLSelect.DMA,
});

export const getEquipmentData = (url: string): IEndPoint => ({
  authorization: AuthProtect.REQUIRED,
  method: 'GET',
  url,
  defaultBaseURL: BaseURLSelect.DMA,
});

export const postEquipmentData = (url: string, isNew: boolean, data: EQFormData): IEndPoint => ({
  authorization: AuthProtect.REQUIRED,
  method: isNew ? 'POST' : 'PUT',
  url,
  defaultBaseURL: BaseURLSelect.DMA,
  data,
});

export const deleteEq = (url: string): IEndPoint => ({
  authorization: AuthProtect.REQUIRED,
  method: 'DELETE',
  url,
  defaultBaseURL: BaseURLSelect.DMA,
});
