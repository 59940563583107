/* eslint-disable @typescript-eslint/no-explicit-any,react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { AxiosRequestConfig } from 'axios';
import { DMAFileTypes } from 'interfaces/ia.interface.ts';
import { bytesToSize, getProperKeyNameDummySearchString, spliceDate } from 'utils/index.ts';
import { Box, useTheme } from '@mui/material';
import useStyles from './Documents.styles';
import { Typo } from 'components/primitive';
import { DropFileZone, FileContainer } from 'components/fragment';
import useUploadForm from 'hooks/useUploadForm.ts';
import { IUserPortal } from 'interfaces/UserAccountInfo.interface.ts';

type DocumentsProps = {
  readOnly: boolean;
  uuid: string;
  user: IUserPortal;
  authToken: string;
  pvDataID: number | string;
  hasOldData?: boolean;
  files?: DMAFileTypes[];
  title?: string;
  defaultHeaderTitle?: string;
  defaultReadOnlyTitleTitle?: string;
  filesQueuedCallback: React.Dispatch<React.SetStateAction<any>>;
  filesDeletedCallback: React.Dispatch<React.SetStateAction<any>>;
  filesUploadedCallback?: React.Dispatch<React.SetStateAction<any>>;
};

const palmettoBaseURL = import.meta.env.VITE_MAIN_PALMETTO_ENDPOINT;
const Documents: React.FC<DocumentsProps> = ({
  readOnly,
  hasOldData = false,
  title = 'Documents',
  defaultHeaderTitle = 'File Upload',
  defaultReadOnlyTitleTitle = 'Please upload a document.',
  filesQueuedCallback,
  filesDeletedCallback,
  filesUploadedCallback,
  ...props
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { downloadForm, uploadedDocuments, inProgress } = useUploadForm<DMAFileTypes>(
    Boolean(props.files && props.files.length > 0)
  );
  const [imgOnErrorURL, setImgOnErrorURL] = useState<string>();
  useEffect(() => {
    if (filesUploadedCallback) {
      filesUploadedCallback(uploadedDocuments);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadedDocuments]);

  useEffect(() => {
    const fetchData = (url: string) => {
      const config: AxiosRequestConfig = {
        headers: { 'Content-Type': 'application/json' },
      };
      downloadForm(url, config, props.files || []).catch((err) => {
        console.error('🚀 Documents.tsx fetchData err: ', err);
      });
    };

    if (props.uuid && props.authToken) {
      const url = `${palmettoBaseURL}/api/files?access_token=${props.authToken}&filter=${JSON.stringify({ where: { pvGlobalGroupID: props.uuid } })}`;
      fetchData(url);
    }
  }, [props.uuid, props.authToken, hasOldData, props.files]);

  const downloadFile = (item: DMAFileTypes) => {
    const payload = item;
    const isNew = Object.prototype.hasOwnProperty.call(payload, 'cbrnDataFileName');
    const a = document.createElement('a');
    a.href = imgOnErrorURL
      ? imgOnErrorURL
      : isNew
        ? `${palmettoBaseURL || window.origin}${payload?.cbrnDataFileURIID}?access_token=${props.authToken}`
        : `${palmettoBaseURL || window.origin}/api/containers/fallbackbucket/download/${payload.file_key}?access_token=${props.authToken}`;
    a.target = '_blank';
    a.download = spliceDate(payload?.cbrnDataFileURIID ?? '');
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const handleImageError = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    const target = e.target as HTMLImageElement;
    const newSRC = target.src.replace('fallbackbucket', 'palmettodev');
    target.src = newSRC;
    setImgOnErrorURL(newSRC);
  };

  return (
    <Box sx={classes.container} hidden={inProgress}>
      <Typo sx={classes.title}>
        {uploadedDocuments.length > 0
          ? title
          : readOnly
            ? defaultReadOnlyTitleTitle
            : defaultHeaderTitle}
      </Typo>
      {readOnly ? (
        <Box sx={classes.content}>
          {!inProgress && Array.isArray(uploadedDocuments) && uploadedDocuments.length > 0 ? (
            uploadedDocuments.map((file, index) => {
              if (!file?.pvVoid || file.deleted === 0 || file.deleted === null) {
                const fileName = Object.prototype.hasOwnProperty.call(file, 'cbrnDataFileName')
                  ? file.cbrnDataFileName
                  : Object.prototype.hasOwnProperty.call(file, 'file_name')
                    ? file.file_name
                    : '';
                const hasDummyName: boolean = fileName.indexOf('_DUMMYSEARCHSTRING_') >= 0;
                const name = hasDummyName ? getProperKeyNameDummySearchString(fileName) : fileName;
                const byteSize = Object.prototype.hasOwnProperty.call(file, 'cbrnDataFileSize')
                  ? bytesToSize(file?.cbrnDataFileSize ?? 0)
                  : bytesToSize(file?.file_size ?? 0);
                return (
                  <FileContainer
                    key={index}
                    authToken={props.authToken || ''}
                    readOnly={true}
                    item={file}
                    name={name}
                    bytes={byteSize}
                    downloadFile={downloadFile}
                    handleImageError={handleImageError}
                  />
                );
              }
              return <></>;
            })
          ) : (
            <Box sx={classes.empty}>
              <Typo sx={classes.emptyText}>No document added</Typo>
            </Box>
          )}
        </Box>
      ) : (
        <Box sx={classes.content}>
          <DropFileZone
            readOnly={readOnly}
            uuid={props.uuid}
            authToken={props.authToken}
            user={props.user}
            hasOldData={hasOldData}
            files={props.files}
            filesQueuedCallback={filesQueuedCallback}
            filesDeletedCallback={filesDeletedCallback}
          />
        </Box>
      )}
    </Box>
  );
};

export default Documents;
