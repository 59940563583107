import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { IUserPortal } from 'interfaces/UserAccountInfo.interface.ts';

const baseURL = 'https://portal.palmettoeoc.com/prod/public/api/cognito/account';
export const verifyToken = createAsyncThunk('verifyTokenThunk', async (token: string) => {
  const headers = {
    Authorization: token,
  };
  try {
    const tokenVerification = await axios.get(baseURL, { headers }).catch((error) => {
      throw error?.response?.data;
    });
    if (tokenVerification) return tokenVerification.data as IUserPortal;
  } catch (error) {
    let message = 'Something went wrong!';
    if (error instanceof Error) message = error.message;
    else {
      if (typeof error === 'object' && error !== null) {
        if ('message' in error) {
          message = String(error.message);
        }
      }
    }
    throw Error(message);
  }
});
