import React, { useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { useTheme } from '@mui/material';
import useStyles from './DrawerMenu.styles.ts';
import Box from '@mui/material/Box';
import { ButtonTwo, ToastMessage, Typo } from 'components/primitive';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import { JPDAIncidentSettingValueType } from 'interfaces/incidents.interface.ts';
import { useAppDispatch, useAppSelector } from 'store/hooks.ts';
import { JPDAIncidentSettingValueSelector } from 'store/feature/incidents/incidents.selector.ts';
import { allGroupsSelector } from 'store/feature/accountsInfo/accountsInfo.selector.ts';
import { CheckSelectOptions } from 'interfaces/SelectOptions.interface.ts';
import ModalJPDASelectGroup from 'components/primitive/DrawerMenu/ModalJPDA.tsx';
import { ToastStatus } from '@/common/enum';
import {
  fetchJPDAIncidentSetting,
  saveJPDAIncidentSetting,
} from 'store/feature/incidents/incidents.thunk.ts';

const defaultJPDAIncidentSettingValue: JPDAIncidentSettingValueType = {
  pa: [],
  ia: [],
};

type JPDASubMenuProps = {
  activeSectionSubMenu: boolean;
  // eslint-disable-next-line no-unused-vars
  closeActiveSectionSubMenu: (value: boolean) => void;
};
const JPDASubMenu: React.FC<JPDASubMenuProps> = ({
  activeSectionSubMenu,
  closeActiveSectionSubMenu,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const dispatch = useAppDispatch();
  const allGroups = useAppSelector(allGroupsSelector);
  const selectedJPDAIncidentSettingValue =
    useAppSelector(JPDAIncidentSettingValueSelector) ?? defaultJPDAIncidentSettingValue;

  const allPalmettoGroups = useMemo(() => {
    const filterGroups =
      allGroups && allGroups.length && allGroups.filter((group) => group.pvIsAgency === 0);
    if (filterGroups && filterGroups.length) return filterGroups;
    else return [];
  }, [allGroups]);
  const JPDA_PA_START = useMemo(
    () => selectedJPDAIncidentSettingValue.pa,
    [selectedJPDAIncidentSettingValue]
  );
  const JPDA_PA_INACTIVE = useMemo(
    () =>
      allPalmettoGroups &&
      allPalmettoGroups.length &&
      allPalmettoGroups.filter((group) => !JPDA_PA_START.includes(group.pvGroupID)).length,
    [allPalmettoGroups, JPDA_PA_START]
  );

  const JPDA_IA_START = useMemo(
    () => selectedJPDAIncidentSettingValue.ia,
    [selectedJPDAIncidentSettingValue]
  );
  const JPDA_IA_INACTIVE = useMemo(
    () =>
      allPalmettoGroups &&
      allPalmettoGroups.length &&
      allPalmettoGroups.filter((group) => !JPDA_IA_START.includes(group.pvGroupID)).length,
    [allPalmettoGroups, JPDA_IA_START]
  );

  const completeActionIcon = <CheckCircleIcon fontSize='small' sx={{ width: '12px' }} />;
  const startActionIcon = <ArrowCircleRightIcon fontSize='small' sx={{ width: '12px' }} />;

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [settingType, setSettingType] = useState<'IA' | 'PA'>('IA');
  const [actionType, setActionType] = useState<'Begin' | 'Complete'>('Begin');
  const [activeStep, setActiveStep] = useState<1 | 2>(1);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const onSubmitJPDA = async (selectedOptions: CheckSelectOptions[]) => {
    if (activeStep === 1) return setActiveStep(2);
    else {
      const payload: JPDAIncidentSettingValueType = {
        pa: [],
        ia: [],
      };
      if (settingType === 'IA') {
        if (actionType === 'Begin') {
          const selectedIA = selectedOptions.map((option) => Number(option.value));
          payload.ia = [...JPDA_IA_START, ...selectedIA];
        }
        if (actionType === 'Complete') {
          payload.ia = JPDA_IA_START.filter(
            (iaValue) => !selectedOptions.some((option) => Number(option.value) === iaValue)
          );
        }
        payload.pa = JPDA_PA_START;
      }
      if (settingType === 'PA') {
        if (actionType === 'Begin') {
          const selectedPA = selectedOptions.map((option) => Number(option.value));
          payload.pa = [...JPDA_PA_START, ...selectedPA];
        }
        if (actionType === 'Complete') {
          payload.pa = JPDA_PA_START.filter(
            (paValue) => !selectedOptions.some((option) => Number(option.value) === paValue)
          );
        }
        payload.ia = JPDA_IA_START;
      }

      try {
        setIsSaving(true);
        const result = await dispatch(saveJPDAIncidentSetting(payload)).unwrap();
        if (result) {
          let message = 'JPDA Started successfully';
          if (actionType === 'Complete') message = 'JPDA Completed successfully';
          toast.info(<ToastMessage status={ToastStatus.SUCCESS} message={message} />);
          dispatch(fetchJPDAIncidentSetting()).unwrap();
        }
      } catch (error) {
        console.log(error);
        toast.error(
          <ToastMessage status={ToastStatus.ERROR} message='Failed processing JPDA Update' />
        );
      }

      setIsSaving(false);
      setIsModalOpen(false);
      closeActiveSectionSubMenu(!activeSectionSubMenu);
      window.dispatchEvent(new CustomEvent('toggle-sidebar'));
    }
  };

  const onCloseModal = () => {
    if (activeStep === 1) return setIsModalOpen(false);
    else {
      setActiveStep(1);
      setIsModalOpen(false);
      closeActiveSectionSubMenu(!activeSectionSubMenu);
      window.dispatchEvent(new CustomEvent('toggle-sidebar'));
    }
  };

  const handleButtonAction = (settingType: 'IA' | 'PA', actionType: 'Begin' | 'Complete') => {
    setActiveStep(1);
    if (actionType === 'Complete') {
      if (settingType === 'IA' && JPDA_IA_START.length === 0) return;
      if (settingType === 'PA' && JPDA_PA_START.length === 0) return;
    }
    if (actionType === 'Begin') {
      if (settingType === 'IA' && JPDA_IA_INACTIVE === 0) return;
      if (settingType === 'PA' && JPDA_PA_INACTIVE === 0) return;
    }
    setSettingType(settingType);
    setActionType(actionType);
    setIsModalOpen(true);
  };

  return (
    <>
      <Box sx={classes.sectionSubMenu.container}>
        <Box sx={classes.sectionSubMenu.content}>
          <Box sx={classes.sectionSubMenu.row}>
            <Typo color={theme.palette.text.secondary} fontSize={'12px'} fontWeight={500}>
              Public Assistance
            </Typo>
            <Box sx={classes.sectionSubMenu.btnGrp}>
              <ButtonTwo
                alternate
                startIcon={<span>{JPDA_PA_INACTIVE}</span>}
                leftText={<span>JPDA</span>}
                rightText={<span>Start</span>}
                endIcon={startActionIcon}
                onClick={() => handleButtonAction('PA', 'Begin')}
              />
              <ButtonTwo
                alternate={JPDA_PA_START.length === 0}
                startIcon={<span>{JPDA_PA_START.length ?? 0}</span>}
                leftText={<span>JPDA</span>}
                rightText={<span>End</span>}
                endIcon={completeActionIcon}
                onClick={() => handleButtonAction('PA', 'Complete')}
              />
            </Box>
          </Box>
          <Box sx={classes.sectionSubMenu.row}>
            <Typo color={theme.palette.text.secondary} fontSize={'12px'} fontWeight={500}>
              Individual Assistance
            </Typo>
            <Box sx={classes.sectionSubMenu.btnGrp}>
              <ButtonTwo
                alternate
                startIcon={<span>{JPDA_IA_INACTIVE}</span>}
                leftText={<span>JPDA</span>}
                rightText={<span>Start</span>}
                endIcon={startActionIcon}
                onClick={() => handleButtonAction('IA', 'Begin')}
              />
              <ButtonTwo
                alternate={JPDA_IA_START.length === 0}
                startIcon={<span>{JPDA_IA_START.length ?? 0}</span>}
                leftText={<span>JPDA</span>}
                rightText={<span>End</span>}
                endIcon={completeActionIcon}
                onClick={() => handleButtonAction('IA', 'Complete')}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <ModalJPDASelectGroup
        steps={activeStep}
        allGroups={allPalmettoGroups}
        jpdaStarted={settingType === 'IA' ? JPDA_IA_START : JPDA_PA_START}
        isOpen={isModalOpen}
        settingType={settingType}
        actionType={actionType}
        isSaving={isSaving}
        onClose={onCloseModal}
        onSubmit={onSubmitJPDA}
      />
    </>
  );
};

export default JPDASubMenu;
