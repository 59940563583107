import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { object, string } from 'yup';
import { PADetail } from 'interfaces/pa.detail.interface.ts';
import { PAJPDAStatus } from '@/common/enum';
import { yupResolver } from '@hookform/resolvers/yup';

export const defaultValues: PADetail = {
  id: null,
  user_id: 0,
  updated_at: null,
  created_at: null,
  group_id: 0,
  user_group_id: null,
  site_number: null,
  category: null,
  building_type: null,
  class_code: null,
  damage_description: null,
  floodplain: null,
  old_facility: null,
  impact: null,
  tax_authority_group: null,
  percent_complete: null,
  cost_estimate: null,
  insurance_status: null,
  amount_deductible: null,
  notes: null,
  address: null,
  address2: null,
  county: null,
  city: null,
  state: null,
  zipCode: null,
  latitude: null,
  longitude: null,
  parcel_id: null,
  comments: null,
  incident_id: 0,
  date: new Date(),
  applicant_county: null,
  name: null,
  phone: null,
  email: null,
  applicant_id: null,
  applicant_name: '',
  team_cost_estimate: null,
  applicant_cost_estimate: null,
  impacted_department: null,
  pvGlobalID: null,
  pvParcelGeometryObject: null,
  state_modified: null,
  posting: null,
  jpda_status: PAJPDAStatus.siteNotVisited,
  files: [],
  has_files: 0,
};

function usePADataForm() {
  const validationSchema = useMemo(
    () =>
      object().shape({
        category: string().required('Category is required'),
        name: string().required('Name is required'),
        // phone: string().required('Phone is required'),
        email: string().email('Invalid email').nullable(),
        // address: string().required('Address is required'),
        // city: string().required('City is required'),
        // state: string().required('State is required'),
        // zipCode: string().required('Zip Code is required'),
        // county: string().required('County is required'),
        // date: string().required('Date is required'),
        // applicant_county: string().required('Applicant County is required'),
        // applicant_cost_estimate: string().required('Applicant Cost Estimate is required'),
        // impacted_department: string().required('Impacted Department is required'),
        // jpda_status: string().required('JPDA Status is required'),
      }),
    []
  );
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return useForm<any>({ defaultValues, resolver: yupResolver(validationSchema) });
}

export default usePADataForm;
