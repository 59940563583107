const getCategoryName = (type: string): string => {
  switch (type) {
    case 'A':
      return 'Cat A: Debris Removal';
    case 'B':
      return 'Cat B: Emergency Protective Measures';
    case 'C':
      return 'Cat C: Roads and Bridges';
    case 'D':
      return 'Cat D: Water Control Facilities';
    case 'E':
      return 'Cat E: Buildings and Equipment';
    case 'F':
      return 'Cat F: Utilities';
    case 'G':
      return 'Cat G: Parks, Recreation, and Other';
    default:
      return type;
  }
};

export default getCategoryName;
