import React from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import { IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { HeaderComponent } from '@zawarski/palmetto-ui-components';
import { ErrorMessage } from 'components/primitive';

const toggleDrawer = () => window.dispatchEvent(new CustomEvent('toggle-sidebar'));

type ErrorHandlerViewProps = {
  headerTitle: string;
  errorMsg?: string;
};
const ErrorHandlerView: React.FC<ErrorHandlerViewProps & BoxProps> = ({
  headerTitle,
  errorMsg,
  ...props
}) => {
  return (
    <Box className='layout vertical full-height'>
      <HeaderComponent
        title={headerTitle}
        icon={
          <IconButton onClick={() => toggleDrawer()}>
            <MenuIcon className='icon' />
          </IconButton>
        }
      />
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
          width: '100%',
        }}>
        {errorMsg && <ErrorMessage message={errorMsg} />}
        {props.children}
      </Box>
    </Box>
  );
};

export default ErrorHandlerView;
