import { createAsyncThunk } from '@reduxjs/toolkit';
import apiFetch, { genericErrorMessage } from '@/services/apiFetch.ts';
import { EQFormData } from 'pages/Equipment/hook/useEQForm.ts';
import { deleteEq, postEquipmentData } from '@/services/equipment.endpoint.ts';
import { EquipmentDetails } from 'interfaces/equipment.interface.ts';

export const saveEqData = createAsyncThunk('equipment/saveEqData', async (payload: EQFormData) => {
  try {
    let isNew = true;
    let url = `/api/equipment`;
    if (payload.id) {
      isNew = false;
      url += `/${payload.id}`;
    }
    const endpoint = postEquipmentData(url, isNew, payload);
    type ResponseType = EquipmentDetails | { success: boolean };
    return await apiFetch<ResponseType>(endpoint).then((res) => res.data ?? null);
  } catch (error) {
    let message = genericErrorMessage;
    if (error instanceof Error) message = error.message;
    else {
      if (typeof error === 'object' && error !== null) {
        if ('message' in error) {
          message = String(error.message);
        }
      }
    }
    throw Error(message);
  }
});

export const deleteEqData = createAsyncThunk('equipment/deleteEqData', async (id: string) => {
  try {
    const url = `/api/equipment/${id}`;
    const endpoint = deleteEq(url);
    return await apiFetch<{ success: boolean }>(endpoint).then((res) => res.data ?? null);
  } catch (error) {
    let message = genericErrorMessage;
    if (error instanceof Error) message = error.message;
    else {
      if (typeof error === 'object' && error !== null) {
        if ('message' in error) {
          message = String(error.message);
        }
      }
    }
    throw Error(message);
  }
});
