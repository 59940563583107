import React from 'react';
import useStyles from './CardCounter.styles.ts';
import { useMediaQuery, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import { NumericFormat as NumberFormat } from 'react-number-format';
import { Typo } from 'components/primitive';
import { SideInfoCardType } from 'pages/Dashboard/Dashboard.props.ts';
import MoneyIcon from '@mui/icons-material/AttachMoneyRounded';
import { convertToTitleCase } from 'utils/index.ts';

type CardCounterProps = {
  bigCount: number | null;
  titleVariant?: 'h1' | 'h2' | 'h3';
  footerTitle: string;
  footerVariant?: 'h3' | 'body1' | 'body2';
  withSideInfo?: boolean;
  endIcon?: React.ReactElement;
  sideInfo?: SideInfoCardType[];
  isDollar?: boolean;
  center?: boolean;
};

const CardCounter: React.FC<CardCounterProps> = ({
  bigCount = 0,
  titleVariant = 'h1',
  withSideInfo = false,
  isDollar = false,
  center = false,
  footerTitle,
  footerVariant = 'h3',
  endIcon,
  sideInfo,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const centerContent = center ? { justifyContent: 'center' } : {};

  return (
    <Box sx={classes.container}>
      <Box sx={[classes.content, centerContent]}>
        {!isDollar ? (
          <Typo variant={titleVariant} sx={classes.bigTitle}>
            {bigCount}
          </Typo>
        ) : (
          <Box sx={classes.numericWrapper}>
            <MoneyIcon
              sx={{ color: theme.palette.grey[400], fontSize: !isMobile ? '40px' : '25px' }}
            />
            <Typo variant={titleVariant} sx={classes.bigTitle}>
              <NumberFormat
                value={bigCount ? bigCount.toFixed(2) : 0}
                displayType={'text'}
                thousandSeparator={true}
                decimalScale={2}
              />
            </Typo>
          </Box>
        )}
        {withSideInfo && (
          <Box sx={classes.sideInfoContainer}>
            {sideInfo &&
              sideInfo.length &&
              sideInfo.map((item) => (
                <Box key={item.title} sx={classes.sideInfoWrapper}>
                  <Typo sx={[classes.sideInfoTitle, { opacity: 0.7 }]}>
                    {convertToTitleCase(item.title)}
                  </Typo>
                  <Typo sx={[classes.sideInfoTitle, { fontWeight: 700 }]}>{item.value}</Typo>
                </Box>
              ))}
          </Box>
        )}
      </Box>
      <Box sx={classes.footerContainer}>
        <Typo
          variant={footerVariant}
          color={theme.palette.common.white}
          sx={{
            overflow: 'hidden',
            textTransform: 'uppercase',
            textOverflow: 'ellipsis',
          }}>
          {footerTitle}
        </Typo>
        {endIcon}
      </Box>
    </Box>
  );
};

export default CardCounter;
