const useStyles = () => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
    gap: '16px',
    padding: '16px',
    maxHeight: '600px',
    minWidth: '450px',
    overflowY: 'scroll',
    overflowX: 'hidden',
  },
  footer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    padding: '16px',
    mt: '10px',
  },
  footerWrapper: {
    display: 'flex',
    width: '100%',
    gap: '8px',
    justifyContent: 'flex-end',
  },
});

export default useStyles;
