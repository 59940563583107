import { Nullable } from 'interfaces/Nullable.ts';
import {
  IUserPortal,
  PalmettoGroups,
  UserDMASettings,
} from 'interfaces/UserAccountInfo.interface.ts';
import { QueryState, UserType } from '@/common/enum';

export type AccountsInfoTypes = {
  appLoading: boolean;
  queryState: QueryState;
  account: Nullable<IUserPortal>;
  error: Nullable<string>;
  userDMASettings: Nullable<UserDMASettings>;
  stateUserSelectedMapSettingID: Nullable<number>;
  parentGroupID: Nullable<number>;
  selectedGroupID: Nullable<number>; // ?: Selected Group ID is the active group from PORTAL
  selectedGroupName: Nullable<string>;
  selectedGroup: Nullable<string>; // ?: Selected Group is the county group in the side panel for STATE USER
  selectedUserGroupName: Nullable<string>;
  selectedGroupNameForParcels: Nullable<string | undefined>;
  isUserGroupAdmin: Nullable<boolean>;
  userType: Nullable<UserType>;
  allGroups: Nullable<PalmettoGroups[]>;
  allSubGroups: Nullable<PalmettoGroups[]>;
};

export const AccountsInfoInitialState: AccountsInfoTypes = {
  appLoading: true,
  queryState: QueryState.UNDEFINED,
  error: null,
  account: null,
  userDMASettings: null,
  parentGroupID: null,
  selectedGroupID: null,
  selectedGroup: null,
  selectedGroupName: null,
  selectedUserGroupName: null,
  selectedGroupNameForParcels: null,
  isUserGroupAdmin: null,
  userType: null,
  stateUserSelectedMapSettingID: null,
  allGroups: null,
  allSubGroups: null,
};
