import React from 'react';
import { Await, defer, LoaderFunctionArgs, useAsyncValue, useLoaderData } from 'react-router-dom';
import apiFetch, { genericErrorMessage } from '@/services/apiFetch.ts';
import { RootState, store } from '@/store';
import { getCatBEquipmentData, getCatBEquipmentNames } from '@/services/category.endpoint.ts';
import { EquipmentDatasetObj } from 'interfaces/equipment.interface.ts';
import { Loading } from 'components/primitive';
import { ErrorHandler } from 'components/fragment';
import { CatBEquipmentObj } from 'interfaces/category.interface.ts';

const EDetailPage = React.lazy(() => import('./Detail.container.tsx'));

export interface EquipmentDetailsResponse {
  data: [{ dataset: EquipmentDatasetObj[] }, CatBEquipmentObj | null];
}

// eslint-disable-next-line react-refresh/only-export-components
export const loaderCatBEqDetails = ({ params }: LoaderFunctionArgs) => {
  try {
    const { id } = params;
    const state = store.getState() as RootState;
    const { selectedGroupID } = state.accountsInfo;
    const url = `/api/catB/equipment/getNames?filters[group_id]=${selectedGroupID}`;
    const promise = [];
    const endpointEquipmentNames = getCatBEquipmentNames(url);
    promise.push(
      apiFetch<{ dataset: EquipmentDatasetObj[] }>(endpointEquipmentNames).then(
        (res) => res.data ?? []
      )
    );
    if (id) {
      const endpointEquipmentData = getCatBEquipmentData(`/api/catB/equipment/${id}`);
      promise.push(
        apiFetch<CatBEquipmentObj | null>(endpointEquipmentData).then((res) => res.data ?? null)
      );
    }

    const equipmentData = Promise.all(promise);
    return defer({
      data: equipmentData,
    });
  } catch (error) {
    let message = genericErrorMessage;
    if (error instanceof Error) message = error.message;
    else {
      if (typeof error === 'object' && error !== null) {
        if ('message' in error) {
          message = String(error.message);
        }
      }
    }
    throw Error(message);
  }
};

function RenderAwaitedData(props: { newEntry: boolean }) {
  const { newEntry } = props;
  const data = useAsyncValue() as [{ dataset: EquipmentDatasetObj[] }, CatBEquipmentObj | null];
  const isNew = newEntry ?? data[1] === null;
  return <EDetailPage newEntry={isNew} data={data} />;
}

const DetailPage: React.FC<{ newEntry: boolean }> = ({ newEntry }) => {
  const data = useLoaderData() as EquipmentDetailsResponse;
  return (
    <React.Suspense fallback={<Loading />}>
      <Await
        resolve={data.data}
        errorElement={
          <ErrorHandler headerTitle='Category B: Equipment' errorMsg='Something went wrong' />
        }>
        <RenderAwaitedData newEntry={newEntry} />
      </Await>
    </React.Suspense>
  );
};

export default DetailPage;
