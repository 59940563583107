export type SideMenuState = {
  openDrawer: boolean;
  openIncidentModal: boolean;
  openGroupModal: boolean;
};

export const DefaultSideMenuState: SideMenuState = {
  openDrawer: false,
  openIncidentModal: false,
  openGroupModal: false,
};
