const useStyles = () => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100vw',
    height: '100vh',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
  },
  fab: {
    bottom: '80px',
    right: '24px',
    position: 'absolute',
  },
  cardContainer: {
    width: '100vw',
    flexDirection: 'column',
    lineHeight: '1.0',
    padding: '10px 0',
    minHeight: '51px',
    maxHeight: '92px',
  },
});

export default useStyles;
