import React from 'react';
import { PageMaxLayout } from 'components/layout';
import IAStateView from './StateView';
import IACountyView from './CountyView';
import { useAppSelector } from 'store/hooks.ts';
import { userTypeSelector } from 'store/feature/accountsInfo/accountsInfo.selector.ts';
import { UserType } from '@/common/enum';

const PAPageView = () => {
  const selectUserType = useAppSelector(userTypeSelector);
  const isStateUser = selectUserType === UserType.STATE_USER;
  const isCountyUser = selectUserType === UserType.COUNTY_USER;

  return (
    <PageMaxLayout>
      {isStateUser && <IAStateView />}
      {isCountyUser && <IACountyView />}
    </PageMaxLayout>
  );
};

export default PAPageView;
