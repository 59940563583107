import { Theme } from '@mui/material';

const useStyles = (theme: Theme) => ({
  root: {
    width: '100%',
    height: 'calc(100vh - 70px)',
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
    flexDirection: 'column',
    backgroundColor: theme.palette.grey[50],
    overflowY: 'scroll',
    // position: 'relative',
  },
  content: {
    display: 'flex',
    maxWidth: '1440px',
    width: '100%',
    height: '100vh',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '24px',
    padding: '24px',
    mb: '60px',
  },
  filterContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    background: 'transparent',
    gap: '10px',
  },
  filterTitle: {
    color: theme.palette.text.secondary,
    textTransform: 'uppercase',
    fontWeight: 700,
    fontSize: '14px',
  },
  headerNavContainer: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    bgcolor: 'var(--pcl-primary-background-color)',
  },
  headerNavWrapper: {
    display: 'flex',
    maxWidth: '1440px',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    bgcolor: 'var(--pcl-primary-background-color)',
  },
  navTabsRoot: {
    zIndex: 1000,
    width: '100%',
    bgcolor: '#1C43B1 !important',
  },
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '16px',
    padding: '16px',
    maxHeight: '600px',
    minWidth: '450px',
    overflowY: 'scroll',
    width: '100%',
  },
  controllerWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  radioGroupWrapper: {
    border: '1px solid #E0E0E0',
    borderRadius: '4px',
    paddingRight: '8px',
    marginLeft: 'unset',
    width: '100%',
    my: '4px',
  },
  footer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    padding: '16px',
    mt: '10px',
  },
  footerWrapper: {
    display: 'flex',
    width: '100%',
    gap: '8px',
  },
});

export default useStyles;
