import React, { useEffect, useMemo, useState } from 'react';
import MuiDrawer, { DrawerProps } from '@mui/material/Drawer';
import { Stack, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import { MenuButton, Typo } from 'components/primitive';
import useStyles, { Img } from './DrawerMenu.styles';
import Logo from '@/assets/img.png';
import { MenuItems, MenuList } from 'interfaces/DrawerMenuTypes.ts';
import HomeIcon from '@mui/icons-material/Home';
import MapIcon from '@mui/icons-material/Map';
import CallToActionIcon from '@mui/icons-material/CallToAction';
import BusinessIcon from '@mui/icons-material/Business';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ViewDayIcon from '@mui/icons-material/ViewDay';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import PersonIcon from '@mui/icons-material/Person';
import PeopleIcon from '@mui/icons-material/People';
import ApplicantIcon from '@mui/icons-material/AccountBox';
import LogoutIcon from '@mui/icons-material/Logout';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import DonutLargeRoundedIcon from '@mui/icons-material/DonutLargeRounded';
import Groups3RoundedIcon from '@mui/icons-material/Groups3Rounded';
import KeyboardArrowLeftSharpIcon from '@mui/icons-material/KeyboardArrowLeftSharp';
import KeyboardArrowRightSharpIcon from '@mui/icons-material/KeyboardArrowRightSharp';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useAppDispatch, useAppSelector } from 'store/hooks.ts';
import { resetAuthToken } from 'store/feature/authToken/authToken.action.ts';
import {
  accountGroupIDSelector,
  allGroupsSelector,
  selectAccount,
  selectedGroupNameSelector,
  selectedGroupSelector,
  userTypeSelector,
} from 'store/feature/accountsInfo/accountsInfo.selector.ts';
import {
  incidentForParcelSyncSelector,
  selectedIncidentSelector,
  userIncidentsSelector,
} from 'store/feature/incidents/incidents.selector.ts';
import { RadioSelectOptions } from 'interfaces/SelectOptions.interface.ts';
import { useSideMenuForm } from 'hooks/index.ts';
import JPDASubMenu from './JPDASubMenu.tsx';
import { FormProvider } from 'react-hook-form';
import { FieldName } from 'hooks/useSideMenu.ts';
import { Nullable } from 'interfaces/Nullable.ts';
import ModalSelect from 'components/primitive/DrawerMenu/ModalSelect.tsx';
import {
  fetchJPDAIncidentSetting,
  saveSelectedIncidentSetting,
  updateDataSyncIncident,
} from 'store/feature/incidents/incidents.thunk.ts';
import { updatedSelectedGroupForMapForStateUser } from 'store/feature/accountsInfo/accountsInfo.thunk.ts';
import { UserIncidentsType } from 'interfaces/incidents.interface.ts';
import { fetchIADataSet } from 'store/feature/ia/ia.thunk.ts';
import { fetchPADataSet } from 'store/feature/pa/pa.thunk.ts';
import { accessTypeSelector } from 'store/feature/authToken/authToken.selector.ts';
import {
  applicantsSelector,
  selectedApplicantSelector,
} from 'store/feature/applicants/applicants.selector.ts';
import { SitePermissionTypes, UserType } from '@/common/enum';
import { convertToTitleCase } from 'utils/index.ts';
import { useNavigate } from 'react-router-dom';
import { ApplicantType } from 'interfaces/applicants.interface.ts';
import { updateSelectedApplicantSettings } from 'store/feature/applicants/applicants.thunk.ts';
import { resetAccountsInfo } from 'store/feature/accountsInfo/accountsInfo.actions.ts';
import { resetIncidents } from 'store/feature/incidents/incidents.actions.ts';
import { resetApplicant } from 'store/feature/applicants/applicants.action.ts';
import { fetchIAHomePage, fetchPAHomePage } from 'store/feature/dashboard/dashboard.thunk.ts';

const DrawerMenu: React.FC<DrawerProps> = () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const form = useSideMenuForm();
  const selectedGroupID = useAppSelector(accountGroupIDSelector);
  const allGroups = useAppSelector(allGroupsSelector);
  const selectedGroup = useAppSelector(selectedGroupSelector);
  const userIncidents = useAppSelector(userIncidentsSelector);
  const selectedIncidentForParcelSync = useAppSelector(incidentForParcelSyncSelector);
  const selectGroupName = useAppSelector(selectedGroupNameSelector) ?? 'No Group';
  const selectedIncident = useAppSelector(selectedIncidentSelector);
  const incidentName = (selectedIncident && selectedIncident?.pvIncidentName) ?? 'No Incident';
  const account = useAppSelector(selectAccount);
  const userType = useAppSelector(userTypeSelector);
  const accessType = useAppSelector(accessTypeSelector);
  const applicantsDataSet = useAppSelector(applicantsSelector);
  const selectedApplicant = useAppSelector(selectedApplicantSelector);

  const applicantsData = useMemo(() => applicantsDataSet ?? [], [applicantsDataSet]);
  const isPalmettoAdmin = useMemo(() => accessType === SitePermissionTypes.A, [accessType]);
  const isPalmettoFinance = useMemo(() => accessType === SitePermissionTypes.F, [accessType]);
  const isStateUser = useMemo(() => userType === UserType.STATE_USER, [userType]);
  const applicantName = useMemo(
    () => (selectedApplicant && selectedApplicant.applicant_name) ?? 'No Applicant',
    [selectedApplicant]
  );

  const activeGroup = selectedGroup ? selectedGroup : selectedGroupID;
  const userFullName =
    account !== null ? `${account?.pvPersonGivenName} ${account?.pvPersonSurName}` : '';
  const userIncidentData =
    userIncidents &&
    userIncidents.length > 0 &&
    userIncidents.find((item) => {
      if (Number(item.pvIncidentID) === Number(selectedIncidentForParcelSync)) {
        return item;
      }
    })?.pvIncidentName;
  const dataGatheringSync = userIncidentData ? userIncidentData : 'No Incident Selected';

  const defaultMenuItems: MenuItems = useMemo(
    () => ({
      menu: [
        {
          key: 'dashboard',
          name: 'Home',
          path: '/dashboard',
          startIcon: <HomeIcon />,
        },
        {
          key: 'map',
          name: 'Map',
          path: '/map',
          startIcon: <MapIcon />,
        },
        {
          key: 'equipment',
          name: 'Equipment',
          path: '/equipment',
          startIcon: <CallToActionIcon />,
        },
        {
          key: 'personnel',
          name: 'Personnel',
          path: '/personnel',
          startIcon: <BusinessIcon />,
        },
        {
          key: 'teams',
          name: 'Teams',
          path: '/teams',
          startIcon: <Diversity3Icon />,
        },
        {
          key: 'ia',
          name: 'Individual assistance (IA)',
          path: '/ia',
          startIcon: <AssignmentIndIcon />,
        },
        {
          key: 'pa',
          name: 'Public assistance (PA)',
          path: 'pa',
          startIcon: <AssignmentIcon />,
          subMenu: [
            {
              key: 'general-info',
              name: 'General Information',
              path: '/pa/general-info',
            },
            {
              key: 'short-form',
              name: 'Short Form Summary',
              path: '/pa/short-form',
            },
            {
              key: 'debris-removal',
              name: 'Debris Removal',
              path: '/pa/a',
            },
            {
              key: 'emergency',
              name: 'Emergency Protective Measure',
              path: '/pa/b',
            },
            {
              key: 'roads',
              name: 'Roads and Bridges',
              path: '/pa/c',
            },
            {
              key: 'water',
              name: 'Water Control Facilities',
              path: '/pa/d',
            },
            {
              key: 'buildings',
              name: 'Buildings and Equipment',
              path: '/pa/e',
            },
            {
              key: 'utilities',
              name: 'Utilities',
              path: '/pa/f',
            },
            {
              key: 'parks',
              name: 'Parks and Recreation',
              path: '/pa/g',
            },
          ],
        },
        {
          key: 'category',
          name: 'Category B: Extended',
          path: '/category/cost',
          startIcon: <ViewDayIcon />,
        },
      ],
      section: [
        {
          key: 'jpda',
          name: 'JPDA',
          path: 'SHOW_JPDA',
          startIcon: <ClearAllIcon sx={{ color: theme.palette.primary.dark }} />,
          subMenu: [
            {
              key: 'jpdaIA',
              name: 'IA: 6-Active',
              path: '',
            },
            {
              key: 'jpdaPA',
              name: 'PA: Inactive',
              path: '',
            },
          ],
        },
        {
          key: 'user',
          name: userFullName,
          path: '',
          startIcon: <PersonIcon sx={{ color: theme.palette.primary.dark }} />,
        },
        {
          key: 'applicants',
          name: 'Manage applicants',
          path: '/applicants',
          endIcon: <PeopleIcon sx={{ color: theme.palette.grey[400] }} />,
        },
        {
          key: 'logout',
          name: 'Log out',
          path: '/logout',
          endIcon: <LogoutIcon sx={{ color: theme.palette.error.main }} />,
        },
      ],
      version: {
        name: 'Version 3.0.0',
        color: '#999',
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [theme.palette.error.main, theme.palette.primary.dark, userFullName]
  );

  const userSectionInfo: MenuList = useMemo(
    () => ({
      key: 'user',
      name: userFullName,
      path: '',
      startIcon: <PersonIcon sx={{ color: theme.palette.primary.dark }} />,
      subMenu: [
        {
          key: 'Data Viewing Incident',
          name: incidentName,
          path: '',
          startIcon: <WarningRoundedIcon sx={classes.sectionSubGroupBtn.iconStyle} />,
        },
        {
          key: 'Data Gathering Incident',
          name: dataGatheringSync,
          path: '',
          startIcon: <DonutLargeRoundedIcon sx={classes.sectionSubGroupBtn.iconStyle} />,
        },
        {
          key: selectGroupName,
          name: 'County',
          path: '',
          startIcon: <Groups3RoundedIcon sx={classes.sectionSubGroupBtn.iconStyle} />,
        },
        {
          key: applicantName,
          name: 'Applicant',
          path: '',
          startIcon: <ApplicantIcon sx={classes.sectionSubGroupBtn.iconStyle} />,
        },
      ],
    }),
    [
      classes.sectionSubGroupBtn.iconStyle,
      dataGatheringSync,
      incidentName,
      selectGroupName,
      theme.palette.primary.dark,
      userFullName,
      applicantName,
    ]
  );

  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const [activePath, setActivePath] = useState('dashboard');
  const [activeSubMenu, setActiveSubMenu] = useState(false);
  const [activeSectionSubMenu, setActiveSectionSubMenu] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [activeHoverIdx, setActiveHoverIdx] = useState<number | null>(null);
  const [activeModal, setActiveModal] = useState<Nullable<FieldName>>(null);
  const [processingData, setProcessingData] = useState({
    group: false,
    incident: false,
    sync: false,
    applicant: false,
  });
  const [menuItems, setMenuItems] = useState<MenuItems>(defaultMenuItems);

  useEffect(() => {
    function removeItems(array: MenuList[], keyToFind: string) {
      return array.filter((item) => item.key !== keyToFind);
    }

    if (accessType) {
      if (!isPalmettoAdmin && !isPalmettoFinance) {
        let tempMenu = defaultMenuItems.menu;
        tempMenu = removeItems(tempMenu, 'equipment');
        tempMenu = removeItems(tempMenu, 'personnel');
        tempMenu = removeItems(tempMenu, 'category');
        setMenuItems((prevState) => ({ ...prevState, menu: tempMenu }));
      }
    }
  }, [accessType, defaultMenuItems, isPalmettoAdmin, isPalmettoFinance]);

  const warningRoundIcon = (iconStyle: object) => {
    return <WarningRoundedIcon sx={iconStyle ?? {}} />;
  };
  const donutRoundIcon = (iconStyle: object) => {
    return <DonutLargeRoundedIcon sx={iconStyle ?? {}} />;
  };
  const group3RoundIcon = (iconStyle: object) => {
    return <Groups3RoundedIcon sx={iconStyle ?? {}} />;
  };

  const applicantIcon = (iconStyle: object) => {
    return <ApplicantIcon sx={iconStyle ?? {}} />;
  };

  useEffect(() => {
    function handleToggle() {
      setIsDrawerOpen((prevState) => !prevState);
    }

    window.addEventListener('toggle-sidebar', handleToggle);
    return () => window.removeEventListener('toggle-sidebar', handleToggle);
  }, []);

  useEffect(() => {
    if (userIncidents) {
      const incidentOptions: RadioSelectOptions[] = userIncidents
        .map((incident) => {
          return { label: incident.pvIncidentName, value: incident.pvIncidentID };
        })
        .sort((a, b) => a.label.localeCompare(b.label));
      form.setValue('incidentOptions', incidentOptions);
      if (selectedIncident) form.setValue('incident', selectedIncident?.pvIncidentID);
      if (selectedIncidentForParcelSync)
        form.setValue('dataGathering', Number(selectedIncidentForParcelSync));
    }
  }, [userIncidents, selectedIncident, selectedIncidentForParcelSync, form]);

  useEffect(() => {
    if (allGroups && allGroups.length) {
      const groupOptions: RadioSelectOptions[] = allGroups
        .map((group) => {
          return { label: group.pvGroupName, value: group.pvGroupID };
        })
        .sort((a, b) => a.label.localeCompare(b.label));
      form.setValue('groupOptions', groupOptions);
      form.setValue('group', Number(activeGroup));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allGroups, selectedGroup, selectedGroupID, form]);

  useEffect(() => {
    if (applicantsData && applicantsData.length) {
      const applicantOptions: RadioSelectOptions[] = applicantsData
        .map((applicant) => {
          return {
            label: applicant.applicant_name ?? '',
            value: applicant.id ?? '',
            withIndicator: Boolean(applicant.state_wide),
          };
        })
        .sort((a, b) => a.label.localeCompare(b.label));
      form.setValue('applicantsOptions', applicantOptions);
      if (selectedApplicant && selectedApplicant.id)
        form.setValue('applicants', selectedApplicant.id);
    }
  }, [selectedApplicant, applicantsData, form]);

  function findValueByKey(arr: MenuList[], pathToFind: string): string | undefined {
    for (const obj of arr) {
      if (obj.path === pathToFind) {
        return obj.name; // *: To select the name of the subMenu
      }

      if (obj.subMenu && obj.subMenu.length) {
        const nestedResult = findValueByKey(obj.subMenu, pathToFind);
        if (nestedResult !== undefined) {
          return nestedResult;
        }
      }
    }

    return undefined;
  }

  const toggleMenu = () => {
    setIsDrawerOpen(!isDrawerOpen);
    setActiveSectionSubMenu(false);
    setActiveSubMenu(false);
    setShowMore(false);
  };

  const handleLogOut = () => {
    dispatch(resetAuthToken());
    dispatch(resetAccountsInfo());
    dispatch(resetIncidents());
    dispatch(resetApplicant());
    sessionStorage.clear();
    localStorage.clear();
    window.location.href = '/';
  };

  const onMenuClick = (path: string) => {
    console.log('path -->', path);
    if (path === '/logout') return handleLogOut();
    if (path === 'SHOW_JPDA' && !activeSectionSubMenu) {
      console.log('EXECUTE FETCH JPDA SETTINGS!!!');
      dispatch(fetchJPDAIncidentSetting()).unwrap();
    }

    setActivePath(path);
    const menu = menuItems.menu.find((item) => item.path === path);

    if (activeSubMenu) {
      const subMenu = findValueByKey(menuItems.menu, path);
      if (subMenu) {
        // setHeaderTitle(subMenu);
        if (!menu) {
          setIsDrawerOpen(false);
          if (path && path.includes('/')) return navigate(path);
        }
      } else {
        setActiveSubMenu((prevState) => !prevState);
      }
    }

    if (menu) {
      if (menu.subMenu && menu.subMenu.length) {
        // For Submenu Action
        console.log('Has SubMenu Selected');
        setActiveSubMenu((prevState) => !prevState);
      } else {
        setIsDrawerOpen(false);
        if (path && path.includes('/')) {
          return navigate(path);
        }
      }
    } else {
      const section = menuItems.section.find((item) => item.path === path);
      if (section) {
        if (section.subMenu && section.subMenu.length) {
          // For Submenu Action
          setActiveSectionSubMenu((prevState) => !prevState);
        } else {
          setIsDrawerOpen(false);
          if (path && path.includes('/')) {
            console.log('section path -->', path);
            return navigate(path);
          }
        }
      }
    }
  };

  const handleShowMore = () => {
    if (!showMore) {
      dispatch(fetchJPDAIncidentSetting()).unwrap();
    }
    setShowMore((prevState) => !prevState);
  };

  const handleMouseOver = (key: number) => {
    setActiveHoverIdx(key);
    setIsHovered(true);
  };

  const handleMouseOut = () => {
    setActiveHoverIdx(null);
    setIsHovered(false);
  };

  const getSectionSubGroupIcon = (idx: number, objectClass: object) => {
    switch (idx) {
      case 0:
        return warningRoundIcon(objectClass);
      case 1:
        return donutRoundIcon(objectClass);
      case 2:
        return group3RoundIcon(objectClass);
      case 3:
        return applicantIcon(objectClass);
      default:
        return warningRoundIcon(objectClass);
    }
  };

  const processSelectedGroup = async (groupID: number) => {
    return await dispatch(updatedSelectedGroupForMapForStateUser(groupID)).unwrap();
  };

  const processDataSyncIncidentSave = async (payload: {
    incidentID: number;
    incidentName: string;
  }) => {
    return await dispatch(updateDataSyncIncident(payload)).unwrap();
  };

  const processIncidentSettingSave = async (payload: UserIncidentsType) => {
    return await dispatch(saveSelectedIncidentSetting(payload)).unwrap();
  };

  const processSelectedApplicant = async (applicant: ApplicantType) => {
    return await dispatch(updateSelectedApplicantSettings(applicant)).unwrap();
  };

  const handleSectionSubMenu = (idx: number) => {
    if (idx > -1) {
      if (idx === 0) {
        // *: Data Viewing Incident
        form.setValue('fieldName', FieldName.incident);
        setActiveModal(FieldName.incident);
        return form.setValue('isOpen', true);
      }
      if (idx === 1 && isPalmettoAdmin) {
        // *: Data Gathering Incident
        form.setValue('fieldName', FieldName.dataGathering);
        setActiveModal(FieldName.dataGathering);
        return form.setValue('isOpen', true);
      }
      if (idx === 2 && isStateUser) {
        // *: Group
        form.setValue('fieldName', FieldName.group);
        setActiveModal(FieldName.group);
        return form.setValue('isOpen', true);
      }
      if (idx === 3) {
        // *: Applicant
        form.setValue('fieldName', FieldName.applicants);
        setActiveModal(FieldName.applicants);
        return form.setValue('isOpen', true);
      }
    }
  };

  const handleModal = (isOpen: boolean) => {
    const selectedField = form.getValues('fieldName');
    if (!isOpen) {
      if (selectedField === FieldName.incident && selectedIncident) {
        form.setValue('incident', selectedIncident.pvIncidentID);
      }
      if (selectedField === FieldName.group && selectedGroupID) {
        form.setValue('group', Number(selectedGroupID));
      }
      if (selectedField === FieldName.dataGathering && selectedIncidentForParcelSync) {
        form.setValue('dataGathering', Number(selectedIncidentForParcelSync));
      }
    }
    form.setValue('isOpen', isOpen);
  };

  function validateIncidentExists(incidentID: number): number {
    if (!userIncidents) return -1;
    return userIncidents.findIndex((item) => Number(item.pvIncidentID) === incidentID);
  }

  const handleModalAction = (fieldName: FieldName) => {
    const data = form.getValues();
    if (fieldName === FieldName.incident && userIncidents) {
      // *: Validate if incident exists in the userIncidents
      const found = validateIncidentExists(Number(data.incident));
      if (found === -1) return;
      const incident = userIncidents[found];
      if (!incident) return;
      setProcessingData((prevState) => ({ ...prevState, incident: true }));
      processIncidentSettingSave(incident).then(() => {
        dispatch(fetchIADataSet({ incidentID: incident.pvIncidentID }));
        dispatch(fetchPADataSet({ incidentID: incident.pvIncidentID }));
        if (isStateUser) dispatch(fetchIAHomePage({ selectedGroupIndex: 0 }));
        else dispatch(fetchIAHomePage({ selectedGroupIndex: Number(activeGroup) }));
        dispatch(fetchPAHomePage());
        setTimeout(() => {
          setProcessingData((prevState) => ({ ...prevState, incident: false }));
          form.setValue('isOpen', false);
          // window.location.reload();
        }, 2000);
      });
    }
    if (fieldName === FieldName.dataGathering && userIncidents) {
      // *: Validate if incident exists in the userIncidents
      const found = validateIncidentExists(Number(data.dataGathering));
      if (found === -1) return;
      const incident = userIncidents[found];
      if (!incident) return;
      setProcessingData((prevState) => ({ ...prevState, sync: true }));
      processDataSyncIncidentSave({
        incidentID: Number(data.dataGathering),
        incidentName: incident.pvIncidentName,
      }).then(() => {
        setTimeout(() => {
          setProcessingData((prevState) => ({ ...prevState, sync: false }));
          form.setValue('isOpen', false);
          // window.location.reload();
        }, 2000);
      });
    }
    if (fieldName === FieldName.group) {
      const groupID = Number(data.group);
      setProcessingData((prevState) => ({ ...prevState, group: true }));
      processSelectedGroup(groupID)
        .then(() => {
          dispatch(fetchIAHomePage({ selectedGroupIndex: Number(activeGroup) }));
          dispatch(fetchPAHomePage());
          setTimeout(() => {
            setProcessingData((prevState) => ({ ...prevState, group: false }));
            form.setValue('isOpen', false);
            // window.location.reload();
          }, 2000);
        })
        .catch((error) => {
          console.log(error);
          setProcessingData((prevState) => ({ ...prevState, group: false }));
        });
    }
    if (fieldName === FieldName.applicants) {
      const applicantID = String(data.applicants);
      const applicantData = applicantsData.find((item) => item.id === applicantID);
      if (applicantData) {
        setProcessingData((prevState) => ({ ...prevState, applicant: true }));
        processSelectedApplicant(applicantData).then(() => {
          setTimeout(() => {
            setProcessingData((prevState) => ({ ...prevState, applicant: false }));
            form.setValue('isOpen', false);
            // window.location.reload();
          }, 2000);
        });
      }
    }
  };

  return (
    <>
      <MuiDrawer elevation={0} open={isDrawerOpen} onClose={toggleMenu}>
        <Box sx={classes.menuWrapper}>
          <Box sx={classes.content}>
            <Box sx={classes.upperContainer}>
              <Img src={Logo} alt='logo' />
              <Typo sx={classes.title}>Damage Assessment</Typo>
              <Box sx={classes.menuContainer}>
                <Typo sx={classes.menuTitle}>Menu</Typo>
                {menuItems.menu && menuItems.menu.length
                  ? menuItems.menu.map((item, idx) => {
                      const isActive = activePath.includes(String(item.key));
                      const withSubMenu = (item?.subMenu && item?.subMenu.length > 0) ?? false;
                      const startIcon = isActive ? (
                        item?.startIcon
                      ) : (
                        <span style={{ display: 'flex', color: '#C2C2C2', alignItems: 'center' }}>
                          {item?.startIcon}
                        </span>
                      );
                      return (
                        <>
                          {activeSubMenu && !isActive ? null : (
                            <MenuButton
                              key={`${item.key}-${idx}` || idx}
                              issubmenu={Boolean(activeSubMenu && isActive) || false}
                              fade={showMore}
                              startIcon={
                                activeSubMenu && isActive ? (
                                  <KeyboardArrowLeftSharpIcon />
                                ) : (
                                  startIcon
                                )
                              }
                              endIcon={
                                withSubMenu ? (
                                  activeSubMenu && isActive ? null : (
                                    <KeyboardArrowRightSharpIcon />
                                  )
                                ) : null
                              }
                              sx={{ padding: '16px' }}
                              onClick={!showMore ? () => onMenuClick(item.path) : undefined}>
                              {item.name}
                            </MenuButton>
                          )}

                          <Box
                            sx={
                              isActive && withSubMenu && activeSubMenu
                                ? classes.stackContainer
                                : { display: 'none' }
                            }>
                            <Stack direction='column' sx={{ paddingLeft: 0, gap: '2px' }}>
                              {item?.subMenu?.map((subItem, idx) => {
                                const isActive = activePath.includes(String(subItem.key));
                                const startIcon = isActive ? (
                                  subItem?.startIcon
                                ) : (
                                  <span
                                    style={{
                                      display: 'flex',
                                      color: '#C2C2C2',
                                      alignItems: 'center',
                                    }}>
                                    {subItem?.startIcon}
                                  </span>
                                );
                                return (
                                  <MenuButton
                                    key={`${subItem.key}-${idx}`}
                                    fade={showMore}
                                    style={{ padding: '12px 16px' }}
                                    fullWidth={true}
                                    onClick={
                                      !showMore ? () => onMenuClick(subItem.path) : undefined
                                    }
                                    startIcon={subItem?.startIcon ? startIcon : null}
                                    endIcon={subItem?.endIcon ? subItem.endIcon : null}>
                                    {subItem.name}
                                  </MenuButton>
                                );
                              })}
                            </Stack>
                          </Box>
                        </>
                      );
                    })
                  : null}
              </Box>
            </Box>
            <Stack direction='column' sx={{ paddingLeft: 0, gap: '2px' }}>
              <Box sx={classes.helperContainer} onClick={handleShowMore}>
                <Typo fontSize={'12px'} sx={{ color: '#000' }}>
                  Settings
                </Typo>
                <KeyboardDoubleArrowUpIcon sx={{ color: '#000', width: '20px', height: '20px' }} />
              </Box>
              {isStateUser && isPalmettoAdmin ? (
                <MenuButton
                  fullWidth
                  style={{ padding: '12px 16px' }}
                  startIcon={menuItems.section[0].startIcon}
                  endIcon={
                    activeSectionSubMenu ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />
                  }
                  onClick={() => onMenuClick(menuItems.section[0].path)}>
                  {menuItems.section[0].name}
                </MenuButton>
              ) : null}
              {!showMore && isStateUser && activeSectionSubMenu && (
                <JPDASubMenu
                  activeSectionSubMenu={activeSectionSubMenu}
                  closeActiveSectionSubMenu={(value) => setActiveSectionSubMenu(value)}
                />
              )}
              <MenuButton
                hidden={showMore}
                fullWidth
                style={{ padding: '12px 16px' }}
                startIcon={menuItems.section[1].startIcon}
                onClick={() => onMenuClick(menuItems.section[1].path)}>
                {userFullName}
              </MenuButton>
              <MenuButton
                hidden={showMore}
                fullWidth
                style={{ padding: '12px 16px' }}
                endIcon={menuItems.section[2].endIcon}
                onClick={() => onMenuClick(menuItems.section[2].path)}>
                {menuItems.section[2].name}
              </MenuButton>
              <MenuButton
                hidden={showMore}
                fullWidth
                style={{ padding: '12px 16px' }}
                endIcon={menuItems.section[3].endIcon}
                onClick={() => onMenuClick(menuItems.section[3].path)}>
                {menuItems.section[3].name}
              </MenuButton>
              <Box sx={{ textAlign: 'center' }} hidden={showMore}>
                <Typo fontWeight={500} fontSize={12} color={menuItems.version?.color}>
                  {menuItems.version.name}
                </Typo>
              </Box>
              {/* SHOW ALL BOTTOM SECTIONS */}
              <Box sx={classes.sectionGroupContainer} hidden={!showMore}>
                <Box sx={classes.helperContainer} onClick={handleShowMore}>
                  <Typo fontSize={'12px'} sx={{ color: '#000' }}>
                    Close Settings
                  </Typo>
                  <KeyboardDoubleArrowDownIcon
                    sx={{ color: '#000', width: '20px', height: '20px' }}
                  />
                </Box>
                {isStateUser ? (
                  <MenuButton
                    style={{ padding: '12px 16px' }}
                    fullWidth
                    startIcon={menuItems.section[0].startIcon}
                    endIcon={<KeyboardArrowDownIcon />}>
                    {menuItems.section[0].name}
                  </MenuButton>
                ) : null}
                {isStateUser ? (
                  <JPDASubMenu
                    activeSectionSubMenu={activeSectionSubMenu}
                    closeActiveSectionSubMenu={() => setShowMore(false)}
                  />
                ) : null}
                <MenuButton
                  style={{ padding: '12px 16px' }}
                  fullWidth
                  startIcon={userSectionInfo?.startIcon ? userSectionInfo.startIcon : null}>
                  {convertToTitleCase(userSectionInfo.name)}
                </MenuButton>
                <Box sx={classes.sectionSubGroupContainer}>
                  {userSectionInfo?.subMenu?.map((item, idx) => {
                    if (item.key === 'jpda' && !isStateUser) return null;
                    // if (item.name === 'Applicant' && !isStateUser) return null;
                    return (
                      <Box
                        key={idx}
                        sx={classes.sectionSubGroupBtn.container}
                        onMouseEnter={() => handleMouseOver(idx)}
                        onMouseLeave={() => handleMouseOut()}
                        onClick={() => handleSectionSubMenu(idx)}>
                        {getSectionSubGroupIcon(
                          idx,
                          !isHovered
                            ? classes.sectionSubGroupBtn.iconStyle
                            : activeHoverIdx === idx
                              ? classes.sectionSubGroupBtn.iconStyleHover
                              : classes.sectionSubGroupBtn.iconStyle
                        )}
                        <Box sx={classes.sectionSubGroupBtn.txtContainer}>
                          <Typo sx={classes.sectionSubGroupBtn.txtTitle}>
                            {convertToTitleCase(String(item?.key))}
                          </Typo>
                          <Typo sx={classes.sectionSubGroupBtn.txtSubTitle}>
                            {convertToTitleCase(item?.name)}
                          </Typo>
                        </Box>
                      </Box>
                    );
                  })}
                  <MenuButton
                    style={{ padding: '12px 16px' }}
                    fullWidth
                    endIcon={menuItems.section[2].endIcon}>
                    {menuItems.section[2].name}
                  </MenuButton>
                  <MenuButton
                    style={{ padding: '12px 16px' }}
                    fullWidth
                    endIcon={menuItems.section[3].endIcon}
                    onClick={() => onMenuClick(menuItems.section[3].path)}>
                    {menuItems.section[3].name}
                  </MenuButton>
                </Box>
                <Box sx={{ textAlign: 'center' }}>
                  <Typo fontWeight={500} fontSize={12} color={menuItems.version?.color}>
                    {menuItems.version.name}
                  </Typo>
                </Box>
              </Box>
            </Stack>
          </Box>
        </Box>
      </MuiDrawer>
      <FormProvider {...form}>
        {activeModal === FieldName.incident ? (
          <ModalSelect
            headerTitle={'Select Data Viewing Incident'}
            isSaving={processingData.incident}
            optionData={'incidentOptions'}
            onClose={handleModal}
            onConfirm={handleModalAction}
          />
        ) : null}
        {activeModal === FieldName.dataGathering ? (
          <ModalSelect
            headerTitle={'Select Data Gathering Incident'}
            isSaving={processingData.sync}
            optionData={'incidentOptions'}
            onClose={handleModal}
            onConfirm={handleModalAction}
          />
        ) : null}
        {activeModal === FieldName.group ? (
          <ModalSelect
            headerTitle={'Select Group'}
            isSaving={processingData.group}
            optionData={'groupOptions'}
            onClose={handleModal}
            onConfirm={handleModalAction}
          />
        ) : null}
        {activeModal === FieldName.applicants ? (
          <ModalSelect
            headerTitle={'Select Applicant'}
            isSaving={processingData.applicant}
            optionData={'applicantsOptions'}
            onClose={handleModal}
            onConfirm={handleModalAction}
            withFilter
          />
        ) : null}
      </FormProvider>
    </>
  );
};

export default DrawerMenu;
