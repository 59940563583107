import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AuthTokenInitialState, AuthTokenTypes } from './authToken.state.ts';
import { verifyToken } from 'store/feature/authToken/authToken.thunk.ts';
import { QueryState } from '@/common/enum';
import SitePermissionTypes from '@/common/enum/SitePermissionTypes.ts';

const initialState: AuthTokenTypes = AuthTokenInitialState;

export const authTokenSlice = createSlice({
  name: 'authToken',
  initialState,
  reducers: {
    setAuthCreds: (
      state,
      action: PayloadAction<{ id: string; userId: number; accessType: SitePermissionTypes }>
    ) => {
      state.token = action.payload.id;
      state.userId = action.payload.userId;
      state.accessType = action.payload.accessType;
    },
    setAuthToken: (state, action) => {
      state.token = action.payload;
    },
    setErrorMsg: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
    resetAuthToken: () => {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(verifyToken.pending, (state) => {
        state.token = null;
        state.tokenVerification = null;
        state.queryState = QueryState.AWAIT;
      })
      .addCase(verifyToken.fulfilled, (state, action) => {
        state.queryState = QueryState.READY;
        if (action.payload) state.tokenVerification = action.payload;
      })
      .addCase(verifyToken.rejected, (state, action) => {
        state.queryState = QueryState.FAIL;
        state.error = action.error.message || 'Something went wrong!';
      });
  },
});

export default authTokenSlice.reducer;
