import { createAsyncThunk } from '@reduxjs/toolkit';
import apiFetch, { genericErrorMessage } from '@/services/apiFetch.ts';
import type { RootState } from '@/store';
import { SitePermissionTypes, UserType } from '@/common/enum';
import {
  deletePADetailsData,
  getPADataSet,
  getPAGenInfoData,
  postPADetailsData,
} from '@/services/pa.endpoints.ts';
import { PAData, PADataSet, PAGenInfoSummary, PAShortSummary } from 'interfaces/pa.interface.ts';
import { PADetail } from 'interfaces/pa.detail.interface.ts';
import moment from 'moment-timezone';

moment.tz.setDefault('America/New_York');

export const fetchPADataSet = createAsyncThunk(
  'fetchPADataSet',
  async (payload: { category?: string; incidentID?: number } | undefined, thunkAPI) => {
    try {
      const store = thunkAPI.getState() as RootState;
      const { selectedGroup, selectedGroupID, allSubGroups, userType } = store.accountsInfo;
      const { selectedIncident } = store.incidents;
      if (!userType || !selectedIncident) return;
      const inc = payload?.incidentID ?? selectedIncident.pvIncidentID;
      let baseURL = '/api';
      const activeGroup = selectedGroup ? Number(selectedGroup) : selectedGroupID;
      let filters = `?filters[incident_id]=${inc}&filters[group_id]=${activeGroup}`;
      if (userType === UserType.STATE_USER) {
        baseURL += '/pa_state';
      } else {
        baseURL += '/pa';
      }
      if (payload?.category) {
        filters += `&filters[category]=${payload.category}`;
      }
      if (allSubGroups && allSubGroups.length) {
        allSubGroups.forEach((subgroup) => {
          filters += `&filters[group_id]=${subgroup.pvGroupID}`;
        });
      }
      baseURL += filters;
      const endpoint = getPADataSet(baseURL);
      const dataResp = await apiFetch<PADataSet>(endpoint).then((res) => res.data ?? null);
      return {
        dataset: dataResp?.dataset
          ? dataResp.dataset.map((data) => ({ ...data, dma_type: 'pa' }))
          : [],
      };
    } catch (error) {
      let message = genericErrorMessage;
      if (error instanceof Error) message = error.message;
      else {
        if (typeof error === 'object' && error !== null) {
          if ('message' in error) {
            message = String(error.message);
          }
        }
      }
      throw Error(message);
    }
  }
);

export const savePAData = createAsyncThunk('SAVE_PA_DATA', async (payload: PADetail, thunkAPI) => {
  try {
    const store = thunkAPI.getState() as RootState;
    const { selectedGroupID, selectedGroup, userType, account } = store.accountsInfo;
    const { selectedIncident } = store.incidents;
    const { accessType } = store.authToken;
    const { isJPDA_PA_Active } = store.incidents;
    const activeGroup = selectedGroup ? Number(selectedGroup) : selectedGroupID;
    if (!activeGroup || !userType || !selectedIncident || !account) return;
    // *: THE RULE IS:
    // When a PA entry is updated if county IS NOT IN JPDA active status,
    // update the corresponding pa_state entry.
    // pa_state editing -- it can only be done when the county IS IN JPDA active status
    let URL = '/api';
    let URLState = '/api/pa_state';
    let isNew = true;
    if (userType === UserType.STATE_USER) {
      if (
        !isJPDA_PA_Active &&
        (accessType === SitePermissionTypes.L || accessType === SitePermissionTypes.A)
      ) {
        URL += '/pa';
      } else {
        URL += '/pa_state';
      }
    } else URL += '/pa';

    if (payload?.id) {
      URL += `/${payload.id}`;
      URLState += `/${payload.id}`;
      isNew = false;
    }

    const endpoint_PA = postPADetailsData(URL, payload);
    const endpoint_PA_STATE = postPADetailsData(URLState, payload);
    if (
      userType === UserType.STATE_USER &&
      !isNew &&
      !isJPDA_PA_Active &&
      (accessType === SitePermissionTypes.L || accessType === SitePermissionTypes.A)
    ) {
      // *: Update pa_state entry
      await apiFetch<PADetail>(endpoint_PA_STATE).then((res) => res.data ?? null);
    }
    return await apiFetch<PADetail>(endpoint_PA).then((res) => res.data ?? null);
  } catch (error) {
    let message = genericErrorMessage;
    if (error instanceof Error) message = error.message;
    else {
      if (typeof error === 'object' && error !== null) {
        if ('message' in error) {
          message = String(error.message);
        }
      }
    }
    throw Error(message);
  }
});

export const deletePAData = createAsyncThunk(
  'DELETE_PA_DATA',
  async (payload: { id: string }, thunkAPI) => {
    try {
      const store = thunkAPI.getState() as RootState;
      const { userType } = store.accountsInfo;
      const { accessType } = store.authToken;
      const { isJPDA_PA_Active } = store.incidents;
      if (!userType) return;
      let URL = '/api';
      const URLState = `/api/pa_state/${payload.id}`;

      if (userType === UserType.STATE_USER) {
        if (
          !isJPDA_PA_Active &&
          (accessType === SitePermissionTypes.L || accessType === SitePermissionTypes.A)
        ) {
          URL += '/pa';
        } else {
          URL += '/pa_state';
        }
      } else URL += '/pa';

      URL += `/${payload.id}`;

      const endpoint = deletePADetailsData(URL);
      if (
        userType === UserType.STATE_USER &&
        !isJPDA_PA_Active &&
        (accessType === SitePermissionTypes.L || accessType === SitePermissionTypes.A)
      ) {
        await apiFetch<{ Success: boolean }>(deletePADetailsData(URLState)).then(
          (res) => res.data ?? null
        );
      }
      return await apiFetch<{ Success: boolean }>(endpoint).then((res) => res.data ?? null);
    } catch (error) {
      let message = genericErrorMessage;
      if (error instanceof Error) message = error.message;
      else {
        if (typeof error === 'object' && error !== null) {
          if ('message' in error) {
            message = String(error.message);
          }
        }
      }
      throw Error(message);
    }
  }
);

export const getPADataFiltered = createAsyncThunk(
  'GET_PA_DATA_FILTERED',
  async (id: string, thunkAPI) => {
    const store = thunkAPI.getState() as RootState;
    const filteredData = store.pa.paDataset?.dataset.filter((item) => item.id === id);
    return filteredData?.sort((a, b) => {
      const dateA = new Date(a.created_at);
      const dateB = new Date(b.created_at);
      return dateB.getTime() - dateA.getTime();
    });
  }
);

export const generateShortSummaryReport = createAsyncThunk(
  'GENERATE_SHORT_SUMMARY_REPORT',
  async (_, thunkAPI) => {
    try {
      const store = thunkAPI.getState() as RootState;
      const { selectedGroupID, selectedGroup, selectedGroupName, userType } = store.accountsInfo;
      const { selectedIncident } = store.incidents;
      const applicant = store.applicants.selectedApplicant;
      if (!userType || !selectedIncident) return;
      const activeGroup = selectedGroup ? Number(selectedGroup) : selectedGroupID;
      // PA Dataset
      const pa = userType === UserType.STATE_USER ? '/api/pa_state' : '/api/pa';
      let paURL = `${pa}?filters[incident_id]=${selectedIncident.pvIncidentID}&filters[group_id]=${activeGroup}`;
      if (applicant && applicant.id) {
        paURL += `&filters[applicant_id]=${applicant.id}`;
      }
      const paEndpoint = getPADataSet(paURL);
      // Short Form
      let sfURL = `/api/pasf/${selectedIncident.pvIncidentID}/${activeGroup}`;
      if (applicant && applicant.id) {
        sfURL += `?filters[applicant_id]=${applicant.id}`;
      }
      const sfEndpoint = getPAGenInfoData(sfURL);
      // General Summary
      const gsURL = `/api/pagis/${selectedIncident.pvIncidentID}/${activeGroup}`;
      const gsEndpoint = getPAGenInfoData(gsURL);

      const [paDataset, shortForm, generalSummary] = await Promise.all([
        apiFetch<{ count: number; dataset: PAData[] }>(paEndpoint).then((res) => res.data ?? null),
        apiFetch<{ data: Partial<PAShortSummary> }>(sfEndpoint).then((res) => res.data ?? null),
        apiFetch<PAGenInfoSummary>(gsEndpoint).then((res) => res.data ?? null),
      ]);

      const summaryPayload = Object.assign({}, shortForm?.data || {}, generalSummary || {}, {
        total_cat_team: 0,
        total_cat_applicant: 0,
        total_cat_total: 0,
      });
      summaryPayload['county'] = selectedGroupName;

      const body = {
        summary: summaryPayload,
        catA: {
          items: getCategory(paDataset.dataset && paDataset.dataset, 'A'),
          total_team: getCategoryTotals(paDataset && paDataset.dataset, 'A').team,
          total_applicant: getCategoryTotals(paDataset && paDataset.dataset, 'A').applicant,
          total: getCategoryTotals(paDataset && paDataset.dataset, 'A').total,
          date: new Date().toLocaleDateString('en-US'),
          county: summaryPayload.county,
          applicant_name: summaryPayload.applicant_name,
          applicant_phone: summaryPayload.applicant_phone,
          applicant_email: summaryPayload.applicant_email,
        },
        catB: {
          items: getCategory(paDataset && paDataset.dataset, 'B'),
          total_team: getCategoryTotals(paDataset && paDataset.dataset, 'B').team,
          total_applicant: getCategoryTotals(paDataset && paDataset.dataset, 'B').applicant,
          total: getCategoryTotals(paDataset && paDataset.dataset, 'B').total,
          date: new Date().toLocaleDateString('en-US'),
          applicant_name: summaryPayload.applicant_name,
          applicant_phone: summaryPayload.applicant_phone,
          applicant_email: summaryPayload.applicant_email,
        },
        catC: {
          items: getCategory(paDataset && paDataset.dataset, 'C'),
          total_team: getCategoryTotals(paDataset && paDataset.dataset, 'C').team,
          total_applicant: getCategoryTotals(paDataset && paDataset.dataset, 'C').applicant,
          total: getCategoryTotals(paDataset && paDataset.dataset, 'C').total,
          date: new Date().toLocaleDateString('en-US'),
          applicant_name: summaryPayload.applicant_name,
          applicant_phone: summaryPayload.applicant_phone,
          applicant_email: summaryPayload.applicant_email,
        },
        catD: {
          items: getCategory(paDataset && paDataset.dataset, 'D'),
          total_team: getCategoryTotals(paDataset && paDataset.dataset, 'D').team,
          total_applicant: getCategoryTotals(paDataset && paDataset.dataset, 'D').applicant,
          total: getCategoryTotals(paDataset && paDataset.dataset, 'D').total,
          date: new Date().toLocaleDateString('en-US'),
          applicant_name: summaryPayload.applicant_name,
          applicant_phone: summaryPayload.applicant_phone,
          applicant_email: summaryPayload.applicant_email,
        },
        catE: {
          items: getCategory(paDataset && paDataset.dataset, 'E'),
          total_team: getCategoryTotals(paDataset && paDataset.dataset, 'E').team,
          total_applicant: getCategoryTotals(paDataset && paDataset.dataset, 'E').applicant,
          total: getCategoryTotals(paDataset && paDataset.dataset, 'E').total,
          date: new Date().toLocaleDateString('en-US'),
          applicant_name: summaryPayload.applicant_name,
          applicant_phone: summaryPayload.applicant_phone,
          applicant_email: summaryPayload.applicant_email,
        },
        catF: {
          items: getCategory(paDataset && paDataset.dataset, 'F'),
          total_team: getCategoryTotals(paDataset && paDataset.dataset, 'F').team,
          total_applicant: getCategoryTotals(paDataset && paDataset.dataset, 'F').applicant,
          total: getCategoryTotals(paDataset && paDataset.dataset, 'F').total,
          date: new Date().toLocaleDateString('en-US'),
          applicant_name: summaryPayload.applicant_name,
          applicant_phone: summaryPayload.applicant_phone,
          applicant_email: summaryPayload.applicant_email,
        },
        catG: {
          items: getCategory(paDataset && paDataset.dataset, 'G'),
          total_team: getCategoryTotals(paDataset && paDataset.dataset, 'G').team,
          total_applicant: getCategoryTotals(paDataset && paDataset.dataset, 'G').applicant,
          total: getCategoryTotals(paDataset && paDataset.dataset, 'G').total,
          date: new Date().toLocaleDateString('en-US'),
          applicant_name: summaryPayload.applicant_name,
          applicant_phone: summaryPayload.applicant_phone,
          applicant_email: summaryPayload.applicant_email,
        },
      };

      body.summary.date = new Date().toLocaleDateString('en-US');
      if (body.summary.fiscal_year_start) {
        body.summary.fiscal_year_start = moment(body.summary.fiscal_year_start).format(
          'MM-DD-YYYY'
        );
      }

      // *: Calculate Totals
      body.summary.total_cat_team =
        body.catA.total_team +
        body.catB.total_team +
        body.catC.total_team +
        body.catD.total_team +
        body.catE.total_team +
        body.catF.total_team +
        body.catG.total_team;

      body.summary.total_cat_applicant =
        body.catA.total_applicant +
        body.catB.total_applicant +
        body.catC.total_applicant +
        body.catD.total_applicant +
        body.catE.total_applicant +
        body.catF.total_applicant +
        body.catG.total_applicant;

      body.summary.total_cat_total = body.summary.total_cat_applicant;

      return body;
    } catch (error) {
      let message = genericErrorMessage;
      if (error instanceof Error) message = error.message;
      else {
        if (typeof error === 'object' && error !== null) {
          if ('message' in error) {
            message = String(error.message);
          }
        }
      }
      throw Error(message);
    }
  }
);

export const generateCatReport = createAsyncThunk('GENERATE_CAT_REPORT', async (_, thunkAPI) => {
  try {
    const store = thunkAPI.getState() as RootState;
    const { selectedGroupID, selectedGroup, selectedGroupName, userType } = store.accountsInfo;
    const { selectedIncident } = store.incidents;
    if (!userType || !selectedIncident) return;
    const activeGroup = selectedGroup ? Number(selectedGroup) : selectedGroupID;
    const url = `/api/${userType === UserType.STATE_USER ? 'pa_state' : 'pa'}?filters[incident_id]=${selectedIncident.pvIncidentID}&filters[group_id]=${activeGroup}`;
    const endpoint = getPADataSet(url);
    const dataSet = await apiFetch<{ count: number; dataset: PAData[] }>(endpoint).then(
      (res) => res.data ?? null
    );

    const docData = {
      pvCountyName: selectedGroupName,
      pvIncidentCity: selectedGroupName,
      pvIncidentName: selectedIncident.pvIncidentName,
      pvIncidentDate: moment(selectedIncident.pvCreateDate).format('MM/DD/YY'),
      facilities: dataSet.dataset.map(populateFacilities),
      totalDebris: getCategoryTotals(dataSet && dataSet.dataset, 'A').total,
      totalProtective: getCategoryTotals(dataSet && dataSet.dataset, 'B').total,
      totalRoad: getCategoryTotals(dataSet && dataSet.dataset, 'C').total,
      totalWater: getCategoryTotals(dataSet && dataSet.dataset, 'D').total,
      totalBuildings: getCategoryTotals(dataSet && dataSet.dataset, 'E').total,
      totalPublic: getCategoryTotals(dataSet && dataSet.dataset, 'F').total,
      totalParks: getCategoryTotals(dataSet && dataSet.dataset, 'G').total,
      totalEstimated: 0,
      date: '',
    };

    docData.totalEstimated =
      docData.totalDebris +
      docData.totalProtective +
      docData.totalRoad +
      docData.totalWater +
      docData.totalBuildings +
      docData.totalPublic +
      docData.totalParks;

    docData.date = new Date().toLocaleDateString('en-US');
    return docData;
  } catch (error) {
    let message = genericErrorMessage;
    if (error instanceof Error) message = error.message;
    else {
      if (typeof error === 'object' && error !== null) {
        if ('message' in error) {
          message = String(error.message);
        }
      }
    }
    throw Error(message);
  }
});

function getCategory(arr: PAData[] = [], category: string) {
  const items: PAData[] = [];
  let count = 1;
  arr.forEach((item) => {
    if (item.category === category) {
      item.site_number_auto = count;
      items.push(item);
      count++;
    }
  });

  return items;
}

function getCategoryTotals(arr: PAData[] = [], category: string) {
  let total_team = 0;
  let total_applicant = 0;
  arr.forEach((item) => {
    if (item.category === category) {
      if (item.applicant_cost_estimate && !isNaN(item.applicant_cost_estimate)) {
        total_applicant += parseFloat(String(item.applicant_cost_estimate));
      }
      if (item.team_cost_estimate && !isNaN(item.team_cost_estimate)) {
        total_team += parseFloat(String(item.team_cost_estimate));
      }
    }
  });

  return {
    team: total_team,
    applicant: total_applicant,
    total: total_applicant,
  };
}

function populateFacilities(dataSet: PAData) {
  let returnObj = {};
  switch (dataSet.category) {
    case 'A':
      returnObj = {
        name: dataSet.address,
        description: dataSet.damage_description,
        debrisRemoval: Number(dataSet.team_cost_estimate) + dataSet.applicant_cost_estimate,
        estimatedTotal: Number(dataSet.team_cost_estimate) + dataSet.applicant_cost_estimate,
      };
      break;
    case 'B':
      returnObj = {
        name: dataSet.address,
        description: dataSet.damage_description,
        protectiveMeasures: Number(dataSet.team_cost_estimate) + dataSet.applicant_cost_estimate,
        estimatedTotal: Number(dataSet.team_cost_estimate) + dataSet.applicant_cost_estimate,
      };
      break;
    case 'C':
      returnObj = {
        name: dataSet.address,
        description: dataSet.damage_description,
        roads: Number(dataSet.team_cost_estimate) + dataSet.applicant_cost_estimate,
        estimatedTotal: Number(dataSet.team_cost_estimate) + dataSet.applicant_cost_estimate,
      };
      break;
    case 'D':
      returnObj = {
        name: dataSet.address,
        description: dataSet.damage_description,
        water: Number(dataSet.team_cost_estimate) + dataSet.applicant_cost_estimate,
        estimatedTotal: Number(dataSet.team_cost_estimate) + dataSet.applicant_cost_estimate,
      };
      break;
    case 'E':
      returnObj = {
        name: dataSet.address,
        description: dataSet.damage_description,
        buildings: Number(dataSet.team_cost_estimate) + dataSet.applicant_cost_estimate,
        estimatedTotal: Number(dataSet.team_cost_estimate) + dataSet.applicant_cost_estimate,
      };
      break;
    case 'F':
      returnObj = {
        name: dataSet.address,
        description: dataSet.damage_description,
        public: Number(dataSet.team_cost_estimate) + dataSet.applicant_cost_estimate,
        estimatedTotal: Number(dataSet.team_cost_estimate) + dataSet.applicant_cost_estimate,
      };
      break;
    case 'G':
      returnObj = {
        name: dataSet.address,
        description: dataSet.damage_description,
        parks: Number(dataSet.team_cost_estimate) + dataSet.applicant_cost_estimate,
        estimatedTotal: Number(dataSet.team_cost_estimate) + dataSet.applicant_cost_estimate,
      };
      break;
  }
  return returnObj;
}
