/* eslint-disable no-unused-vars */
enum DamageStructure {
  business = 'business',
  singleFamily = 'single family',
  multiFamily = 'multi family',
  manufacturedHome = 'manufactured home',
  mobileHome = 'mobile home',
}

enum BuildingDamage {
  affected = 'affected',
  destroyed = 'destroyed',
  major = 'major',
  majorDamage = 'major damage',
  minor = 'minor',
  minorDamage = 'minor damage',
  noDamage = 'no damage',
  inaccessible = 'inaccessible',
}

enum UtilitiesOut {
  noOutages = 'No Outages',
  Electricity = 'Electricity',
  Gas = 'Gas',
  Water = 'Water',
}

enum InsuranceTypes {
  condominium = 'Condominium',
  flood = 'Flood',
  hazardRider = 'Hazard Rider',
  homeOwners = 'Homeowners/Renters',
  manufacturedHome = 'Manufactured Home',
  unknown = 'Unknown',
  noInsurance = 'No Insurance',
}

enum YesNoSelect {
  YES = 'YES',
  NO = 'NO',
}

export { DamageStructure, BuildingDamage, UtilitiesOut, InsuranceTypes, YesNoSelect };
