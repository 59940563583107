import { Theme } from '@mui/material';

const useStyles = (theme: Theme) => ({
  modalContent: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '8px !important',
    minWidth: '320px',
    background: '#FFF',
  },
  content: {
    display: 'flex',
    width: '100%',
    height: 'auto',
    padding: '4px',
    minWidth: '596px',
    overflowY: 'auto',
    overflowX: 'hidden',
    background: '#FFF',
    pb: '100px',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
    height: 'auto',
    gap: '8px',
    padding: '8px',
    borderRadius: '8px',
    background: theme.palette.secondary.main,
  },
  header: {
    container: {
      display: 'flex',
      flexDirection: 'column',
      padding: '24px 16px',
      alignItems: 'flex-start',
      alignSelf: 'stretch',
      gap: '4px',
      flex: '1 0 0',
      background: '#FFF',
      borderTopLeftRadius: '8px',
      borderTopRightRadius: '8px',
    },
    title: {
      fontSize: '24px',
      fontWeight: 600,
    },
    subTitle: {
      color: theme.palette.grey[400],
      fontSize: '14px',
      fontWeight: 500,
    },
  },
});

export default useStyles;
