import React from 'react';
import Box from '@mui/material/Box';
import { ApplicationSpinner } from '@zawarski/palmetto-ui-components';
import useStyles from './Loading.styles.ts';

type LoadingProps = {
  message?: string;
};

const Loading: React.FC<LoadingProps> = ({ message = 'Loading, please wait...' }) => {
  const classes = useStyles();
  return (
    <Box sx={classes.container}>
      <ApplicationSpinner />
      <Box sx={classes.wrapper}>{message}</Box>
    </Box>
  );
};

export default Loading;
