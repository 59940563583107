import React, { lazy, Suspense } from 'react';
import { createBrowserRouter, createRoutesFromElements, Route } from 'react-router-dom';
import AuthPage from '@/auth';
import { MainLayout } from 'components/layout';
import { ErrorHandler } from 'components/fragment';
import LinearProgress from '@mui/material/LinearProgress';
import DashBoardPage from 'pages/Dashboard';
import IAListPage from 'pages/IA/Grid';
import EquipmentGridList, { loaderEquipmentDataSet } from 'pages/Equipment/Grid/Grid.loader.tsx';
import PersonnelGridList, { loaderPersonnelDataSet } from 'pages/Personnel/Grid/GridContainer.tsx';
import IADetailsContainer, { loaderDetails } from 'pages/IA/Details/DetailsContainer.tsx';
import EqDetailsContainer, { loaderEqDetails } from 'pages/Equipment/Details/DetailsContainer.tsx';
import PeDetailsContainer, {
  loaderPersonnelDetails,
} from 'pages/Personnel/Details/DetailsContainer.tsx';
import TeamsGridList, { loaderTeamsDataSet } from 'pages/Teams/Grid/GridContainer.tsx';
import TeamDetailsContainer, { loaderTeamsDetails } from 'pages/Teams/Details/DetailsContainer.tsx';
import MapPage, { loaderMapPage } from 'pages/MapPage/MapPageContainer.tsx';
import GenInfoContainer, {
  loaderGenInfoDetails,
} from 'pages/PublicAssistance/GenInfo/GenInfoLoader.tsx';
import ShortSummaryContainer, {
  loaderShortSummary,
} from 'pages/PublicAssistance/ShortSummary/ShortSummaryLoader.tsx';
import PAEditContainer, { loaderPADetails } from 'pages/PublicAssistance/Edit/Edit.loader.tsx';
import { PACategories } from '@/common/static';

import CategoryCostPage, { loaderCostData } from 'pages/Category/Cost/Cost.loader.tsx';
import CategoryLaborDetailsPage, {
  loaderLaborDetails,
} from 'pages/Category/LaborDetails/Detail.loader.tsx';
import CategoryEquipmentDetailsPage, {
  loaderCatBEqDetails,
} from 'pages/Category/EquipmentDetails/Detail.loader.tsx';
import CategoryMaterialsDetailsPage, {
  loaderMaterialDetails,
} from 'pages/Category/MaterialDetails/Detail.loader.tsx';
import CategoryRentalDetailsPage, {
  loaderRentalDetails,
} from 'pages/Category/RentalDetails/Detail.loader.tsx';
import CategoryContractsDetailsPage, {
  loaderContractDetails,
} from 'pages/Category/ContractDetails/Detail.loader.tsx';
import ApplicantsGridList, { loaderApplicantsDataSet } from 'pages/Applicants/Grid/Grid.loader.tsx';
import ApplicantsDetailsPage, {
  loaderApplicantsDetails,
} from 'pages/Applicants/Details/Details.loader.tsx';

const CategoryLaborPage = lazy(() => import('pages/Category/Labor'));
const CategoryEquipmentPage = lazy(() => import('pages/Category/Equipment'));
const CategoryMaterialPage = lazy(() => import('pages/Category/Material'));
const CategoryRentalPage = lazy(() => import('pages/Category/Rental'));
const CategoryContractsPage = lazy(() => import('pages/Category/Contracts'));

const RedirectPage = lazy(() => import('pages/Redirect'));
const LoginErrorPage = lazy(() => import('pages/LoginError'));
const LoginPage = lazy(() => import('pages/Login'));
const EQDetailsPage = lazy(() => import('pages/Equipment/Details/Details.tsx'));
const PersonnelDetailsPage = lazy(() => import('pages/Personnel/Details/Details.tsx'));
const IADetailsPage = lazy(() => import('pages/IA/Details/Details.tsx'));
const PAGridPage = lazy(() => import('pages/PublicAssistance/Grid'));
const PAEditPage = lazy(() => import('pages/PublicAssistance/Edit'));

const JSXRoutes = createBrowserRouter(
  createRoutesFromElements(
    <Route path='/' element={<AuthPage />}>
      <Route path='dashboard' element={<MainLayout />}>
        <Route index element={<DashBoardPage />} />
      </Route>
      <Route path='map' element={<MainLayout />}>
        <Route
          index
          loader={loaderMapPage}
          element={<MapPage />}
          errorElement={<ErrorHandler headerTitle={'Map'} errorMsg={'Something went wrong!'} />}
        />
      </Route>
      <Route path='equipment' element={<MainLayout />}>
        <Route index loader={loaderEquipmentDataSet} element={<EquipmentGridList />} />
        <Route
          path='new'
          element={
            <Suspense fallback={<LinearProgress />}>
              <EQDetailsPage newEntry={true} />
            </Suspense>
          }
        />
        <Route path=':id' loader={loaderEqDetails} element={<EqDetailsContainer />} />
      </Route>
      <Route path='personnel' element={<MainLayout />}>
        <Route index loader={loaderPersonnelDataSet} element={<PersonnelGridList />} />
        <Route
          path='new'
          element={
            <Suspense fallback={<LinearProgress />}>
              <PersonnelDetailsPage newEntry={true} />
            </Suspense>
          }
        />
        <Route path=':id' loader={loaderPersonnelDetails} element={<PeDetailsContainer />} />
      </Route>
      <Route path='ia' element={<MainLayout />}>
        <Route index element={<IAListPage />} />
        <Route
          path='new'
          element={
            <Suspense fallback={<LinearProgress />}>
              <IADetailsPage newEntry={true} />
            </Suspense>
          }
        />
        <Route path=':id' loader={loaderDetails} element={<IADetailsContainer />} />
      </Route>
      <Route path='teams' element={<MainLayout />}>
        <Route index loader={loaderTeamsDataSet} element={<TeamsGridList />} />
        <Route path='new' loader={loaderTeamsDetails} element={<TeamDetailsContainer />} />
        <Route path=':id' loader={loaderTeamsDetails} element={<TeamDetailsContainer />} />
      </Route>
      <Route path='pa' element={<MainLayout />}>
        <Route path='general-info' loader={loaderGenInfoDetails} element={<GenInfoContainer />} />
        <Route path='short-form' loader={loaderShortSummary} element={<ShortSummaryContainer />} />
        {PACategories.map((category) => [
          <Route
            key={category.path}
            path={category.path}
            element={
              <Suspense fallback={<LinearProgress />}>
                <PAGridPage
                  category={category.category}
                  title={category.title}
                  path={category.path}
                />
              </Suspense>
            }
          />,
          <Route
            key={`${category.path}/new`}
            path={`${category.path}/new`}
            element={
              <Suspense fallback={<LinearProgress />}>
                <PAEditPage
                  newEntry={true}
                  category={category.category}
                  path={category.path}
                  title={category.title}
                />
              </Suspense>
            }
          />,
          <Route
            key={`${category.path}/:id`}
            path={`${category.path}/:id`}
            loader={loaderPADetails}
            element={
              <PAEditContainer
                category={category.category}
                path={category.path}
                title={category.title}
              />
            }
          />,
        ])}
      </Route>
      <Route path='category' element={<MainLayout />}>
        <Route path='cost' loader={loaderCostData} element={<CategoryCostPage />} />
        <Route
          path='labor'
          element={
            <Suspense fallback={<LinearProgress />}>
              <CategoryLaborPage />
            </Suspense>
          }
        />
        <Route
          path='labor/new'
          loader={loaderLaborDetails}
          element={<CategoryLaborDetailsPage newEntry />}
        />
        <Route
          path='labor/:id'
          loader={loaderLaborDetails}
          element={<CategoryLaborDetailsPage newEntry={false} />}
        />
        <Route
          path='equipment'
          element={
            <Suspense fallback={<LinearProgress />}>
              <CategoryEquipmentPage />
            </Suspense>
          }
        />
        <Route
          path='equipment/new'
          loader={loaderCatBEqDetails}
          element={<CategoryEquipmentDetailsPage newEntry />}
        />
        <Route
          path='equipment/:id'
          loader={loaderCatBEqDetails}
          element={<CategoryEquipmentDetailsPage newEntry={false} />}
        />
        <Route
          path='materials'
          element={
            <Suspense fallback={<LinearProgress />}>
              <CategoryMaterialPage />
            </Suspense>
          }
        />
        <Route
          path='materials/new'
          loader={loaderMaterialDetails}
          element={<CategoryMaterialsDetailsPage newEntry />}
        />
        <Route
          path='materials/:id'
          loader={loaderMaterialDetails}
          element={<CategoryMaterialsDetailsPage newEntry={false} />}
        />
        <Route
          path='rental'
          element={
            <Suspense fallback={<LinearProgress />}>
              <CategoryRentalPage />
            </Suspense>
          }
        />
        <Route
          path='rental/new'
          loader={loaderRentalDetails}
          element={<CategoryRentalDetailsPage newEntry />}
        />
        <Route
          path='rental/:id'
          loader={loaderRentalDetails}
          element={<CategoryRentalDetailsPage newEntry={false} />}
        />
        <Route
          path='contracts'
          element={
            <Suspense fallback={<LinearProgress />}>
              <CategoryContractsPage />
            </Suspense>
          }
        />
        <Route
          path='contracts/new'
          loader={loaderContractDetails}
          element={<CategoryContractsDetailsPage newEntry />}
        />
        <Route
          path='contracts/:id'
          loader={loaderContractDetails}
          element={<CategoryContractsDetailsPage newEntry={false} />}
        />
      </Route>
      <Route path='applicants' element={<MainLayout />}>
        <Route index loader={loaderApplicantsDataSet} element={<ApplicantsGridList />} />
        <Route
          path='new'
          loader={loaderApplicantsDetails}
          element={<ApplicantsDetailsPage newEntry />}
        />
        <Route
          path=':id'
          loader={loaderApplicantsDetails}
          element={<ApplicantsDetailsPage newEntry={false} />}
        />
      </Route>
      <Route
        path='login'
        element={
          <Suspense fallback={<LinearProgress />}>
            <LoginPage />
          </Suspense>
        }
      />
      <Route
        path='login-error'
        element={
          <Suspense fallback={<LinearProgress />}>
            <LoginErrorPage />
          </Suspense>
        }
      />
      <Route path='*' element={<RedirectPage />} />
    </Route>
  )
);

export default JSXRoutes;
