/* eslint-disable no-unused-vars */
import { RadioSelectOptions } from 'interfaces/SelectOptions.interface.ts';
import { useForm } from 'react-hook-form';

export enum FieldName {
  incident = 'incident',
  group = 'group',
  dataGathering = 'dataGathering',
  applicants = 'applicants',
}

export type SideMenuActionType = {
  incidentOptions: RadioSelectOptions[];
  groupOptions: RadioSelectOptions[];
  applicantsOptions: RadioSelectOptions[];
  incident: string | number;
  group: string | number;
  applicants: string;
  dataGathering: string | number;
  isOpen: boolean;
  fieldName: FieldName;
  filterName?: string;
};

export const defaultValues: SideMenuActionType = {
  incidentOptions: [],
  groupOptions: [],
  applicantsOptions: [],
  dataGathering: '',
  incident: '',
  group: '',
  applicants: '',
  isOpen: false,
  fieldName: FieldName.incident,
  filterName: '',
};

function useSideMenuForm() {
  return useForm({
    defaultValues,
  });
}

export default useSideMenuForm;
