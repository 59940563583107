import { Outlet, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks.ts';
import { verifyToken } from 'store/feature/authToken/authToken.thunk.ts';
import {
  selectAuthTokenError,
  selectAuthTokenQueryState,
  selectAuthTokenVerification,
} from 'store/feature/authToken/authToken.selector.ts';
import { setAuthCreds } from 'store/feature/authToken/authToken.action.ts';
import { setAccount, setLoadingState } from 'store/feature/accountsInfo/accountsInfo.actions.ts';
import { QueryState } from '@/common/enum';
import { ErrorMessage, Loading } from 'components/primitive';
import { GetSitePermission } from 'utils/index.ts';
import SitePermissionTypes from '@/common/enum/SitePermissionTypes.ts';

const AuthPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const idToken = searchParams.get('id_token');
  const urlPath = location.pathname;
  const dispatch = useAppDispatch();
  const authTokenVerification = useAppSelector(selectAuthTokenVerification);
  const authTokenError = useAppSelector(selectAuthTokenError);
  const isLoading = useAppSelector(selectAuthTokenQueryState) === QueryState.AWAIT;
  const isError = useAppSelector(selectAuthTokenQueryState) === QueryState.FAIL;
  const isReady = useAppSelector(selectAuthTokenQueryState) === QueryState.READY;
  const [hasError, setHasError] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState('Something went wrong, please try again later.');

  useEffect(() => {
    if (authTokenError) {
      setHasError(true);
      return setErrorMsg(authTokenError);
    }
    if (isError && !authTokenError) {
      setHasError(true);
      return setErrorMsg('Something went wrong, please try again later.');
    }
  }, [authTokenError, isError]);
  useEffect(() => {
    if (idToken) {
      dispatch(setLoadingState(true));
      dispatch(verifyToken(idToken));
    }
  }, [dispatch, idToken]);

  useEffect(() => {
    if (
      isReady &&
      authTokenVerification &&
      authTokenVerification.attributes &&
      authTokenVerification.accessToken
    ) {
      let accessType;
      const groupId = authTokenVerification.pvActiveGroupID;
      const sitePermission = GetSitePermission('DMA', groupId, authTokenVerification.attributes);
      const permissions = sitePermission.sitePermissions;
      // *: Permission Type
      switch (permissions && permissions[0]) {
        case 'A':
          accessType = SitePermissionTypes.A;
          break;
        case 'F':
          accessType = SitePermissionTypes.F;
          break;
        case 'U':
          accessType = SitePermissionTypes.U;
          break;
        case 'L':
          accessType = SitePermissionTypes.L;
          break;
        default:
          accessType = null;
      }

      if (!accessType) {
        setHasError(true);
        setErrorMsg("You don't have access to the Damage Assessment App.");
        return;
      }

      const userAuthCreds = {
        id: authTokenVerification.accessToken,
        userId: authTokenVerification.id,
        accessType: accessType,
      };
      sessionStorage.setItem('userAuthCreds', JSON.stringify(userAuthCreds));
      dispatch(setAccount(authTokenVerification));
      dispatch(setAuthCreds(userAuthCreds));
      return navigate('/dashboard');
    }
    navigate('/login');
  }, [authTokenVerification, dispatch, isReady, navigate]);

  useEffect(() => {
    if (isReady && urlPath === '/' && !authTokenVerification) return navigate('/login');
    if ((!isReady || !isLoading) && urlPath === '/' && !authTokenVerification)
      return navigate('/login');
  }, [authTokenVerification, isReady, isLoading, navigate, urlPath]);

  if (isLoading) {
    return <Loading message={'Authentication in process, please wait...'} />;
  }

  if (hasError) {
    return <ErrorMessage message={errorMsg} />;
  }

  return <Outlet />;
};

export default AuthPage;
