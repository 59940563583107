import React from 'react';
import { CircularProgress } from '@mui/material';
import MuiButton, { ButtonProps as MuiButtonProps } from '@mui/material/Button';

interface ButtonProps extends MuiButtonProps {
  alternate?: boolean;
  neutral?: boolean;
  progress?: boolean;
}

function ProgressIcon() {
  return <CircularProgress color='inherit' size={22} sx={{ mr: 0.5 }} />;
}

const Button: React.FC<ButtonProps> = ({
  alternate,
  neutral,
  progress = false,
  ...props
}: ButtonProps) => {
  return (
    <MuiButton
      {...props}
      variant={alternate ? 'outlined' : 'contained'}
      color={neutral ? 'secondary' : props.color}
      startIcon={!progress && props.startIcon}
      endIcon={progress ? <ProgressIcon /> : props.endIcon}
      disableElevation>
      {props.children}
    </MuiButton>
  );
};

export default Button;
