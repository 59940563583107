const useStyles = () => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  text: {
    fontSize: '14px',
    fontWeight: 600,
  },
  icon: {
    width: '14px',
    height: '14px',
  },
});

export default useStyles;
