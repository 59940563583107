import React from 'react';
import useStyles from './StatusCell.styles.ts';
import { PostingOptions, PostingTheme } from '@/common/enum';
import Box from '@mui/material/Box';
import { convertToTitleCase } from 'utils/index.ts';

type StatusCellProps = {
  value?: string | PostingOptions;
};

const StatusCell: React.FC<StatusCellProps> = ({ value }) => {
  const classes = useStyles();
  const style = {
    backgroundColor: value
      ? value.toLowerCase() === PostingOptions.Unsafe.toLowerCase()
        ? PostingTheme.Unsafe
        : value.toLowerCase() === PostingOptions.RestrictedUse.toLowerCase()
          ? PostingTheme.RestrictedUse
          : value.toLowerCase() === PostingOptions.Inspected.toLowerCase()
            ? PostingTheme.Inspected
            : 'inherit'
      : 'inherit',
  };

  return <Box sx={[classes.container, style]}>{value ? convertToTitleCase(value) : ''}</Box>;
};

export default StatusCell;
