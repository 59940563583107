import { Theme } from '@mui/material';
import { styled } from '@mui/material/styles';

const useStyles = (theme: Theme) => ({
  menuWrapper: {
    height: '100%',
    width: '326px',
    borderRadius: '8px',
    backgroundColor: '#F7F7F7',
    boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.16)',
    paddingY: '1.5rem',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '100%',
    color: theme.palette.common.black,
    padding: '12px',
  },
  title: {
    fontSize: '14px',
    fontWeight: 600,
    color: 'rgba(0, 0, 0, 0.88)',
  },
  menuTitle: {
    color: '#707070',
    fontSize: '14px',
    fontWeight: 500,
    py: '8px',
  },
  upperContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  menuContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '1.5rem',
    width: '100%',
    position: 'relative',
    gap: '2px',
  },
  stackContainer: {
    overflow: 'hidden',
    display: 'flex',
  },
  groupContainer: {
    maxHeight: '720px',
    overflowY: 'auto',
  },
  groupTitle: {
    textAlign: 'left',
    color: 'rgba(0, 0, 0, 0.5)',
  },
  menuBtn: {
    whiteSpace: 'wrap',
    maxWidth: '200px',
    overflow: 'hidden',
    textAlign: 'left',
    // textOverflow: 'ellipsis',
  },
  activeIcon: {
    color: theme.palette.primary.main,
  },
  inActiveIcon: {
    color: '#C2C2C2',
  },
  helperContainer: {
    display: 'flex',
    width: '290px',
    justifyContent: 'flex-end',
    alignItems: 'center',
    py: '4px',
    gap: '4px',
    cursor: 'pointer',
    borderRadius: '4px',
    border: `1px solid ${theme.palette.grey[100]}`,
    background: theme.palette.secondary.main,
  },
  sectionSubMenu: {
    container: {
      display: 'flex',
      flexDirection: 'column',
      padding: '8px',
      alignItems: 'center',
      gap: '8px',
      alignSelf: 'stretch',
      borderRadius: '4px',
      background: '#F0F0F0',
    },
    content: {
      display: 'flex',
      // padding: '8px',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-start',
      gap: '8px',
      alignSelf: 'stretch',
    },
    row: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-start',
      alignSelf: 'stretch',
    },
    btnGrp: {
      display: 'flex',
      flexDirection: 'row',
      gap: '4px',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
    },
  },
  sectionBtn: {
    display: 'flex',
    padding: '6px 8px',
    alignItems: 'center',
    gap: '10px',
    flex: '1 0 0',
    alignSelf: 'stretch',
    borderRadius: '2px',
    background: '#FFF',
    overflow: 'hidden',
    justifyContent: 'space-between',
    cursor: 'pointer',
  },
  sectionBtnTxt: {
    color: theme.palette.primary.main,
    fontSize: '12px',
    fontWeight: 600,
    textOverflow: 'ellipsis',
  },
  sectionGroupContainer: {
    position: 'absolute',
    bottom: 20,
    zIndex: 10,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    bgcolor: '#F7F7F7',
  },
  sectionSubGroupContainer: {
    display: 'flex',
    padding: '12px 8px 8px 8px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    gap: '2px',
    alignSelf: 'stretch',
    borderRadius: '4px',
    background: '#F0F0F0',
  },
  sectionSubGroupBtn: {
    container: {
      display: 'flex',
      padding: '8px',
      alignItems: 'center',
      gap: '16px',
      cursor: 'pointer',
      width: '100%',
      ':hover': {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.primary.main,
      },
    },
    iconStyle: {
      color: theme.palette.grey[500],
    },
    iconStyleHover: {
      color: theme.palette.primary.main,
    },
    txtContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    txtTitle: {
      fontSize: '12px',
      fontWeight: 600,
      color: '#474747',
    },
    txtSubTitle: {
      fontSize: '12px',
      fontWeight: 400,
      color: '#707070',
    },
  },
  modal: {
    content: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '16px',
      padding: '16px',
      maxHeight: '600px',
      minWidth: '450px',
      overflowY: 'scroll',
      overflowX: 'hidden',
      width: '100%',
    },
    controllerWrapper: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    radioGroupWrapper: {
      border: '1px solid #E0E0E0',
      borderRadius: '4px',
      paddingRight: '8px',
      marginLeft: 'unset',
      width: '100%',
      my: '4px',
    },
    footer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      padding: '16px',
      mt: '10px',
    },
    footerWrapper: {
      display: 'flex',
      width: '100%',
      gap: '8px',
    },
    labelWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      minWidth: '320px',
    },
    textWrapper: {
      display: 'flex',
      alignItems: 'flex-start',
      flex: '1 0 0',
    },
  },
});

const Img = styled(`img`)`
  width: 80px;
  object-fit: contain;
  mix-blend-mode: multiply;
`;

export { Img };

export default useStyles;
